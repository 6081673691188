import React from 'react';
import Select from 'react-select';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import injectSheet from 'react-jss';
import { thunks } from 'services/thunks';
import { getDeliveryZone } from '../../../../../selectors/getDeliveryZone';
import { getDriverWorkingZone } from 'selectors/getDriverWorkingZone';
import { deliveryZoneDropDown } from '../../../../styles/Driver/Inventory/deliveryZoneDropDown';
import {
  dropDownStyles,
  dropDownTheme,
} from '../../../../styles/Driver/Inventory/dropDown';

const mapStateToProps = state => ({
  deliveryZones: getDeliveryZone(state),
  currentZone: getDriverWorkingZone(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeDeliveryZone: thunks.changeDeliveryZone,
    },
    dispatch,
  );

class _DeliveryZoneDropDown extends React.Component {
  onChange = ({ value }) => {
    this.props.changeDeliveryZone(value);
  };

  render() {
    const { classes, deliveryZones, currentZone } = this.props;
    return (
      <div className={classes.container}>
        <Select
          onChange={this.onChange}
          isSearchable={false}
          options={deliveryZones}
          value={currentZone}
          placeholder="Select area"
          theme={dropDownTheme}
          styles={dropDownStyles}
        />
      </div>
    );
  }
}

export const DeliveryZoneDropDown = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(deliveryZoneDropDown),
)(_DeliveryZoneDropDown);
