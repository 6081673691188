import React from 'react';
import injectSheet from 'react-jss';
import { AddressTransition } from '../../organisms/Customer/Profile/AddressTransition';
import { AddAddress } from '../../atoms/Customer/Profile/AddAddress';
import { addresses as addressesStyle } from '../../../styles/Customer/CustomerProfile/addresses';

const _Addresses = ({ classes, addresses, canSelect, admin }) => (
  <div className={classes.container}>
    {addresses.map(address => (
      <AddressTransition
        key={address.get('id')}
        id={address.get('id')}
        data={address}
        enableDelete={addresses.size > 1}
        canSelect={canSelect}
        admin={admin}
      />
    ))}
    {addresses.size < 3 && <AddAddress admin={admin} />}
  </div>
);

export const Addresses = injectSheet(addressesStyle)(_Addresses);
