import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { push } from 'connected-react-router';
import { Dashboard } from './Dashboard';
import { ProductsTable } from 'ui/components/ecosystems/Admin/Products/ProductsTable';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { AddButton } from '../../atoms/Commons/Admin/AddButton';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';
import { AddEditDriver } from '../../organisms/Admin/Driver/AddEditDriver';

const mapStateToProps = state => ({
  isModalOpen: state.getIn(['app', 'modals', 'addDriver']),
});

const mapDispatchToProps = dispatch => ({
  addProduct: () => dispatch(push('/admin/products/add')),
});

const _Products = ({ t, addDriver, isModalOpen, closeModal, addProduct }) => (
  <>
    <Dashboard>
      <PageRow>
        <PageTitle title={t('Products')}>
          <AddButton text={t('Add New Item')} handleClick={addProduct} />
        </PageTitle>
      </PageRow>
      <ProductsTable />
    </Dashboard>
    <AddEditDriver
      submit={addDriver}
      isOpenModal={isModalOpen}
      closeModal={closeModal}
    />
  </>
);

export const Products = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_Products);
