import React from 'react';
import { bindActionCreators, compose } from 'redux';
import injectSheet from 'react-jss';
import { thunks } from 'services/thunks';
import { NavLink } from 'react-router-dom';
import { Category } from 'ui/components/organisms/Customer/Products/Category';
import connect from 'react-redux/es/connect/connect';
import { withNamespaces } from 'react-i18next';

import { products as productsStyles } from '../../../styles/Customer/CustomerHome/products';
import { actions } from '../../../../store/actions';



const mapStateToProps = state => ({
  isAuth: state.getIn(['app', 'isAuth']),
  deliveryZoneId: state.getIn(['app', 'user', 'deliveryZoneId']),
  products: state.getIn(['app', 'products']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProducts: thunks.getProducts,
      setProductQuantity: actions.setProductQuantity.act,
    },
    dispatch,
  );

class _Products extends React.PureComponent {
  componentDidMount() {
    this.props.getProducts(this.props.deliveryZoneId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryZoneId !== prevProps.deliveryZoneId){
      this.props.getProducts(this.props.deliveryZoneId);
    }
  }


  render() {
    const {
      classes,
      products,
      isAuth,
      isLoading,
      setProductQuantity,
    } = this.props;
    if (isLoading) return <div>Loading...</div>;
    return (
      <div className={classes.container}>
        <div className={classes.placeOrderText}>
          <span>Choose Your Bottles</span>
          <br/>
          Our drivers are waiting! 
          <br/>
          <span style={{ fontSize: '14px' }}><strong>Need Help?</strong> <span style={{ color: 'blue'}}><a href="/customer/help">Go here</a></span></span>
          <br/>
        </div>
        {/* <div className={classes.placeOrderText}>
        	<a href='#spirits' className={classes.linkToProducts}>Spirits</a>
        	<a href='#beer' className={classes.linkToProducts}>Beer</a>
        	<a href='#wine' className={classes.linkToProducts}>Wine</a>
        </div> */}
        <div id='order'/>
        {products.get('categoryList').map(id => (
          <Category
            key={id}
            id={id}
            category={products.getIn(['categoryMap', id])}
            items={products.get('items')}
            setProductQuantity={setProductQuantity}
          />
        ))}
      </div>
    );
  }
}

export const Products = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  injectSheet(productsStyles),
)(_Products);
