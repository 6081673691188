import React from 'react';
import injectSheet from 'react-jss';
import { tooltipPriceList } from 'ui/styles/Admin/Products/tooltipPriceList';

const _TooltipOrderItemsList = ({ classes, items }) => (
  <div className={classes.tooltipPrices}>
    {items.map(item => (
      <span key={item.name}>{`${item.quantity} ${item.name}`}</span>
    ))}
  </div>
);

export const TooltipOrderItemsList = injectSheet(tooltipPriceList)(
  _TooltipOrderItemsList,
);
