import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const editDeliveryZone = (id, { name, postalCodes, header }) =>
  privateAxios({
    method: 'put',
    baseURL: adminAPI,
    url: `/deliveryzone/${id}`,
    data: {
      name,
      postalCodes,
      header,
    },
  });
