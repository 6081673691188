import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';
import defaultHeader from '../../constants/defaultHeader';
import isEqual from 'lodash/isEqual';

export const addEditDeliveryZoneConfig = {
  mapPropsToValues: ({ name, postalCodes, header }) => ({
    name: name || '',
    postalCodes: postalCodes || [],
    header: header || defaultHeader,
  }),
  validationSchema: Yup.object().shape({
    name: validators.name,
    postalCodes: validators.postalCodes,
  }),
  handleSubmit: (
    { name, postalCodes, header },
    { props: { submit, closeModal, header: initialHeader } },
  ) => {
    submit(
      {
        name,
        postalCodes: postalCodes.map(x => ({ code: x.value })),
        header: isEqual(header, initialHeader || defaultHeader) ? null : header,
      },
      closeModal,
    );
  },
  displayName: 'addDeliveryZone',
};
