import React, { useCallback, Fragment } from 'react';
import { Field } from 'formik';
import { Button, IconButton, TextField, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import get from 'lodash/get';
import last from 'lodash/last';
import injectSheet from 'react-jss';

import { TimePicker } from 'ui/components/atoms/Commons/Admin/TimePicker';
import { daysOfWeek } from '../../../../constants';
import { operationalHours } from 'ui/styles/Admin/TimeSettings/operationalHours';

const _OperationalHours = ({ push, remove, classes, form, name }) => {
  const operationalHours = form.values[name];
  const errors = form.errors[name];
  const addDay = useCallback(
    () => {
      const { dayOfWeek, from, to } = last(operationalHours) || {
        dayOfWeek: 0,
        from: { hours: 22 },
        to: { hours: 7 },
      };
      push({
        dayOfWeek: (dayOfWeek + 1) % 7,
        from,
        to,
      });
    },
    [operationalHours, push],
  );
  const normalizeTime = useCallback(
    moment => ({
      hours: moment.get('hour'),
      minutes: moment.get('minute'),
    }),
    [],
  );

  return (
    <div className={classes.operationalHoursWrapper}>
      {typeof errors === 'string' && (
        <div className={classes.error}>{errors}</div>
      )}
      {operationalHours.map((item, index) => (
        <Fragment key={index}>
          <div className={classes.operationalHours}>
            <Field
              name={`operationalHours[${index}].dayOfWeek`}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  className={classes.dayPicker}
                  name={name}
                  select
                  label="On"
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                >
                  {daysOfWeek.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Field
              component={TimePicker}
              name={`operationalHours[${index}].from`}
              label="From"
              className={classes.timePickerSmall}
              normalize={normalizeTime}
            />
            <Field
              component={TimePicker}
              name={`operationalHours[${index}].to`}
              label="To"
              className={classes.timePickerSmall}
              normalize={normalizeTime}
            />
            <IconButton
              onClick={() => remove(index)}
              className={classes.buttonRemove}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          {typeof errors !== 'string' && get(errors, index) && (
            <div className={classes.itemError}>{errors[index]}</div>
          )}
        </Fragment>
      ))}
      <Button
        color="primary"
        variant="contained"
        onClick={addDay}
        className={classes.buttonAdd}
      >
        {'ADD'}
      </Button>
    </div>
  );
};

export const OperationalHours = injectSheet(operationalHours)(
  _OperationalHours,
);
