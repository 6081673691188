import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const editDriver = (id, data) => async dispatch => {
  try {
    await api.editDriver(id, data);
    dispatch(actions.editDriver.act({ id, ...data }));
  } catch (e) {
    return e;
  }
};
