import { push } from 'connected-react-router';
import { api } from 'services/api';
import { getInventoryForUpdate } from 'selectors/getInventoryForUpdate';
import { setErrorToStore } from 'services/utils';

export const updateInventory = () => async (dispatch, getState) => {
  const state = getState();
  const deliveryZoneId = state.getIn(['app', 'user', 'deliveryZoneId']);
  if (!deliveryZoneId) {
    setErrorToStore('You need to choose delivery zone from list', dispatch);
    return;
  }
  const inventory = getInventoryForUpdate(state);
  try {
    await api.updateInventory(inventory);
    dispatch(push('/orderboard'));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
