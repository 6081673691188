import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import { thunks } from 'services/thunks';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { ModalWithButtons } from 'ui/components/organisms/Admin/Commons/ModalWithButtons';
import { addCustomerConfig } from '../../../../configs/formik/addCustomerConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { TextArea } from '../../../atoms/Commons/Client/TextArea';
import { AddressField } from 'ui/components/organisms/Commons/AddressField';
import injectSheet from 'react-jss';
import { addCustomer } from '../../../../styles/Admin/Customers/addCustomer';

const mapStateToProps = state => ({
  isAddressSelected: state.getIn(['app', 'validations', 'addressSelected']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      geocodeAddress: thunks.geocodeAddress,
    },
    dispatch,
  );

const _AddCustomer = ({
  touched,
  errors,
  classes,
  closeModal,
  isOpenModal,
  geocodeAddress,
  handleSubmit,
}) => (
  <ModalWithButtons
    onClose={closeModal}
    isOpen={isOpenModal}
    onClick={handleSubmit}
  >
    <h1 className={classes.title}>Add New Customer</h1>
    <Form className={classes.form}>
      <Field
        component={Input}
        type="text"
        name="firstName"
        placeholder="First Name"
        labelText="Name"
        error={touched.firstName && errors.firstName}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="text"
        name="lastName"
        placeholder="Last Name"
        error={touched.lastName && errors.lastName}
        $container={classes.inputHalfContainerWithoutLabel}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={PhoneInput}
        type="text"
        name="phone"
        labelText="Phone"
        error={touched.phone && errors.phone}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="text"
        name="email"
        placeholder="example@gmail.com"
        labelText="Email"
        error={touched.email && errors.email}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={AddressField}
        type="text"
        name="address"
        labelText="Address"
        placeholder="Start to type your address"
        error={touched.address && errors.postalCode}
        geocodeAddress={geocodeAddress}
      />
      <Field
        component={Input}
        type="text"
        name="boozer"
        placeholder="Unit Number and Buzzer"
        error={touched.boozer && errors.boozer}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="text"
        name="nearestIntersection"
        labelText="Nearest main intersection"
        placeholder="Main and Broad"
        error={touched.nearestIntersection && errors.nearestIntersection}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={TextArea}
        name="instructions"
        labelText="Instructions"
        placeholder="eg. Meet in lobby, Name for concierge, Basement or back, etc."
        error={touched.instructions && errors.instructions}
        $container={classes.textareaContainer}
        $label={classes.inputLabel}
        $textarea={classes.textarea}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="password"
        name="password"
        placeholder="Setup a password"
        labelText="Password"
        error={touched.password && errors.password}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="password"
        name="confirmPassword"
        placeholder="Confirm password"
        error={touched.confirmPassword && errors.confirmPassword}
        $container={classes.inputHalfContainerWithoutLabel}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
  </ModalWithButtons>
);

export const AddCustomer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withFormik(addCustomerConfig),
  injectSheet(addCustomer),
)(_AddCustomer);
