import { fromJS } from 'immutable';
import createAction from 'utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.setIn(['driver', 'activeOrders'], fromJS(data));

export const updateActiveOrders = createAction({
  action: ['updateActiveOrders', 'data'],
  handler,
});
