import { fromJS } from 'immutable';
import { createAction } from 'redux-actum';

const getProducts = products => ({
  ...products,
  items: products.items.map(item => ({
    ...item,
    productPrices: item.productPrices.sort((a, b) => a.price - b.price),
  })),
});

const handler = (state, { products }) =>
  state.mergeIn(['admin', 'products'], fromJS(getProducts(products)));

export const adminSetProducts = createAction({
  action: ['adminSetProducts', 'products'],
  handler,
});
