import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const blockCustomer = (id, reason) =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: `/customer/block/${id}`,
    data: {
      reason,
    },
  });
