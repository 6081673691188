import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const addAddressConfig = {
  mapPropsToValues: () => ({
    address: '',
    postalCode: '',
    validDeliveryArea: true,
    latitude: '',
    longitude: '',
    boozer: '',
    nearestIntersection: '',
    instructions: '',
  }),
  validationSchema: Yup.object().shape({
    address: validators.address,
    validDeliveryArea: Yup.bool(),
    postalCode: validators.addressPostalCode,
    boozer: validators.boozer,
    nearestIntersection: validators.intersection,
    instructions: validators.instructions,
  }),
  handleSubmit: (values, { props: { addAddress, closeModal } }) => {
    addAddress(values, closeModal);
  },
  displayName: 'addAddress',
};
