import React, { Fragment } from 'react';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import { AppTopBar } from 'ui/components/ecosystems/Customer/AppTopBar';
import { BackButton } from 'ui/components/atoms/Commons/Client/BackButton';
import { withContainer } from 'ui/hoc/withContainer';
import { terms } from 'ui/styles/Driver/Terms/terms';

const _Terms = ({ classes }) => (
  <Fragment>
    <AppTopBar login signup />
    <BackButton from="/" />
    <div className={classes.container}>
      <h1>Terms and conditions</h1>
      <p>
        Please read carefully and completely the following terms and conditions
        laid out by the Boo Zapp web site (Boo Zapp, We or Us) and be aware that by that by
        doing so and accepting these terms you are hereby entering a legal
        agreement of service with Boo Zapp and any deviation from these terms
        will suspend this agreement and suspend service by Boo Zapp.
      </p>
      <p>
        1) I agree that I am at least 19 years old. I am aware that I am
        required to provide legal government-issued identification which
        includes a photograph of the Eligible Individual and states my date of
        birth (“Required Documentation”) for inspection. Failure to do so will
        result in refusal of service. Driver has ultimate discretion wether to
        grant service or not.
      </p>
      <p>
        2) I agree that I am fully able and legally competent enough to both
        agree to this license and to fulfill my role in any transaction
        resulting from use of Boo Zapp.
      </p>
      <p>
        3) I agree that i will not use Boo Zapp on behalf those
        unable to agree to either 1 or 2.
      </p>
      <p>
        4) I am aware that aggressive, rude, abusive or intimidating behaviour
        may result in termination of transaction or complete suspension of use
        of Boo Zapp.
      </p>
      <p>
        5) I am aware that if I appear intoxicated the the driver will refuse to
        complete the transaction.
      </p>
      <p>
        6) FAILING TO PROVIDE PAYMENT IN FULL AND WITHIN A REASONABLE AMOUNT OF
        TIME UPON DRIVERS ARRIVAL WILL NULLIFY TRANSACTION.
      </p>
      <p>
        7) CUSTOMER SHALL BE AT THE ADDRESS FROM WHICH THEY PLACED THEIR ORDER,
        AND NOT EXPECT THE DRIVER TO WAIT FOR CUSTOMER TO ARRIVE. FAILURE TO
        ANSWER CALLS FROM DRIVER MAY RESULT IN INCOMPLETE DELIVERY. DRIVERS
        CANNOT BE EXPECTED TO DRIVE CUSTOMER TO ATM OR ANYWHERE ELSE.
      </p>
      <p>
        BOO ZAP IS A SERVICE FOR PEOPLE IN PRIVATE LOCATIONS. CERTAIN LOCATIONS
        WILL NOT BE SERVICED
      </p>
      <p>
        HE OR SHE WILL NOT BE SERVED IN A PARK, PARKING LOT, ON THE STREET,
        SCHOOL, REHAB CENTRE, OR ANY AREA OR FACILITY WHERE ALCOHOL IS
        PROHIBITED.
      </p>
      <p>
        BOO ZAPP WILL NOT DELIVER TO A BAR, RESTAURANT OR LICENSED ESTABLISHMENT.
      </p>
      <p>
        BOO ZAPP PROVIDES A PLATFORM FOR THE PURPOSE OF CONNECTING BUYERS WITH
        SELLERS. BOO ZAPP IS NOT DIRECTLY INVOLVED IN THE SALE OF ALCOHOL IN ANY
        WAY. THE TRANSACTION IS SOLELY BETWEEN THE DRIVER AND THE CUSTOMER.
      </p>
      <p>
        THEREFORE, BOO ZAPP ABSOLVES ITSELF FROM ANY DAMAGES OR DISPUTES THAT MAY
        ARRISE BETWEEN DRIVER AND CUSTOMER.
      </p>
      <p>
        BOO ZAPP AND USER AGREE TO INDEMNIFY AND HOLD ONE ANOTHER HARMLESS
        AGAINST ANY CLAIMS OF LOSS OR DAMAGE ARISING FROM THE DELIVERY.
      </p>
      <p>
        DRIVER SHALL BE CONSIDERED AN INDEPENDENT CONTRACTOR OF CUSTOMER. NO FORMAL
        CONTRACT IS IMPLIED OR ESTABLISHED BETWEEN DRIVER AND CUSTOMER OR BETWEEN
        BOO ZAPP AND DRIVER. BOTH BOO ZAPP AND CUSTOMER WILL TREAT ALL INFORMATION
        RELATED TO THIS SERVICE AS PROPRIETARY AND CONFIDENTIAL. NO INFORMATION
        SHALL BE DISCLOSED TO THIRD PARTIES WITHOUT APPROVAL FROM BOTH BOO ZAPP
        AND CUSTOMER.
      </p>
      <p>
        CUSTOMER AGREES TO KEEP OTHERS FROM USING HIS OR HER ACCOUNT AND TO KEEP
        PASSWORD SECURE.
      </p>
      <p>
        BY CHECKING THIS BOX I HEREBY AGREE THAT I HAVE READ, ACCEPT, AND FULLY
        UNDERSTAND ALL OF THE ABOVE CONDITIONS.
      </p>
    </div>
  </Fragment>
);

export const Terms = compose(
  withContainer(),
  injectSheet(terms),
)(_Terms);
