import React from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { checkPhonePopup } from 'ui/styles/Customer/CompleteOrder/checkPhonePopup';
import { formatPhoneNumber } from 'react-phone-number-input';

const _ResendCheckPhonePopup = ({ classes, isOpen, onClick, value }) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
  >
    <h1 className={classes.title}>Thanks, check your texts</h1>
    <p className={classes.text}>
      Please check that <strong>{formatPhoneNumber(value)}</strong> is the
      correct number.
    </p>
    <button type="button" onClick={onClick} className={classes.singleButton}>
      OK
    </button>
  </Modal>
);

export const ResendCheckPhonePopup = compose(
  withNamespaces(),
  injectSheet(checkPhonePopup),
)(_ResendCheckPhonePopup);
