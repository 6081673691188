import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const addCustomer = ({ ...data }) => async dispatch => {
  try {
    await api.addCustomer(data);
    dispatch(actions.closeModal.act('addCustomer'));
    dispatch(actions.needToUpdateData.act(true));
  } catch (e) {
    return e;
  }
};
