import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { thunks } from '../../../../../services/thunks';
import { editCustomerInfoConfig } from '../../../../configs/formik/editCustomerInfoConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { editCustomerInfo } from '../../../../styles/Customer/CustomerProfile/editCustomerInfo';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editCustomerInfo: thunks.editCustomerInfo,
    },
    dispatch,
  );

const _EditCustomerInfoForm = ({ classes, errors, touched, toggleEdit }) => (
  <div className={classes.container}>
    <Form className={classes.form}>
      <Field
        component={Input}
        type="text"
        name="firstName"
        labelText="First Name"
        error={touched.firstName && errors.firstName}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="text"
        name="lastName"
        labelText="Last Name"
        error={touched.lastName && errors.lastName}
        $container={classes.inputHalfContainerRight}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={PhoneInput}
        type="text"
        name="phone"
        labelText="Phone"
        error={touched.phone && errors.phone}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="text"
        name="email"
        labelText="Email"
        error={touched.email && errors.email}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="password"
        name="oldPassword"
        labelText="Old Password"
        error={touched.oldPassword && errors.oldPassword}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="password"
        name="newPassword"
        labelText="New Password"
        error={touched.newPassword && errors.newPassword}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="password"
        name="confirmNewPassword"
        labelText="Confirm New Password"
        error={touched.confirmNewPassword && errors.confirmNewPassword}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <div className={classes.buttons}>
        <button type="submit" className={classes.submit}>
          Save changes
        </button>
        <button type="button" onClick={toggleEdit} className={classes.cancel}>
          Cancel
        </button>
      </div>
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
  </div>
);

export const EditCustomerInfoForm = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withFormik(editCustomerInfoConfig),
  injectSheet(editCustomerInfo),
)(_EditCustomerInfoForm);
