import { media } from 'ui/styles/theme';

export const inventory = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    [media.mobile]: {
      alignItems: 'center',
    }
  },
};
