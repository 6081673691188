import get from 'lodash/get';

import { getCurrentPostalCode, cookieControlledStorage } from '../../../utils';
import { api, errorCodes } from '../../api';
import { actions } from '../../../store/actions';
import { handleDeliveryZoneChoice } from './handleDeliveryZoneChoice';

export const detectDeliveryZone = () => async dispatch => {
  try {
    let value = cookieControlledStorage.getItem('deliveryZoneId');
    if (value) {
      return;
    }

    const postalCode = await getCurrentPostalCode();
    if (!postalCode) {
      dispatch(actions.openModal.act('chooseArea', null));
      return;
    }

    try {
      const response = await api.findByPostalCode(postalCode);
      value = response.data;
    } catch (e) {
      if (get(e, ['response', 'data', 'errorCode']) == errorCodes.NotFound) {
        dispatch(actions.openModal.act('chooseArea', postalCode));
        return;
      }
      throw e;
    }

    dispatch(handleDeliveryZoneChoice({ value }));
  } catch (e) {
    return e;
  }
};
