import { media } from 'ui/styles/theme';

export const card = {
  container: {
    color: '#898989',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    userSelect: 'none',
    '& > span': {
      marginLeft: '1rem',
      [media.mobile]: {
        marginLeft: '1rem',
      },
    },
    '& > span:last-child': {
      color: '#111111',
      fontSize: '1.125rem',
      fontWeight: 500,
    },
  },
  icon: {
    height: ({ brand }) => {
      if (brand === 'Visa') return '3rem';
      if (brand === 'MasterCard') return '2.25rem';
      return '1.75rem';
    },
    [media.mobile]: {
      height: '1.5rem!important',
    },
  },
};
