import { formatPhone } from 'utils';
import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const addCustomerConfig = {
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    postalCode: '',
    validDeliveryArea: true,
    latitude: '',
    longitude: '',
    boozer: '',
    nearestIntersection: '',
    instructions: '',
    password: '',
    confirmPassword: '',
  }),
  validationSchema: Yup.object().shape({
    firstName: validators.firstName,
    lastName: validators.lastName,
    phone: validators.phone,
    email: validators.email,
    address: validators.address,
    validDeliveryArea: Yup.bool(),
    postalCode: validators.addressPostalCode,
    boozer: validators.boozer,
    nearestIntersection: validators.intersection,
    instructions: validators.instructions,
    password: validators.password,
    confirmPassword: validators.confirmPassword,
  }),
  handleSubmit: (
    values,
    { props: { addCustomer }, setErrors, setSubmitting },
  ) => {
    addCustomer(
      { ...values, phone: formatPhone(values.phone) },
      setErrors,
      setSubmitting,
    );
  },
  displayName: 'AddCustomer',
};
