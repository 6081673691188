import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { id }) =>
  state.updateIn(['tables', 'orders', 'items'], list =>
    list.map(el => {
      if (el.id === id) {
        let obj = el;
        obj.status = 'Canceled';
        return obj;
      }
      return el;
    }),
  );

export const adminCancelOrder = createAction({
  action: ['adminCancelOrder', 'id'],
  handler,
});
