import React, { useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { geProductFormValues } from 'selectors/geProductFormValues';
import { ProductForm } from 'ui/components/ecosystems/Admin/AddProduct/ProductForm';
import { thunks } from 'services/thunks';
import { Dashboard } from 'ui/components/environments/Admin/Dashboard';
import { PageRow } from 'ui/components/atoms/Commons/Admin/PageRow';
import { PageTitle } from 'ui/components/atoms/Commons/Admin/PageTitle';

const mapStateToProps = state => ({
  product: geProductFormValues(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProduct: thunks.getProduct,
      editProduct: thunks.editProduct,
    },
    dispatch,
  );

const _EditProduct = ({ t, match, getProduct, editProduct, product }) => {
  useEffect(() => {
    if (!product) {
      getProduct(match.params.id);
    }
  }, []);

  return (
    <Dashboard>
      <PageRow>
        <PageTitle title={t('Edit Item')} />
      </PageRow>
      {product && <ProductForm onSubmit={editProduct} product={product} />}
    </Dashboard>
  );
};

export const EditProduct = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_EditProduct);
