import { createSelector } from 'reselect';
import moment from 'moment';

export const getTimeForCompletedOrders = createSelector(
  time => time,
  time => {
    const t = moment(time);
    const timeNow = moment(new Date());
    const moreThenDay = timeNow.diff(t, 'hours') > 23;
    return moment(time).format(moreThenDay ? 'l LT' : 'LT');
  }
);
