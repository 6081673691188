import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import injectSheet from 'react-jss';
import { addressField } from 'ui/styles/Commons/addressField';

class _AddressField extends Component {
  handleChange = address => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;
    setFieldValue(name, address + '\n');
    setFieldValue('postalCode', '');
    setFieldValue('validDeliveryArea', true);
  };

  handleSelect = async (address, placeId) => {
    const {
      form: { setFieldValue, setFieldError },
      field: { name },
      geocodeAddress,
    } = this.props;
    geocodeAddress(placeId, address, name, setFieldValue, setFieldError);
  };

  handleError = status => {
    const {
      form: { setFieldError },
      field: { name },
    } = this.props;
    setFieldError(name, status);
  };

  handleBlurSpecial = () => {
    const {
      form: { setFieldError, setFieldTouched, errors },
      field: { name },
    } = this.props;
    setFieldTouched(name, true, !errors[name]);
    setFieldError(name, errors[name]);
  };

  render() {
    const {
      field: { name, value },
      classes,
      type,
      labelText,
      error,
      placeholder,
    } = this.props;
    // const { address } = this.state;
    return (
      <PlacesAutocomplete
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        shouldFetchSuggestions={value.length > 1}
        debounce={500}
		searchOptions={{
          componentRestrictions: {country: 'ca'}
        }}
            >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={classes.inputContainer}>
            <label className={classes.label} htmlFor={name}>
              {labelText}
              {<span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
            </label>
            <input
              {...getInputProps()}
              id={name}
              onBlur={this.handleBlurSpecial}
              placeholder={placeholder}
              className={classes.input}
              type={type}
            />
            {suggestions.length > 0 && (
              <div className={classes.suggestionContainer}>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    className={
                      suggestion.active
                        ? classes.activeSuggestion
                        : classes.suggestion
                    }
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ))}
                    <span align="right"><img align="right" src={require('../../../images/powered_by_google_on_white.png')}/></span>
              </div>
            )}
            {error && <span className={classes.error}>{error}</span>}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export const AddressField = injectSheet(addressField)(_AddressField);
