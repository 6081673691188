import * as Yup from 'yup';

const checkSpaces = /[a-zA-Z0-9]/;

export const editDataPassword = Yup.string()
  .notRequired()
  .matches(
    checkSpaces,
    'Password should contain at least one number or one latin letter',
  )
  .min(8, 'Password should be more than 8 symbols')
  .max(15, 'Password should be less than 15 symbols');
