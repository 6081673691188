import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const blockCustomer = ({ id, reason }) => async dispatch => {
  try {
    await api.blockCustomer(id, reason);
    dispatch(
      actions.updateCustomer.act({ id, isBlocked: true, blockReason: reason }),
    );
  } catch (e) {
    return e;
  }
};
