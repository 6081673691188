import React from 'react';
import { Field, getIn } from 'formik';
import { TextField } from '@material-ui/core';
import { getProductPrice } from '../../../../../selectors/getProductRowPrice';
import { PriceMenuItemField } from './PriceMenuItemField';

const quantities = [6, 12, 18, 24, 36, 48, 60, 72];

export const PriceQuantityField = ({
  form: { touched, errors },
  field: { value, name },
  label,
  className,
  InputProps,
}) => {
  let error = getIn(touched, name) && getIn(errors, name);
  if (error && typeof (error) !== 'string') {
    error = error.filter(x => !!x && !!x.value)[0].value;
  }

  return (
    <TextField
      error={!!error}
      className={className}
      name={name}
      select
      label={error || label}
      value={Object.values(value).filter(x => !!x && !x.disabled).map(x => x.value)}
      variant="outlined"
      SelectProps={{
        multiple: true,
        renderValue: x => getProductPrice(x),
      }}
    >
      {quantities.map((x, i) => (
        <Field
          key={i}
          name={`${name}[${x}]`}
          label={`x${x}`}
          component={PriceMenuItemField}
          autoFocus={i === 0}
          isLast={i === quantities.length - 1}
          InputProps={InputProps}
        />
      ))}
    </TextField>
  );
};
