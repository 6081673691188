import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const validateOrderModal = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    fontFamily: 'Roboto',
    [media.mobile]: {
      width: '90%',
      height: '500px',
      maxWidth: '400px',
      overflowY: 'scroll',
    },
    [media.desktop]: {
      width: '800px',
    },
  },
  titleContainer: {
    width: '90%',
    textAlign: 'center',
    fontWeight: 500,
    [media.mobile]: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      marginTop: '3rem',
      '& > span:nth-child(1)': {
        textTransform: 'uppercase',
        textAlign: 'center',
        width: '100%',
        display: 'block',
      },
    },
    [media.desktop]: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
      marginTop: '3rem',
    },
  },
  errors: {
    fontSize: '1.125rem',
    color: '#363636',
    margin: '2.5rem 0',
    lineHeight: '2.5rem',
    [media.mobile]: {
      width: '90%',
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: '1.5rem',
      margin: '1.5rem 0',
      '& > p': {
        margin: '1rem 0',
      },
    },
  },
  instructions: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '1.5rem',
    [media.mobile]: {
      width: '90%',
      display: 'block',
    },
    '& > h2': {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '3rem',
      [media.mobile]: {
        textAlign: 'center',
        width: '100%',
      },
    },
    '& > p': {
      width: '100%',
      color: '#444343',
      fontWeight: 300,
    },
    '& > p > span': {
      fontWeight: 500,
    },
    '& > span': {
      lineHeight: '2rem',
      color: '#444343',
      fontWeight: 500,
      [media.mobile]: {
        display: 'block',
        textAlign: 'center',
        width: '100%',
      },
    },
  },
  buttons: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3.75rem 0',
    [media.mobile]: {
      width: '90%',
      margin: '2rem 0 4rem 0',
    },
  },
  cancel: {
    ...button.white,
  },
  ok: {
    ...button.yellow,
  },
};

