import axios from 'axios';
import qs from 'qs';
import { commonAPI } from 'services/api/apiUrl';

export const login = ({ email, password }, role) =>
  axios({
    method: 'post',
    url: `${commonAPI}/auth/token`,
    data: qs.stringify({
      access_scope: 'offline',
      grant_type: role,
      username: email,
      password,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
