import axios from 'axios';
import { publicAPI } from 'services/api/apiUrl';

export const createOrder = (
  {
    firstName,
    lastName,
    phone,
    email,
    address,
    latitude,
    longitude,
    boozer,
    nearestIntersection,
    instructions,
    deliveryZoneId,
  },
  orderItems,
) =>
  axios({
    method: 'post',
    url: `${publicAPI}/order`,
    data: {
      firstName,
      lastName,
      phone,
      email,
      address,
      latitude,
      longitude,
      boozer,
      nearestIntersection,
      instructions,
      orderItems,
      deliveryZoneId,
    },
  });
/*
{
  "deliveryAddress": "string",
  "name": "string",
  "email": "string",
  "phone": "string",
  "nearestIntersection": "string",
  "boozer": "string",
  "note": "string",
  "latitude": 0,
  "longitude": 0,
  "tipAmount": 0,
  "taxAmount": 0,
  "orderItems": [
    {
      "productId": 0,
      "quantity": 0
    }
  ]
}
 */
