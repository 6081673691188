import { media } from 'ui/styles/theme';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: '24rem',
    minHeight: '60vh',
  },
  background: {
    width: '100vw',
    backgroundColor: 'white',
    filter: 'brightness(0.5)',
  },
  content: {
    fontFamily: 'Roboto',
    width: '1030px',
    margin: 'auto',
    [media.mobile]: {
      width: '90vw',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
    color: 'white',
  },
});
