import axios from 'axios';
import { publicAPI } from 'services/api/apiUrl';

export const sendRestorePasswordEmail = ({ resetPasswordEmail }, role) =>
  axios({
    method: 'post',
    url: `${publicAPI}/account/sendrestorepasswordemail`,
    data: {
      email: resetPasswordEmail,
      accountType: role,
    },
  });
