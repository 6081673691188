import { getActiveDeliveryZone } from 'selectors/getActiveDeliveryZone';
import { api } from 'services/api';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { getPostalCode } from 'services/utils';
import { store } from 'store';

export const geocodeAddress = (
  placeId,
  address,
  name,
  setFieldValue,
  setFieldError,
) => async () => {
  const state = store.getState();
  const activeZone = getActiveDeliveryZone(state);
  try {
    const response = await geocodeByPlaceId(placeId);
    const postalCode = getPostalCode(response[0]);
    const zoneIdRes = await api.findByPostalCode(postalCode);
    // If address zone id doesn't match with chosen delivery zone, set error
    /*if (+zoneIdRes.data !== +activeZone.value) {
      setFieldError(
        name,
        `Sorry but your address doesn't in the
        ${activeZone.label} ,change your region on the main page`,
      );
      return;
    }*/
    const { lat, lng } = await getLatLng(response[0]);
    setFieldValue(name, address);
    setFieldValue('postalCode', postalCode);
    setFieldValue('latitude', lat);
    setFieldValue('longitude', lng);
    setFieldValue('deliveryZoneId', zoneIdRes.data);
  } catch (e) {
    console.log(e.response);
    setFieldValue('validDeliveryArea', false);
    // setFieldError('postalCode', 'Sorry, but currently we not support your address');
  }
};
