import React, { useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { EditHeader } from '../../../organisms/Admin/DeliveryZones/EditHeader';
import Editor from '../../../organisms/Commons/RichText/Editor';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

const useStyles = createUseStyles({
  field: {
    cursor: 'pointer !important',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: 135,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: 14,
  },
  scaled: {
    transform: 'scale(0.24)',
    display: 'inline-flex !important',
    margin: '0 -50% !important',
    pointerEvents: 'none',

    '& > img': {
      width: '1920px !important',
      height: '562px !important',
      minHeight: 'unset !important',
    },

    '& .rdw-editor-main': {
      height: '562px !important',
      minHeight: 'unset !important',
    },
  },
});

const HeaderPreview = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Editor value={value} className={classes.scaled} readOnly={true} />
    </div>
  );
};

const InputProps = { inputComponent: HeaderPreview };
const InputLabelProps = { shrink: true };

export const HeaderField = ({
  field: { name, value, onChange },
  className,
  label,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const openEditor = useCallback(() => setIsOpen(true), []);

  const closeEditor = useCallback(() => setIsOpen(false), []);

  const handleOnChange = useCallback(
    newValue => {
      if (!isEqual(value, newValue)) {
        onChange({ target: { type: 'text', name, id: name, value: newValue } });
      }
    },
    [name, value, onChange],
  );

  return (
    <>
      <TextField
        className={clsx(classes.field, className)}
        name={name}
        label={label}
        onClick={openEditor}
        variant="outlined"
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        inputProps={{ value }}
      />

      <EditHeader
        isOpen={isOpen}
        closeModal={closeEditor}
        value={value}
        onChange={handleOnChange}
      />
    </>
  );
};
