import React from 'react';
import { getIn } from 'formik';
import { TextField } from '@material-ui/core';

class _FormikInputField extends React.PureComponent {
  onChange = e => {
    const { form, field } = this.props;
    form.setFieldValue(field.name, e.target.value);
  };

  render() {
    const {
      form: { touched, errors },
      field: { value, name, onBlur },
      label,
      className,
      disabled,
      InputProps,
      multiline,
      rows,
      rowsMax,
    } = this.props;
    const error = getIn(touched, name) && getIn(errors, name);
    return (
      <TextField
        error={!!error}
        id={name}
        value={value == null ? '' : value}
        label={error || label}
        variant="outlined"
        onChange={this.onChange}
        onBlur={onBlur}
        className={className}
        disabled={disabled}
        InputProps={InputProps}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
      />
    );
  }
}

export const FormikInputField = _FormikInputField;
