import React from 'react';
import { ListIcon } from 'ui/components/atoms/icons/ListIcon';
import injectSheet from 'react-jss';
import { noOrders } from 'ui/styles/Driver/OrderBoard/noOrders';

const _NoOrders = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.wrapper}>
      <ListIcon className={classes.icon} />
      <span>The list is empty</span>
    </div>
  </div>
);

export const NoOrders = injectSheet(noOrders)(_NoOrders);
