import React, { Component } from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withStyles, TableRow, TableCell } from '@material-ui/core';
import StarRatings from 'react-star-ratings';
import { customerRow } from 'ui/styles/Admin/TableRow';
import { CustomerActions } from '../../../molecules/Admin/CustomerActions/CustomerActions';
import { NameCell } from '../../../atoms/Commons/Admin/NameCell';
import { thunks } from '../../../../../services/thunks';

const mapDispatchToProps = dispatch => ({
  setRating: (id, rate) => dispatch(thunks.setCustomerRating(id, rate)),
});

class _CustomerRow extends Component {
  changeRating = rate => {
    this.props.setRating(this.props.item.id, rate);
  };

  render() {
    const {
      item: { firstName, lastName, rate, id, isBlocked, blockReason },
      classes,
    } = this.props;

    const name = `${firstName} ${lastName}`;

    return (
      <TableRow>
        <TableCell>
          <NameCell name={name} blocked={isBlocked} reason={blockReason} />
        </TableCell>
        <TableCell classes={{ root: classes.wrap }}>
          <StarRatings
            rating={rate}
            starDimension="20px"
            starSpacing="5px"
            starRatedColor="orange"
            changeRating={!isBlocked ? this.changeRating : null}
          />
        </TableCell>
        <CustomerActions id={id} isBlocked={isBlocked} name={name} />
      </TableRow>
    );
  }
}

const CustomerRow = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(customerRow),
)(_CustomerRow);
export default CustomerRow;
