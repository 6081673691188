import React from 'react';
import injectSheet from 'react-jss';
import { tab } from '../../../../styles/Commons/tab';

const _Tab = ({ classes, active, Icon, text, onClick }) => (
  <div
    role="presentation"
    onClick={onClick}
    className={`${classes.container} ${active && classes.active}`}
  >
    {active && Icon && <Icon className={classes.icon} />}
    <span>{text}</span>
  </div>
);

export const Tab = injectSheet(tab)(_Tab);
