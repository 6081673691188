import { media, palette } from 'ui/styles/theme';

export const button = {
  yellow: {
    width: '110px',
    height: '40px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.8rem',
    fontWeight: '500',
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: palette.white,
    backgroundColor: palette.yellow,
    border: `1px solid ${palette.yellow}`,
    [media.desktop]: {
      width: '10rem',
      height: '3.125rem',
      fontSize: '1rem',
    },
  },
  white: {
    width: '110px',
    height: '40px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.8rem',
    fontWeight: '500',
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    backgroundColor: palette.white,
    color: palette.yellow,
    border: `1px solid ${palette.yellow}`,
    [media.desktop]: {
      width: '10rem',
      height: '3.125rem',
      fontSize: '1rem',
    },
  },
};
