import { push } from 'connected-react-router';
import { api } from 'services/api';
import { getRefuseReason } from 'services/utils';
import { actions } from 'store/actions';
import { removeActiveOrder } from 'utils';

export const refuseOrder = (values, id, reasons) => async dispatch => {
  const reason = getRefuseReason(values, reasons);
  try {
    await api.refuseOrder(id, reason);
    removeActiveOrder();
    dispatch(actions.setData.act(['activeOrder'], null));
    dispatch(push('/orderboard'));
  } catch (e) {
    return e;
  }
};
