import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const setNewPasswordConfig = {
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
  }),
  validationSchema: Yup.object().shape({
    password: validators.password,
    confirmPassword: validators.confirmPassword,
  }),
  handleSubmit: (
    values,
    {
      props: {
        setNewPassword,
        location: { search },
      },
      setErrors,
      setSubmitting,
    },
  ) => {
    setNewPassword(values, search, setErrors, setSubmitting);
  },
  displayName: 'SetNewPassword',
};
