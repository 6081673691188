import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { id, value }) =>
  state.withMutations(next =>
    next
      .setIn(['products', 'items', id, 'currentQuantity'], value)
      .update('orderList', list => {
        if (list.includes(id) && value > 0) {
          return list;
        }
        if (!list.includes(id) && value > 0) {
          return list.push(id);
        }
        if (value === 0) {
          return list.filter(item => item !== id);
        }
        return list;
      }),
  );

export const setProductQuantity = createAction({
  action: ['setProductQuantity', 'id', 'value'],
  handler,
});
