import { formatPhone, getPhoneForForm } from 'utils';
import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const editDriverInfoConfig = {
  mapPropsToValues: ({ user }) => ({
    firstName: user.get('firstName'),
    lastName: user.get('lastName'),
    phone: getPhoneForForm(user.get('phone')),
    email: user.get('email'),
    driverLicense: user.get('driverLicense'),
    smartserve: user.get('smartserve') || '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }),
  validationSchema: Yup.object().shape({
    firstName: validators.firstName,
    lastName: validators.lastName,
    phone: validators.phone,
    email: validators.email,
    oldPassword: validators.oldPassword,
    newPassword: validators.newPassword,
    confirmNewPassword: validators.confirmNewPassword,
  }),
  handleSubmit: (
    values,
    { props: { toggleEdit, editDriverInfo }, setErrors, setSubmitting },
  ) => {
    editDriverInfo(
      { ...values, phone: formatPhone(values.phone) },
      toggleEdit,
      setErrors,
      setSubmitting,
    );
  },
  displayName: 'EditDriverInfoForm',
};
