export const getPendingOrder = () => localStorage.getItem('pendingOrder');

export const setPendingOrder = id => localStorage.setItem('pendingOrder', id);

export const removePendingOrder = () => localStorage.removeItem('pendingOrder');

export const getActiveOrder = () => localStorage.getItem('activeOrder');

export const setActiveOrder = id => localStorage.setItem('activeOrder', id);

export const removeActiveOrder = () => localStorage.removeItem('activeOrder');

export const formatPhone = phone => phone;

export const getPhoneForForm = (phone = '') => phone;

export * from './secretOrderHelper';
export * from './googleMaps';
export * from './cookieControlledStorage';
export * from './priceHelper';
