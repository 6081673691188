import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const customerAddressConfig = {
  mapPropsToValues: ({
    address,
    postalCode,
    latitude,
    longitude,
    boozer,
    nearestIntersection,
    instructions,
  }) => ({
    address,
    postalCode: postalCode || '',
    validDeliveryArea: true,
    latitude: latitude || '',
    longitude: longitude || '',
    boozer: boozer || '',
    nearestIntersection,
    instructions: instructions || '',
  }),
  validationSchema: Yup.object().shape({
    address: validators.address,
    validDeliveryArea: Yup.bool(),
    postalCode: validators.addressPostalCode,
    boozer: validators.boozer,
    nearestIntersection: validators.intersection,
    instructions: validators.instructions,
  }),
  handleSubmit: (
    values,
    { props: { toggleEdit, editCustomerAddress, id } },
  ) => {
    editCustomerAddress(values, id, toggleEdit);
  },
  displayName: 'editCustomerInfo',
};
