import get from 'lodash/get';
import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import injectSheet from 'react-jss';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { DriverTopBar } from '../../molecules/Driver/DriverTopBar';
import { thunks } from '../../../../services/thunks';
import { InventoryProducts } from '../../ecosystems/Driver/InventoryProducts';
import { DeliveryZoneDropDown } from '../../organisms/Driver/Inventory/DeliveryZoneDropDown';
import { inventory } from '../../../styles/Driver/Inventory/inventory';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInventory: thunks.getInventory,
      updateInventory: thunks.updateInventory,
    },
    dispatch,
  );

class _Inventory extends PureComponent {
  componentDidMount() {
    const { isLoading, getInventory, endLoading } = this.props;
    if (isLoading) getInventory(endLoading);
  }

  render() {
    const { isLoading, classes, updateInventory } = this.props;
    const blockRouter = get(
      this.props,
      ['location', 'state', 'blockRouter'],
      false,
    );
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <DriverTopBar showProfile={!blockRouter} blockRouter={blockRouter} />
          <hr className={classes.hr} />
          <div className={classes.inventory}>Inventory</div>
          {isLoading ? (
            <div>Loading</div>
          ) : (
            <>
              <DeliveryZoneDropDown />
              <InventoryProducts />
              <button
                type="button"
                onClick={updateInventory}
                className={classes.updateInventory}
              >
                Update
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export const Inventory = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withCommonLoading(),
  injectSheet(inventory),
)(_Inventory);
