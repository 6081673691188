import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const resetPasswordConfig = {
  mapPropsToValues: () => ({
    resetPasswordEmail: '',
  }),
  validationSchema: Yup.object().shape({
    resetPasswordEmail: validators.email,
  }),
  handleSubmit: (
    values,
    {
      props: { resetPassword, close, role },
      setErrors,
      setSubmitting,
      setStatus,
      resetForm,
    },
  ) => {
    resetPassword(
      values,
      close,
      role,
      setErrors,
      setSubmitting,
      setStatus,
      resetForm,
    );
  },
  displayName: 'ResetPassword',
};
