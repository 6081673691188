import { Map, List } from 'immutable';

export const initState = Map({
  isAuth: false,
  isUserAdult: false,
  activeOrder: null,
  pendingOrder: null,
  error: '',
  notification: Map({}),
  validateError: List([]),
  user: Map({}),
  modals: Map({
    chooseArea: false,
    emailWhenAreaSupported: false,
    thxForSubscribe: false,
    resetPassword: false,
    addAddress: false,
    checkCustomerPhone: false,
    rateCustomer: false,
    rateDriver: false,
    addZone: false,
    addCustomer: false,
    addDriver: false,
    confirmModal: false,
    addNotes: false,
    refuseOrder: false,
    cancelOrder: false,
    completeOrder: false,
    card: false,
    error: false,
    validateOrderError: false,
  }),
  validations: Map({
    addressSelected: false,
  }),
  loading: Map({
    submitButton: false,
  }),
  customer: Map({
    deliveryZone: null,
    singleOrderPage: Map({
      orderData: Map({}),
    }),
  }),
  driver: Map({
    activeOrders: Map({}),
    order: Map({}),
  }),
  admin: Map({
    products: Map({
      orderDirection: 'asc',
      orderBy: '',
      pageNumber: 1,
      pageSize: 10,
    }),
    schedule: Map({}),
  }),
  deliveryZones: List([]),
  currentDeliveryZoneHeader: {
    blocks: [
      {
        text: '',
      },
    ],
    entityMap: {},
  },
  addresses: List([]),
  activeAddress: null,
  inventory: List([Map({ items: List([]) })]),
  products: Map({
    categoryList: List([]),
    categoryMap: Map({}),
    items: Map({}),
  }),
  orderList: List([]),
  tables: Map({
    drivers: Map({}),
    deliveryZones: Map({}),
    customers: Map({}),
    orders: Map({}),
    transactions: Map({}),
    needToUpdateData: false,
  }),
  postalCodes: Map({}),
  selectedDeliveryZone: null,
  selectedDriver: null,
  selectedCustomer: null,
});
