import axios from 'axios';
import { publicAPI } from 'services/api/apiUrl';

export const validateOrder = (deliveryZoneId, orderItems) =>
  axios({
    method: 'post',
    url: `${publicAPI}/order/validate`,
    data: {
      deliveryZoneId,
      orderItems,
    },
  });
