import React, { Fragment } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { thunks } from 'services/thunks';
import { actions } from 'store/actions';
import { NotificationModal } from 'ui/components/molecules/Commons/Client/NotificationModal';
import { withContainer } from 'ui/hoc/withContainer';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import { AppTopBar } from 'ui/components/ecosystems/Customer/AppTopBar';
import { CustomerOrderCard } from 'ui/components/ecosystems/Customer/CustomerOrderCard';
import { RatePopup } from 'ui/components/organisms/Commons/RatePopup';
import { palette } from 'ui/styles/theme';
import { getOrderSecret } from 'utils';

const mapStateToProps = state => ({
  orderData: state.getIn(['app', 'customer', 'singleOrderPage', 'orderData']),
  isRatePopupOpen: state.getIn(['app', 'modals', 'rateDriver']),
  isCancelModalOpen: state.getIn(['app', 'modals', 'cancelOrder']),
  isShowPhoneOpen: state.getIn(['app', 'modals', 'showPhone']),
  notification: state.getIn(['app', 'notification', 'showPhone']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomerSingleOrder: thunks.getCustomerSingleOrder,
      cancelOrder: thunks.cancelOrder,
      cancelRateDriver: thunks.cancelRateDriver,
      rateDriver: thunks.rateDriver,
      getPhone: thunks.getPhone,
      openModal: actions.openModal.act,
      closeModal: actions.closeModal.act,
    },
    dispatch,
  );

class _SingleOrderPage extends React.PureComponent {
  componentDidMount() {
    const {
      getCustomerSingleOrder,
      startLoading,
      endLoading,
      match,
    } = this.props;
    let secret = getOrderSecret(this.props.location.search);
    getCustomerSingleOrder(match, startLoading, endLoading, secret);
  }

  openConfirmModal = () => {
    this.props.openModal('cancelOrder');
  };

  closeConfirmModal = () => {
    this.props.closeModal('cancelOrder');
  };

  cancelOrder = () => {
    const { cancelOrder, match } = this.props;
    cancelOrder(match.params.id);
  };

  getPhone = () => {
    const { getPhone, match, isMobile } = this.props;
    getPhone(match.params.id, isMobile, 'driver');
  };

  closePhoneOrder = () => {
    this.props.closeModal('showPhone');
  };

  render() {
    const {
      isLoading,
      orderData,
      isRatePopupOpen,
      cancelRateDriver,
      rateDriver,
      isCancelModalOpen,
      isShowPhoneOpen,
      notification,
    } = this.props;
    return (
      <Fragment>
        <AppTopBar />
        <div
          style={{
            fontSize: '16px',
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            width: '90%',
            textAlign: 'center',
            color: palette.yellow,
          }}
        >
          Order confirmed!
          <br />
          <br />
          <br />
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <CustomerOrderCard
            orderData={orderData}
            getPhone={this.getPhone}
            cancelOrder={this.cancelOrder}
            isCancelModalOpen={isCancelModalOpen}
            openConfirmModal={this.openConfirmModal}
            closeConfirmModal={this.closeConfirmModal}
          />
        )}
        <RatePopup
          isOpen={isRatePopupOpen}
          text="How was your driver?"
          allowComment={true}
          cancel={cancelRateDriver}
          rate={rateDriver}
          orderId={orderData.get('id')}
        />
        <NotificationModal
          isOpen={isShowPhoneOpen}
          close={this.closePhoneOrder}
        >
          <p>{notification}</p>
        </NotificationModal>
        <button>
          <span style={{ fontSize: '9pt', fontFamily: 'Roboto' }}>
            (Must be 19 or older. Driver reserves right to refuse order.)
          </span>
          <br />
          <span style={{ fontSize: '12pt' }}>
            Need Help?{' '}
            <span style={{ color: 'blue' }}>
              <a href="/customer/help">Go here</a>
            </span>
          </span><br/>
          <span style={{ fontSize: '12pt' }}>
            Stuck on this page?{' '}
            <span style={{ color: 'blue' }}>
              <a href="/customer/help#stuck">Go here</a>
            </span>
          </span>
          <br />
          <br />
          <a
            style={{
              textDecoration: 'none',
              color: palette.yellow,
              fontWeight: 100,
              paddingBottom: '2.5rem',
            }}
            href="mailto:info@boozapp.delivery"
            target="_blank"
          >
            {' '}
            info@boozapp.delivery
          </a>
        </button>
      </Fragment>
    );
  }
}

export const SingleOrderPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withContainer(),
  withCommonLoading(),
  withMobileDetect(),
)(_SingleOrderPage);
