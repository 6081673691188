import React, { Component } from 'react';

import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';

import { constants } from '../../../constants';
import { actions } from '../../../../store/actions';
import { thunks } from '../../../../services/thunks';
import { Dashboard } from './Dashboard';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { AddButton } from '../../atoms/Commons/Admin/AddButton';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';
import { PageSearch } from '../../molecules/Commons/Admin/PageSearch';
import { BaseTable } from '../../ecosystems/Admin/BaseTable';
import { AddEditDeliveryZone } from '../../organisms/Admin/DeliveryZones/AddEditDeliveryZone';
import DeliveryZonesRow from '../../organisms/Admin/DeliveryZones/DeliveryZonesRow';
import { getTableData } from '../../../../selectors/getTableData';

// @TODO add snackbar
const mapDispatchToProps = dispatch => ({
  openModal: () => dispatch(actions.openModal.act('addZone')),
  closeModal: () => dispatch(actions.closeModal.act('addZone')),
  getZones: ({ ...data }) => dispatch(thunks.getDeliveryZones(data)),
  addDeliveryZone: ({ ...data }) => dispatch(thunks.addDeliveryZone(data)),
  deleteDeliveryZone: id => dispatch(thunks.deleteDeliveryZone(id)),
});

const mapStateToProps = state => {
  return {
    deliveryZones: getTableData(state, [
      'app',
      'tables',
      'deliveryZones',
      'items',
    ]),
    totalRecords: state.getIn([
      'app',
      'tables',
      'deliveryZones',
      'totalRecords',
    ]),
    postalCodes: state.getIn(['app', 'postalCodes']).toJS(),
    isOpenModal: state.getIn(['app', 'modals', 'addZone']),
    needToUpdateData: state.getIn(['app', 'tables', 'needToUpdateData']),
  };
};

class _DeliveryZones extends Component {
  state = {
    keyWord: '',
    order: 'asc',
    sortColumn: 'name',
    pageSize: 10,
    pageIndex: 1,
  };

  handleCreate = () => {
    this.props.openModal();
  };

  handleChangeSearch = e => {
    this.setState({
      keyWord: e.target.value,
      pageIndex: 1,
    });
  };

  getData = () => {
    this.props.getZones({
      page: this.state.pageIndex,
      pageSize: this.state.pageSize,
      orderBy: this.state.sortColumn,
      orderDirection: this.state.order,
      search: this.state.keyWord,
    });
  };

  onChangePage = (data, value) => {
    this.setState({ pageIndex: value + 1 });
  };

  onChangeRowsPerPage = data => {
    this.setState({ pageSize: data.target.value });
  };

  onHandleRequestSort = (e, columnName) => {
    this.setState(prevState => {
      return {
        order: prevState.order === 'asc' ? 'desc' : 'asc',
        sortColumn: columnName,
      };
    });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.order !== this.state.order ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.pageIndex !== this.state.pageIndex ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.keyWord !== this.state.keyWord ||
      (prevProps.needToUpdateData !== this.props.needToUpdateData &&
        this.props.needToUpdateData)
    ) {
      this.getData();
    }
  }

  render() {
    const { keyWord, order, sortColumn, pageIndex, pageSize } = this.state;
    const { t, closeModal, addDeliveryZone, isOpenModal } = this.props;

    return (
      <Dashboard>
        <PageRow>
          <PageTitle title={t('Delivery Zones')}>
            <AddButton
              text={t('Add New Zone')}
              handleClick={this.handleCreate}
            />
          </PageTitle>
        </PageRow>

        <PageRow>
          <PageSearch
            handleSearch={this.handleSearch}
            onChange={this.handleChangeSearch}
            value={keyWord}
          />
        </PageRow>

        <BaseTable
          order={order}
          sortColumn={sortColumn}
          pageSize={pageSize}
          pageIndex={pageIndex}
          data={this.props.deliveryZones}
          totalRecords={this.props.totalRecords}
          Row={DeliveryZonesRow}
          columnsData={constants.deliveryZoneTableColumns}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onHandleRequestSort={this.onHandleRequestSort}
        />
        {isOpenModal && (
          <AddEditDeliveryZone
            submit={addDeliveryZone}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
          />
        )}
      </Dashboard>
    );
  }
}

export const DeliveryZones = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_DeliveryZones);
