import React from 'react';

export const ListIcon = ({ className }) => (
  <svg viewBox="0 0 60 60" className={className}>
    <g>
      <path
        className="st0"
        d="M23.4,17H47c0.6,0,1-0.4,1-1s-0.4-1-1-1H23.4c-0.6,0-1,0.4-1,1C22.4,16.6,22.9,17,23.4,17z"
      />
      <path
        className="st0"
        d="M23.4,32H47c0.6,0,1-0.4,1-1s-0.4-1-1-1H23.4c-0.6,0-1,0.4-1,1S22.9,32,23.4,32z"
      />
      <path
        className="st0"
        d="M23.4,47H47c0.6,0,1-0.4,1-1s-0.4-1-1-1H23.4c-0.6,0-1,0.4-1,1S22.9,47,23.4,47z"
      />
      <path
        className="st0"
        d="M59,0H1C0.4,0,0,0.4,0,1v58c0,0.6,0.4,1,1,1h58c0.6,0,1-0.4,1-1V1C60,0.4,59.6,0,59,0z M58,58H2V2h56V58z"
      />
      <polygon
        className="st0"
        points="12.5,18.5 14.9,17.2 17.4,18.5 16.9,15.8 18.9,13.9 16.1,13.5 14.9,11 13.7,13.5 11,13.9 13,15.8  "
      />
      <polygon
        className="st0"
        points="12.5,33.6 14.9,32.3 17.4,33.6 16.9,30.9 18.9,28.9 16.1,28.5 14.9,26.1 13.7,28.5 11,28.9 13,30.9     "
      />
      <polygon
        className="st0"
        points="12.5,49 14.9,47.7 17.4,49 16.9,46.3 18.9,44.4 16.1,44 14.9,41.5 13.7,44 11,44.4 13,46.3  "
      />
    </g>
  </svg>
);
