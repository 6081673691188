import React, { useCallback, useMemo } from 'react';
import ImageSelect from './ImageSelect';
import ImageFit from './ImageFit';
import ImagePosition from './ImagePosition';
import { EditorState } from 'draft-js';
import { BACKGROUND } from '../../constants';

export default ({ editorState, onChange, modalHandler }) => {
  const data = useMemo(
    () => {
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(contentState.entityMap[BACKGROUND]);
      return entity.get('data');
    },
    [editorState],
  );

  const update = useCallback(
    data => {
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(contentState.entityMap[BACKGROUND]);
      const newEntity = entity.setIn(['data'], {
        ...entity.get('data'),
        ...data,
      });
      const entityKey = contentState
        .addEntity(newEntity)
        .getLastCreatedEntityKey();
      const newContentState = contentState.setIn(['entityMap'], {
        ...contentState.entityMap,
        [BACKGROUND]: entityKey,
      });

      onChange(EditorState.push(editorState, newContentState, 'apply-entity'));
    },
    [editorState],
  );

  return (
    <div
      className="rdw-custom-wrapper rdw-background-image-wrapper"
      aria-label="rdw-background-image-control"
    >
      <ImageSelect update={update} />
      <ImageFit
        modalHandler={modalHandler}
        value={data.objectFit}
        update={update}
      />
      <ImagePosition
        modalHandler={modalHandler}
        value={data.objectPosition}
        update={update}
        disabled={data.objectFit === 'fill'}
      />
    </div>
  );
};
