import { api } from 'services/api';
import { actions } from 'store/actions';

export const onEnterCustomerOrder = () => async dispatch => {
  try {
    const info = await api.getCustomerInfo();
    const addresses = await api.getCustomerAddresses();
    dispatch(actions.setCustomerInfo.act(info.data));
    dispatch(actions.setCustomerAddresses.act(addresses.data));
  } catch (err) {
    return err;
  }
};
