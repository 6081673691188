import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const phone = Yup.string()
  .required('Phone is required')
  .test(
    'isValidPhoneNumber',
    'Phone number is not valid',
    x => x && isValidPhoneNumber(x),
  );
