import { fromJS } from 'immutable';
import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.setIn(['tables', 'orders'], fromJS(data));

export const setOrders = createAction({
  action: ['setOrders', 'data'],
  handler,
});
