import { push } from 'connected-react-router';
import { api } from 'services/api';
import { checkDeliveryTime } from 'utils/googleMaps';
import { setErrorToStore } from 'services/utils';
import { actions } from 'store/actions';
import { setActiveOrder, getOrderWithSecret } from 'utils';

export const acceptOrder = (id, endLoading) => async dispatch => {
  try {
    const order = (await api.getDriverOrder(id)).data;
    const deliveryTime = (await api.getDeliveryTime(order.deliveryZoneId)).data;
    const canBeAccept = await checkDeliveryTime(order.address, deliveryTime);
    if (canBeAccept) {
      await api.acceptOrder(id);
      const orderWithSecret = getOrderWithSecret(id, order.secret);
      setActiveOrder(orderWithSecret);
      dispatch({ type: 'subscribeForOrder', id });
      dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
      dispatch(actions.setDriverOrder.act(order));
      dispatch(push(`/driver/order/${orderWithSecret}`));
    } else {
      const err = `Sorry, you cannot accept this because you are more than 
      ${deliveryTime} minutes away from the destination.`;
      setErrorToStore(err, dispatch);
      endLoading();
    }
  } catch (err) {
    setErrorToStore(err, dispatch);
    endLoading();
  }
};
