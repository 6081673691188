import { changePassword } from 'services/api/public/account/changePassword';
import { sendRestorePasswordEmail } from 'services/api/public/account/sendRestorePasswordEmail';
import { setNewPassword } from 'services/api/public/account/setNewPassword';
import { addAddress } from 'services/api/public/address/addAddress';
import { deleteAddress } from 'services/api/public/address/deleteAddress';
import { editCustomerAddress } from 'services/api/public/address/editCustomerAddress';
import { getCustomerAddresses } from 'services/api/public/address/getCustomerAddresses';
import { editCustomerInfo } from 'services/api/public/customer/editCustomerInfo';
import { getCustomerInfo } from 'services/api/public/customer/getCustomerInfo';
import { getDeliveryZone } from 'services/api/public/deliveryZone/getDeliveryZone';
import { getDeliveryZoneHeader } from 'services/api/public/deliveryZone/getDeliveryZoneHeader';
import { sendSubscribe } from 'services/api/public/deliveryZone/sendSubscribe';
import { getInventory } from 'services/api/public/driver/getInventory';
import { getProducts } from 'services/api/public/product/getProducts';
import { signUpCustomer } from 'services/api/public/registration/signUpCustomer';
import { signUpDriver } from 'services/api/public/registration/signUpDriver';
import { updateInventory } from 'services/api/public/driver/updateInventory';
import { getDriverAccountInfo } from 'services/api/public/driver/getDriverAccountInfo';
import { setDeliveryZone } from 'services/api/public/driver/setDeliveryZone';
import { createOrder } from 'services/api/public/order/createOrder';
import { sendVerificationCode } from 'services/api/public/order/sendVerificationCode';
import { confirmOrder } from 'services/api/public/order/confirmOrder';
import { getOrder } from 'services/api/public/order/getOrder';
import { cancelOrder } from 'services/api/public/order/cancelOrder';
import { getActiveOrders } from 'services/api/public/orderBoard/getActiveOrders';
import { acceptOrder } from 'services/api/public/orderBoard/acceptOrder';
import { getDriverOrder } from 'services/api/public/orderBoard/getDriverOrder';
import { getCompletedOrders } from 'services/api/public/orderBoard/getCompletedOrders';
import { completeOrder } from 'services/api/public/orderBoard/completeOrder';
import { rateCustomer } from 'services/api/public/review/rateCustomer';
import { rateDriver } from 'services/api/public/review/rateDriver';
import { findByPostalCode } from 'services/api/public/deliveryZone/findByPostalCode';
import { editDriverInfo } from 'services/api/public/driver/editDriverInfo';
import { refuseOrder } from 'services/api/public/orderBoard/refuseOrder';
import { rejectOrder } from 'services/api/public/orderBoard/rejectOrder';
import { setPaymentToken } from 'services/api/public/driver/setPaymentToken';
import { payDebt } from 'services/api/public/driver/payDebt';
import { validateOrder } from 'services/api/public/order/validateOrder';
import { getPhone } from 'services/api/public/order/getPhone';
import { isCustomerBlocked } from 'services/api/public/customer/isCustomerBlocked';
import { getDriverStatus } from 'services/api/public/driver/getDriverStatus';
import { getCustomerStatus } from 'services/api/public/customer/getCustomerStatus';
import { getDeliveryTime } from 'services/api/public/order/geDeliveryTime';
import { checkOperationHours } from 'services/api/public/order/checkOperationHours';

export const publicApi = {
  checkOperationHours,
  getDeliveryTime,
  getDriverStatus,
  getCustomerStatus,
  isCustomerBlocked,
  getPhone,
  validateOrder,
  payDebt,
  setPaymentToken,
  rejectOrder,
  refuseOrder,
  editDriverInfo,
  findByPostalCode,
  rateDriver,
  rateCustomer,
  completeOrder,
  getCompletedOrders,
  getDriverOrder,
  acceptOrder,
  getActiveOrders,
  cancelOrder,
  getOrder,
  confirmOrder,
  sendVerificationCode,
  createOrder,
  changePassword,
  sendRestorePasswordEmail,
  setNewPassword,
  addAddress,
  deleteAddress,
  editCustomerAddress,
  getCustomerAddresses,
  editCustomerInfo,
  getCustomerInfo,
  getDeliveryZone,
  getDeliveryZoneHeader,
  sendSubscribe,
  getInventory,
  getProducts,
  signUpCustomer,
  signUpDriver,
  updateInventory,
  getDriverAccountInfo,
  setDeliveryZone,
};
