import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) => {
  let zone = data;
  zone.postalCodes = data.postalCodes.map(item => item.code).sort();
  const array = state.getIn(['tables', 'deliveryZones', 'items']);
  const index = array.findIndex(el => el.get('id') === data.id);
  return state.mergeIn(['tables', 'deliveryZones', 'items', index], zone);
};

export const editDeliveryZone = createAction({
  action: ['editDeliveryZone', 'data'],
  handler,
});
