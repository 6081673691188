import React from 'react';

export const AddIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 25 25">
    <g>
      <path d="M24.9,12.5c0-3.3-1.3-6.5-3.7-8.8c-4.9-4.9-12.7-4.9-17.6,0C1.2,6.1,0,9.3,0,12.5s1.2,6.4,3.7,8.8c4.9,4.9,12.7,4.9,17.6,0   c1-1,1.7-2,2.3-3.2c0.1-0.3,0-0.6-0.2-0.7c-0.3-0.1-0.6,0-0.7,0.2c-0.5,1.1-1.2,2-2.1,2.9C16.1,25,8.9,25,4.5,20.5S0,8.7,4.5,4.3   s11.8-4.5,16.1,0c2.2,2.2,3.4,5.1,3.4,8.1" />
      <path d="M0,12.5c0,3.3,1.3,6.5,3.7,8.8c4.9,4.9,12.7,4.9,17.6,0c2.4-2.4,3.7-5.6,3.7-8.8s-1.2-6.4-3.7-8.8   c-4.9-4.9-12.7-4.9-17.6,0c-1,1-1.7,2-2.3,3.2c-0.1,0.3,0,0.6,0.2,0.7c0.3,0.1,0.6,0,0.7-0.2c0.5-1.1,1.2-2,2.1-2.9   C8.9,0,16.1,0,20.5,4.5s4.5,11.8,0,16.2s-11.8,4.5-16.1,0c-2.2-2.2-3.4-5-3.4-8.1" />
      <path d="M12.5,4.4c-0.3,0-0.5,0.2-0.5,0.5v15.2c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V4.9C12.9,4.6,12.7,4.4,12.5,4.4z" />
      <path d="M4.4,12.5c0,0.3,0.2,0.5,0.5,0.5H20c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H4.9C4.6,12,4.4,12.2,4.4,12.5z" />
    </g>
  </svg>
);
