import React, { Fragment } from 'react';
import { withFormik, Field, Form } from 'formik';
import { TextArea } from 'ui/components/atoms/Commons/Client/TextArea';
import { FormCheckbox } from 'ui/components/molecules/Commons/Client/FormCheckbox';
import { refuseOrderConfig } from 'ui/configs/formik/refuseOrderConfig';
import { compose } from 'redux';
import { ConfirmModal } from 'ui/components/molecules/Commons/Client/ConfirmModal';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { refuseOrderModal } from 'ui/styles/Driver/SingleOrder/refuseOrderModal';

const _RefuseOrderModal = ({
  classes,
  isOpen,
  errors,
  touched,
  handleSubmit,
  validateForm,
  isValid,
  dirty,
  isConfirmOpen,
  openConfirmModal,
  closeRefuseModal,
  closeConfirmModal,
}) => (
  <Fragment>
    <Modal
      $backstage={classes.backstage}
      $container={classes.container}
      isOpen={isOpen}
      onClose={closeRefuseModal}
    >
      <h1 className={classes.title}>
        Please select a reason to refuse the order
      </h1>
      <Form className={classes.form} id="refuse-order">
        <Field
          component={FormCheckbox}
          name="drunk"
          text="Drunk"
          $container={classes.checkbox}
        />
        <Field
          component={FormCheckbox}
          name="underAge"
          text="Under-age"
          $container={classes.checkbox}
        />
        <Field
          component={FormCheckbox}
          name="noShow"
          text="No-show"
          $container={classes.checkbox}
        />
        <Field
          component={FormCheckbox}
          name="noEnoughMoney"
          text="Not enough money"
          $container={classes.checkbox}
        />
        <Field
          component={FormCheckbox}
          name="abusiveOrViolent"
          text="Abusive or violent"
          $container={classes.checkbox}
        />
        <Field
          component={FormCheckbox}
          name="atTreatmentFacility"
          text="At treatment facility"
          $container={classes.checkbox}
        />
        <Field
          component={TextArea}
          name="otherReason"
          placeholder="Other reason"
          error={touched.otherReason && errors.otherReason}
          $container={classes.textareaContainer}
          $textarea={classes.textarea}
          $error={classes.inputError}
        />
      </Form>
      <div className={classes.buttons}>
        <button
          type="button"
          onClick={closeRefuseModal}
          className={classes.cancel}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={!dirty}
          onClick={async () => {
            await validateForm();
            if (isValid) openConfirmModal();
          }}
          className={`${classes.ok} ${!dirty && classes.disabled}`}
        >
          Refuse Order
        </button>
      </div>
    </Modal>
    <ConfirmModal
      isOpen={isConfirmOpen}
      actionText="Are you sure want to reject order?"
      close={closeConfirmModal}
      onClick={handleSubmit}
    />
  </Fragment>
);

export const RefuseOrderModal = compose(
  withFormik(refuseOrderConfig),
  injectSheet(refuseOrderModal),
)(_RefuseOrderModal);
