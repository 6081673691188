import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) => {
  const array = state.getIn(['tables', 'customers', 'items']);
  const index = array.findIndex(el => el.get('id') === data.id);
  return state.mergeIn(['tables', 'customers', 'items', index], data);
};

export const updateCustomer = createAction({
  action: ['updateCustomer', 'data'],
  handler,
});
