import React from 'react';
import injectSheet from 'react-jss';
import { Tab } from '../../../atoms/Commons/Client/Tab';
import { CustomerIcon } from '../../../atoms/icons/CustomerIcon';
import { DriverIcon } from '../../../atoms/icons/DriverIcon';
import { tabSwitcher } from '../../../../styles/Commons/tabSwitcher';

const _TabSwitcher = ({
  classes,
  activeTab,
  leftClick,
  rightClick,
  leftText,
  rightText,
}) => (
  <div className={classes.container}>
    <Tab
      Icon={CustomerIcon}
      active={activeTab === 'customer'}
      text={leftText}
      onClick={leftClick}
    />
    <Tab
      Icon={DriverIcon}
      active={activeTab === 'driver'}
      text={rightText}
      onClick={rightClick}
    />
  </div>
);

export const TabSwitcher = injectSheet(tabSwitcher)(_TabSwitcher);
