import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const editDeliveryZone = (id, data) => async dispatch => {
  try {
    await api.editDeliveryZone(id, data);
    dispatch(actions.editDeliveryZone.act({ id, ...data }));
  } catch (e) {
    return e;
  }
};
