import React, { Component, } from "react";
import ReactGA from "react-ga";
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

const history = createBrowserHistory();


export const initGA = (trackingID) => { ReactGA.initialize(trackingID); }

export const initTestGA = (trackingID) => ReactGA.initialize(trackingID, {
  	debug: true,
  	titleCase: false,
  	gaOptions: {
    testMode: true
  }
});

export const PageView = () => { ReactGA.pageview(window.location.pathname + window.location.search); }

export const TrackView = () => { history.listen( location => {ReactGA.set({ page: location.pathname }); ReactGA.pageview( location.pathname )} ); }

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};