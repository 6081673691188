import React, { Fragment } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { thunks } from 'services/thunks';
import { actions } from 'store/actions';
import { NotificationModal } from 'ui/components/molecules/Commons/Client/NotificationModal';
import { withContainer } from 'ui/hoc/withContainer';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { AppTopBar } from 'ui/components/ecosystems/Customer/AppTopBar';
import { DriverOrderCard } from 'ui/components/ecosystems/Driver/DriverOrderCard';
import { RatePopup } from 'ui/components/organisms/Commons/RatePopup';

const mapStateToProps = state => ({
  orderData: state.getIn(['app', 'driver', 'order']),
  isRatePopupOpen: state.getIn(['app', 'modals', 'rateCustomer']),
  isCancelOpen: state.getIn(['app', 'modals', 'cancelOrder']),
  isCompleteOpen: state.getIn(['app', 'modals', 'completeOrder']),
  isShowPhoneOpen: state.getIn(['app', 'modals', 'showPhone']),
  notification: state.getIn(['app', 'notification', 'showPhone']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDriverOrder: thunks.getDriverOrder,
      completeOrder: thunks.completeOrder,
      rejectOrder: thunks.rejectOrder,
      cancelRateCustomer: thunks.cancelRateCustomer,
      rateCustomer: thunks.rateCustomer,
      getPhone: thunks.getPhone,
      openModal: actions.openModal.act,
      closeModal: actions.closeModal.act,
    },
    dispatch,
  );

class _DriverOrder extends React.PureComponent {
  componentDidMount() {
    const { getDriverOrder, endLoading, match, isLoading } = this.props;
    if (isLoading) getDriverOrder(match, endLoading);
  }

  completeOrder = () => {
    const { completeOrder, orderData } = this.props;
    completeOrder(orderData.get('id'));
  };

  getPhone = () => {
    const { getPhone, orderData, isMobile } = this.props;
    getPhone(orderData.get('id'), isMobile, 'customer');
  };

  openCompleteOrder = () => {
    this.props.openModal('completeOrder');
  };

  closeCompleteOrder = () => {
    this.props.closeModal('completeOrder');
  };

  rejectOrder = () => {
    const { rejectOrder, orderData } = this.props;
    rejectOrder(orderData.get('id'));
  };

  openCancelOrder = () => {
    this.props.openModal('cancelOrder');
  };

  closeCancelOrder = () => {
    this.props.closeModal('cancelOrder');
  };

  closePhoneOrder = () => {
    this.props.closeModal('showPhone');
  };

  render() {
    const {
      isLoading,
      orderData,
      cancelRateCustomer,
      rateCustomer,
      isRatePopupOpen,
      isCancelOpen,
      isCompleteOpen,
      isShowPhoneOpen,
      notification,
    } = this.props;
    return (
      <Fragment>
        <AppTopBar />
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <DriverOrderCard
            orderData={orderData}
            isCompleteOpen={isCompleteOpen}
            completeOrder={this.completeOrder}
            openCompleteOrder={this.openCompleteOrder}
            closeCompleteOrder={this.closeCompleteOrder}
            isCancelOpen={isCancelOpen}
            rejectOrder={this.rejectOrder}
            getPhone={this.getPhone}
            openCancelOrder={this.openCancelOrder}
            closeCancelOrder={this.closeCancelOrder}
          />
        )}
        <RatePopup
          isOpen={isRatePopupOpen}
          text="How was your customer?"
          cancel={cancelRateCustomer}
          rate={rateCustomer}
          orderId={orderData.get('id')}
        />
        <NotificationModal
          isOpen={isShowPhoneOpen}
          close={this.closePhoneOrder}
        >
          <p>{notification}</p>
        </NotificationModal>
      </Fragment>
    );
  }
}

export const DriverOrder = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withContainer(),
  withCommonLoading(),
  withMobileDetect(),
)(_DriverOrder);
