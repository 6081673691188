import React from 'react';

export const withCommonLoading = (
  { initLoading } = { initLoading: true },
) => WrappedComponent =>
  class WithCommonLoading extends React.PureComponent {
    state = {
      isLoading: initLoading,
    };

    startLoading = () => {
      this.setState({ isLoading: true });
    };

    endLoading = () => {
      this.setState({ isLoading: false });
    };

    render() {
      const { isLoading } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          isLoading={isLoading}
          startLoading={this.startLoading}
          endLoading={this.endLoading}
        />
      );
    }
  };
