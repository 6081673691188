import { privateAxios } from 'services/api/privateAxios';

export const rateCustomer = (orderId, rating) =>
  privateAxios({
    method: 'post',
    url: '/review/forcustomer',
    data: {
      orderId,
      rating,
    },
  });
