import React, { Component } from 'react';
import {
  Table,
  TableFooter,
  TableBody,
  TableRow,
  TablePagination,
  Paper,
  withStyles,
} from '@material-ui/core';

import { EnchancedTableHead } from '../../molecules/Commons/Admin/EnchancedTableHead';
import { TablePaginationActions } from '../../molecules/Commons/Admin/TablePaginationActions';
import styles from '../../../styles/Admin/commons/table';

class _BaseTable extends Component {
  render() {
    const {
      order,
      sortColumn,
      pageSize,
      pageIndex,
      data,
      Row,
      columnsData,
      totalRecords,
      onChangePage,
      onChangeRowsPerPage,
      onHandleRequestSort,
      customName,
      classes,
      styleColumns,
      useImmutable, // Todo Refactor BaseTable
      getPageData,
    } = this.props;

    return (
      <Paper>
        <Table className={classes.table}>
          <EnchancedTableHead
            columns={columnsData}
            order={order}
            orderBy={sortColumn}
            onRequestSort={onHandleRequestSort}
            customName={customName}
            classes={classes}
            styleColumns={styleColumns}
          />

          <TableBody>
            {data &&
              data.map(item => (
                <Row
                  key={useImmutable ? item.get('id') : item.id}
                  item={item}
                  rate={item.rate || 0}
                  getPageData={getPageData}
                />
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalRecords || 0}
                rowsPerPage={pageSize}
                page={pageIndex - 1}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export const BaseTable = withStyles(styles)(_BaseTable);
