import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const profile = {
  currentInventory: {
    width: '1030px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E1E1E1',
    marginTop: '2rem',
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
    fontWeight: 300,
    [media.mobile]: {
      width: '100%',
    },
  },
  updateInventoryWrapper: {
    [media.mobile]: {
      width: '100%',
    },
  },
  updateInventory: {
    ...button.white,
    color: 'black',
    borderColor: 'black',
    margin: '2rem 0 4rem 0',
    [media.desktop]: {
      ...button.white[media.desktop],
      width: '15rem',
    },
    [media.mobile]: {
      ...button.white[media.mobile],
      width: '100%',
    },
  },
};
