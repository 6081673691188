import React from 'react';
import { PaymentCard } from 'ui/components/organisms/Driver/Profile/PaymentCard';
import { CardInfoBlock } from 'ui/components/organisms/Driver/Profile/CardInfoBlock';
import injectSheet from 'react-jss';
import { paymentSection } from 'ui/styles/Driver/Profile/paymentSection';

const _PaymentSection = ({ classes, card, status }) => (
  <div className={classes.container}>
    {card.get('last4') ? (
      <CardInfoBlock card={card} status={status} />
    ) : (
      <PaymentCard className={classes.addCard} text="Add card" />
    )}
  </div>
);

export const PaymentSection = injectSheet(paymentSection)(_PaymentSection);
