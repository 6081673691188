import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getPostalCodes = search => async dispatch => {
  try {
    const response = await api.getPostalCodes(search);
    dispatch(actions.setPostalCodes.act(response.data));
  } catch (e) {
    return e;
  }
};
