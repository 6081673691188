import { media } from 'ui/styles/theme';

export const terms = {
  container: {
    fontFamily: 'Roboto',
    margin: '3rem 0',
    [media.desktop]: {
      width: '1030px',
      lineHeight: '1.75rem',
      fontSize: '1.125rem',
    },
    // lineHeight: '1.75rem',
    // fontSize: '1.125rem',
    '& > h1': {
      marginBottom: '3rem',
      textAlign: 'center',
    },
    '& > p': {
      margin: '1.75rem 0',
    },
  },
};
