import { api } from 'services/api';
import { actions } from 'store/actions';
import { setErrorToStore } from 'services/utils';

export const getProductsByAdmin = (values, endLoading) => async dispatch => {
  try {
    const products = await api.getProductsByAdmin(values);
    dispatch(actions.adminSetProducts.act(products.data));
    endLoading();
  } catch (e) {
    setErrorToStore(e, dispatch);
    endLoading();
  }
};
