import React from 'react';

export const DriverIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 40 40">
    <title>driver_sign up icon</title>
    <path d="M20,0A20,20,0,1,0,40,20,20.06,20.06,0,0,0,20,0Zm0,38.8A18.8,18.8,0,1,1,38.8,20,18.78,18.78,0,0,1,20,38.8Z" />
    <path d="M25.1,20A5.1,5.1,0,1,0,20,25.1,5.12,5.12,0,0,0,25.1,20ZM20,23.9A3.9,3.9,0,0,1,16.1,20a3.9,3.9,0,0,1,7.8,0A3.9,3.9,0,0,1,20,23.9Z" />
    <path d="M35.7,23.6h0A11.57,11.57,0,0,0,21.2,36.1h0a.56.56,0,0,0,.6.5h.1a16.46,16.46,0,0,0,9-4,17.3,17.3,0,0,0,5.2-8.3A.57.57,0,0,0,35.7,23.6Zm-5.5,8.1a15.17,15.17,0,0,1-7.8,3.6A10.31,10.31,0,0,1,34.8,24.6,14.65,14.65,0,0,1,30.2,31.7Z" />
    <path d="M4.3,23.6h0a.63.63,0,0,0-.4.8,17.3,17.3,0,0,0,5.2,8.3,17.12,17.12,0,0,0,9,4h.1a.63.63,0,0,0,.6-.5h0a11.57,11.57,0,0,0-3.3-9.5A11.76,11.76,0,0,0,4.3,23.6ZM17.6,35.4a15.17,15.17,0,0,1-7.8-3.6,16.13,16.13,0,0,1-4.7-7.1,10.46,10.46,0,0,1,9.4,2.8A9.85,9.85,0,0,1,17.6,35.4Z" />
    <path d="M31.7,8.1a16.66,16.66,0,0,0-23.4,0,16.93,16.93,0,0,0-5,11.7.82.82,0,0,0,.4.6h.2c.2,0,.3-.1.5-.2,3.5-4.3,9.4-6.8,15.7-6.8s12.2,2.5,15.7,6.8a.62.62,0,0,0,.7.2.68.68,0,0,0,.4-.6A17.13,17.13,0,0,0,31.7,8.1ZM20,12.2a21.78,21.78,0,0,0-15.4,6A15.47,15.47,0,0,1,9.1,9,15,15,0,0,1,20,4.5,15.26,15.26,0,0,1,30.9,8.9a15.47,15.47,0,0,1,4.5,9.2A22.14,22.14,0,0,0,20,12.2Z" />
  </svg>
);
