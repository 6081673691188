import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { name, data, clear = true }) =>
  state
    .update('modals', modal => clear ? modal.clear() : modal)
    .setIn(['modals', name], true)
    .setIn(['notification', name], data);

export const openModal = createAction({
  action: ['openModal', 'name', 'data', 'clear'],
  handler,
});
