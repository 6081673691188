import React, { Fragment } from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { AppTopBar } from '../../ecosystems/Customer/AppTopBar';
import { LoginCard } from '../../ecosystems/General/LoginCard';
import { signUp } from '../../../styles/General/SignUp/signUp';

const _Login = ({ classes, location }) => (
  <Fragment>
    <AppTopBar signup />
    <div className={classes.container}>
      <LoginCard location={location} />
    </div>
  </Fragment>
);

export const Login = compose(
  withNamespaces(),
  injectSheet(signUp),
)(_Login);
