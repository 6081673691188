const action = {
  id: 'actions',
  align: 'left',
  label: 'Actions',
  empty: true,
};

const driverTableColumns = [
  {
    id: 'fullname',
    align: 'left',
    label: 'Name',
  },
  {
    id: 'rate',
    align: 'left',
    label: 'Rate',
  },
  {
    id: 'debt',
    align: 'left',
    label: 'Debt',
  },
  action,
];

const customerTableColumns = [
  {
    id: 'fullname',
    align: 'left',
    label: 'Name',
  },
  {
    id: 'rate',
    align: 'left',
    label: 'Rate',
  },
  action,
];

const deliveryZoneTableColumns = [
  {
    id: 'name',
    align: 'left',
    label: 'District',
  },
  {
    id: 'postalCodes',
    align: 'left',
    label: 'Postal Code',
    empty: true,
  },
  action,
];

const orderTableColumns = [
  {
    id: 'drivername',
    align: 'left',
    label: 'Driver',
  },
  {
    id: 'customername',
    align: 'left',
    label: 'Customer',
  },
  {
    id: 'createdate',
    align: 'left',
    label: 'Date',
  },
  {
    id: 'time',
    align: 'left',
    label: 'Time',
  },
  {
    id: 'total',
    align: 'left',
    label: 'Price',
  },
  {
    id: 'quantity',
    align: 'left',
    label: 'Quantity',
  },
  {
    id: 'status',
    align: 'left',
    label: 'Status',
  },
];

const transactionTableColumns = [
  {
    id: 'fullname',
    align: 'left',
    label: `Driver's name`,
  },
  {
    id: 'createdate',
    align: 'left',
    label: 'Date of transaction',
  },
  {
    id: 'sum',
    align: 'left',
    label: 'Sum of transaction',
  },
];

export const tableColumns = {
  driverTableColumns,
  customerTableColumns,
  deliveryZoneTableColumns,
  orderTableColumns,
  transactionTableColumns,
};
