import React, { Component } from 'react';

import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withNamespaces } from 'react-i18next';

import { constants } from '../../../constants';
import { thunks } from '../../../../services/thunks';
import { Dashboard } from './Dashboard';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { PageSearch } from '../../molecules/Commons/Admin/PageSearch';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';
import { BaseTable } from '../../ecosystems/Admin/BaseTable';
import { OrderRow } from 'ui/components/organisms/Admin/Order/OrderRow';
import { DatePickerForm } from '../../molecules/Commons/Admin/DatePickerForm';
import { getTableData } from '../../../../selectors/getTableData';

// @TODO add snackbar

const mapStateToProps = state => {
  return {
    orders: getTableData(state, ['app', 'tables', 'orders', 'items']),
    totalRecords: state.getIn(['app', 'tables', 'orders', 'totalRecords']),
  };
};

const mapDispatchToProps = dispatch => ({
  getOrders: data => dispatch(thunks.getOrders(data)),
});

class _AdminOrders extends Component {
  state = {
    keyWord: '',
    order: 'desc',
    sortColumn: 'createdate',
    pageSize: 10,
    pageIndex: 1,
    startDate: null,
    endDate: null,
  };

  handleChangeSearch = e => {
    this.setState({
      keyWord: e.target.value,
      pageIndex: 1,
    });
  };

  getData = () => {
    this.props.getOrders({
      page: this.state.pageIndex,
      pageSize: this.state.pageSize,
      orderBy: this.state.sortColumn,
      orderDirection: this.state.order,
      search: this.state.keyWord,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  onChangePage = (data, value) => {
    this.setState({ pageIndex: value + 1 });
  };

  onChangeRowsPerPage = data => {
    this.setState({ pageSize: data.target.value });
  };

  onHandleRequestSort = (e, columnName) => {
    this.setState(prevState => {
      return {
        order: prevState.order === 'asc' ? 'desc' : 'asc',
        sortColumn: columnName,
      };
    });
  };

  onChangeFrom = e => {
    this.setState({ startDate: e.target.value });
  };

  onChangeTo = e => {
    this.setState({ endDate: e.target.value });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.order !== this.state.order ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.pageIndex !== this.state.pageIndex ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.keyWord !== this.state.keyWord ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.getData();
    }
  }

  render() {
    const { keyWord, order, sortColumn, pageIndex, pageSize } = this.state;
    const { t, orders, totalRecords } = this.props;

    return (
      <Dashboard>
        <PageRow>
          <PageTitle title={t('Orders')} />
        </PageRow>

        <PageRow>
          <PageSearch
            handleSearch={this.handleSearch}
            onChange={this.handleChangeSearch}
            value={keyWord}
          />

          <DatePickerForm
            onChangeFrom={this.onChangeFrom}
            onChangeTo={this.onChangeTo}
          />
        </PageRow>

        <BaseTable
          order={order}
          sortColumn={sortColumn}
          pageSize={pageSize}
          pageIndex={pageIndex}
          data={orders}
          getPageData={this.getData}
          customName={true}
          totalRecords={totalRecords}
          Row={OrderRow}
          columnsData={constants.orderTableColumns}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onHandleRequestSort={this.onHandleRequestSort}
          styleColumns
        />
      </Dashboard>
    );
  }
}

export const AdminOrders = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_AdminOrders);
