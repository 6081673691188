import React from 'react';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import { FormikPersist } from 'ui/components/atoms/Commons/Client/FormikPersist';
import { AddressField } from 'ui/components/organisms/Commons/AddressField';
import injectSheet from 'react-jss';
import { anonymousAddressConfig } from '../../../../configs/formik/anonymousAddressConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { TextArea } from '../../../atoms/Commons/Client/TextArea';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { FormCheckbox } from '../../../molecules/Commons/Client/FormCheckbox';
import { anonymousAddressForm } from '../../../../styles/Customer/CompleteOrder/anonymousAddressForm';


const _AnonymousAddressForm = ({
  classes,
  errors,
  touched,
  id,
  geocodeAddress,
  isMobile,
  phoneChange,
}) => {
  const termsText = (
    <span>
      I agree with&nbsp;
      <NavLink to="/customer/terms" className={classes.termsLink}>
        Terms and Conditions
      </NavLink>
    </span>
  );
  return (
    <Form className={classes.form} id={id} onBlur={phoneChange}>
      <Field
        component={Input}
        type="text"
        name="firstName"
        placeholder={isMobile ? 'John' : 'First Name'}
        labelText={isMobile ? 'First Name' : 'Name'}
        error={touched.firstName && errors.firstName}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="text"
        name="lastName"
        placeholder={isMobile ? 'Doe' : 'Last Name'}
        labelText={isMobile && 'Last Name'}
        required
        error={touched.lastName && errors.lastName}
        $container={classes.inputHalfContainerWithoutLabel}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={PhoneInput}
        type="text"
        name="phone"
        labelText="Phone"
        error={touched.phone && errors.phone}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      
      <Field
        component={AddressField}
        type="text"
        name="address"
        labelText="Address"
        placeholder="Type your address and select from dropdown options"
        error={touched.address && errors.postalCode}
        geocodeAddress={geocodeAddress}
      />
      <Field
        component={Input}
        type="text"
        name="boozer"
        placeholder="Unit Number and Buzzer"
        error={touched.address2 && errors.address2}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="text"
        name="nearestIntersection"
        labelText="Nearest main intersection"
        placeholder="Main and Broad"
        error={touched.nearestIntersection && errors.nearestIntersection}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={TextArea}
        name="instructions"
        labelText="Instructions"
        placeholder="eg. Meet in lobby, Name for concierge, Basement or back, etc."
        error={touched.instructions && errors.instructions}
        $container={classes.textareaContainer}
        $label={classes.inputLabel}
        $textarea={classes.textarea}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="text"
        name="email"
        placeholder="example@gmail.com"
        labelText="Email (optional)"
        error={touched.email && errors.email}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={FormCheckbox}
        name="terms"
        error={touched.terms && errors.terms}
        text={termsText}
        $container={classes.termsContainer}
        $error={classes.termsError}
      />
      <div className={classes.backError}>{errors.backError}</div>
      <FormikPersist name="customerOrder" />
    </Form>
  );
};

export const AnonymousAddressForm = compose(
  withNamespaces(),
  withFormik(anonymousAddressConfig),
  withMobileDetect(),
  injectSheet(anonymousAddressForm),
)(_AnonymousAddressForm);
