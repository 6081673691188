import React from 'react';
import injectSheet from 'react-jss';
import { category as categoryStyles } from 'ui/styles/Driver/Profile/category';

const _ProfileCategory = ({ classes, category }) => (
  <div className={classes.container}>
    <span className={classes.name}>{category.get('name')}</span>
    <div className={classes.items}>
      {category.get('items').map(item => (
        <div key={item.get('id')} className={classes.item}>
          <span>{item.get('quantity')}</span>
          <span>{item.get('name')}</span>
          {category.get('name') !== 'Beer' && <span>{item.get('unit')}</span>}
        </div>
      ))}
    </div>
  </div>
);

export const ProfileCategory = injectSheet(categoryStyles)(_ProfileCategory);
