import React from 'react';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { NavLink } from 'react-router-dom';
import injectSheet from 'react-jss';
import { CategoryField } from 'ui/components/molecules/Admin/Products/CategoryField';
import Button from '@material-ui/core/Button';
import { addProductConfig } from 'ui/configs/formik/addProductConfig';
import { Paper } from '@material-ui/core';
import { UploadImage } from 'ui/components/molecules/Admin/Products/UploadImage';
import { ProductPriceField } from 'ui/components/molecules/Admin/Products/ProductPriceField';
import { FormikInputField } from 'ui/components/atoms/Commons/Admin/FormikInputField';
import { FormikNumberInputField } from 'ui/components/atoms/Commons/Admin/FormikNumberInputField';
import { productForm } from 'ui/styles/Admin/AddProduct/productForm';

const _ProductForm = ({ classes, errors, values }) => (
  <div className={classes.container}>
    <Paper className={classes.paper}>
      <Form className={classes.form}>
        <Field
          component={FormikInputField}
          name="productName"
          label="Product Name"
          className={classes.input}
        />
        <Field
          component={FormikInputField}
          name="unit"
          label="Units (L/ml)"
          className={classes.input}
          disabled={values.category === 2} // Disabled when the category is bear
        />
        <Field
          component={FormikNumberInputField}
          name="order"
          label="Order (ID)"
          className={classes.input}
          inputProps={{
            strict: true,
          }}
        />
        <Field
          component={CategoryField}
          name="category"
          label="Category"
          className={classes.input}
        />
        <Field
          component={ProductPriceField}
          name="prices"
          label="Prices"
          className={classes.input}
          isFixedPrice={values.category !== 2}
        />
        <Field component={UploadImage} name="image" />
        <div className={classes.buttons}>
          <NavLink to="/admin/products">
            <Button
              variant="contained"
              className={classes.cancel}
              type="button"
            >
              Cancel
            </Button>
          </NavLink>
          <Button variant="contained" className={classes.save} type="submit">
            Save
          </Button>
        </div>
        <div className={classes.backError}>{errors.backError}</div>
      </Form>
    </Paper>
  </div>
);

export const ProductForm = compose(
  withFormik(addProductConfig),
  injectSheet(productForm),
)(_ProductForm);
