import { actions } from 'store/actions';
import { api } from '../../api';
import { setErrorToStore } from 'services/utils';

export const sendVerificationCode = () => async (dispatch, getState) => {
  const orderId =  getState().getIn(['app', 'pendingOrder']);
  try {
    const res = await api.sendVerificationCode(orderId);
    console.log(res.data);
    dispatch(actions.closeModal.act('checkCustomerPhone'));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
