import { button } from 'ui/styles/Commons/button';
import { media, palette } from 'ui/styles/theme';

export const paymentSection = {
  container: {
    gridArea: 'e',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addCard: {
    ...button.yellow,
    width: '100%',
    fontSize: '1rem',
    fontWeight: 500,
    backgroundColor: ({ status }) =>
      status === 'Suspended' ? palette.red : palette.yellow,
    borderColor: ({ status }) =>
      status === 'Suspended' ? palette.red : palette.yellow,
    [media.desktop]: {
      ...button[media.desktop],
      width: '12rem',
    },
  },
};
