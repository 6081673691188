const styles = () => ({
  wrap: {
    display: 'flex',
  },
  searchWrap: {
    flexGrow: 1,
  },
  buttonWrap: {
    width: '52px',
    paddingLeft: '4px',
  },
});

export default styles;
