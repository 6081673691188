import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const confirmModal = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    gridTemplateAreas: `
      '. a .'
      '. b .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    fontFamily: 'Roboto',
    [media.mobile]: {
      width: '90%',
      height: '220px',
      maxWidth: '400px',
      gridTemplateRows: '50% 50%',
    },
    [media.desktop]: {
      width: '630px',
      height: '300px',
      gridTemplateColumns: '15% 70% 15%',
      gridTemplateRows: '50% 50%',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'center',
    fontSize: '1.4rem',
    textAlign: 'center',
    fontWeight: 500,
    [media.desktop]: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
      marginTop: '15%',
    },
  },
  cancel: {
    ...button.white,
    gridArea: 'b',
    alignSelf: 'center',
    justifySelf: 'start',
  },
  ok: {
    ...button.yellow,
    gridArea: 'b',
    alignSelf: 'center',
    justifySelf: 'end',
  },
};

