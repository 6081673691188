import React from 'react';
import { compose } from 'redux';
import { Tab } from 'ui/components/atoms/Commons/Client/Tab';
import injectSheet from 'react-jss';
import { tabs } from 'ui/styles/Driver/OrderBoard/tabs';

const _Tabs = ({
  classes,
  activeTab,
  chooseOpenOrders,
  chooseCompletedOrders,
  isMobile,
}) => {
  const tabStyles = {
    container: classes.tabContainer,
    active: classes.activeTab,
  };
  return (
    <div className={classes.container}>
      <Tab
        text={isMobile ? 'Open' : 'Open Orders'}
        active={activeTab === 'openOrders'}
        classes={tabStyles}
        onClick={chooseOpenOrders}
      />
      <Tab
        text={isMobile ? 'Completed' : 'Completed  Orders'}
        active={activeTab === 'completedOrders'}
        classes={tabStyles}
        onClick={chooseCompletedOrders}
      />
    </div>
  );
};

export const Tabs = compose(injectSheet(tabs))(_Tabs);
