import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) => {
  const array = state.getIn(['tables', 'drivers', 'items']);
  const index = array.findIndex(el => el.get('id') === data.id);
  return state.mergeIn(['tables', 'drivers', 'items', index], data);
};

export const editDriver = createAction({
  action: ['editDriver', 'data'],
  handler,
});
