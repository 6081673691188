import React from 'react';

export const CustomerIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 32 40">
    <title>customer_sign up icon</title>
    <path
      d="M32,33,30.19,9.5c-.1-1.5-1.3-1.6-2.8-1.6h-3.5V7.7A7.72,7.72,0,0,0,16.1,0,7.87,7.87,0,0,0,8.21,7.7v.2H4.71c-1.6,0-2.8.1-2.9,1.6L0,33c-.1,1.8.5,4.6,1.7,5.9S4.81,40,6.61,40H25.49c1.8,0,3.5.2,4.8-1.1S32.09,34.8,32,33ZM9.6,7.7c0-5.2,4.5-6.4,6.5-6.4,2.2,0,6.5,1.8,6.5,6.4v.2H9.6ZM29.39,36.9a6.31,6.31,0,0,1-3.9,1.7H6.61a7.13,7.13,0,0,1-4.1-1.3,6.69,6.69,0,0,1-1.1-4.1L3.11,9.7c0-.2,1.2-.4,1.5-.4h3.5v3.9a1.22,1.22,0,0,0,1.2,1.2c.6,0,.2-.5.2-1.2V9.3H22.4v3.9c0,.6-.5,1.2.2,1.2a1.15,1.15,0,0,0,1.2-1.2V9.3h3.5c.2,0,1.4.2,1.5.4l1.8,23.49A5,5,0,0,1,29.39,36.9Z"
      transform="translate(0 -0.01)"
    />
    <path
      d="M21.3,18.9c-.5-.5-1.2.5-1.7,1l-5.2,5.2-2-2c-.5-.5-1.2-1.5-1.7-1a1.15,1.15,0,0,0,0,1.7l2.8,2.8a1.22,1.22,0,0,0,1.6,0l6.2-6.1A1,1,0,0,0,21.3,18.9Z"
      transform="translate(0 -0.01)"
    />
  </svg>
);
