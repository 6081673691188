import axios from 'axios';
import { push } from 'connected-react-router';
import { store } from 'store';
import { publicAPI } from 'services/api/apiUrl';
import { refreshToken } from 'services/api/common/auth/refreshToken';
import {
  getAccessToken,
  isAccessTokenAlive,
  setNewAccessToken,
  deleteUserData,
} from 'services/utils';
import { actions } from 'store/actions';
import { thunks } from 'services/thunks/index';

const getConfigWithToken = config => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

const logout = () => {
  deleteUserData();
  store.dispatch(actions.logout.act());
  store.dispatch(thunks.setLocalDataToStore());
  store.dispatch(push('/'));
};

const _privateAxios = axios.create({ baseURL: publicAPI });

_privateAxios.interceptors.request.use(async config => {
  if (isAccessTokenAlive()) return getConfigWithToken(config);
  try {
    const response = await refreshToken();
    setNewAccessToken(response.data);
    return getConfigWithToken(config);
  } catch (err) {
    logout();
  }
});

_privateAxios.interceptors.response.use(null, error => {
  switch (error.response.status) {
    case 401:
    case 403:
      logout();
      break;
  }
  return Promise.reject(error);
});

export const privateAxios = _privateAxios;
