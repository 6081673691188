import { media } from 'ui/styles/theme';

export const inventoryItem = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontWeight: '300',
    margin: '1.5rem 0',
    lineHeight: '1.5rem',
    [media.mobile]: {
      fontSize: '1rem',
    },
    [media.desktop]: {
      fontSize: '1.375rem',
    },
  },
  names: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [media.mobile]: {
      width: '62%',
    },
    [media.desktop]: {
      width: '80%',
    },
  },
};
