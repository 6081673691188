import createAction from 'utils/redux-helpers/createAction';

const handler = (state, { last4, brand }) =>
  state
    .setIn(['user', 'card', 'last4'], last4)
    .setIn(['user', 'card', 'brand'], brand)
    .setIn(['modals', 'card'], false);

export const setCard = createAction({
  action: ['setCard', 'last4', 'brand'],
  handler,
});
