import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const activateDriver = id => async dispatch => {
  try {
    await api.activateDriver(id);
    dispatch(
      actions.updateDriver.act({ id, status: 'Active', banReason: null }),
    );
  } catch (e) {
    return e;
  }
};
