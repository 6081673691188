import React from 'react';

import { withStyles, Input } from '@material-ui/core';

import styles from '../../../../styles/Admin/Search/searchInput';

const _SearchInput = ({ value, onChange, classes, autoFocus }) => (
  <div className={classes.search}>
    <Input
      value={value}
      onChange={onChange}
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      autoFocus={autoFocus}
    />
  </div>
);

export const SearchInput = withStyles(styles)(_SearchInput);
