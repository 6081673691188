import { push } from 'connected-react-router';
import { actions } from 'store/actions';
import { api } from 'services/api';
import { setActiveOrder } from 'utils';
import { setErrorToStore } from 'services/utils';
import { handleOrderStatus } from 'services/thunks/auth/checkAuth';
import { getOrderWithSecret } from 'utils';

export const confirmOrder = (location, match) => async dispatch => {
  const { id } = location.params;
  const { search } = match;
  let orderWithSecret;
  try {
    const response = await api.confirmOrder(id, search);
    orderWithSecret = getOrderWithSecret(id, response.data);
    dispatch({ type: 'subscribeForOrder', id });
    setActiveOrder(orderWithSecret);
    dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    dispatch(push(`/order/${orderWithSecret}`));
  } catch (e) {
    const { secret } = e.response.data;
    if (secret) {
      // if order is not in pending status
      orderWithSecret = getOrderWithSecret(id, secret);
      await handleOrderStatus(orderWithSecret, 'customer', dispatch);
      dispatch(push(`/order/${orderWithSecret}`));
      return false;
    }
    setErrorToStore(e, dispatch);
  }
};
