import { createSelector } from 'reselect';

const hasDropdown = item => item.get('productPrices').size > 1;

const getItemPrice = item =>
  hasDropdown(item)
    ? item
        .get('productPrices')
        .find(i => i.get('quantity') === item.get('currentQuantity'))
        .get('price')
    : item.getIn(['productPrices', 0, 'price']) * item.get('currentQuantity');

const handler = (orderList, items) =>
  orderList.reduce(
    (prev, id) => {
      const price = getItemPrice(items.get(id));
      const total = prev.total + price;
      return {
        items: [
          ...prev.items,
          {
            quantity: items.getIn([id, 'currentQuantity']),
            unit: items.getIn([id, 'unit']),
            name: items.getIn([id, 'name']),
            price,
          },
        ],
        total,
      };
    },
    { items: [], total: 0 },
  );

export const getOrderList = createSelector(
  state => state.getIn(['app', 'orderList']),
  state => state.getIn(['app', 'products', 'items']),
  (orderList, items) => handler(orderList, items),
);
