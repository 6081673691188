import React, { useCallback } from 'react';
import clsx from 'clsx';

export default ({
  onClick,
  children,
  value,
  className,
  activeClassName = '',
  active,
  disabled,
  title,
}) => {
  const onClickHandler = useCallback(
    () => {
      if (!disabled && onClick) {
        onClick(value);
      }
    },
    [disabled, onClick, value],
  );

  return (
    <div
      className={clsx('rdw-option-wrapper', className, {
        [`rdw-option-active ${activeClassName}`]: active,
        'rdw-option-disabled': disabled,
      })}
      onClick={onClickHandler}
      aria-selected={active}
      title={title}
    >
      {children}
    </div>
  );
};
