import React, { useCallback } from 'react';
import Dropdown from '../Dropdown';
import DropdownOption from '../DropdownOption';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  dropdown: {
    width: 110,
  },
  value: {
    whiteSpace: 'nowrap',
    maxWidth: 85,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const values = {
  fill: 'fill',
  contain: 'contain',
  cover: 'cover',
  none: 'none',
  'scale-down': 'scale-down',
};

export default ({ modalHandler, value, update }) => {
  const classes = useStyles();
  const onChange = useCallback(
    newValue => {
      if (newValue !== value) {
        update({ objectFit: newValue });
      }
    },
    [value, update],
  );

  return (
    <Dropdown
      className={classes.dropdown}
      modalHandler={modalHandler}
      onChange={onChange}
      title="Image Fit"
      value={<span className={classes.value}>{values[value]}</span>}
    >
      {Object.entries(values).map(([key, value], index) => (
        <DropdownOption active={value === key} value={key} key={index}>
          {value}
        </DropdownOption>
      ))}
    </Dropdown>
  );
};
