import { media } from '../../theme';

export const spiritProduct = {
  container: {
    height: '30rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    gridTemplateRows: '5% 55% 15% 15% 5%',
    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. h .'
      '. b .'
      '. c .'
    `,
    backgroundColor: 'white',
    marginBottom: '2rem',
    flexShrink: 0,
    '& > img': {
      gridArea: 'a',
      justifySelf: 'center',
      height: '100%',
    },
    [media.desktop]: {
      width: '30%',
      '&:nth-child(1 + 2n)': {
        backgroundColor: 'orange',
      },
    },
  },
  wrapper: {
    gridArea: 'b',
    justifySelf: 'center',
    alignSelf: 'start',
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  price: {
    gridArea: 'h',
    justifySelf: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  capacity: {
    width: '60px',
    overflow: 'hidden',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
  },
  productName: {
    gridArea: 'c',
    justifySelf: 'center',
    fontFamily: 'Roboto',
    fontWeight: '300',
    fontSize: '1.375rem',
    textAlign: 'center',
  },
};

export const numberInput = {
  wrap: {
    gridArea: 'b',
    justifySelf: 'end',
    width: '100px',
    height: '45px',
    border: '1px solid black',
    flexShrink: '0',
    //  padding: '2px 2.26ex 2px 2px',
    // borderRadius: '6px 3px 3px 6px',
    fontSize: '1rem',
  },
  input: {
    // borderRadius: '4px 2px 2px 4px',
    width: 'calc(100% - 45px)',
    height: '100%',
    fontFamily: 'Roboto',
    color: 'black',
    border: '0',
    margin: '0',
    fontWeight: 400,
    textAlign: 'center',
    padding: '0 0 0 0',
  },
  btn: {
    background: 'black',
    border: 0,
    borderRadius: 0,
    width: '45px',
    cursor: 'pointer',
    boxShadow: 'none!important',
    right: 0,
  },
  'btn:hover': {
    background: 'black',
  },
  'btn:active': {
    boxShadow: 0,
  },
  btnUp: {
    top: 0,
    height: '49%',
    borderRadius: 0,
  },
  btnDown: {
    height: '49%',
    top: '51%',
    borderRadius: 0,
  },
  arrowUp: {
    borderBottomColor: 'white',
    borderWidth: '0px 0.4rem 0.4rem',
    margin: '-0.2rem 0px 0px -0.3rem',
    // width: '1rem',
  },
  arrowDown: {
    borderTopColor: 'white',
    borderWidth: '0.4rem 0.4rem 0',
    margin: '-0.2rem 0px 0px -0.3rem',
  },
};
