import React, { Component, Fragment } from 'react';

import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';
import { NameCell } from '../../../atoms/Commons/Admin/NameCell';

class _EnchancedTableHead extends Component {
  shouldComponentUpdate(nextProps) {
    const { order, orderBy, columns } = this.props;

    return (
      nextProps.order !== order ||
      nextProps.orderBy !== orderBy ||
      columns.length !== nextProps.columns.length
    );
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      columns,
      order,
      orderBy,
      t,
      customName,
      classes,
      styleColumns,
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          {columns.map(col => {
            const { id, numeric, label, empty } = col;
            const isOrderById = orderBy === id;
            return (
              <Fragment key={id}>
                {empty ? (
                  <TableCell key={id} className={classes[id]}>
                    {label}
                  </TableCell>
                ) : (
                  <TableCell
                    key={id}
                    numeric={numeric}
                    sortDirection={isOrderById ? order : false}
                    className={styleColumns && classes[id]}
                  >
                    <Tooltip title="Sort" enterDelay={300}>
                      <TableSortLabel
                        active={isOrderById}
                        direction={order}
                        onClick={this.createSortHandler(id)}
                      >
                        {customName === id ? (
                          <NameCell name={t(`${col.label}`)} />
                        ) : (
                          t(`${col.label}`)
                        )}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )}
              </Fragment>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

export const EnchancedTableHead = compose(withNamespaces('admin'))(
  _EnchancedTableHead,
);
