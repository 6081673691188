import React from 'react';
import injectSheet from 'react-jss';
import { CheckIcon } from '../../../atoms/icons/CheckIcon';
import { staticAddressData } from '../../../../styles/Customer/CustomerProfile/staticAddressData';

const _StaticAddressData = ({
  classes,
  toggleEdit,
  address,
  nearestIntersection,
  instructions,
  enableDelete,
  deleteAddress,
  canSelect,
  selectAddress,
}) => (
  <div
    className={classes.container}
    role="presentation"
    onClick={canSelect && selectAddress}
  >
    <div className={classes.activeIndicator}>
      <CheckIcon className={classes.checkIcon} />
    </div>
    <div className={classes.leftSide}>
      <div className={classes.field}>
        <span>Address</span>
        <p>{address}</p>
      </div>
      <div className={classes.field}>
        <span>Nearest Intersection</span>
        <p>{nearestIntersection}</p>
      </div>
    </div>
    {instructions && (
      <div className={classes.rightSide}>
        <div className={classes.field}>
          <span>Instructions</span>
          <p>{instructions}</p>
        </div>
      </div>
    )}
    <div className={classes.buttons}>
      <button type="button" onClick={toggleEdit} className={classes.edit}>
        Edit
      </button>
      {enableDelete && (
        <button
          type="button"
          onClick={deleteAddress}
          className={classes.delete}
        >
          Delete
        </button>
      )}
    </div>
  </div>
);

export const StaticAddressData = injectSheet(staticAddressData)(
  _StaticAddressData,
);
