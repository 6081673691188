import { firstName } from 'ui/configs/formik/validators/firstName';
import { lastName } from 'ui/configs/formik/validators/lastName';
import { phone } from 'ui/configs/formik/validators/phone';
import { email } from 'ui/configs/formik/validators/email';
import { address } from 'ui/configs/formik/validators/address';
import { boozer } from 'ui/configs/formik/validators/boozer';
import { intersection } from 'ui/configs/formik/validators/intersection';
import { instructions } from 'ui/configs/formik/validators/instructions';
import { password } from 'ui/configs/formik/validators/password';
import { confirmPassword } from 'ui/configs/formik/validators/confirmPassword';
import { postalCode } from 'ui/configs/formik/validators/postalCode';
import { terms } from 'ui/configs/formik/validators/terms';
import { driverLicense } from 'ui/configs/formik/validators/driverLicense';
import { smartserve } from 'ui/configs/formik/validators/smartserve';
import { checkr } from 'ui/configs/formik/validators/checkr';
import { oldPassword } from 'ui/configs/formik/validators/oldPassword';
import { newPassword } from 'ui/configs/formik/validators/newPassword';
import { confirmNewPassword } from 'ui/configs/formik/validators/confirmNewPassword';
import { postalCodes } from 'ui/configs/formik/validators/postalCodes';
import { name } from 'ui/configs/formik/validators/name';
import { notes } from './notes';
import { editDataPassword } from './editDataPassword';
import { editDataConfirmPassword } from './editDataConfirmPassword';
import { productName } from 'ui/configs/formik/validators/productName';
import { fixedPrice } from 'ui/configs/formik/validators/fixedPrice';
import { priceGrid } from 'ui/configs/formik/validators/priceGrid';
import { uploadImage } from 'ui/configs/formik/validators/uploadImage';
import { addressPostalCode } from 'ui/configs/formik/validators/addressPostalCode';
import { unit } from 'ui/configs/formik/validators/unit';
import { otherReason } from 'ui/configs/formik/validators/otherReason';
import { operationalHours } from 'ui/configs/formik/validators/operationalHours';

// Todo change intersection => nearestIntersection
export const validators = {
  otherReason,
  unit,
  addressPostalCode,
  priceGrid,
  fixedPrice,
  uploadImage,
  productName,
  firstName,
  lastName,
  phone,
  email,
  address,
  boozer,
  intersection,
  instructions,
  password,
  confirmPassword,
  oldPassword,
  newPassword,
  confirmNewPassword,
  postalCode,
  terms,
  driverLicense,
  smartserve,
  checkr,
  postalCodes,
  name,
  notes,
  editDataPassword,
  editDataConfirmPassword,
  operationalHours,
};
