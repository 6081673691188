import { api } from 'services/api';
import { actions } from 'store/actions';

export const completeOrder = id => async dispatch => {
  try {
    await api.completeOrder(id);
    dispatch(actions.openModal.act('rateCustomer'));
  } catch (e) {
    return e;
  }
};
