import { createUseStyles } from 'react-jss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default createUseStyles({
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 19,
  },
  container: {
    width: '95%',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    zIndex: 20,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '2rem 0',
  },
  cancel: {
    padding: '0.75rem 3rem',
    fontSize: '1rem',
  },
  submit: {
    padding: '0.75rem 3rem',
    fontSize: '1rem',
  },
});
