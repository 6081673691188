import React from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { ModalWithButtons } from 'ui/components/organisms/Admin/Commons/ModalWithButtons';
import { confirmModal } from 'ui/styles/Admin/commons/confirmModal';

const _ConfirmModal = ({
  classes,
  actionText,
  confirm,
  closeModal,
  isOpenModal,
  id,
}) => (
  <ModalWithButtons
    $container={classes.container}
    onClose={closeModal}
    isOpen={isOpenModal}
    onClick={() => confirm(id)}
  >
    <h1>{`Are you sure you want to ${actionText}`}</h1>
  </ModalWithButtons>
);

export const ConfirmModal = compose(
  withNamespaces('admin'),
  injectSheet(confirmModal),
)(_ConfirmModal);
