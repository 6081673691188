import React from 'react';

import { withStyles, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import styles from '../../../../styles/Admin/commons/addButton';

const _AddButton = ({ text, handleClick, classes }) => (
  <Button variant="contained" onClick={handleClick}>
    <AddIcon className={classes.icon} />
    {text}
  </Button>
);

export const AddButton = withStyles(styles)(_AddButton);
