import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

const reasons = {
  drunk: 'Drunk',
  underAge: 'Under-Are',
  noShow: 'No-show',
  noEnoughMoney: 'Not enough money',
  abusiveOrViolent: 'Abusive or Violent',
  atTreatmentFacility: 'At treatment facility',
};

export const refuseOrderConfig = {
  mapPropsToValues: () => ({
    drunk: false,
    underAge: false,
    noShow: false,
    noEnoughMoney: false,
    abusiveOrViolent: false,
    atTreatmentFacility: false,
    otherReason: '',
  }),
  validationSchema: Yup.object().shape({
    drunk: Yup.bool(),
    underAge: Yup.bool(),
    noShow: Yup.bool(),
    noEnoughMoney: Yup.bool(),
    abusiveOrViolent: Yup.bool(),
    atTreatmentFacility: Yup.bool(),
    otherReason: validators.otherReason,
  }),
  handleSubmit: (values, { props: { refuseOrder, id } }) => {
    refuseOrder(values, id, reasons);
  },
  displayName: 'RefuseOrderForm',
  enableReinitialize: true,
};
