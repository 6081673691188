import React from 'react';
import { compose } from 'redux';
import injectSheet from 'react-jss';
import { withMobileDetect } from '../../../hoc/withMobileDetect';
import get from 'lodash/get';
import { TabSwitcher } from '../../molecules/Commons/Client/TabSwitcher';
import { SignUpCustomerForm } from '../../organisms/General/SignUp/SignUpCustomerForm';
import { SignUpDriverForm } from '../../organisms/General/SignUp/SignUpDriverForm';
import { signUpCard } from '../../../styles/General/SignUp/signUpCard';

class _SignUpCard extends React.Component {
  state = { activeTab: 'customer' };

  componentDidMount() {
    const activeTab = get(
      this.props,
      ['location', 'state', 'openTab'],
      'customer',
    );
    this.setState({ activeTab });
  }

  chooseCustomer = () => this.setState({ activeTab: 'customer' });

  chooseDriver = () => this.setState({ activeTab: 'driver' });

  render() {
    const { classes, isMobile } = this.props;
    const { activeTab } = this.state;
    return (
      <div className={classes.container}>
        {isMobile && <h3>Sign up as</h3>}
        <TabSwitcher
          activeTab={activeTab}
          leftClick={this.chooseCustomer}
          rightClick={this.chooseDriver}
          leftText={isMobile ? 'A customer' : 'Sign up as a customer'}
          rightText={isMobile ? 'A driver' : 'Sign up as a driver'}
        />
        {activeTab === 'customer' && <SignUpCustomerForm />}
        {activeTab === 'driver' && <SignUpDriverForm />}
      </div>
    );
  }
}

export const SignUpCard = compose(
  injectSheet(signUpCard),
  withMobileDetect(),
)(_SignUpCard);
