import { createSelector } from 'reselect';

const getDropDownData = deliveryZones =>
  deliveryZones
    ? deliveryZones
        .map(item => ({
          value: item.get('id'),
          label: item.get('name'),
        }))
        .push({
          value: 'noArea',
          label: 'Email when my area is covered',
        })
        .toJS()
    : [];

export const getChooseAreaDropDownData = createSelector(
  state => state.getIn(['app', 'deliveryZones']),
  deliveryZones => getDropDownData(deliveryZones),
);
