import React from 'react';

import { withStyles, Paper, Typography } from '@material-ui/core';

import styles from '../../../../styles/Admin/commons/pageTitle';

export const _PageTitle = ({ title, classes, children }) => (
  <Paper className={classes.paper}>
    <div className={classes.wrap}>
      <div>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
      </div>
      {children && <div className={classes.childWrap}>{children}</div>}
    </div>
  </Paper>
);

export const PageTitle = withStyles(styles)(_PageTitle);
