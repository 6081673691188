import React, { Fragment } from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import injectSheet from 'react-jss';
import { actions } from '../../../../../store/actions';
import { thunks } from '../../../../../services/thunks';
import { AddIcon } from '../../icons/AddIcon';
import { AddAddressModal } from '../../../organisms/Customer/Profile/AddAddressModal';
import { addAddress as addAddressStyles } from '../../../../styles/Customer/CustomerProfile/addAddress';

const mapStateToProps = state => ({
  isOpen: state.getIn(['app', 'modals', 'addAddress']),
});

const mapDispatchToProps = (dispatch, { admin }) => ({
  openModal: () => dispatch(actions.openModal.act('addAddress', null, false)),
  closeModal: () => dispatch(actions.closeModal.act('addAddress')),
  addAddress: (...props) =>
    dispatch(
      admin ? thunks.adminAddAddress(...props) : thunks.addAddress(...props),
    ),
  geocodeAddress: (...props) => dispatch(thunks.geocodeAddress(...props)),
});

const _AddAddress = ({
  classes,
  openModal,
  closeModal,
  addAddress,
  isOpen,
  geocodeAddress,
}) => (
  <Fragment>
    <div role="presentation" onClick={openModal} className={classes.container}>
      <AddIcon className={classes.icon} />
      Add Address
    </div>
    <AddAddressModal
      closeModal={closeModal}
      addAddress={addAddress}
      isOpen={isOpen}
      geocodeAddress={geocodeAddress}
    />
  </Fragment>
);

export const AddAddress = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(addAddressStyles),
)(_AddAddress);
