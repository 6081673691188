import React from 'react';
import { compose } from 'redux';
import { Button } from '@material-ui/core';
import { Modal } from '../../Commons/Modal';
import injectSheet from 'react-jss';
import { modalWithButtons } from 'ui/styles/Admin/commons/modalWithButtons';

const _ModalWithButtons = ({
  classes,
  onClose,
  onClick,
  children,
  isOpen,
  container,
}) => (
  <Modal
    $backstage={classes.backstage}
    $container={`${classes.container} ${container}`}
    onClose={onClose}
    isOpen={isOpen}
  >
    {children}
    <div className={classes.buttons}>
      <Button
        type="button"
        onClick={onClose}
        variant="contained"
        color="secondary"
        className={classes.button}
      >
        Cancel
      </Button>
      <Button
        type="button"
        onClick={onClick}
        variant="contained"
        className={classes.button}
      >
        Submit
      </Button>
    </div>
  </Modal>
);

export const ModalWithButtons = compose(injectSheet(modalWithButtons))(
  _ModalWithButtons,
);
