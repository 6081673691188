import React, { useMemo } from 'react';
import classNames from 'clsx';
import Option from './Option';
import { RichUtils } from 'draft-js';

const UPPERCASE = 'UPPERCASE';

export default ({ editorState, onChange }) => {
  const currentState = useMemo(
    () => editorState.getCurrentInlineStyle().has(UPPERCASE),
    [editorState],
  );

  const toggle = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, UPPERCASE);
    if (newState) {
      onChange(newState);
    }
  };

  return (
    <div
      className={classNames('rdw-custom-wrapper', 'rdw-link-buttons-wrapper')}
      aria-label="rdw-link-buttons-control"
    >
      <Option onClick={toggle} title="Order Now" active={currentState}>
        AA
      </Option>
    </div>
  );
};
