import { api } from 'services/api';
import { actions } from 'store/actions';
import { getOrderWithSecret, removeActiveOrder } from 'utils';
import { replace } from 'connected-react-router';

// Todo Допрацювати (statusId === 5 && !driverRated)
export const getCustomerSingleOrder = (
  match,
  startLoading,
  endLoading,
  secret,
) => async dispatch => {
  try {
    startLoading();
    const { data } = await api.getOrder(
      getOrderWithSecret(match.params.id, secret),
    );
    dispatch(actions.setSingleOrderData.act(data));
    const { driverRated, statusId } = data;
    if (statusId === 5 && !driverRated) {
      dispatch(actions.openModal.act('rateDriver'));
    }
    endLoading();
  } catch (e) {
    removeActiveOrder();
    dispatch(actions.setData.act(['activeOrder'], null));
    dispatch(replace('/'));
  }
};
