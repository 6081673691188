import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { thunks } from 'services/thunks';
import { LoadButton } from 'ui/components/atoms/Commons/Client/LoadButton';
import injectSheet from 'react-jss';
import { payNow } from 'ui/styles/Driver/Profile/payNow';

const mapStateToProps = state => ({
  last4: state.getIn(['app', 'user', 'card', 'last4']),
  dueBalance: state.getIn(['app', 'user', 'dueBalance']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      payDebt: thunks.payDebt,
    },
    dispatch,
  );

const _PayNow = ({
  classes,
  status,
  last4,
  dueBalance,
  payDebt,
  isLoading,
  startLoading,
}) =>
  status === 'Suspended' &&
  dueBalance > 0 &&
  last4 && (
    <LoadButton
      isLoading={isLoading}
      className={classes.payNow}
      onClick={() => payDebt(startLoading)}
    >
      Pay Now
    </LoadButton>
  );

export const PayNow = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCommonLoading({ initLoading: false }),
  injectSheet(payNow),
)(_PayNow);
