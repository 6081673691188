import React from 'react';
import injectSheet from 'react-jss';
import { UsersIcon } from '../../atoms/icons/UsersIcon';
import { CustomerStaticInfo } from '../../molecules/Customer/CustomerProfile/CustomerStaticInfo';
import { EditCustomerInfoForm } from '../../organisms/Customer/Profile/EditCustomerInfoForm';
import { customerProfileBlock } from '../../../styles/Customer/CustomerProfile/customerProfileBlock';

const _CustomerProfileBlock = ({ classes, data, toggleEdit, status }) => (
  <div className={classes.container}>
    <div className={classes.usersIconContainer}>
      <UsersIcon className={classes.icon} />
    </div>
    <CustomerStaticInfo status={status} toggleEdit={toggleEdit} data={data} />
    {status === 'entered' && (
      <EditCustomerInfoForm
        status={status}
        toggleEdit={toggleEdit}
        firstName={data.get('firstName')}
        lastName={data.get('lastName')}
        phone={data.get('phone')}
        email={data.get('email')}
      />
    )}
  </div>
);

export const CustomerProfileBlock = injectSheet(customerProfileBlock)(
  _CustomerProfileBlock,
);
