import get from 'lodash/get';
import qs from 'qs';
import { push } from 'connected-react-router';
import { api } from '../../api';

export const setNewPassword = (
  values,
  search,
  setErrors,
  setSubmitting,
) => async dispatch => {
  try {
    const { token, userId } = qs.parse(search, { ignoreQueryPrefix: true });
    const newToken = token.replace(/ /g, '+');
    await api.setNewPassword(values, newToken, userId);
    dispatch(push('/login'));
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
