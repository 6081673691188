import React from 'react';
import { NavBar } from 'ui/components/organisms/Admin/NavBar/NavBar';
import { withStyles } from '@material-ui/core';
import styles from '../../../styles/Admin/Dashboard';

const _Dashboard = ({ classes, children }) => (
  <div className={classes.shell}>
    <NavBar />
    <main className={classes.content}>
      <div className={classes.contentWrap}>{children}</div>
    </main>
  </div>
);

export const Dashboard = withStyles(styles)(_Dashboard);
