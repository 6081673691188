import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose, bindActionCreators } from 'redux';
import { thunks } from 'services/thunks';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { BaseTable } from 'ui/components/ecosystems/Admin/BaseTable';
import { ProductRow } from 'ui/components/organisms/Admin/Products/ProductRow';

const tableColumns = [
  { id: 'order', align: 'left', label: 'Order ID' },
  { id: 'name', align: 'left', label: 'Name' },
  { id: 'category', align: 'left', label: 'Category' },
  { id: 'price', align: 'left', label: 'Price', empty: true },
  { id: 'actions', align: 'left', label: 'Actions', empty: true },
];

const mapStateToProps = state => ({
  products: state.getIn(['app', 'admin', 'products']).toJS(),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProductsByAdmin: thunks.getProductsByAdmin,
    },
    dispatch,
  );

class _ProductsTable extends PureComponent {
  componentDidMount() {
    const { products, getProductsByAdmin, endLoading } = this.props;
    getProductsByAdmin(products, endLoading);
  }

  changePage = (event, page) => {
    const { products, getProductsByAdmin, endLoading } = this.props;
    getProductsByAdmin({ ...products, page: page + 1 }, endLoading);
  };

  changeRowsPerPage = data => {
    const { products, getProductsByAdmin, endLoading } = this.props;
    getProductsByAdmin(
      { ...products, pageSize: data.target.value },
      endLoading,
    );
  };

  onHandleRequestSort = (e, columnName) => {
    const { products, getProductsByAdmin, endLoading } = this.props;
    getProductsByAdmin(
      {
        ...products,
        orderBy: columnName,
        orderDirection: products.orderDirection === 'asc' ? 'desc' : 'asc',
      },
      endLoading,
    );
  };

  render() {
    const {
      products: {
        items,
        page,
        pageSize,
        totalRecords,
        orderDirection,
        orderBy,
      },
      isLoading,
    } = this.props;
    if (isLoading) return <div>Loading...</div>;
    return (
      <BaseTable
        data={items}
        order={orderDirection}
        sortColumn={orderBy}
        pageSize={pageSize}
        pageIndex={page}
        totalRecords={totalRecords}
        Row={ProductRow}
        columnsData={tableColumns}
        onChangePage={this.changePage}
        onChangeRowsPerPage={this.changeRowsPerPage}
        onHandleRequestSort={this.onHandleRequestSort}
      />
    );
  }
}

export const ProductsTable = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCommonLoading(),
)(_ProductsTable);
