import { Field } from 'formik';
import React, { useEffect } from 'react';
import { FormikNumberInputField } from '../../../atoms/Commons/Admin/FormikNumberInputField';

export const InputProps = {
  onFocus: e => e.target.select(),
  inputProps: {
    min: 0,
    precision: 2,
    strict: true,
    format: value => value + '$',
  },
};

export const PriceInputField = ({
  className,
  label,
  form: { setFieldValue, setFieldTouched },
  field: { name, value },
}) => {
  useEffect(
    () => {
      if (value && value.value == '') {
        setFieldValue(name, undefined);
        setFieldTouched(name, false);
      }
    },
    [value],
  );

  return (
    <Field
      name={`${name}.value`}
      label={label}
      component={FormikNumberInputField}
      InputProps={InputProps}
      className={className}
    />
  );
};
