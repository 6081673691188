import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { InventoryItem } from '../../../molecules/Driver/Inventory/InventoryItem';
import { inventeryCategory } from '../../../../styles/Driver/Inventory/inventeryCategory';

const mapStateToProps = state => ({
  deliveryZone: state.getIn(['app', 'user', 'deliveryZoneId']),
});

const _Category = ({
  classes,
  data,
  changeItemQuantity,
  categoryIndex,
  deliveryZone,
}) => {
  const items = useMemo(
    () => {
      const zone = +deliveryZone;

      return data
        .get('items')
        .toJS()
        .map(x =>
          Object.assign(x, {
            hide: deliveryZone && !x.deliveryZones.includes(zone),
          }),
        );
    },
    [data, deliveryZone],
  );

  return (
    <div className={classes.container}>
      <h3>{data.get('name')}</h3>
      {items.map(
        (item, index) =>
          !item.hide && (
            <InventoryItem
              key={item.productId}
              data={item}
              categoryName={data.get('name')}
              itemIndex={index}
              categoryIndex={categoryIndex}
              changeItemQuantity={changeItemQuantity}
            />
          ),
      )}
    </div>
  );
};

export const Category = compose(
  connect(mapStateToProps),
  injectSheet(inventeryCategory),
)(_Category);
