import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';
import { multiSelectField } from 'ui/styles/Admin/commons/select';
import { multiSelectStyles } from 'ui/styles/Admin/commons/select';

class _MultiSelectField extends Component {
  state = {
    values: [],
  };

  onChange = (value, e) => {
    this.props.field.onChange({
      target: {
        name: this.props.field.name,
        value
      },
    });
  };

  createOption = (label) => ({
    label: label.toUpperCase(),
    value: label.toUpperCase(),
  });

  onInputChange = (input, e) => {
    if (e.action === 'input-change') {
      console.log(input);
      this.props.onInputChange(input);
    }
  };

  render() {
    const {
      field: { value },
      error,
      classes,
      placeholder,
      options,
      $error,
      onInputChange,
    } = this.props;

    return (
      <>
        <CreatableSelect
          isMulti
          defaultValue={value || []}
          options={options}
          createOption={this.createOption}
          className={classes.creatableSelect}
          placeholder={placeholder}
          onChange={this.onChange}
          onFocus={() => onInputChange('')}
          onInputChange={this.onInputChange}
          styles={multiSelectStyles}
        />
        {error && <span className={classes.error}>{error}</span>}
      </>
    );
  }
}

export const MultiSelectField = withStyles(multiSelectField)(_MultiSelectField);
