import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getDeliveryZoneById = id => async dispatch => {
  try {
    const response = await api.getDeliveryZoneById(id);
    let data = response.data;
    data.postalCodes = response.data.postalCodes.map(el => {
      return { label: el, value: el };
    });
    dispatch(actions.setDeliveryZoneData.act(data));
  } catch (e) {
    return e;
  }
};
