import { media, palette } from '../../theme';

export const customerStaticInfo = {
  container: {
    gridArea: 'b',
    width: '100%',
    height: '100%',
    display: ({ status }) =>
      status === 'entering' || status === 'entered' || status === 'exiting'
        ? 'none'
        : 'grid',
    gridTemplateColumns: '5% 90% 5%',
    gridTemplateRows: '25% 20% auto',
    gridTemplateAreas: `
      '. a .'
      '. b .'
      '. c .'
    `,
    [media.desktop]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: '30% 70%',
      gridTemplateAreas: `
        'a'
        'b'
      `,
    },
  },
  userName: {
    gridArea: 'b',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    [media.desktop]: {
      gridArea: 'a',
      fontSize: '1.75rem',
    },
  },
  emailPhoneWrapper: {
    gridArea: 'c',
    display: 'flex',
    flexDirection: 'column',
    color: '#444343',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    lineHeight: '2rem',
    [media.desktop]: {
      gridArea: 'b',
    },
  },
  edit: {
    gridArea: 'a',
    justifySelf: 'end',
    [media.desktop]: {
      alignSelf: 'start',
      cursor: 'pointer',
      marginTop: '-0.5rem',
      '&:hover': {
        color: palette.yellow,
        fill: palette.yellow,
      },
    },
  },
  editContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& span': {
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: '300',
    },
  },
  editIcon: {
    height: '1.5rem',
    marginLeft: '0.5rem',
  },
};
