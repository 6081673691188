import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const customerTermsConfig = {
  mapPropsToValues: () => ({
    terms: true,
  }),
  validationSchema: Yup.object().shape({
    terms: validators.terms,
  }),
  handleSubmit: (
    values,
    { props: { sendCustomerOrder }, setErrors, setSubmitting },
  ) => {
    sendCustomerOrder(setErrors, setSubmitting);
  },
  displayName: 'CustomerTermsForm',
};
