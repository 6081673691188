import React from 'react';

export const withMobileDetect = () => WrappedComponent =>
  class WithMobileDetect extends React.PureComponent {
    state = { width: window.innerWidth };

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };

    render() {
      const { width } = this.state;
      const isMobile = width < 1200;
      return <WrappedComponent {...this.props} isMobile={isMobile} />;
    }
  };
