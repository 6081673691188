import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const orderItemsList = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto',
    marginBottom: '2rem',
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    [media.mobile]: {
      margin: '2rem 0',
      fontSize: '1.5rem',
    },
    [media.desktop]: {
      margin: '2rem',
      fontSize: '1.75rem',
    },
  },
  itemRow: {
    width: '60%',
    display: 'grid',
    gridTemplateRows: 'auto',
    lineHeight: '2rem',
    fontWeight: 300,
    '& > span:last-child': {
      justifySelf: 'end',
    },
    [media.mobile]: {
      width: '100%',
      gridTemplateColumns: '10% 45% 20% 25%',
    },
    [media.desktop]: {
      width: '60%',
      gridTemplateColumns: '10% 50% 20% 20%',
    },
  },
  paymentRow: {
    composes: '$itemRow',
    marginTop: '1rem',
    lineHeight: '1.5rem',
    [media.mobile]: {
      width: '100%',
    },
  },
  total: {
    gridArea: '1 / 3 / 1 / 5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 -5%',
    padding: '0.5rem 5%',
    backgroundColor: '#F3F3F3',
    lineHeight: '2rem',
    '& > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& > div > span:first-child': {
      fontWeight: 500,
    },
    [media.mobile]: {
      gridArea: '1 / 1 / 1 / 5',
      margin: '0',
      padding: '1rem 10%',
    },
  },
  submit: {
    ...button.yellow,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem auto 5rem auto',
  },
};
