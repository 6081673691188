import React from 'react';
import { createPortal } from 'react-dom';

const stopPropagation = e => e.stopPropagation();

export const Modal = ({
  isOpen,
  children,
  onClose,
  renderIn = 'modal',
  $backstage,
  $container,
}) =>
  isOpen
    ? createPortal(
        <div role="presentation" onClick={onClose} className={$backstage}>
          <div
            role="presentation"
            onClick={stopPropagation}
            className={$container}
          >
            {children}
          </div>
        </div>,
        document.getElementById(renderIn),
      )
    : null;
