import React from 'react';
import { PaymentIcon } from 'ui/components/atoms/Driver/Profile/PaymentIcon';
import injectSheet from 'react-jss';
import { card } from 'ui/styles/Driver/Profile/card';

const _Card = ({ classes, last4, brand }) => (
  <div className={classes.container}>
    <PaymentIcon className={classes.icon} brand={brand} />
    <span>&#9679;&#9679;&#9679;&#9679;</span>
    <span>&#9679;&#9679;&#9679;&#9679;</span>
    <span>&#9679;&#9679;&#9679;&#9679;</span>
    <span>{last4}</span>
  </div>
);

export const Card = injectSheet(card)(_Card);
