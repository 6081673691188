import { input } from '../../Commons/input';
import { button } from '../../Commons/button';
import { media, palette } from '../../theme';

export const addCustomer = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10001,
    flexShrink: 0,
  },
  container: {
    width: '950px',
    maxHeight: '90vh',
    overflowY: 'scroll',
    display: 'block',
  },
  /*
  container: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    overflow: 'auto',
    [media.desktop]: {
      width: '70%',
      maxWidth: '70%',
    },
  },
  */
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    fontFamily: 'Roboto',
    fontWeight: '300',
    textAlign: 'center',
    [media.mobile]: {
      fontSize: '1rem',
      margin: '1.5rem 0 1rem 0',
    },
    [media.desktop]: {
      margin: '2rem 0',
      fontSize: '1.375rem',
    },
  },
  form: {
    gridArea: 'b',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    [media.mobile]: {
      width: '90%',
    },
    [media.desktop]: {
      width: '90%',
    },
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: '7.5rem auto',
    },
  },
  inputHalfContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      width: '55%',
      gridTemplateColumns: '7.5rem auto',
    },
  },
  inputHalfContainerWithoutLabel: {
    ...input.container,

    [media.desktop]: {
      ...input.container[media.desktop],
      width: '45%',
      gridTemplateColumns: '2.5rem auto',
    },
  },
  inputLabel: {
    ...input.label,
    lineHeight: '1.4rem',
  },
  input: {
    ...input.input,
    '&::placeholder': {
      color: '#a9a9a9',
    },
    '&:focus': {
      borderColor: palette.black,
    },
  },
  inputError: { ...input.error },
  textareaContainer: {
    ...input.container,
    [media.mobile]: {
      height: '10rem',
      gridTemplateRows: '2.25rem auto 1.75rem',
    },
    [media.desktop]: {
      ...input.container[media.desktop],
      height: '8rem',
      gridTemplateColumns: '7.5rem auto',
      gridTemplateRows: '2.25rem auto 1.75rem',
      gridTemplateAreas: `
      'a b'
      '. b'
      '. c'
      `,
    },
  },
  textarea: {
    ...input.input,
    padding: '0.5rem',
    resize: 'none',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  submit: {
    ...button.yellow,
    margin: '1rem auto',
    backgroundColor: palette.blue,
    border: `1px solid ${palette.blue}`,
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
