import React, { useCallback } from 'react';
import clsx from 'clsx';

export default ({
  children,
  value,
  onClick,
  onSelect,
  setHighlighted,
  index,
  disabled,
  active,
  highlighted,
  className,
  activeClassName = '',
  disabledClassName = '',
  highlightedClassName = '',
  title,
}) => {
  const onClickHandler = useCallback(
    event => {
      if (!disabled) {
        if (onSelect) {
          onSelect(value);
        }
        if (onClick) {
          event.stopPropagation();
          onClick(value);
        }
      }
    },
    [onSelect, onClick, value, disabled],
  );

  const setHighlightedHandler = useCallback(
    () => {
      setHighlighted(index);
    },
    [setHighlighted, index],
  );

  const resetHighlightedHandler = useCallback(
    () => {
      setHighlighted(-1);
    },
    [setHighlighted],
  );

  return (
    <li
      className={clsx('rdw-dropdownoption-default', className, {
        [`rdw-dropdownoption-active ${activeClassName}`]: active,
        [`rdw-dropdownoption-highlighted ${highlightedClassName}`]: highlighted,
        [`rdw-dropdownoption-disabled ${disabledClassName}`]: disabled,
      })}
      onMouseEnter={setHighlightedHandler}
      onMouseLeave={resetHighlightedHandler}
      onClick={onClickHandler}
      title={title}
    >
      {children}
    </li>
  );
};
