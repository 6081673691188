import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { withStyles, TableCell, Button } from '@material-ui/core';
import { actions } from '../../../../../store/actions';
import { thunks } from '../../../../../services/thunks';
import { ConfirmModal } from '../../../organisms/Admin/Commons/ConfirmModal';
import { AddReasonModal } from '../../../organisms/Admin/Commons/AddReasonModal';
import { EditCustomer } from '../../../organisms/Admin/Customer/EditCustomer';
import styles from '../../../../styles/Admin/RowActions';

const mapStateToProps = (state, { id }) => ({
  isOpenCustomerEditModal: state.getIn(['app', 'modals', `editCustomer-${id}`]),
  selectedCustomer: state.getIn(['app', 'selectedCustomer']),
});

const mapDispatchToProps = (dispatch, { id }) => ({
  deleteCustomer: () => dispatch(thunks.deleteCustomer(id)),
  activateCustomer: () => dispatch(thunks.activateCustomer(id)),
  blockCustomer: data => dispatch(thunks.blockCustomer(data)),
  editCustomer: data => dispatch(thunks.editCustomer(id, data)),
  getCustomerDataById: () => dispatch(thunks.getCustomerById(id)),
  closeEditCustomerModal: () =>
    dispatch(actions.closeModal.act(`editCustomer-${id}`)),
});

class _CustomerActions extends Component {
  state = {
    isOpenConfirmModal: false,
    isOpenReasonModal: false,
    isOpenEditModal: false,
  };

  handleEdit = () => {
    this.props.getCustomerDataById(this.props.id);
  };

  handleBlock = () => {
    this.toggleModal('isOpenReasonModal');
  };

  handleDelete = () => {
    this.toggleModal('isOpenConfirmModal');
  };

  handleActivate = () => {
    this.props.activateCustomer(this.props.id);
  };

  addReason = formData => {
    this.props.blockCustomer({ id: this.props.id, reason: formData.reason });
    this.toggleModal('isOpenReasonModal');
  };

  toggleModal = type => {
    this.setState(prevState => ({ [type]: !prevState[type] }));
  };

  submit = data => {
    this.props.editCustomer(data);
    this.closeEditModal();
  };

  closeEditModal = () => {
    this.toggleModal('isOpenEditModal');
  };
  /*
  componentDidUpdate(prevProps) {
    /*console.log('Prev castomer :', prevProps.selectedCustomer);
    console.log('this.props castomer :', this.props.selectedCustomer);
    if (
      //!prevProps.selectedCustomer &&
      //this.props.selectedCustomer &&
      this.props.selectedCustomer.id === this.props.id
    ) {
      console.log('update');
      this.toggleModal('isOpenEditModal');
    }
  }
*/

  render() {
    const {
      classes: { button },
      t,
      id,
      deleteCustomer,
      name,
      isBlocked,
      selectedCustomer,
      isOpenCustomerEditModal,
      closeEditCustomerModal,
    } = this.props;

    const { isOpenConfirmModal, isOpenReasonModal } = this.state;

    return (
      <TableCell>
        <div>
          <Button
            variant="contained"
            className={button}
            onClick={this.handleEdit}
            disabled={isBlocked}
          >
            {t('Edit')}
          </Button>

          <Button
            variant="contained"
            className={button}
            onClick={this.handleBlock}
            disabled={isBlocked}
          >
            {t('Block')}
          </Button>
          <Button
            variant="contained"
            className={button}
            onClick={this.handleActivate}
            disabled={!isBlocked}
          >
            {t('Reactivate')}
          </Button>
          <Button
            variant="contained"
            className={button}
            onClick={this.handleDelete}
          >
            {t('Delete')}
          </Button>

          {isOpenConfirmModal && (
            <ConfirmModal
              id={id}
              isOpenModal={isOpenConfirmModal}
              confirm={deleteCustomer}
              closeModal={() => this.toggleModal('isOpenConfirmModal')}
              actionText={`delete the customer ${name}?`}
            />
          )}

          {isOpenReasonModal && (
            <AddReasonModal
              id={id}
              addReason={this.addReason}
              closeModal={() => this.toggleModal('isOpenReasonModal')}
              isOpenModal={isOpenReasonModal}
              header="Indicate the reason for customer blocking"
            />
          )}

          {isOpenCustomerEditModal && (
            <EditCustomer
              id={id}
              isOpenModal={isOpenCustomerEditModal}
              closeModal={closeEditCustomerModal}
              {...selectedCustomer}
              editCustomer={this.submit}
              addresses={selectedCustomer.customerAddresses}
            />
          )}
        </div>
      </TableCell>
    );
  }
}

export const CustomerActions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withStyles(styles),
)(_CustomerActions);
