import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const deleteDeliveryZone = id => async dispatch => {
  try {
    await api.deleteDeliveryZone(id);
    dispatch(actions.deleteDeliveryZone.act(id));
    dispatch(actions.needToUpdateData.act(true));
  } catch (e) {
    return e;
  }
};
