import get from 'lodash/get';
import { api } from 'services/api';
import { push } from 'connected-react-router';
import { actions } from 'store/actions';
import { getUserInfo, setUserData } from 'services/utils';

export const adminLogin = (
  values,
  role,
  setErrors,
  setSubmitting,
) => async dispatch => {
  try {
    const loginResponse = await api.login(values, role);
    setUserData(loginResponse.data);
    dispatch(actions.login.act(getUserInfo()));
    dispatch(push('/admin/drivers'));
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
