import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';

import { withStyles, Button } from '@material-ui/core';

import { thunks } from '../../../../../services/thunks';
import { loginConfig } from '../../../../configs/formik/loginConfig';

import { InputField } from '../../../atoms/Commons/Admin/InputField';
import { Snackbar } from '../../../atoms/Commons/Admin/Snackbar';

import styles from '../../../../styles/Admin/LoginForm';

const mapDispatchToProps = dispatch => ({
  login: (...props) => dispatch(thunks.adminLogin(...props)),
});

class _LoginForm extends Component {
  handleClose = () => this.props.setErrors({ backError: null });

  render() {
    const {
      classes: { inputWrap, submitWrap, submit },
      touched,
      t,
      errors,
      errors: { backError },
    } = this.props;

    return (
      <Form>
        <div className={inputWrap}>
          <Field
            name="email"
            component={InputField}
            type="text"
            placeholder={t('Email')}
            error={touched.email && errors.email}
          />
        </div>

        <div>
          <Field
            name="password"
            component={InputField}
            type="password"
            placeholder={t('Password')}
            error={touched.password && errors.password}
          />
        </div>

        <div className={submitWrap}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={submit}
          >
            {t('Sign in')}
          </Button>
        </div>

        <Snackbar
          open={!!backError}
          onClose={this.handleClose}
          onClick={this.handleClose}
          message={backError}
        />
      </Form>
    );
  }
}

export const LoginForm = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withFormik(loginConfig),
  withStyles(styles),
)(_LoginForm);
