import { button } from 'ui/styles/Commons/button';
import { media, maxWidth } from 'ui/styles/theme';

export const inventory = {
  container: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [media.mobile]: {
      maxWidth: maxWidth.mobile,
    },
    [media.desktop]: {
      maxWidth: maxWidth.desktop,
    },
  },
  hr: {
    width: '100vw',
    display: 'block',
    height: '1px',
    backgroundColor: '#E1E1E1',
    [media.desktop]: {
      display: 'none',
    },
  },
  inventory: {
    width: '100%',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: '300',
    backgroundColor: '#E1E1E1',
    margin: '1rem 0',
  },
  updateInventory: {
    ...button.yellow,
    margin: '2rem 0',
    [media.desktop]: {
      ...button.yellow[media.desktop],
      margin: '3rem 0 5rem 0',
    },
  },
};
