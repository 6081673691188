import { push } from 'connected-react-router';
import { api } from 'services/api';
import { deleteUserData, setErrorToStore } from 'services/utils';
import { actions } from '../../../store/actions';
import { thunks } from 'services/thunks/index';

export const logout = ({ isBlocked = false }) => async dispatch => {
  try {
    await api.logout();
    deleteUserData();
    dispatch(actions.logout.act());
    dispatch(thunks.setLocalDataToStore());
    dispatch(push('/'));
    if (isBlocked) {
      dispatch(
        actions.setError.act(`Sorry, but your account was blocked.
        Please, contact with us by email: shitomi@protonmail.com`),
      );
    }
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
