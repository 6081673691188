import { createSelector } from 'reselect';

const handler = (deliveryZones, activeId) => {
  if (!deliveryZones) return null;

  if (deliveryZones.size > 0 && !activeId) {
   return {
      value: deliveryZones.getIn([0, 'id']),
      label:deliveryZones.getIn([0, 'name']),
    }
  }

  const activeZone = deliveryZones.find(zone => +zone.get('id') === +activeId);
  return activeZone
    ? {
        value: activeZone.get('id'),
        label: activeZone.get('name'),
      }
    : null;
};

export const getActiveDeliveryZone = createSelector(
  state => state.getIn(['app', 'deliveryZones']),
  state => state.getIn(['app', 'user', 'deliveryZoneId']),
  (deliveryZones, activeId) => handler(deliveryZones, activeId),
);
