import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const addProductConfig = {
  mapPropsToValues: ({
    product = {
      productName: '',
      unit: '',
      order: '',
      category: 1,
      image: null,
      prices: {},
    },
  }) => product,
  validationSchema: Yup.object().shape({
    category: Yup.number().required(),
    productName: validators.productName,
    unit: validators.unit,
    order: Yup.number().required().label('Order'),
    image: validators.uploadImage,
    prices: validators.priceGrid,
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    if (typeof values.image === 'string') {
      onSubmit(values);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(values.image);
      reader.onloadend = () => {
        onSubmit({
          ...values,
          uploadImage: reader.result.split(',').pop(), // remove data:image/jpeg;base64,
        });
      };
    }
  },
  displayName: 'AddProduct',
};
