import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const addReasonConfig = {
  mapPropsToValues: () => ({
    reason: '',
  }),
  validationSchema: Yup.object().shape({
    reason: validators.notes,
  }),
  handleSubmit: (
    values,
    { props: { addReason }, setErrors, setSubmitting },
  ) => {
    addReason(values, setErrors, setSubmitting);
  },
  displayName: 'AddNotes',
};
