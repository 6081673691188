import React from 'react';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { AddressField } from 'ui/components/organisms/Commons/AddressField';
import { Modal } from '../../Commons/Modal';
import { addAddressConfig } from '../../../../configs/formik/addAddressConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { addAddressForm } from '../../../../styles/Customer/CustomerProfile/addAddressForm';
import { TextArea } from '../../../atoms/Commons/Client/TextArea';

const _AddAddressModal = ({
  classes,
  errors,
  touched,
  closeModal,
  isOpen,
  geocodeAddress,
}) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
    onClose={closeModal}
  >
    <h1 className={classes.title}>Add New Address</h1>
    <Form className={classes.form} id="addAddressForm">
      <Field
        component={AddressField}
        type="text"
        name="address"
        labelText="Address"
        placeholder="Start to type your address"
        error={touched.address && errors.postalCode}
        geocodeAddress={geocodeAddress}
      />
      <Field
        component={Input}
        type="text"
        name="boozer"
        placeholder="Unit Number and Buzzer"
        error={touched.boozer && errors.boozer}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="text"
        name="nearestIntersection"
        placeholder="Nearest main intersection"
        labelText="Nearest main intersection"
        error={touched.nearestIntersection && errors.nearestIntersection}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={TextArea}
        name="instructions"
        labelText="Instructions"
        placeholder="eg. Meet in lobby, Name for concierge, Basement or back, etc."
        error={touched.instructions && errors.instructions}
        $container={classes.textareaContainer}
        $label={classes.inputLabel}
        $textarea={classes.textarea}
        $error={classes.inputError}
      />
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
    <div className={classes.buttons}>
      <button type="button" onClick={closeModal} className={classes.cancel}>
        Cancel
      </button>
      <button type="submit" form="addAddressForm" className={classes.add}>
        Add
      </button>
    </div>
  </Modal>
);

export const AddAddressModal = compose(
  withNamespaces(),
  withFormik(addAddressConfig),
  injectSheet(addAddressForm),
)(_AddAddressModal);
