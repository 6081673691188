import React from 'react';
import NumericInput from 'react-numeric-input';
import injectSheet from 'react-jss';
import { commonAPI } from '../../../../../services/api/apiUrl';
import {
  spiritProduct,
  numberInput,
} from '../../../../styles/Customer/CustomerHome/spiritProduct';
import { formatPrice } from '../../../../../utils';
// Todo Переписати на класс

const _SpiritProduct = ({ classes, data, id, setProductQuantity }) => (
  <div className={classes.container}>
    <img src={`${commonAPI}${data.get('imagePath')}`} alt={data.get('name')} />
    <span className={classes.price}>
      {formatPrice(data.getIn(['productPrices', 0, 'price']))}
    </span>
    <div className={classes.wrapper}>
      <span className={classes.capacity}>{data.get('unit')}</span>
      <NumericInput
        mobile={false}
        style={numberInput}
        min={0}
        max={3}
        value={data.get('currentQuantity')}
        strict
        onChange={value => setProductQuantity(id, value)}
      />
    </div>
    <span className={classes.productName}>{data.get('name')}</span>
  </div>
);

export const SpiritProduct = injectSheet(spiritProduct)(_SpiritProduct);
