export const timeSettingsForm = {
  form: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '3rem 0',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
  },
  description: {
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'center',
    fontFamily: 'Roboto',
    lineHeight: '1.5rem',
    '& > span:nth-child(1)': {
      fontSize: '1.5rem',
    },
    '& > span:nth-child(2)': {
      color: '#00bcd4',
    },
    '& > p': {
      color: '#f44336',
    },
  },
  descriptionTop: {
    alignSelf: 'flex-start',
    height: 56,
    justifyContent: 'center',
  },
  checkBox: {
    margin: 0,
    position: 'relative',

    '& > span:first-child': {
      position: 'absolute',
      right: '100%',
    },

    '& > span:last-child': {
      fontSize: '1.5rem',
    }
  },
  inputField: {
    width: '100%',
    margin: '0 2rem',
  },
  timePicker: {
    width: '9rem',
    margin: '0 2rem',
  },
  buttons: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5rem',
  },
  save: {
    padding: '0.75rem 2.5rem',
    minWidth: '9rem',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    backgroundColor: '#03a9f4',
    '&:hover': {
      backgroundColor: '#2F80EC',
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  cancel: {
    composes: '$save',
    backgroundColor: '#9e9e9e',
    '&:hover': {
      backgroundColor: '#757575',
    },
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
