import React from 'react';
import { Checkbox } from '../../../atoms/Commons/Client/Checkbox';

export const FormCheckbox = ({
  field: { name, value },
  form: { setFieldValue },
  text,
  error,
  $container,
  $error,
}) => (
  <div className={$container}>
    <Checkbox onChange={setFieldValue} text={text} name={name} value={value} />
    <span className={$error}>{error}</span>
  </div>
);
