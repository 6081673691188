import { getDeliveryZones } from 'services/api/admin/deliveryZone/getDeliveryZones';
import { getDriversList } from 'services/api/admin/driver/getDriversList';
import { getCustomersList } from 'services/api/admin/customer/getCustomersList';
import { addDeliveryZone } from 'services/api/admin/deliveryZone/addDeliveryZone';
import { addDriver } from 'services/api/admin/driver/addDriver';
import { addCustomer } from 'services/api/admin/customer/addCustomer';
import { getPostalCodes } from 'services/api/admin/deliveryZone/getPostalCodes';
import { deleteCustomer } from 'services/api/admin/customer/deleteCustomer';
import { deleteDriver } from 'services/api/admin/driver/deleteDriver';
import { blockCustomer } from './customer/blockCustomer';
import { blockDriver } from './driver/blockDriver';
import { activateDriver } from './driver/activateDriver';
import { suspendDriver } from './driver/suspendDriver';
import { unblockCustomer } from './customer/unblockCustomer';
import { deleteDeliveryZone } from './deliveryZone/deleteDeliveryZone';
import { editCustomer } from './customer/editCustomer';
import { editDriver } from './driver/editDriver';
import { editDeliveryZone } from './deliveryZone/editDeliveryZone';
import { getCustomerById } from './customer/getCustomerById';
import { getDeliveryZoneById } from './deliveryZone/getDeliveryZoneById';
import { getDriverById } from './driver/getDriverById';
import { getOrders } from './order/getOrders';
import { adminCancelOrder } from 'services/api/admin/order/adminCancelOrder';
import { setDriverRating } from './driver/setDriverRating';
import { setCustomerRating } from './customer/setCustomerRating';
import { getTransactions } from './transaction/getTransactions';
import { getProductsByAdmin } from 'services/api/admin/products/getProductsByAdmin';
import { addProduct } from 'services/api/admin/products/addProduct';
import { getProduct } from 'services/api/admin/products/getProduct';
import { deleteProduct } from 'services/api/admin/products/deleteProduct';
import { getSchedule } from 'services/api/admin/schedule/getSchedule';
import { editSchedule } from 'services/api/admin/schedule/editSchedule';
import { editProduct } from 'services/api/admin/products/editProduct';

export const adminApi = {
  editProduct,
  editSchedule,
  getSchedule,
  deleteProduct,
  getProduct,
  addProduct,
  getProductsByAdmin,
  getDeliveryZones,
  getDriversList,
  getCustomersList,
  addDeliveryZone,
  addDriver,
  addCustomer,
  getPostalCodes,
  deleteCustomer,
  deleteDriver,
  blockDriver,
  blockCustomer,
  unblockCustomer,
  activateDriver,
  suspendDriver,
  deleteDeliveryZone,
  editDriver,
  editCustomer,
  editDeliveryZone,
  getCustomerById,
  getDeliveryZoneById,
  getDriverById,
  getOrders,
  adminCancelOrder,
  setCustomerRating,
  setDriverRating,
  getTransactions,
};
