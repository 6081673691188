import { input } from 'ui/styles/Commons/input';
import { media, palette } from 'ui/styles/theme';

export const addressField = {
  inputContainer: {
    ...input.container,
    height: '5rem',
    gridTemplateRows: '1.5rem 3.25rem auto',
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: '7.5rem auto',
      height: '5rem',
      gridTemplateRows: '3.25rem auto',
    },
  },
  label: {
    ...input.label,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10pt',
    lineHeight: '1.4rem',
  },
  input: {
    ...input.input,
    background: '#f5f5f5',
    borderColor: '#a1a1a1',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  suggestionContainer: {
    gridArea: 'c',
    zIndex: 50,
    backgroundColor: 'white',
    border: `1px solid ${palette.yellow}`,
    marginTop: '3px',
    padding: '0.5rem',
  },
  suggestion: {
    margin: '0.5rem',
    fontFamily: 'Roboto',
    lineHeight: '1.5rem',
    cursor: 'pointer',
  },
  activeSuggestion: {
    composes: '$suggestion',
    color: palette.yellow,
  },
  error: { ...input.error },
};
