import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'clsx';

const stopPropagation = event => {
  event.stopPropagation();
};

export default ({
  modalHandler,
  value,
  children,
  onChange,
  className,
  onExpandEvent,
  optionWrapperClassName,
  ariaLabel,
  title,
}) => {
  const [toggled, setToggled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [highlighted, setHighlighted] = useState(-1);

  const toggleExpansion = useCallback(() => {
    setToggled(true);
    setExpanded(x => !x);
  }, []);

  const collapse = useCallback(() => {
    setToggled(toggled => {
      if (!toggled) {
        setExpanded(false);
      }
      return false;
    });
  }, []);

  const onSelect = useCallback(
    value => {
      if (onChange) {
        onChange(value);
      }
      toggleExpansion();
    },
    [toggleExpansion, onChange],
  );

  useEffect(() => {
    modalHandler.registerCallBack(collapse);
    return () => {
      modalHandler.deregisterCallBack(collapse);
    };
  }, []);

  useEffect(
    () => {
      if (!expanded) {
        setHighlighted(-1);
      }
    },
    [expanded],
  );

  return (
    <div
      className={classNames('rdw-dropdown-wrapper', className)}
      aria-expanded={expanded}
      aria-label={ariaLabel || 'rdw-dropdown'}
    >
      <a
        className="rdw-dropdown-selectedtext"
        onClick={toggleExpansion}
        title={title}
      >
        {value}
        <div
          className={classNames({
            'rdw-dropdown-carettoclose': expanded,
            'rdw-dropdown-carettoopen': !expanded,
          })}
        />
      </a>
      {expanded ? (
        <ul
          className={classNames(
            'rdw-dropdown-optionwrapper',
            optionWrapperClassName,
          )}
          onClick={stopPropagation}
        >
          {React.Children.map(children, (option, index) => {
            const temp =
              option &&
              React.cloneElement(option, {
                onSelect,
                highlighted: highlighted === index,
                setHighlighted,
                index,
              });
            return temp;
          })}
        </ul>
      ) : (
        undefined
      )}
    </div>
  );
};
