const styles = theme => ({
  inputWrap: {
    paddingBottom: theme.spacing.unit * 2,
  },
  submitWrap: {
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
  },
  submit: {
    marginTop: theme.spacing.unit * 4,
  },
});

export default styles;
