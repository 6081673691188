import React from 'react';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { customerTermsConfig } from '../../../../configs/formik/customerTermsConfig';
import { FormCheckbox } from '../../../molecules/Commons/Client/FormCheckbox';
import { customerTermsForm } from '../../../../styles/Customer/CompleteOrder/customerTermsForm';

const _CustomerTermsForm = ({ classes, errors, touched, id }) => {
  const termsText = (
    <span>
      I agree with&nbsp;
      <NavLink to="/customer/terms" className={classes.termsLink}>
        Terms and Conditions
      </NavLink>
    </span>
  );
  return (
    <Form className={classes.form} id={id}>
      <Field
        component={FormCheckbox}
        name="terms"
        error={touched.terms && errors.terms}
        text={termsText}
        $container={classes.termsContainer}
        $error={classes.termsError}
      />
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
  );
};

export const CustomerTermsForm = compose(
  withNamespaces(),
  withFormik(customerTermsConfig),
  injectSheet(customerTermsForm),
)(_CustomerTermsForm);
