import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { scroller } from 'react-scroll';

class ScrollToTop extends Component {
     componentDidMount() {
        scroller.scrollTo("scrollToTop", {
          duration: 100,
          delay: 0,
          smooth: 'easeInOut',
        });
     }

     render() {
         return (
          <div name="scrollToTop" {...this.props}/>
         )
     }
 }

export default withRouter(ScrollToTop)