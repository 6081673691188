import React, { PureComponent } from 'react';
import Transition from 'react-transition-group/Transition';
import { getDriverStatus } from 'selectors/getDriverStatus';
import { UsersIcon } from 'ui/components/atoms/icons/UsersIcon';
import { PersonalInfoBlock } from 'ui/components/molecules/Driver/DriverProfile/PersonalInfoBlock';
import { EditDriverInfoForm } from 'ui/components/organisms/Driver/Profile/EditPersonalInfoForm';
import injectSheet from 'react-jss';
import { driverPersonalInfo } from 'ui/styles/Driver/Profile/driverPersonalInfo';

class _DriverPersonalInfo extends PureComponent {
  state = { isEdit: false };

  toggleEdit = () => {
    this.setState(prevState => ({ isEdit: !prevState.isEdit }));
  };

  render() {
    const { classes, user } = this.props;
    const driverStatus = getDriverStatus(user);
    return (
      <Transition in={this.state.isEdit} timeout={250}>
        {status => (
          <div
            className={
              status === 'entering' || status === 'entered'
                ? classes.editContainer
                : classes.container
            }
          >
            <div className={classes.usersIconContainer}>
              <UsersIcon className={classes.icon} />
            </div>
            {status === 'exited' && (
              <PersonalInfoBlock
                status={status}
                toggleEdit={this.toggleEdit}
                data={user}
                driverStatus={driverStatus}
              />
            )}
            {status === 'entered' && (
              <EditDriverInfoForm
                status={status}
                toggleEdit={this.toggleEdit}
                user={user}
              />
            )}
          </div>
        )}
      </Transition>
    );
  }
}

export const DriverPersonalInfo = injectSheet(driverPersonalInfo)(
  _DriverPersonalInfo,
);
