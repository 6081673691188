import React, { Fragment } from 'react';
import StarRatings from 'react-star-ratings';
import { compose } from 'redux';
import { RatingStar } from 'ui/components/atoms/icons/RatingStar';
import { UserRoundIcon } from 'ui/components/atoms/icons/UserRoundIcon';
import { OpenNativeMap } from 'ui/components/atoms/Driver/SingleOrder/OpenNativeMap';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import injectSheet from 'react-jss';
import { orderAccountInfo } from 'ui/styles/Customer/SingleOrderPage/orderAccountInfo';

const _AccountInfo = ({ classes, orderData, forDriver, isMobile }) => (
  <Fragment>
    <UserRoundIcon className={classes.icon} />
    {forDriver && (
      <div className={classes.rating}>
        <StarRatings
          rating={orderData.get('customerRating')}
          starRatedColor="#FFC80A"
          starHoverColor="#FFC80A"
          numberOfStars={5}
          name="rating"
          svgIconPath={RatingStar}
          svgIconViewBox="0 0 24 24"
          starDimension={isMobile ? '16px' : '24px'}
          starSpacing="6px"
        />
      </div>
    )}
    <p className={classes.name}>
      {`${orderData.get('firstName')} ${orderData.get('lastName')}`}
    </p>
    <div className={classes.leftSide}>
      <p className={classes.address}>{orderData.get('address')}</p>
      <p className={classes.boozer}>{orderData.get('boozer')}</p>
      <p className={classes.intersection}>
        {orderData.get('nearestIntersection')}
      </p>
      {forDriver && <OpenNativeMap orderData={orderData} />}
    </div>
    <div className={classes.instructions}>
      <span>Instructions</span>
      <p>{orderData.get('instructions')}</p>
    </div>
  </Fragment>
);

export const AccountInfo = compose(
  withMobileDetect(),
  injectSheet(orderAccountInfo),
)(_AccountInfo);
