import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { withStyles, TableCell, Button } from '@material-ui/core';
import { actions } from '../../../../../store/actions';
import { thunks } from '../../../../../services/thunks';
import styles from '../../../../styles/Admin/RowActions';
import { ConfirmModal } from '../../../organisms/Admin/Commons/ConfirmModal';
import { AddEditDeliveryZone } from '../../../organisms/Admin/DeliveryZones/AddEditDeliveryZone';

const mapStateToProps = state => {
  return {
    selectedZone: state.getIn(['app', 'selectedDeliveryZone']),
  };
};

const mapDispatchToProps = dispatch => ({
  deleteZone: id => dispatch(thunks.deleteDeliveryZone(id)),
  editDeliveryZone: (id, data) => dispatch(thunks.editDeliveryZone(id, data)),
  getDeliveryZoneById: id => dispatch(thunks.getDeliveryZoneById(id)),
  clearSelectedDeliveryZone: () =>
    dispatch(actions.setDeliveryZoneData.act(null)),
});

class _DeliveryZonesActions extends Component {
  state = {
    isOpenConfirmModal: false,
    isOpenEditModal: false,
  };

  handleEdit = () => {
    this.props.getDeliveryZoneById(this.props.id);
  };

  handleDelete = () => {
    this.toggleModal('isOpenConfirmModal');
  };

  toggleModal = type => {
    this.setState((prevState, props) => {
      return {
        [type]: !prevState[type],
      };
    });
  };

  closeEditModal = () => {
    this.props.clearSelectedDeliveryZone();
    this.toggleModal('isOpenEditModal');
  };

  submit = data => {
    this.props.editDeliveryZone(this.props.id, data);
    this.closeEditModal();
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.selectedZone &&
      this.props.selectedZone &&
      this.props.selectedZone.id === this.props.id
    ) {
      this.toggleModal('isOpenEditModal');
    }
  }

  render() {
    const {
      classes: { button },
      t,
      id,
      deleteZone,
      name,
      selectedZone,
    } = this.props;

    const { isOpenConfirmModal, isOpenEditModal } = this.state;

    return (
      <TableCell>
        <div>
          <Button
            variant="contained"
            className={button}
            onClick={this.handleEdit}
          >
            {t('Edit')}
          </Button>

          <Button
            variant="contained"
            className={button}
            onClick={this.handleDelete}
          >
            {t('Delete')}
          </Button>

          {isOpenConfirmModal && (
            <ConfirmModal
              id={id}
              isOpenModal={isOpenConfirmModal}
              confirm={deleteZone}
              closeModal={() => this.toggleModal('isOpenConfirmModal')}
              actionText={`delete delivery zone ${name}?`}
            />
          )}

          {isOpenEditModal && (
            <AddEditDeliveryZone
              id={id}
              closeModal={this.closeEditModal}
              isOpenModal={isOpenEditModal}
              {...selectedZone}
              submit={this.submit}
            />
          )}
        </div>
      </TableCell>
    );
  }
}

export const DeliveryZonesActions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withStyles(styles),
)(_DeliveryZonesActions);
