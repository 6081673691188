import { api } from 'services/api';
import { push } from 'connected-react-router';
import { setErrorToStore } from 'services/utils';

export const editProduct = values => async dispatch => {
  try {
    await api.editProduct(values);
    dispatch(push('/admin/products'));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
