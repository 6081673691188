import { createSelector } from 'reselect';

const handler = (user, addresses, activeAddress) => {
  const address = addresses
    .find(addresa => addresa.get('id') === activeAddress)
    .toJS();
  return {
    ...address,
    ...user.toJS(),
  };
};

export const getUserDataForApi = createSelector(
  state => state.getIn(['app', 'user']),
  state => state.getIn(['app', 'addresses']),
  state => state.getIn(['app', 'activeAddress']),
  (user, addresses, activeAddress) => handler(user, addresses, activeAddress),
);
