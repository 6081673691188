import { privateAxios } from 'services/api/privateAxios';

export const getCompletedOrders = (
  page,
  pageSize = 10,
  search = null,
  orderBy = null,
  orderDirection = null,
) =>
  privateAxios({
    method: 'post',
    url: '/orderboard/completedorders',
    data: {
      page,
      pageSize,
      search,
      orderBy,
      orderDirection,
    },
  });
