import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { thunks } from '../../../../services/thunks';
import { setNewPasswordConfig } from '../../../configs/formik/setNewPasswordConfig';
import { Input } from '../../atoms/Commons/Client/Input';
import { setNewPassword } from '../../../styles/General/SetNewPassword/setNewPassword';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNewPassword: thunks.setNewPassword,
    },
    dispatch,
  );

class _SetNewPassword extends React.PureComponent {
  render() {
    const { classes, errors, touched, t } = this.props;
    return (
      <div className={classes.backstage}>
        <div className={classes.container}>
          <h1 className={classes.title}>{t('Enter new password')}</h1>
          <Form className={classes.form} id="setNewPasswordForm">
            <Field
              component={Input}
              type="password"
              name="password"
              placeholder="New password"
              error={touched.password && errors.password}
              $container={classes.inputContainer}
              $label={classes.inputLabel}
              $input={classes.input}
              $error={classes.inputError}
            />
            <Field
              component={Input}
              type="password"
              name="confirmPassword"
              placeholder="Confirm new password"
              error={touched.confirmPassword && errors.confirmPassword}
              $container={classes.inputContainer}
              $label={classes.inputLabel}
              $input={classes.input}
              $error={classes.inputError}
            />
          </Form>
          <button
            type="submit"
            form="setNewPasswordForm"
            className={classes.ok}
          >
            Set password
          </button>
          <div className={classes.backError}>{errors.backError}</div>
        </div>
      </div>
    );
  }
}

export const SetNewPassword = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withFormik(setNewPasswordConfig),
  injectSheet(setNewPassword),
)(_SetNewPassword);
