import React from 'react';

export const PromoTimeIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 104 104">
    <path d="M46.9,58.9h-4c2.8-2.8,6-7,6-11.1c0-4-2.7-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2    c0-1.5,1.2-2.7,2.7-2.7c2.7,0,2.7,2,2.7,2.7c0,4.2-6.2,9.7-8.6,11.5c-0.7,0.5-1,1.4-0.7,2.3c0.3,0.8,1.1,1.4,1.9,1.4h9.4    c1.1,0,2-0.9,2-2C48.9,59.8,48,58.9,46.9,58.9z" />
    <path d="M63.2,41c-1.1,0-2,0.9-2,2v17.9c0,1.1,0.9,2,2,2s2-0.9,2-2V43.1C65.2,41.9,64.3,41,63.2,41z" />
    <path d="M66.5,50h-9.9v-6.9c0-1.1-0.9-2-2-2s-2,0.9-2,2V52c0,1.1,0.9,2,2,2h11.9c1.1,0,2-0.9,2-2    C68.6,50.9,67.7,50,66.5,50z" />
    <path d="M52,20.8c-1.1,0-2,0.9-2,2v4.2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-4.2C54,21.8,53.1,20.8,52,20.8z" />
    <path d="M52,74.9c-1.1,0-2,0.9-2,2v4.2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-4.2C54,75.8,53.1,74.9,52,74.9z" />
    <path d="M81.1,50h-4.2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h4.2c1.1,0,2-0.9,2-2C83.2,50.9,82.2,50,81.1,50z" />
    <path d="M27.1,50h-4.2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h4.2c1.1,0,2-0.9,2-2C29.1,50.9,28.2,50,27.1,50z" />
    <path d="M102,50H89.3c-1.1,0-2,0.9-2,2v0.1c0,19.5-15.9,35.3-35.3,35.3c-15.3,0-28.7-9.7-33.5-24.2    c-0.3-0.9-0.6-1.9-0.8-2.8c-0.3-1.1-1.4-1.8-2.5-1.5c-1.1,0.3-1.8,1.4-1.5,2.5c0.3,1,0.6,2.1,0.9,3.1c5.4,16.1,20.4,27,37.4,27    C73,91.5,90.3,74.9,91.3,54h8.6c-0.5,12-5.4,23.3-14,31.9c-9.1,9.1-21.1,14-33.9,14s-24.8-5-33.9-14c-18.7-18.7-18.7-49.1,0-67.8    C36.6-0.4,66.9-0.6,85.5,17.7c0.8,0.8,2.1,0.8,2.9,0l0.6-0.6v11H78l1.4-1.4c0.4-0.4,0.6-0.9,0.6-1.4c0-0.5-0.2-1.1-0.6-1.4    c0,0,0,0,0,0C72,16.6,62.2,12.7,51.9,12.7c-8.7,0-16.9,2.8-23.8,8c-6.7,5.1-11.6,12.3-14,20.3c-0.3,1.1,0.3,2.2,1.4,2.5    c1.1,0.3,2.2-0.3,2.5-1.4c4.4-14.9,18.3-25.3,33.9-25.3c8.6,0,16.7,3,23.1,8.6l-3.4,3.4c-0.6,0.6-0.8,1.5-0.4,2.2    c0.3,0.8,1.1,1.3,1.9,1.3H91c1.1,0,2-0.9,2-2V12.3c0-0.8-0.5-1.6-1.3-1.9c-0.8-0.3-1.6-0.1-2.2,0.4l-2.6,2.6    C77.3,4.7,64.9-0.1,51.7,0C38,0.1,25,5.5,15.2,15.2C5.4,25.1,0,38.1,0,52s5.4,26.9,15.2,36.8C25.1,98.6,38.1,104,52,104    s26.9-5.4,36.8-15.2C98.6,78.9,104,65.9,104,52C104,50.9,103.1,50,102,50z" />
    <path d="M14.5,50c-1.1,0-2,0.9-2,2v0c0,1.1,0.9,2,2,2s2-0.9,2-2v0C16.6,50.9,15.7,50,14.5,50z" />
  </svg>
);
