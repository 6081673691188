import { api } from '../../api';
import { actions } from '../../../store/actions';

export const getDeliveryZoneHeader = id => async dispatch => {
  try {
    const response = await api.getDeliveryZoneHeader(id);
    dispatch(actions.setDeliveryZoneHeader.act(response.data));
  } catch (e) {
    return e;
  }
};
