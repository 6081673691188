import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { Modal } from '../../Commons/Modal';
import useStyles from '../../../../styles/Admin/DelivaryZone/editHeader';
import useHeaderStyles from '../../../../styles/Commons/zoneHeader';
import Editor from '../../Commons/RichText/Editor';
import { convertToRaw } from 'draft-js';
import { BACKGROUND } from '../../Commons/RichText/Editor/constants';

export const EditHeader = ({ value, onChange, closeModal, isOpen }) => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();

  const content = useRef();

  const handleOnChange = useCallback(x => {
    content.current = x;
  }, []);

  const save = useCallback(() => {
    const background = content.current.getEntity(
      content.current.entityMap[BACKGROUND],
    );
    const newValue = convertToRaw(content.current);
    newValue.entityMap[BACKGROUND] = background.toJS();
    onChange(newValue);
    closeModal();
  }, []);

  return (
    <>
      <div id="modalHost" />
      <Modal
        $backstage={classes.backstage}
        $container={classes.container}
        isOpen={isOpen}
        renderIn="modalHost"
      >
        <Editor value={value} onChange={handleOnChange} />
        <div className={clsx(headerClasses.content, classes.buttons)}>
          <Button
            type="button"
            onClick={closeModal}
            variant="contained"
            color="secondary"
            className={classes.cancel}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={save}
            variant="contained"
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};
