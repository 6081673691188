import { fromJS } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.update('addresses', addresses => addresses.unshift(fromJS(data)));

export const addAddress = createAction({
  action: ['addAddress', 'data'],
  handler,
});
