import { api } from 'services/api';
import { actions } from 'store/actions';
import { store } from 'store';
import { setErrorToStore } from 'services/utils';

export const deleteProduct = id => async dispatch => {
  const state = store.getState();
  const products = state.getIn(['app', 'admin', 'products']).toJS();
  try {
    await api.deleteProduct(id);
    const response = await api.getProductsByAdmin(products);
    dispatch(actions.adminSetProducts.act(response.data));
    dispatch(actions.closeModal.act(`deleteProduct-${id}`));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
