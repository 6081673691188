import { HubConnectionBuilder, NullLogger } from '@aspnet/signalr';
import { getAccessToken } from 'services/utils';
import { API } from 'services/api/apiUrl';
import { orderStatusChanged } from 'store/middlewares/websockets/orderStatusChanged';
import { block } from 'store/middlewares/websockets/block';
import { suspend } from 'store/middlewares/websockets/suspend';
import { orderBoardNotification } from 'store/middlewares/websockets/orderBoardNotification';
import { getOrderId } from '../../utils';

const subscribe = {
  activeOrder: {
    needToSubscribe: false,
    id: null,
    subscribe: (connection, id) => {
      connection
        .invoke('SubscribeToOrder', id)
        .then(() => {
          /*console.log(`subscribed to order ${id}`) */
        })
        .catch(e => console.log(e));
    },
  },
  orderBoard: {
    needToSubscribe: false,
    needToUnsubscribe: false,
    page: 1,
    prevPage: 0,
    subscribe: (connection, page, prevPage) => {
      connection
        .invoke('OnConnectDashBoard', page, prevPage)
        .then(() => {
          // console.log('Subscribed to orderboard')
        })
        .catch(e => console.log(e));
    },
    unsubscribe: (connection, page) => {
      connection
        .invoke('OnDisconnectDashBoard', page)
        .then(() => {
          // console.log('Unsubscribed from orderboard')
        })
        .catch(e => console.log(e));
    },
  },
};

const createSecureConnection = store => {
  const token = getAccessToken();
  const connection = new HubConnectionBuilder()
    .withUrl(`${API}/notification?access_token=${token}`)
    .configureLogging(NullLogger)
    .build();

  connection.logging = false;

  connection.on('OrderStatusChanged', orderStatusChanged(store));
  connection.on('OrderBoardNotification', orderBoardNotification(store));
  connection.on('Block', block(store));
  connection.on('Suspend', suspend(store));

  (async () => {
    const { activeOrder, orderBoard } = subscribe;
    try {
      await connection.start();
      if (orderBoard.needToSubscribe) {
        orderBoard.subscribe(connection, orderBoard.page, orderBoard.prevPage);
      }
      if (orderBoard.needToUnsubscribe) {
        orderBoard.unsubscribe(connection, orderBoard.page);
      }
      if (activeOrder.needToSubscribe) {
        activeOrder.subscribe(connection, activeOrder.id);
      }
    } catch (e) {
      console.log(e);
    }
  })();
  return connection;
};

/** ************************************************************************** */
/** **************** Redux middleware for websockets ************************* */
/** ************************************************************************** */

export const websocketMiddleware = store => {
  let connection = createSecureConnection(store);
  return next => action => {
    /** Create auth connection for driver or logged customer* */

    if (action.type === 'login' || action.type === 'logout') {
      connection.stop().then(() => {
        connection = createSecureConnection(store);
      });
    }

    /** Subscribe to orderboard by driver * */

    if (action.type === 'setActiveOrders') {
      const { page, prevPage } = action;
      const { orderBoard } = subscribe;
      if (connection.state === 1) {
        orderBoard.subscribe(connection, page, prevPage);
      } else {
        orderBoard.needToSubscribe = true;
        orderBoard.page = page;
        orderBoard.prevPage = prevPage;
      }
    }

    /** Unsubscribe from orderboard by driver * */

    if (action.type === 'unsubscribeFromOrderboard') {
      const { page } = action;
      const { orderBoard } = subscribe;
      if (connection.state === 1) {
        // console.log('websocket - fire - unsubscribeFromOrderboard');
        orderBoard.unsubscribe(connection, page);
      } else {
        orderBoard.needToUnsubscribe = true;
        orderBoard.page = page;
      }
    }

    /** Subscribe to active order for receive order events * */

    if (
      action.type === 'openVerificationModal' ||
      action.type === 'subscribeForOrder'
    ) {
      const id = getOrderId(action.id);
      const { activeOrder } = subscribe;
      // console.log(connection.state);
      if (connection.state === 1) {
        activeOrder.subscribe(connection, id);
      } else {
        activeOrder.needToSubscribe = true;
        activeOrder.id = id;
      }
    }

    return next(action);
  };
};
