import React from 'react';

import { compose } from 'redux';
import { withRouter } from 'react-router';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import {
  withStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import styles from '../../../../styles/Admin/commons/navLink';

const _NavLink = ({ classes, to, text, location: { pathname } }) => {
  const isActive = pathname.includes(to);

  return (
    <ReactRouterNavLink
      to={to}
      className={classes.link}
      activeClassName={classes.activeLink}
    >
      <ListItem button>
        <ListItemText classes={{ primary: classes.text }} primary={text} />
        {isActive && (
          <ListItemIcon>
            <ChevronRightIcon classes={{ root: classes.text }} />
          </ListItemIcon>
        )}
      </ListItem>
    </ReactRouterNavLink>
  );
};

export const NavLink = compose(
  withStyles(styles),
  withRouter,
)(_NavLink);
