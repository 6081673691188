import { palette } from '../../theme';

export const orderRow = {
  button: {
    padding: '0',
    display: 'block',
    fontSize: '9px',
    minWidth: '53px',
    minHeight: '20px',
  },
  relative: {
    position: 'relative',
  },
  icon: {
    width: 20,
    height: 20,
    color: palette.blue,
    position: 'absolute',
    top: '28%',
    left: 0,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dateTooltip: {
    marginLeft: '0.5rem',
    width: 20,
    height: 20,
    color: palette.blue,
  },
  createdAt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > span': {
      lineHeight: '1.35rem',
    }
  },
  acceptedAt: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      lineHeight: '1.35rem',
    }
  },
  orderItems: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginLeft: '0.5rem',
    },
  },
  customerInfo: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginRight: '0.5rem',
    },
  },
  deliverAddressInfo: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      lineHeight: '1.35rem',
    },
  },
};
