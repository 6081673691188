import { api } from '../../api';
import { actions } from '../../../store/actions';

export const getDeliveryZone = endLoading => async dispatch => {
  console.log(' getDeliveryZone ');
  try {
    const response = await api.getDeliveryZone();
    dispatch(actions.setDeliveryZone.act(response.data));
    endLoading();
  } catch (e) {
    return e;
  }
};
