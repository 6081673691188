import React from 'react';
import injectSheet from 'react-jss';
import { CheckIcon } from '../../icons/CheckIcon';
import { checkbox } from '../../../../styles/Commons/checkbox';

class _Checkbox extends React.Component {
  toggleCheck = () => {
    const { onChange, name, value } = this.props;
    onChange(name, !value);
  };

  render() {
    const { classes, text, value } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={classes.checkboxWrapper}
          role="presentation"
          onClick={this.toggleCheck}
        >
          <div className={classes.fakeBox}>{value && <CheckIcon />}</div>
        </div>
        <span className={classes.text}>{text}</span>
      </div>
    );
  }
}

export const Checkbox = injectSheet(checkbox)(_Checkbox);
