import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

const prepUserDataForUpdate = (state, values) => {
  const customer = state.getIn(['app', 'selectedCustomer']);
  const customerAddresses = customer.customerAddresses.push(values).toJS();
  return {
    ...customer,
    customerAddresses,
  };
};

export const adminAddAddress = (values, closeModal) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const data = prepUserDataForUpdate(state, values);
  const customerId = state.getIn(['app', 'selectedCustomer', 'id']);
  try {
    await api.editCustomer(customerId, data);
    const response = await api.getCustomerById(customerId);
    dispatch(actions.setCustomer.act({ ...response.data, id: customerId }));
    closeModal();
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
