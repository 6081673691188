import { privateAxios } from 'services/api/privateAxios';

export const setPaymentToken = (id, last4, brand) =>
  privateAxios({
    method: 'post',
    url: '/driver/setpaymenttoken',
    data: {
      id,
      last4,
      brand,
    },
  });
