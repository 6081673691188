import { button } from '../../Commons/button';
import { media, palette } from '../../theme';

export const staticAddressData = {
  container: {
    width: '100%',
    height: '100%',
    [media.mobile]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: ' center',
    },
    [media.desktop]: {
      display: 'grid',
      gridTemplateColumns: '5% 30% 5% 30% 5% auto',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
        '. a . b. c'
      `,
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    [media.mobile]: {
      width: '85%',
    },
    [media.desktop]: {
      gridArea: 'a',
    },
  },
  rightSide: {
    composes: '$leftSide',
    gridArea: 'b',
    [media.desktop]: {
      gridArea: 'b',
    },
  },
  field: {
    margin: '0.5rem 0',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    lineHeight: '1.5rem',

    '& > span': {
      fontWeight: 500,
    },
    '& > p': {
      fontWeight: 300,
    },
    [media.desktop]: {
      margin: '1.75rem 0',
      '&:first-child': {
        marginBottom: 0,
      },
    },
  },
  buttons: {
    gridArea: 'c',
    display: 'flex',
    [media.mobile]: {
      width: '85%',
      justifyContent: ({ enableDelete }) =>
        enableDelete ? 'space-between' : 'center',
      alignItems: 'flex-start',
      margin: '1rem 0',
    },
    [media.desktop]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  edit: {
    ...button.yellow,
    fontWeight: 600,
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',
    width: '45%',
    [media.desktop]: {
      ...button.yellow[media.desktop],
      marginBottom: ({ enableDelete }) => (enableDelete ? '1.5rem' : 0),
      height: '2.5rem', // 40px
      width: '9.375rem', // 150px
    },
  },
  delete: {
    ...button.yellow,
    fontWeight: 600,
    color: palette.yellow,
    backgroundColor: 'white',
    width: '45%',
    [media.desktop]: {
      ...button.yellow[media.desktop],
      height: '2.5rem', // 40px
      width: '9.375rem', // 150px
    },
  },
  activeIndicator: {
    opacity: ({ canSelect, isActive }) => (canSelect && isActive ? '1' : '0'),
    display: 'flex',
    border: '1px solid #E0B317',
    backgroundColor: '#E0B317',
    [media.mobile]: {
      width: '100%',
      marginTop: '-2px',
      justifyContent: 'flex-end',
    },
    [media.desktop]: {
      gridArea: 'c',
      justifySelf: 'end',
      width: '15%',
      justifyContent: 'center',
    },
  },
  checkIcon: {
    height: '2.5rem',
    fill: 'white',
    [media.mobile]: {
      height: '1.5rem',
    },
  },
};
