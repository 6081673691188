import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { id }) =>
  state
    .setIn(['modals', 'checkCustomerPhone'], true)
    .setIn(['pendingOrder'], id);

export const openVerificationModal = createAction({
  action: ['openVerificationModal', 'id'],
  handler,
});
