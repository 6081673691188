import React from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { underage } from '../../../styles/General/Underage/underage';

const _Underage = ({ classes, t }) => (
  <div className={classes.container}>
    <div className={classes.wrapper}>
      <h1 className={classes.boozApp}>Boo Zapp</h1>
      <hr className={classes.hr} />
      <h2 className={classes.sorry}>{t('We are sorry!')}</h2>
      <p className={classes.explanation}>
        {t('You must be 19 years of age or older to enter this site')}
      </p>
    </div>
  </div>
);

export const Underage = compose(
  withNamespaces(),
  injectSheet(underage),
)(_Underage);
