import { fromJS } from 'immutable';
import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.setIn(['tables', 'customers'], fromJS(data));

export const setCustomers = createAction({
  action: ['setCustomers', 'data'],
  handler,
});
