import React from 'react';
import { compose } from 'redux';
import Divider from '@material-ui/core/Divider';
import injectSheet from 'react-jss';
import { orderStatus } from 'ui/constants';
import { ButtonWithConfirm } from 'ui/components/atoms/Commons/Client/ButtonWithConfirm';
import { OrderItemsList } from '../../molecules/Customer/OrderPage/OrderItemsList';
import { AccountInfo } from '../../molecules/Customer/OrderPage/AccountInfo';
import { orderCard } from '../../../styles/Customer/SingleOrderPage/orderCard';
import { palette } from 'ui/styles/theme';

const _CustomerOrderCard = ({
  classes,
  orderData,
  getPhone,
  cancelOrder,
  isCancelModalOpen,
  openConfirmModal,
  closeConfirmModal,
}) => {
  const status = orderData.get('statusId');
  return (
    <div className={classes.container}>
      <AccountInfo orderData={orderData} />
      <Divider className={classes.diriver1} />
      <div className={classes.order}>
        <span className={classes.orderSummary}>Order Summary</span>
        <OrderItemsList orderData={orderData} />
      </div>
      <Divider className={classes.diriver2} />
      <div className={classes.buttons}>
        {status === orderStatus.accepted && (
          <button type="button" onClick={getPhone} className={classes.call}>
            Call driver
          </button>
        )}
        {status === orderStatus.new && (
          <div
            style={{
              fontSize: '16px',
              fontFamily: 'Roboto',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <br />
            <br />
            <strong>
              <div style={{ color: palette.yellow }}>Order confirmed!</div>
            </strong>{' '}
            <br />
            We are contacting your driver.
            <br />
          </div>
        )}
        {status !== orderStatus.refused && (
          <ButtonWithConfirm
            className={classes.cancel}
            text="Cancel order"
            onClick={cancelOrder}
            confirmText="Your order is in progress. Are you sure want to cancel it?"
            isConfirmOpen={isCancelModalOpen}
            openConfirmModal={openConfirmModal}
            closeConfirmModal={closeConfirmModal}
          />
        )}
      </div>
    </div>
  );
};
export const CustomerOrderCard = compose(injectSheet(orderCard))(
  _CustomerOrderCard,
);
