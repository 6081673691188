import { timeSettingsForm } from './timeSettingsForm';

const { form, wrapper, description } = timeSettingsForm;

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  card: {
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    ...form,
    margin: '1rem 0',
  },
  wrapper,
  description,
  select: {
    margin: '0 2rem',
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
  },
  divider: {
    width: '90%',
  },
};

export default styles;
