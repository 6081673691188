import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getTransactions = ({ ...data }) => async dispatch => {
  try {
    const response = await api.getTransactions(data);
    dispatch(actions.setTransactions.act(response.data));
  } catch (e) {
    return e;
  }
};
