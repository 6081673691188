import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const addDeliveryZone = ({ name, postalCodes }) =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: '/deliveryzone',
    data: {
      name,
      postalCodes,
    },
  });
