import React, { PureComponent } from 'react';
import { compose } from 'redux';
import injectSheet from 'react-jss';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import { CompleteOrders } from 'ui/components/ecosystems/Driver/CompleteOrders';
import { DriverTopBar } from 'ui/components/molecules/Driver/DriverTopBar';
import { Tabs } from 'ui/components/organisms/Driver/OrderBoard/Tabs';
import { OpenOrders } from 'ui/components/ecosystems/Driver/OpenOrders';
import { orderBoard } from 'ui/styles/Driver/OrderBoard/orderBoard';

class _OrderBoard extends PureComponent {
  state = { activeTab: 'openOrders' };

  chooseOpenOrders = () => {
    this.setState({ activeTab: 'openOrders' });
  };

  chooseCompletedOrders = () => {
    this.setState({ activeTab: 'completedOrders' });
  };

  render() {
    const { classes, isMobile } = this.props;
    const { activeTab } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <DriverTopBar showProfile />
          <Tabs
            activeTab={activeTab}
            chooseOpenOrders={this.chooseOpenOrders}
            chooseCompletedOrders={this.chooseCompletedOrders}
            isMobile={isMobile}
          />
          {activeTab === 'openOrders' ? (
            <OpenOrders isMobile={isMobile} />
          ) : (
            <CompleteOrders isMobile={isMobile} />
          )}
        </div>
      </div>
    );
  }
}

export const OrderBoard = compose(
  withMobileDetect(),
  injectSheet(orderBoard),
)(_OrderBoard);
