import { formatPhone, getPhoneForForm } from 'utils';
import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const editCustomerInfoConfig = {
  mapPropsToValues: ({ firstName, lastName, phone, email }) => ({
    firstName,
    lastName,
    phone: getPhoneForForm(phone),
    email,
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }),
  validationSchema: Yup.object().shape({
    firstName: validators.firstName,
    lastName: validators.lastName,
    phone: validators.phone,
    email: validators.email,
    oldPassword: validators.oldPassword,
    newPassword: validators.newPassword,
    confirmNewPassword: validators.confirmNewPassword,
  }),
  handleSubmit: (
    values,
    { props: { toggleEdit, editCustomerInfo }, setErrors, setSubmitting },
  ) => {
    editCustomerInfo(
      { ...values, phone: formatPhone(values.phone) },
      toggleEdit,
      setErrors,
      setSubmitting,
    );
  },
  displayName: 'editCustomerInfo',
};
