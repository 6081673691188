import { api } from 'services/api';
import { actions } from 'store/actions';
import { setErrorToStore } from 'services/utils';

export const getProduct = id => async dispatch => {
  try {
    const { data } = await api.getProduct(id);
    await dispatch(actions.setData.act(['admin', 'product'], data));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
