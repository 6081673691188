import { media } from 'ui/styles/theme';

export const backButton = {
  container: {
    width: '1030px',
    display: 'flex',
    alignItems: 'center',
    [media.mobile]: {
      width: '100%',
      marginTop: '1rem',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    cursor: 'pointer',
  },
  icon: {
    height: '1.5rem',
    marginRight: '0.25rem',
    fill: '#444343',
  },
  text: {
    fontWeight: 300,
  },
};
