import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data: { firstName, lastName, role } }) =>
  state.withMutations(next =>
    next
      .set('isAuth', true)
      .setIn(['loading', 'submitButton'], false)
      .setIn(['user', 'firstName'], firstName)
      .setIn(['user', 'lastName'], lastName)
      .setIn(['user', 'role'], role),
  );

export const login = createAction({
  action: ['login', 'data'],
  handler,
});
