import * as Yup from 'yup';
import { mapSchema } from './mapSchema';

export const priceGrid = mapSchema(
  undefined,
  mapSchema(
    undefined,
    Yup.object().shape({
      value: Yup.number()
        .transform(x => x || 0)
        .positive('Invalid price')
        .required('Invalid price'),
      disabled: Yup.bool().notRequired(),
    }).notRequired(),
  ),
);
