import colors from '../colors';

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: 240,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: colors.mainTheme,
    zIndex: 0,
  },
  titleRoot: {
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    color: colors.light,
    textAlign: 'center',
  },
});

export default styles;
