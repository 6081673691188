import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { thunks } from '../../../../../services/thunks';
import { MultiSelectField } from '../../../atoms/Commons/Admin/MultiSelectField';
import { Modal } from '../../Commons/Modal';
import { addEditDeliveryZoneConfig } from '../../../../configs/formik/addEditDeliveryZoneConfig';
import { addDeliveryZoneForm } from '../../../../styles/Admin/DelivaryZone/addDeliveryZoneForm';
import { HeaderField } from 'ui/components/molecules/Admin/DeliveryZones/HeaderField.jsx';
import clsx from 'clsx';

const mapStateToProps = state => ({
  postalCodesForAutocomplete: state.getIn(['app', 'postalCodes']).toJS(),
});

const mapDispatchToProps = dispatch => ({
  getPostalCodes: search => dispatch(thunks.getPostalCodes(search)),
});

class _AddEditDeliveryZone extends Component {
  transformData(data) {
    let array = [];
    for (let key in data) {
      array.push({ value: key.toUpperCase(), label: key.toUpperCase() });
    }
    return array;
  }

  render() {
    const {
      touched,
      errors,
      classes,
      postalCodesForAutocomplete,
      getPostalCodes,
      closeModal,
      isOpenModal,
      id,
    } = this.props;

    return (
      <Modal
        $backstage={this.props.classes.backstage}
        $container={this.props.classes.container}
        onClose={closeModal}
        isOpen={isOpenModal}
      >
        <h1 className={classes.title}>{`${id ? 'Edit' : 'Add New'} Zone`}</h1>
        <Form className={classes.form}>
          <Field
            name="name"
            render={({ field }) => {
              const error = touched[field.name] && errors[field.name];
              return (
                <TextField
                  label={error || 'Zone Name'}
                  error={!!error}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  variant="outlined"
                  type="text"
                  placeholder="Name"
                  className={classes.input}
                />
              );
            }}
          />
          <Field
            name="postalCodes"
            component={MultiSelectField}
            placeholder="Postal Codes"
            onInputChange={getPostalCodes}
            options={this.transformData(postalCodesForAutocomplete)}
            error={touched.postalCodes && errors.postalCodes}
          />
          <Field
            label="Header"
            name="header"
            component={HeaderField}
            className={clsx(classes.row, classes.header)}
          />
          <div className={classes.buttons}>
            <Button
              type="button"
              onClick={closeModal}
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export const AddEditDeliveryZone = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withFormik(addEditDeliveryZoneConfig),
  injectSheet(addDeliveryZoneForm),
)(_AddEditDeliveryZone);
