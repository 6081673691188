import React, { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import styleMap from '../styleMap';
import CustomButtons from './CustomButtons';
import useHeaderStyles from '../../../../../styles/Commons/zoneHeader';
import { Editor } from 'react-draft-wysiwyg';
import toolbar from './toolbar';
import decorators from '../decorators';
import { createUseStyles } from 'react-jss';
import 'ui/styles/font-awesome.css';
import { BACKGROUND } from './constants';

const useStyles = createUseStyles({
  wrapper: {
    margin: '0 -5vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  toolbar: {
    '& .rdw-colorpicker-modal-options': {
      overflowX: 'auto',
    },
    '& .rdw-custom-wrapper': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '6px',
      flexWrap: 'wrap',
    },
  },
  background: {
    position: 'absolute',
    bottom: 0,
  },
  text: {
    '& .DraftEditor-root': {
      height: '100%',
      padding: '.6em 0',
      textAlign: 'inherit',
    },
    '& .DraftEditor-editorContainer': {
      textAlign: 'inherit',
    },
    '& .public-DraftEditor-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'inherit',

      '& h1': {
        fontSize: '2.5em',
      },
      '& h3': {
        opacity: 0.8,
        fontSize: '12pt',
        fontWeight: 'normal',

        '& > div': {
          margin: 0,
        },
      },
    },
    '& .public-DraftStyleDefault-block': {
      // Fixed too big margins when block aligned (margns uncollapsed)
      margin: '.4em 0',
    },
    '& .public-DraftStyleDefault-ltr': {
      // Fixed aligning in multiline blocks
      textAlign: 'inherit',
    },
    '& [data-block] > div': {
      // Fixed Margin collapse if not aligned
      display: 'inline-block',
      // Fixed cursor disapearing in empty inline blocks
      minWidth: 1,
    },
  },
});

export default ({ className, value, onChange, readOnly = false }) => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const [background, setBackground] = useState(
    get(value, ['entityMap', BACKGROUND, 'data']),
  );

  useEffect(
    () => setBackground(get(value, ['entityMap', BACKGROUND, 'data'])),
    [value],
  );

  const customStyleMap = useMemo(() => styleMap(readOnly), [readOnly]);

  const setEditorReference = useCallback(
    ref => ref && !readOnly && ref.focus(),
    [],
  );

  const setWrapperReference = useCallback(
    ref => ref && readOnly && (ref.focusEditor = () => {}),
    [],
  );

  const handleChange = useCallback(x => {
    const contentState = x.getCurrentContent();
    const entity = contentState.getEntity(contentState.entityMap[BACKGROUND]);
    setBackground(entity.get('data'));
    onChange && onChange(contentState);
  }, []);

  const [image, imageStyle] = useMemo(
    () => {
      const { src, ...style } = background || {};
      return [src, style];
    },
    [background],
  );

  return (
    <div className={clsx(classes.wrapper, className)}>
      <img
        className={clsx(
          headerClasses.container,
          headerClasses.background,
          classes.background,
        )}
        style={imageStyle}
        src={image}
      />
      <Editor
        ref={setWrapperReference}
        readOnly={readOnly}
        toolbarHidden={readOnly}
        contentState={value}
        onEditorStateChange={handleChange}
        editorRef={setEditorReference}
        wrapperClassName={headerClasses.content}
        toolbarClassName={classes.toolbar}
        toolbar={toolbar}
        toolbarCustomButtons={CustomButtons}
        editorClassName={clsx(
          headerClasses.container,
          headerClasses.text,
          classes.text,
        )}
        customStyleMap={customStyleMap}
        customDecorators={decorators}
      />
    </div>
  );
};
