import { push } from 'connected-react-router';
import { api } from 'services/api';
import { actions } from 'store/actions';
import { getOrderStatus } from 'ui/constants';
import { setErrorToStore } from 'services/utils';

export const getDriverOrder = (match, endLoading) => async dispatch => {
  try {
    const { id } = match.params;
    const response = await api.getDriverOrder(id);
    dispatch(actions.setDriverOrder.act(response.data));
    const { customerRated, statusId } = response.data;
    const status = getOrderStatus(statusId);
    if (status === 'completed' && !customerRated) {
      dispatch(actions.openModal.act('rateCustomer'));
    }
    if (status === 'canceled' && !customerRated) {
      dispatch(actions.openModal.act('rateCustomer'));
    }
    if (status === 'canceled' && customerRated) {
      dispatch(push('/orderboard'));
    }
    endLoading();
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
