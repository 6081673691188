import get from 'lodash/get';
import { getActiveDeliveryZone } from 'selectors/getActiveDeliveryZone';
import { api } from 'services/api';
import { actions } from 'store/actions';
import { getOrderListForApi } from 'selectors/getOrderListForApi';
import { getUserDataForApi } from 'selectors/getUserDataForApi';
import { setPendingOrder, getOrderWithSecret } from 'utils';

export const sendCustomerOrder = (setErrors, setSubmitting) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  try {
    const orderItems = getOrderListForApi(state);
    const userData = getUserDataForApi(state);
    const deliveryZoneId = await api.findByPostalCode(userData.postalCode);
    const activeZone = getActiveDeliveryZone(state);
    if (+deliveryZoneId.data !== +activeZone.value) {
      setErrors({
        backError: `Sorry, your address is not in the ${activeZone.label}
        delivery zone. Go back to the main page to change your zone.`,
      });
      return false;
    }
    // Receive delivery zone from saved user address

    const response = await api.createOrder(
      { ...userData, deliveryZoneId: deliveryZoneId.data },
      orderItems,
    );
    const { id, secret } = response.data;
    setPendingOrder(getOrderWithSecret(id, secret));
    dispatch(actions.openVerificationModal.act(id));
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
