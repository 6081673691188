import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { thunks } from 'services/thunks';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { OrdersTableRow } from 'ui/components/organisms/Driver/OrderBoard/OrdersTableRow';
import { Pagination } from 'ui/components/organisms/Driver/OrderBoard/Pagination';
import { NoOrders } from 'ui/components/molecules/Driver/OrderBoard/NoOrders';
import injectSheet from 'react-jss';
import { openOrders } from 'ui/styles/Driver/OrderBoard/openOrders';

const mapStateToProps = state => ({
  activeOrders: state.getIn(['app', 'driver', 'activeOrders']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getActiveOrders: thunks.getActiveOrders,
      acceptOrder: thunks.acceptOrder,
      unsubscribe: page => ({ type: 'unsubscribeFromOrderboard', page }),
    },
    dispatch,
  );

class _OpenOrders extends React.Component {
  componentDidMount() {
    const { endLoading, getActiveOrders } = this.props;
    getActiveOrders(1, 0, endLoading);
  }

  componentWillUnmount() {
    const { unsubscribe, activeOrders } = this.props;
    unsubscribe(activeOrders.get('page'));
  }

  render() {
    const {
      classes,
      activeOrders,
      isLoading,
      getActiveOrders,
      acceptOrder,
      isMobile,
    } = this.props;
    if (isLoading || activeOrders.get('items').size === 0) {
      return <NoOrders />;
    }
    return (
      <div className={classes.container}>
        <div className={classes.table}>
          {activeOrders.get('items').map(order => (
            <OrdersTableRow
              key={order.get('orderId')}
              data={order}
              acceptOrder={acceptOrder}
              isMobile={isMobile}
            />
          ))}
        </div>
        <Pagination
          activeOrders={activeOrders}
          getActiveOrders={getActiveOrders}
        />
      </div>
    );
  }
}

export const OpenOrders = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCommonLoading(),
  injectSheet(openOrders),
)(_OpenOrders);
