import { privateAxios } from 'services/api/privateAxios';

export const changePassword = ({ oldPassword, newPassword }) =>
  privateAxios({
    method: 'post',
    url: '/account/changepassword',
    data: {
      oldPassword,
      newPassword,
    },
  });
