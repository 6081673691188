import { api } from 'services/api';
import { actions } from 'store/actions';
import { setErrorToStore } from 'services/utils';

export const getPhone = (id, isMobile, role) => async dispatch => {
  try {
    const response = await api.getPhone(id);
    if (isMobile) {
      window.location.href = `tel:${response.data}`;
    } else {
      dispatch(
        actions.openModal.act(
          'showPhone',
          `Here is the number to call to reach your ${role}: ${response.data}`,
        ),
      );
    }
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
