import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../../../../styles/Admin/commons/nameCell';

export const _InfoIconWithTooltip = ({
  tooltipMessage,
  classes,
  classForIcon,
}) => {
  return (
    <Tooltip
      title={<Fragment>{tooltipMessage}</Fragment>}
      placement="top"
      classes={{ popper: classes.popper, tooltip: classes.lightTooltip }}
    >
      <InfoRounded className={classForIcon || classes.icon} />
    </Tooltip>
  );
};

export const InfoIconWithTooltip = withStyles(styles)(_InfoIconWithTooltip);
