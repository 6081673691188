import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import injectSheet from 'react-jss';
import { ProfileIcon } from '../../atoms/icons/ProfileIcon';
import { appTopBar } from '../../../styles/Customer/AppTopBar/appTopBar';
import { thunks } from '../../../../services/thunks';

const mapStateToProps = state => ({
  isAuth: state.getIn(['app', 'isAuth']),
  firstName: state.getIn(['app', 'user', 'firstName']),
  lastName: state.getIn(['app', 'user', 'lastName']),
  role: state.getIn(['app', 'user', 'role']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: thunks.logout,
    },
    dispatch,
  );

// Todo Додати локалізацію
// Todo Виправити  becomeDriver, login, signup
const _AppTopBar = ({
  isAuth,
  showLogout,
  classes,
  becomeDriver,
  login,
  signup,
  logout,
  firstName,
  lastName,
  role,
}) => (
  <div className={classes.container}>
    <NavLink className={classes.logo} to="/">
      <span>Boo Zapp</span>
    </NavLink>

    {!isAuth && becomeDriver && (
      <NavLink className={classes.becomeDriver} to="become-a-driver">
        <span>Become a Driver</span>
      </NavLink>
    )}
    {!isAuth && login && (
      <NavLink to="/login" className={classes.loginLink}>
        <button type="button" className={classes.login}>
          Log In
        </button>
      </NavLink>
    )}
    {/* {!isAuth && signup && (
      <NavLink to="/signup" className={classes.signUpLink}>
        <button type="button" className={classes.signUp}>
          Sign Up
        </button>
      </NavLink>
    )} */}
    {isAuth && !showLogout && (
      <NavLink className={classes.profileLink} to={`/${role}/profile`}>
        <div>{`${firstName} ${lastName}`}</div>
        <ProfileIcon className={classes.profileIcon} />
      </NavLink>
    )}
    {isAuth && showLogout && (
      <button type="button" onClick={logout} className={classes.logout}>
        Log out
      </button>
    )}
  </div>
);

export const AppTopBar = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(appTopBar),
)(_AppTopBar);
