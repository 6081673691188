import React from 'react';
import injectSheet from 'react-jss';
import { EditIcon } from '../../../atoms/icons/EditIcon';
import { customerStaticInfo } from '../../../../styles/Customer/CustomerProfile/customerStaticInfo';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const _CustomerStaticInfo = ({ classes, data, toggleEdit }) => (
  <div className={classes.container}>
    <h1 className={classes.userName}>
      {`${data.get('firstName')} ${data.get('lastName')}`}
    </h1>
    <div className={classes.emailPhoneWrapper}>
      <span>{`Phone: ${formatPhoneNumberIntl(data.get('phone'))}`}</span>
      <span>{`Email: ${data.get('email')}`}</span>
    </div>
    <button type="button" onClick={toggleEdit} className={classes.edit}>
      <div className={classes.editContainer}>
        <span>Edit profile</span>
        <EditIcon className={classes.editIcon} />
      </div>
    </button>
  </div>
);

export const CustomerStaticInfo = injectSheet(customerStaticInfo)(
  _CustomerStaticInfo,
);
