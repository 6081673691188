import React from 'react';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { Addresses } from 'ui/components/ecosystems/Customer/Addresses';
import { ModalWithButtons } from 'ui/components/organisms/Admin/Commons/ModalWithButtons';
import { editCustomerConfig } from '../../../../configs/formik/editCustomerConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import injectSheet from 'react-jss';
import { addCustomer } from '../../../../styles/Admin/Customers/addCustomer';

const _EditCustomer = ({
  touched,
  errors,
  classes,
  closeModal,
  isOpenModal,
  id,
  handleSubmit,
  addresses,
}) => (
  <ModalWithButtons
    onClose={closeModal}
    isOpen={isOpenModal}
    onClick={handleSubmit}
    container={classes.container}
  >
    <h1 className={classes.title}>{`${id ? 'Edit' : 'Add New'} Customer`}</h1>
    <Form className={classes.form}>
      <Field
        component={Input}
        type="text"
        name="firstName"
        placeholder="First Name"
        labelText="Name"
        error={touched.firstName && errors.firstName}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="text"
        name="lastName"
        placeholder="Last Name"
        error={touched.lastName && errors.lastName}
        $container={classes.inputHalfContainerWithoutLabel}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={PhoneInput}
        type="text"
        name="phone"
        labelText="Phone"
        error={touched.phone && errors.phone}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="text"
        name="email"
        placeholder="example@gmail.com"
        labelText="Email"
        error={touched.email && errors.email}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={Input}
        type="password"
        name="password"
        placeholder="Setup a password"
        labelText="Password"
        error={touched.password && errors.password}
        $container={classes.inputHalfContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="password"
        name="confirmPassword"
        placeholder="Confirm password"
        error={touched.confirmPassword && errors.confirmPassword}
        $container={classes.inputHalfContainerWithoutLabel}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
    <Addresses addresses={addresses} canSelect admin />
  </ModalWithButtons>
);

export const EditCustomer = compose(
  withNamespaces('admin'),
  withFormik(editCustomerConfig),
  injectSheet(addCustomer),
)(_EditCustomer);
