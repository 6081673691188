import { media } from '../../theme';

export const customerProfileBlock = {
  container: {
    transition: '150ms',
    boxShadow: '0 0 35px 2px rgba(115,115,131,0.27)',
    [media.mobile]: {
      width: '90%',
      maxWidth: '500px',
      height: ({ status }) =>
        status === 'entering' || status === 'entered' ? '43rem' : '10rem',
      display: 'flex',
      marginTop: '1rem',
    },
    [media.desktop]: {
      width: '1030px',
      height: ({ status }) =>
        status === 'entering' || status === 'entered' ? '40rem' : '13.75rem',
      display: 'grid',
      gridTemplateColumns: '33% 7% auto 2%',
      gridTemplateRows: '2.5rem 25% auto',
      gridTemplateAreas: `
        'a . . .'
        'a . b .'
        'a . b .'
      `,
      marginTop: '4rem',
    },
  },
  usersIconContainer: {
    display: 'none',
    [media.desktop]: {
      gridArea: 'a',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#E1E1E1',
    },
  },
  icon: {
    height: '8rem',
    fill: 'white',
  },
};
