import { fromJS } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.withMutations(next =>
    next
      .set('isAuth', data.isAuth)
      .set('activeOrder', data.activeOrder)
      .set('pendingOrder', data.pendingOrder)
      .setIn(['loading', 'submitButton'], false)
      .setIn(['user', 'firstName'], data.firstName)
      .setIn(['user', 'lastName'], data.lastName)
      .setIn(['user', 'role'], data.role)
      .setIn(['user', 'deliveryZoneId'], data.deliveryZoneId)
      .setIn(['deliveryZones'], fromJS(data.deliveryZones)),
  );

export const setLocalDataToStore = createAction({
  action: ['setLocalDataToStore', 'data'],
  handler,
});
