import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const cardInfoBlock = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [media.mobile]: {
      // flexDirection: 'column',
      marginTop: '1rem',
      flexWrap: 'wrap',
    },
  },
  edit: {
    ...button.white,
    fontSize: '1rem',
    fontWeight: 500,
    color: 'black',
    borderColor: 'black',
    [media.mobile]: {
      width: ({ status }) => (status === 'Suspended' ? '45%' : '100%'),
      margin: '1rem 0',
    },
  },
};
