import { api } from 'services/api';
import { setErrorToStore } from 'services/utils';
import { actions } from 'store/actions';

export const editSchedule = (values, onSuccess) => async dispatch => {
  try {
    await api.editSchedule(values);
    const { data } = await api.getSchedule(values.deliveryZoneId);
    dispatch(actions.setData.act(['admin', 'schedule'], data));
    onSuccess && onSuccess();
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
