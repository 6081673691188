import get from 'lodash/get';
import { api } from '../../api';

export const changePassword = (
  values,
  setErrors,
  setSubmitting,
) => async dispatch => {
  try {
    await api.changePassword(values);
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
