import { fromJS } from 'immutable';
import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state
    .set('selectedCustomer', data)
    .setIn(
      ['selectedCustomer', 'customerAddresses'],
      fromJS(data.customerAddresses),
    );

export const setCustomer = createAction({
  action: ['setCustomer', 'data'],
  handler,
});
