import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import injectSheet from 'react-jss';
import { actions } from '../../../../store/actions';
import { Category } from '../../organisms/Driver/Inventory/Category';
import { inventoryProducts } from '../../../styles/Driver/Inventory/inventoryProducts';

const mapStateToProps = state => ({
  products: state.getIn(['app', 'inventory']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeItemQuantity: actions.changeItemQuantity.act,
    },
    dispatch,
  );

const _InventoryProducts = ({ products, changeItemQuantity }) => (
  <div>
    {products.map((category, index) => (
      <Category
        key={category.get('name')}
        data={category}
        categoryIndex={index}
        changeItemQuantity={changeItemQuantity}
      />
    ))}
  </div>
);

export const InventoryProducts = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(inventoryProducts),
)(_InventoryProducts);
