import { Component } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { thunks } from 'services/thunks';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirmOrder: thunks.confirmOrder,
    },
    dispatch,
  );
// Todo Rewrite as a function?
class _ConfirmOrder extends Component {
  componentDidMount() {
    const { match, location, confirmOrder } = this.props;
    confirmOrder(match, location);
  }

  render() {
    return null;
  }
}

export const ConfirmOrder = connect(
  null,
  mapDispatchToProps,
)(_ConfirmOrder);
