import { api } from '../../api';
import { actions } from '../../../store/actions';

export const getDriverProfile = endLoading => async dispatch => {
  try {
    const info = await api.getDriverAccountInfo();
    dispatch(actions.setDriverInfo.act(info.data));
    const deliveryZone = await api.getDeliveryZone();
    dispatch(actions.setDeliveryZone.act(deliveryZone.data));
    endLoading();
  } catch (err) {
    return err;
  }
};
