import { media, palette } from 'ui/styles/theme';

export const tabs = {
  container: {
    display: 'flex',
    alignSelf: 'flex-start',
    [media.mobile]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    gridTemplateRows: 'auto',
    fontFamily: 'Roboto',
    fontWeight: '500',
    userSelect: 'none',
    textTransform: 'uppercase',
    backgroundColor: '#EEEEEE',
    marginTop: '3rem',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    [media.mobile]: {
      width: '48%',
      fontSize: '1rem',
      lineHeight: '2.5rem',
    },
    [media.desktop]: {
      width: '300px',
      fontSize: '1.375rem',
      lineHeight: '3.5rem',
      cursor: 'pointer',
      '&:last-child': {
        marginLeft: '50px',
      },
    },
  },
  activeTab: {
    color: palette.yellow,
    backgroundColor: 'white',
  },
};
