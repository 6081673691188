import { fromJS, Map } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { products, zones, operationTime, deliveryZoneId }) =>
  state.withMutations(next =>
    next
      .set('deliveryZones', fromJS(zones))
      .setIn(['user', 'deliveryZoneId'], deliveryZoneId)
      .set(
        'products',
        Map({
          categoryList: fromJS(products.map(category => category.id)),
          categoryMap: products.reduce(
            (prev, category) =>
              prev.set(
                category.id,
                Map({
                  id: category.id,
                  name: category.name,
                  itemList: fromJS(category.items.map(item => item.id)),
                }),
              ),
            Map({}),
          ),
          items: products.reduce((prev, category) => {
            const categoryItems = category.items.reduce((pre, item) => {
              const existedItem = state.getIn(['products', 'items', item.id]);
              const currentQuantity =
                existedItem && existedItem.get('currentQuantity');
              const activeOption =
                existedItem && existedItem.get('currentQuantity');
              return pre.set(
                item.id,
                fromJS(item)
                  .set('currentQuantity', currentQuantity || 0)
                  .update('productPrices', list =>
                    item.isFixedPrice
                      ? list
                      : list.unshift(
                          Map({ quantity: 0, price: list.getIn([0, 'price']) }),
                        ),
                  )
                  .set('activeOption', activeOption || 0),
              );
            }, Map({}));
            return prev.merge(categoryItems);
          }, Map({})),
        }),
      ),
  );

export const onLoadMainPage = createAction({
  action: ['onLoadMainPage', 'products', 'zones', 'deliveryZoneId'],
  handler,
});
