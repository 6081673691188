import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import injectSheet from 'react-jss';
import { thunks } from '../../../../../services/thunks';
import { thxForSubscribe as styles } from '../../../../styles/Customer/CustomerHome/thxForSubscribe';

// Todo Додати локалізацію (і для валідацій)
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      thxForSubscribe: thunks.thxForSubscribe,
    },
    dispatch,
  );

const _ThxForSubscribe = ({ classes, thxForSubscribe }) => (
  <div className={classes.backstage}>
    <div className={classes.container}>
      <h1 className={classes.title}>Thanks for your interest!</h1>
      <p className={classes.text}>
        We will contact you when your
        <br />
        area is covered.
      </p>
      <button type="button" onClick={thxForSubscribe} className={classes.gotIt}>
        Got It
      </button>
    </div>
  </div>
);

export const ThxForSubscribe = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  injectSheet(styles),
)(_ThxForSubscribe);
