import React from 'react';

export const EditIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 21 24">
    <g>
      <path d="M17,1.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.2,0.2L8.1,13.9C8,13.9,8,14,8,14l-0.6,3.9c0,0.1,0,0.3,0.2,0.4   c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l3.4-1.9c0,0,0.1-0.1,0.1-0.1l8.4-12.4C20,3.7,20,3.5,19.8,3.4L17,1.4z M10.9,15.8   l-2.7,1.5l0.4-3.1l8.2-12l2.2,1.6L10.9,15.8z" />
      <path d="M19.4,8.9C19.2,8.9,19,9,19,9.2v12.6c0,0.8-0.6,1.5-1.4,1.5H2.2c-0.8,0-1.4-0.7-1.4-1.5V6c0-0.8,0.6-1.5,1.4-1.5h9.1   c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H2.2C1,3.8,0,4.8,0,6v15.8C0,23,1,24,2.2,24h15.4c1.2,0,2.2-1,2.2-2.2V9.2   C19.7,9,19.6,8.9,19.4,8.9z" />
      <path d="M20.8,2.1l-2.9-2c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5l2.9,2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.3-0.2   C21.1,2.4,21,2.2,20.8,2.1z" />
    </g>
  </svg>
);
