import React from 'react';
import Transition from 'react-transition-group/Transition';
import { Address } from '../../../molecules/Customer/CustomerProfile/Address';

export class AddressTransition extends React.Component {
  state = {
    isEdit: false,
    height: 'auto',
  };

  toggleEdit = e => {
    if (e) e.stopPropagation();
    this.setState(prevState => ({ isEdit: !prevState.isEdit }));
  };

  setAddressHeight = height => {
    this.setState({ height });
  };

  render() {
    const { data, enableDelete, canSelect, admin, id } = this.props;
    const { isEdit, height } = this.state;
    return (
      <Transition in={isEdit} timeout={250}>
        {status => (
          <Address
            status={status}
            height={height}
            data={data}
            id={id}
            toggleEdit={this.toggleEdit}
            setAddressHeight={this.setAddressHeight}
            enableDelete={enableDelete}
            canSelect={canSelect}
            admin={admin}
          />
        )}
      </Transition>
    );
  }
}
