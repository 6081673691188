import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const emailAreaConfig = {
  mapPropsToValues: () => ({
    email: '',
    postalCode: '',
  }),
  validationSchema: Yup.object().shape({
    email: validators.email,
    postalCode: validators.postalCode,
  }),
  handleSubmit: (values, { props: { sendSubscribe } }) => {
    sendSubscribe(values);
  },
  displayName: 'emailArea',
};
