import React from 'react';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { AddressField } from 'ui/components/organisms/Commons/AddressField';
import { customerAddressConfig } from '../../../../configs/formik/customerAddressConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { editAddressForm } from '../../../../styles/Customer/CustomerProfile/editAddressForm';
import { TextArea } from '../../../atoms/Commons/Client/TextArea';

const _EditAddressForm = ({
  classes,
  errors,
  touched,
  toggleEdit,
  id,
  geocodeAddress,
}) => (
  <div className={classes.container}>
    <Form className={classes.form} id={`addressForm-${id}`}>
      <Field
        component={AddressField}
        name="address"
        type="text"
        labelText="Address"
        placeholder="Start to type your address"
        error={touched.address && errors.postalCode}
        geocodeAddress={geocodeAddress}
        classes={{
          inputContainer: classes.inputContainer,
          label: classes.inputLabel,
          input: classes.input,
          error: classes.inputError,
          suggestionContainer: classes.suggestionContainer,
          suggestion: classes.suggestion,
          activeSuggestion: classes.activeSuggestion,
        }}
      />
      <Field
        component={Input}
        type="text"
        name="boozer"
        placeholder="Unit Number and Buzzer"
        error={touched.boozer && errors.boozer}
        $container={classes.inputContainerWithoutLabel}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
      <Field
        component={Input}
        type="text"
        name="nearestIntersection"
        labelText="Nearest main intersection"
        error={touched.nearestIntersection && errors.nearestIntersection}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
        required
      />
      <Field
        component={TextArea}
        name="instructions"
        labelText="Instructions"
        error={touched.instructions && errors.instructions}
        $container={classes.textareaContainer}
        $label={classes.inputLabel}
        $textarea={classes.textarea}
        $error={classes.inputError}
      />
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
    <div className={classes.buttons}>
      <button type="submit" form={`addressForm-${id}`} className={classes.save}>
        Save
      </button>
      <button type="button" onClick={toggleEdit} className={classes.cancel}>
        Cancel
      </button>
    </div>
  </div>
);

export const EditAddressForm = compose(
  withNamespaces(),
  withFormik(customerAddressConfig),
  injectSheet(editAddressForm),
)(_EditAddressForm);
