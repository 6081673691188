import { createSelector } from 'reselect';
import Immutable from 'immutable';
import moment from 'moment';

const handler = schedule => ({
  isClosed: schedule.getIn(['isClosed']),
  closedReason: schedule.getIn(['closedReason']),
  dailyDriversLogoutTime: moment().set({
    hours: schedule.getIn(['dailyDriversLogoutTime', 'hours']),
    minutes: schedule.getIn(['dailyDriversLogoutTime', 'minutes']),
  }),
  operationalHours: schedule
    .get('operationalHours', Immutable.fromJS([]))
    .toJS(),
  payDayDay: schedule.getIn(['payday', 'dayOfWeek']),
  payDayTime: moment().set({
    hours: schedule.getIn(['payday', 'time', 'hours']),
    minutes: schedule.getIn(['payday', 'time', 'minutes']),
  }),
  orderTimeout: schedule.getIn(['orderTimeout']),
  deliveryTime: schedule.getIn(['deliveryTime']),
});

export const getScheduleFormValues = createSelector(
  state => state.getIn(['app', 'admin', 'schedule']),
  schedule => handler(schedule),
);
