import { media } from 'ui/styles/theme';
import { button } from 'ui/styles/Commons/button';

export const orderCard = {
  container: {
    width: '95%',
    boxShadow: '0 0 35px 2px rgba(115,115,131,0.27)',
    display: 'grid',
    fontFamily: 'Roboto',
    marginBottom: '3rem',
    [media.mobile]: {
      gridTemplateColumns: '5% 5rem auto 5%',
      gridTemplateRows: 'auto auto auto auto auto auto',
      gridTemplateAreas: `
        '. a b .'
        '. a g .'
        '. c c .'
        '. d d .'
        '. d d .'
        '. e e .'
        '. f f .'
      `,
    },
    [media.desktop]: {
      gridTemplateColumns: '35% 30% auto',
      gridTemplateRows: '4rem 3rem auto auto auto auto',
      gridTemplateAreas: `
        'a . .'
        'a b .'
        'a c d'
        'g c d'
        'e e e'
        'f f f'
      `,
    },
  },
  diriver1: {
    width: '98%',
    justifySelf: 'center',
    backgroundColor: '#E1E1E1',
    [media.mobile]: {
      width: '110%',
      gridArea: '5/2/5/4',
    },
    [media.desktop]: {
      gridArea: '5/1/5/4',
    },
  },
  order: {
    gridArea: 'e',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  orderSummary: {
    fontWeight: 500,
    textTransform: 'uppercase',
    [media.mobile]: {
      margin: '2rem 0',
      fontSize: '1.5rem',
    },
    [media.desktop]: {
      margin: '2rem',
      fontSize: '1.75rem',
    },
  },
  diriver2: {
    composes: '$diriver1',
    [media.mobile]: {
      gridArea: '6/2/6/4',
    },
    [media.desktop]: {
      gridArea: '6/1/6/4',
    },
  },
  buttons: {
    gridArea: 'f',
    display: 'flex',
    [media.mobile]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
    [media.desktop]: {
      justifyContent: 'flex-end',
      paddingRight: '4.375rem',
    },
  },
  call: {
    ...button.white,
    borderColor: 'black',
    color: 'black',
    [media.mobile]: {
      width: '100%',
      margin: '1rem 0',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      margin: '2rem 0',
      width: '15rem',
    },
  },
  refuse: {
    ...button.yellow,
    backgroundColor: 'black',
    borderColor: 'black',
    [media.mobile]: {
      width: '100%',
      margin: '1rem 0',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      margin: '2rem 0 2rem 1.5rem',
      width: '13rem',
    },
  },
  cancel: {
    ...button.white,
    [media.mobile]: {
      width: '100%',
      margin: '1rem 0',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      margin: '2rem 0 2rem 1.5rem',
      width: '13rem',
    },
  },
  complete: {
    ...button.yellow,
    [media.mobile]: {
      width: '100%',
      margin: '1rem 0',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      margin: '2rem 0 2rem 1.5rem',
      width: '13rem',
    },
  },
};
