/*eslint-disable*/
import get from 'lodash/get';

import { getPostalCode } from '../services/utils';

const distanceMatrixService = new google.maps.DistanceMatrixService();
const geocoder = new google.maps.Geocoder();

const getCurrentPosition = () =>
  new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej);
  });

const handleGoogleDistanceResponse = res => response => {
  const time = get(
    response,
    ['rows', 0, 'elements', 0, 'duration', 'value'],
    0,
  );
  return res(time);
};

const getDeliveryTime = (position, address) =>
  new Promise((res, rej) => {
    try {
      distanceMatrixService.getDistanceMatrix(
        {
          origins: [
            {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          ],
          destinations: [address],
          travelMode: 'DRIVING',
        },
        handleGoogleDistanceResponse(res),
      );
    } catch (e) {
      rej(e);
    }
  });

export const checkDeliveryTime = async (address, time) => {
  try {
    const position = await getCurrentPosition();
    const deliveryTime = await getDeliveryTime(position, address);
    return deliveryTime > 0 && deliveryTime < time * 60; // time is in minutes
  } catch (e) {
    return e;
  }
};

const getPositionPostalCode = position =>
  new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          for (let i = 0; i < results.length; i++) {
            let postalCode = getPostalCode(results[i]);
            if (postalCode) {
              resolve(postalCode);
              return;
            }
          }
        }
        reject();
      },
    );
  });

export const getCurrentPostalCode = async () => {
  try {
    const position = await getCurrentPosition();
    return await getPositionPostalCode(position);
  } catch (e) {
    return null;
  }
};

export const openNativeMap = (latitude, longitude) => {
  if (
    navigator.platform.indexOf('iPhone') != -1 ||
    navigator.platform.indexOf('iPod') != -1 ||
    navigator.platform.indexOf('iPad') != -1
  ) {
    window.open(
      `maps://maps.google.com/maps?daddr=${latitude}, ${longitude}&amp;ll=`,
    );
  } else {
    window.open(
      `https://maps.google.com/maps?daddr=${latitude}, ${longitude}&amp;ll=`,
    );
  }
};
