import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { error }) =>
  state
    .setIn(['modals', 'validateOrderError'], true)
    .setIn(['validateError'], error);

export const setValidateOrderError = createAction({
  action: ['setValidateOrderError', 'error'],
  handler,
});
