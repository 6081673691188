import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isUserAdult } from 'services/utils';

const mapStateToProps = state => ({
  role: state.getIn(['app', 'user', 'role']),
  activeOrder: state.getIn(['app', 'activeOrder']),
});

const _CustomerRoute = ({ role, activeOrder, isCheckAge, ...props }) => {
  if (isCheckAge && !isUserAdult()) return <Redirect to="/check-age" />;
  if (role === 'driver') return <Redirect to="/orderboard" />;
  if (role === 'admin') return <Redirect to="/admin/drivers" />;
  if (
    activeOrder &&
    props.path !== '/order/:id' &&
    props.path !== '/order/confirm/:id'
  ) {
    return <Redirect to={`/order/${activeOrder}`} />;
  }
  return <Route {...props} />;
};

export const CustomerRoute = connect(mapStateToProps)(_CustomerRoute);
