import React from 'react';
import { EditIcon } from 'ui/components/atoms/icons/EditIcon';
import Divider from '@material-ui/core/Divider';
import { PaymentSection } from 'ui/components/organisms/Driver/Profile/PaymentSection';
import { DeliveryZoneDropDown } from 'ui/components/organisms/Driver/Inventory/DeliveryZoneDropDown';
import { PayNow } from 'ui/components/atoms/Driver/Profile/PayNow';
import injectSheet from 'react-jss';
import { personalInfoBlock } from 'ui/styles/Driver/Profile/personalInfoBlock';
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const _PersonalInfoBlock = ({ classes, data, toggleEdit, driverStatus }) => {
  return (
    <div className={classes.container}>
      <h1 className={classes.userName}>
        {`${data.get('firstName')} ${data.get('lastName')}`}
      </h1>
      <button type="button" onClick={toggleEdit} className={classes.edit}>
        <div className={classes.editContainer}>
          <span>Edit profile</span>
          <EditIcon className={classes.editIcon} />
        </div>
      </button>
      <div className={classes.status}>
        <span />
        <p>{driverStatus}</p>
      </div>
      <div className={classes.emailPhoneWrapper}>
        <p className={classes.suspendReason}>{data.get('suspendReason')}</p>
        <span>{`Phone: ${formatPhoneNumberIntl(data.get('phone'))}`}</span>
        <span>{`Email: ${data.get('email')}`}</span>
        <span>{`Driver License: ${data.get('driverLicense')}`}</span>
        {data.get('smartserve') && (
          <span>{`Smartserve : ${data.get('smartserve')}`}</span>
        )}
      </div>
      <DeliveryZoneDropDown
        classes={{ container: classes.chooseDeliveryZone }}
      />
      {driverStatus === 'Suspended' && <PayNow status={driverStatus} desktop />}
      <Divider className={classes.divider1} />
      <PaymentSection card={data.get('card')} status={driverStatus} />
      <Divider className={classes.divider2} />
      <div className={classes.balanceWrapper}>
        <div>
          <span>Total Earned</span>
          <span>{`$${data.get('totalEarned')}`}</span>
        </div>
        <div>
          <span>Due Balance</span>
          <span>{`$${data.get('dueBalance')}`}</span>
        </div>
      </div>
    </div>
  );
};

export const PersonalInfoBlock = injectSheet(personalInfoBlock)(
  _PersonalInfoBlock,
);
