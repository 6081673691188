import React, { Fragment, Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { thunks } from 'services/thunks';
import { actions } from 'store/actions';
import { Elements } from 'react-stripe-elements';
import { CardModal } from 'ui/components/organisms/Driver/Profile/CardModal';

const mapStateToProps = state => ({
  isCardOpen: state.getIn(['app', 'modals', 'card']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createStripeClient: thunks.createStripeClient,
      openModal: actions.openModal.act,
      closeModal: actions.closeModal.act,
    },
    dispatch,
  );

class _OpenCardModal extends Component {
  openCardModal = () => {
    this.props.openModal('card');
  };

  closeCardModal = () => {
    this.props.closeModal('card');
  };

  render() {
    const { className, isCardOpen, text, createStripeClient } = this.props;
    return (
      <Fragment>
        <button
          type="button"
          className={className}
          onClick={this.openCardModal}
        >
          {text}
        </button>
        {isCardOpen && (
          <Elements
            fonts={[
              {
                cssSrc:
                  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,600',
              },
            ]}
          >
            <CardModal
              isOpen={isCardOpen}
              closeCardModal={this.closeCardModal}
              createStripeClient={createStripeClient}
            />
          </Elements>
        )}
      </Fragment>
    );
  }
}

export const PaymentCard = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(_OpenCardModal);
