import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { thunks } from 'services/thunks';

import { withStyles, Button } from '@material-ui/core';

import { logout as logoutStyle } from 'ui/styles/Admin/Logout';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: thunks.logout,
    },
    dispatch,
  );

const _Logout = ({ logout, classes, t }) => (
  <div className={classes.wrap}>
    <Button onClick={logout} color="secondary" variant="contained">
      {t('Log out')}
    </Button>
  </div>
);

export const Logout = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withStyles(logoutStyle),
)(_Logout);
