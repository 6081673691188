const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  paperShort: {
    padding: theme.spacing.unit * 2,
    width: '600px',
    margin: '0 auto',
  },
});

export default styles;
