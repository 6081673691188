import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { ProfileIcon } from 'ui/components/atoms/icons/ProfileIcon';
import injectSheet from 'react-jss';
import { driverTopBar } from 'ui/styles/Driver/DriverTopBar/driverTopBar';
import { thunks } from 'services/thunks';

const mapStateToProps = state => ({
  firstName: state.getIn(['app', 'user', 'firstName']),
  lastName: state.getIn(['app', 'user', 'lastName']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: thunks.logout,
    },
    dispatch,
  );

const _DriverTopBar = ({
  showProfile,
  showLogout,
  blockRouter,
  classes,
  logout,
  firstName,
  lastName,
}) => (
  <div className={classes.container}>
    <NavLink
      className={classes.logo}
      to="/orderboard"
      onClick={e => blockRouter && e.preventDefault()}
    >
      <span>Boo Zapp</span>
    </NavLink>

    {showProfile && (
      <NavLink className={classes.profileLink} to="/driver/profile">
        <div>{`${firstName} ${lastName}`}</div>
        <ProfileIcon className={classes.profileIcon} />
      </NavLink>
    )}
    {showLogout && (
      <button type="button" onClick={logout} className={classes.logout}>
        Log out
      </button>
    )}
  </div>
);

export const DriverTopBar = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(driverTopBar),
)(_DriverTopBar);
