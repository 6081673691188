import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { constants } from '../../../constants';
import { actions } from '../../../../store/actions';
import { thunks } from '../../../../services/thunks';
import { Dashboard } from './Dashboard';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { AddButton } from '../../atoms/Commons/Admin/AddButton';
import { PageSearch } from '../../molecules/Commons/Admin/PageSearch';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';
import { BaseTable } from '../../ecosystems/Admin/BaseTable';
import DriverRow from '../../organisms/Admin/Driver/DriverRow';
import { AddEditDriver } from '../../organisms/Admin/Driver/AddEditDriver';
import { getTableData } from '../../../../selectors/getTableData';

// @TODO add snackbar
const mapDispatchToProps = dispatch => ({
  getDrivers: ({ ...data }) => dispatch(thunks.getDriversList(data)),
  openModal: () => dispatch(actions.openModal.act('addDriver')),
  closeModal: () => dispatch(actions.closeModal.act('addDriver')),
  addDriver: ({ ...data }) => dispatch(thunks.addDriver(data)),
});

const mapStateToProps = state => {
  return {
    drivers: getTableData(state, ['app', 'tables', 'drivers', 'items']),
    isOpenModal: state.getIn(['app', 'modals', 'addDriver']),
    totalRecords: state.getIn(['app', 'tables', 'drivers', 'totalRecords']),
    needToUpdateData: state.getIn(['app', 'tables', 'needToUpdateData']),
  };
};

class _AdminDrivers extends Component {
  state = {
    keyWord: '',
    order: 'asc',
    sortColumn: 'name',
    pageSize: 10,
    pageIndex: 1,
  };

  handleChangeSearch = e => {
    this.setState({
      keyWord: e.target.value,
      pageIndex: 1,
    });
  };

  getData = () => {
    this.props.getDrivers({
      page: this.state.pageIndex,
      pageSize: this.state.pageSize,
      orderBy: this.state.sortColumn,
      orderDirection: this.state.order,
      search: this.state.keyWord,
    });
  };

  onChangePage = (data, value) => {
    this.setState({ pageIndex: value + 1 });
  };

  onChangeRowsPerPage = data => {
    this.setState({ pageSize: data.target.value });
  };

  onHandleRequestSort = (e, columnName) => {
    this.setState(prevState => {
      return {
        order: prevState.order === 'asc' ? 'desc' : 'asc',
        sortColumn: columnName,
      };
    });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.order !== this.state.order ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.pageIndex !== this.state.pageIndex ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.keyWord !== this.state.keyWord ||
      (prevProps.needToUpdateData !== this.props.needToUpdateData &&
        this.props.needToUpdateData)
    ) {
      this.getData();
    }
  }

  render() {
    const { keyWord, order, sortColumn, pageIndex, pageSize } = this.state;
    const { t, openModal, addDriver, isOpenModal, closeModal } = this.props;

    return (
      <Dashboard>
        <PageRow>
          <PageTitle title={t('Drivers')}>
            <AddButton text={t('Add New Driver')} handleClick={openModal} />
          </PageTitle>
        </PageRow>

        <PageRow>
          <PageSearch
            handleSearch={this.handleSearch}
            onChange={this.handleChangeSearch}
            value={keyWord}
          />
        </PageRow>

        <BaseTable
          order={order}
          sortColumn={sortColumn}
          pageSize={pageSize}
          pageIndex={pageIndex}
          customName={'fullname'}
          data={this.props.drivers}
          totalRecords={this.props.totalRecords}
          Row={DriverRow}
          columnsData={constants.driverTableColumns}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onHandleRequestSort={this.onHandleRequestSort}
          styleColumns
        />
        {isOpenModal && (
          <AddEditDriver
            submit={addDriver}
            isOpenModal={isOpenModal}
            closeModal={closeModal}
          />
        )}
      </Dashboard>
    );
  }
}

export const AdminDrivers = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_AdminDrivers);
