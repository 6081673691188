import * as Yup from 'yup';

const checkSpaces = /[a-zA-Z0-9]/;

export const newPassword = Yup.string().when('oldPassword', {
  is: value => value,
  then: Yup.string()
    .matches(
      checkSpaces,
      'Password should contain at least one number or one latin letter',
    )
    .min(8, 'New  Password should be more than 8 symbols')
    .max(15, 'New Password should be less than 15 symbols')
    .required('New Password is required'),
});
