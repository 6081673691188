import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { ModalWithButtons } from 'ui/components/organisms/Admin/Commons/ModalWithButtons';
import { addEditDriverConfig } from '../../../../configs/formik/addEditDriverConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { addDriver } from '../../../../styles/Admin/Drivers/addDriver';
import { Modal } from '../../Commons/Modal';

const mapDispatchToProps = dispatch => ({});

class _AddEditDriver extends Component {
  render() {
    const {
      touched,
      errors,
      classes,
      closeModal,
      isOpenModal,
      id,
      handleSubmit,
    } = this.props;

    return (
      <ModalWithButtons
        $backstage={this.props.classes.backstage}
        $container={this.props.classes.container}
        onClose={closeModal}
        isOpen={isOpenModal}
        onClick={handleSubmit}
      >
        <h1 className={classes.title}>{`${id ? 'Edit' : 'Add New'} Driver`}</h1>
        <Form className={classes.form}>
          <Field
            component={Input}
            type="text"
            name="firstName"
            placeholder="First Name"
            labelText="Name"
            error={touched.firstName && errors.firstName}
            $container={classes.inputHalfContainer}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
            required
          />
          <Field
            component={Input}
            type="text"
            name="lastName"
            placeholder="Last Name"
            error={touched.lastName && errors.lastName}
            $container={classes.inputHalfContainerWithoutLabel}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
            required
          />
          <Field
            component={PhoneInput}
            type="text"
            name="phone"
            labelText="Phone"
            error={touched.phone && errors.phone}
            $container={classes.inputHalfContainer}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
            required
          />
          <Field
            component={Input}
            type="text"
            name="email"
            placeholder="example@gmail.com"
            labelText="Email"
            error={touched.email && errors.email}
            $container={classes.inputContainer}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
            required
          />
          <Field
            component={Input}
            type="text"
            name="driverLicense"
            labelText="Driver's License Number"
            error={touched.driverLicense && errors.driverLicense}
            $container={classes.inputContainer}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
            required
          />
          <Field
            component={Input}
            type="text"
            name="smartserve"
            labelText="Smartserve Number"
            error={touched.smartserve && errors.smartserve}
            $container={classes.inputContainer}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
          />
          <Field
            component={Input}
            type="password"
            name="password"
            placeholder="Setup a password"
            labelText="Password"
            error={touched.password && errors.password}
            $container={classes.inputHalfContainer}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
          />
          <Field
            component={Input}
            type="password"
            name="confirmPassword"
            placeholder="Confirm password"
            error={touched.confirmPassword && errors.confirmPassword}
            $container={classes.inputHalfContainerWithoutLabel}
            $label={classes.inputLabel}
            $input={classes.input}
            $error={classes.inputError}
          />
        </Form>
      </ModalWithButtons>
    );
  }
}

export const AddEditDriver = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withFormik(addEditDriverConfig),
  injectSheet(addDriver),
)(_AddEditDriver);
