import bannerBackground from '../images/good-time.jpg';
import { palette } from '../styles/theme';

export default {
  blocks: [
    {
      key: '637gs',
      text: 'Boo! Zapp!',
      type: 'header-one',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 12,
          length: 16,
          style: `color-${palette.yellow}`,
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: '637gr',
      text: 'After Hours Alcohol Delivery',
      type: 'header-two',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 29,
          style: 'UPPERCASE',
        },
        {
          offset: 12,
          length: 16,
          style: `color-${palette.yellow}`,
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: 'dsp7g',
      text: 'Booze delivered in 30 minutes ',
      type: 'header-two',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 18,
          length: 11,
          style: `color-${palette.yellow}`,
        },
        {
          offset: 29,
          length: 2,
          style: 'fontfamily-Font Awesome',
        },
        {
          offset: 29,
          length: 1,
          style: 'fontsize-19',
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: '6ks74',
      text: '* Cash or E-transfer * no credit card required.',
      type: 'header-three',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 47,
          style: 'UPPERCASE',
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: 'b7or3',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '7oalk',
      text: "We're open now!!",
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 16,
          style: 'BOLD',
        },
        {
          offset: 0,
          length: 16,
          style: 'UPPERCASE',
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: '5s67d',
      text: 'Proudly serving Ontario',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 23,
          style: 'UPPERCASE',
        },
      ],
      entityRanges: [],
      data: {},
    },
    {
      key: 'e4f71',
      text: 'Order Now',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [
        {
          offset: 0,
          length: 9,
          style: 'UPPERCASE',
        },
        {
          offset: 0,
          length: 9,
          style: 'fontfamily-Arial',
        },
      ],
      entityRanges: [
        {
          offset: 0,
          length: 9,
          key: 0,
        },
      ],
      data: {},
    },
  ],
  entityMap: {
    '0': {
      type: 'LINK',
      mutability: 'MUTABLE',
      data: {
        url: '#order',
        className: 'button anchor',
      },
    },
    background: {
      type: 'IMAGE',
      mutability: 'MUTABLE',
      data: {
        src: bannerBackground,
        objectFit: 'cover',
        objectPosition: 'top left',
      },
    },
  },
};
