import { api } from '../../api';
import { actions } from '../../../store/actions';
import { setErrorToStore } from 'services/utils';

export const sendSubscribe = ({ email, postalCode }) => async dispatch => {
  try {
    await api.sendSubscribe(email, postalCode);
    dispatch(actions.openModal.act('thxForSubscribe'));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
