import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'clsx';
import Option from '../Option';
import { createUseStyles } from 'react-jss';

const stopPropagation = event => {
  event.stopPropagation();
};

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  modal: {
    width: 84,
    height: 84,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  active: {
    backgroundColor: 'black',
  },
  activeSvg: {
    fill: 'black',
    strokeWidth: 0.7,
  },
});

const values = [
  'top left',
  'top',
  'top right',
  'left',
  'center',
  'right',
  'bottom left',
  'bottom',
  'bottom right',
];

export default ({ modalHandler, value, update, disabled }) => {
  const classes = useStyles();
  const [toggled, setToggled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = useCallback(() => {
    setToggled(true);
    setExpanded(x => !x);
  }, []);

  const collapse = useCallback(() => {
    setToggled(toggled => {
      if (!toggled) {
        setExpanded(false);
      }
      return false;
    });
  }, []);

  const onSelect = useCallback(
    value => {
      update({ objectPosition: value });
      toggleExpansion();
    },
    [toggleExpansion, update],
  );

  useEffect(() => {
    modalHandler.registerCallBack(collapse);
    return () => {
      modalHandler.deregisterCallBack(collapse);
    };
  }, []);

  const transform = useMemo(
    () => {
      const index = values.indexOf(value);
      const x = (index % 3) * 5;
      const y = Math.floor(index / 3) * 3;
      return `translate(${x}, ${y})`;
    },
    [value],
  );

  return (
    <div
      className={classes.wrapper}
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
      title="Image Position"
    >
      <Option onClick={toggleExpansion} disabled={disabled}>
        <svg width="25" height="20" xmlns="http://www.w3.org/2000/svg">
          <g stroke="black" strokeWidth=".1" fill="transparent">
            <rect y={0} x={0} height={20} width={25} />
          </g>
          <g fill="#000" fillRule="evenodd" transform={transform}>
            <path d="M14.741 0H.26C.116 0 0 .136 0 .304v13.392c0 .168.116.304.259.304H14.74c.143 0 .259-.136.259-.304V.304C15 .136 14.884 0 14.741 0zm-.258 13.391H.517V.61h13.966V13.39z" />
            <path d="M4.138 6.738c.794 0 1.44-.76 1.44-1.695s-.646-1.695-1.44-1.695c-.794 0-1.44.76-1.44 1.695 0 .934.646 1.695 1.44 1.695zm0-2.781c.509 0 .923.487.923 1.086 0 .598-.414 1.086-.923 1.086-.509 0-.923-.487-.923-1.086 0-.599.414-1.086.923-1.086zM1.81 12.174c.06 0 .122-.025.171-.076L6.2 7.728l2.664 3.134a.232.232 0 0 0 .366 0 .343.343 0 0 0 0-.43L7.987 8.969l2.374-3.06 2.912 3.142c.106.113.27.105.366-.02a.343.343 0 0 0-.016-.43l-3.104-3.347a.244.244 0 0 0-.186-.08.245.245 0 0 0-.18.1L7.622 8.537 6.394 7.094a.232.232 0 0 0-.354-.013l-4.4 4.56a.343.343 0 0 0-.024.43.243.243 0 0 0 .194.103z" />
          </g>
        </svg>
      </Option>
      {expanded && (
        <div
          className={classNames('rdw-colorpicker-modal', classes.modal)}
          onClick={stopPropagation}
        >
          {values.map((position, index) => (
            <Option
              value={position}
              key={index}
              className="rdw-colorpicker-option"
              activeClassName={classNames(
                'rdw-colorpicker-option-active',
                classes.active,
              )}
              active={value === position}
              onClick={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};
