import { palette, media } from 'ui/styles/theme';

export const openNativeMap = {
  container: {
    display: 'flex',
    textTransform: 'uppercase',
    [media.mobile]: {
      width: '15%',
      order: 2,
      flexDirection: 'column',
      alignItems: 'center',
      '& > span': {
        order: 2,
      },
    },
    [media.desktop]: {
      marginTop: '1rem',
      alignItems: 'center',
      cursor: 'pointer',
      transition: '200ms',
      '&:hover': {
        color: palette.yellow,
      },
      '&:hover > svg': {
        fill: palette.yellow,
      },
    },
  },
  icon: {
    height: '2rem',
    [media.mobile]: {
      order: 1,
    },
    [media.desktop]: {
      marginLeft: '1rem',
      transition: '200ms',
    },
  },
};
