import React from 'react';
import Select from 'react-select';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import injectSheet from 'react-jss';
import { getDeliveryZone } from '../../../../../selectors/getDeliveryZone';
import { getActiveDeliveryZone } from '../../../../../selectors/getActiveDeliveryZone';
import { changeDeliveryZone } from 'ui/styles/Customer/CustomerHome/changeDeliveryZone';
import {
  dropDownStyles,
  dropDownTheme,
} from '../../../../styles/Driver/Inventory/dropDown';
import { thunks } from 'services/thunks';

const mapStateToProps = state => ({
  deliveryZones: getDeliveryZone(state),
  currentZone: getActiveDeliveryZone(state),
});

const mapDispatchToProps = {
  setActiveDeliveryZone: thunks.setActiveDeliveryZone,
};

class _DeliveryZoneDropDown extends React.Component {
  onChange = ({ value }) => {
    this.props.setActiveDeliveryZone(value);
  };

  render() {
    const { classes, deliveryZones, currentZone } = this.props;
    return (
      <div className={classes.container}>
        <h4>Your region:</h4>
        <div className={classes.dropdown}>
          <Select
            onChange={this.onChange}
            isSearchable={false}
            options={deliveryZones}
            value={currentZone}
            placeholder="Select your area"
            theme={dropDownTheme}
            styles={dropDownStyles}
          />
        </div>
      </div>
    );
  }
}

export const CustomerDeliveryZone = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(changeDeliveryZone),
)(_DeliveryZoneDropDown);
