import React from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { thunks } from '../../../../../services/thunks';
import { emailAreaConfig } from '../../../../configs/formik/emailAreaConfig';
import { selectors } from '../../../../../selectors';
import { Input } from '../../../atoms/Commons/Client/Input';
import { emailWhenAreaSupported } from '../../../../styles/Customer/CustomerHome/emailWhenAreaSupported';
import { actions } from '../../../../../store/actions';

const mapStateToProps = state => ({
  deliveryZones: selectors.getChooseAreaDropDownData(state),
});

const mapDispatchToProps = dispatch => ({
  cancel: () => dispatch(actions.openModal.act('chooseArea')),
  sendSubscribe: (email, code) => dispatch(thunks.sendSubscribe(email, code)),
});

class _EmailWhenAreaSupported extends React.PureComponent {
  render() {
    const { classes, errors, touched, t, cancel } = this.props;
    return (
      <div className={classes.backstage}>
        <div className={classes.container}>
          <h1 className={classes.title}>{t('Please enter the details')}</h1>
          <Form className={classes.form} id="emailAreaForm">
            <Field
              component={Input}
              type="text"
              name="email"
              labelText="Email"
              error={touched.email && errors.email}
              $container={classes.inputContainer}
              $label={classes.inputLabel}
              $input={classes.input}
              $error={classes.inputError}
            />
            <Field
              component={Input}
              type="text"
              name="postalCode"
              labelText="Postal code"
              error={touched.postalCode && errors.postalCode}
              $container={classes.inputContainer}
              $label={classes.inputLabel}
              $input={classes.input}
              $error={classes.inputError}
            />
          </Form>
          <button type="button" onClick={cancel} className={classes.cancel}>
            Cancel
          </button>
          <button type="submit" form="emailAreaForm" className={classes.ok}>
            Ok
          </button>
        </div>
      </div>
    );
  }
}

export const EmailWhenAreaSupported = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withFormik(emailAreaConfig),
  injectSheet(emailWhenAreaSupported),
)(_EmailWhenAreaSupported);
