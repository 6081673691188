import { button } from '../../Commons/button';
import { backstage } from '../../Commons/backstage';
import { media, palette } from '../../theme';

export const chooseAreaModal = {
  backstage,
  container: {
    width: '90%',
    height: '275px',
    maxWidth: '400px',
    display: 'grid',
    gridTemplateColumns: '3% 94% 3%',
    gridTemplateRows: '12% 25% 40% auto',
    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. b .'
      '. c .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    [media.desktop]: {
      width: '630px',
      maxWidth: '630px',
      height: '365px',
      gridTemplateColumns: '20% 60% 20%',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginTop: 15,
    [media.desktop]: {
      fontSize: '1.75rem',
      textTransform: 'none',
    },
  },
  message: {
    gridArea: 'a',
    alignSelf: 'start',
    fontFamily: 'Roboto',
    color: palette.red,
    textAlign: 'center',
  },
  dropDown: {
    gridArea: 'b',
    alignSelf: 'start',
  },
  link: {
    gridArea: 'b',
    fontFamily: 'Roboto',
    textAlign: 'center',
    marginTop: 40,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: palette.blue,
  },
  ok: {
    ...button.yellow,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'center',
  },
};
