import { button } from 'ui/styles/Commons/button';
import { media, palette } from 'ui/styles/theme';

export const driverTopBar = {
  container: {
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [media.mobile]: {
      width: '100%',
    },
    [media.desktop]: {
      width: '1030px',
      height: '5.5rem',
    },
  },
  logo: {
    gridArea: 'a',
    alignSelf: 'center',
    fontSize: '1.125rem',
    fontFamily: 'Righteous',

    textTransform: 'uppercase',
    cursor: 'pointer',
    [media.desktop]: {
      width: '10rem',
      fontSize: '1.8rem',
    },
  },
  profileLink: {
    gridArea: 'a',
    justifySelf: 'end',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontWeight: 300,
    [media.mobile]: {
      gridArea: 'c',
      fontSize: '1rem',
      textAlign: 'end',
    },
  },
  profileIcon: {
    fill: 'white',
    flexShrink: 0,
    '& circle': {
      fill: palette.yellow,
    },
    [media.mobile]: {
      height: '2.5rem',
      marginLeft: '0.5rem',
    },
    [media.desktop]: {
      height: '3.25rem',
      marginLeft: '1rem',
    },
  },
  logout: {
    ...button.white,
    color: 'black',
    borderColor: 'black',
    width: '88px',
    fontWeight: 600,
    [media.desktop]: {
      ...button.white[media.desktop],
      width: '8.125rem',
      gridArea: 'a',
    },
  },
};
