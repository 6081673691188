export const operationalHours = {
  operationalHoursWrapper: {
    flex: 1,
  },
  operationalHours: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 2rem',
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&>div': {
      margin: 0,
    },
  },
  buttonRemove: {
    position: 'absolute',
    left: '100%',
    height: 'fit-content',
    margin: 'auto 1rem',
    top: 0,
    bottom: 0,
    color: '#f34b73',
    '&:hover': {
      color: '#f31821',
    },
  },
  buttonAdd: {
    margin: '.7rem 2rem',
    backgroundColor: '#03a9f4',
    '&:hover': {
      backgroundColor: '#2F80EC',
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  dayPicker: {
    width: '9rem',
  },
  timePickerSmall: {
    width: '5rem',
  },
  error: {
    fontFamily: 'Roboto',
    margin: '1rem 2rem',
    color: '#f31821',
  },
  itemError: {
    fontFamily: 'Roboto',
    margin: '-.5rem 2rem 0',
    color: '#f31821',
  },
};
