import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ProductForm } from 'ui/components/ecosystems/Admin/AddProduct/ProductForm';
import { thunks } from 'services/thunks';
import { Dashboard } from 'ui/components/environments/Admin/Dashboard';
import { PageRow } from 'ui/components/atoms/Commons/Admin/PageRow';
import { PageTitle } from 'ui/components/atoms/Commons/Admin/PageTitle';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addProduct: thunks.addProduct,
    },
    dispatch,
  );

const _AddProduct = ({ t, addProduct }) => (
  <Dashboard>
    <PageRow>
      <PageTitle title={t('Add New Item')} />
    </PageRow>
    <ProductForm onSubmit={addProduct} />
  </Dashboard>
);

export const AddProduct = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_AddProduct);
