import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const openOrderTableRow = {
  container: {
    width: '100%',
    minHeight: '5rem',
    display: 'grid',
    borderBottom: '1px solid #E1E1E1',
    fontFamily: 'Roboto',
    '&:last-child': {
      borderBottom: 'none',
    },
    [media.mobile]: {
      gridTemplateColumns: '30% auto 30%',
      gridTemplateRows: 'auto auto auto auto auto',
      gridTemplateAreas: `
        '. . c'
        'a b b'
        'd d d'
        'e e e'
        'g g g'
      `,
    },
    [media.desktop]: {
      gridTemplateColumns: '12% 29% auto 5% 20%',
      gridTemplateRows: '50% 50%',
      gridTemplateAreas: `
        'a b d e g'
        'a c d e g'
      `,
    },
  },
  icon: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'center',
    fill: 'white',
    '& > circle': {
      fill: '#E1E1E1',
    },
    [media.mobile]: {
      height: '5rem',
    },
    [media.desktop]: {
      height: '6rem',
    },
  },
  time: {
    gridArea: 'c',
    fontWeight: 300,
    color: '#959595',
    [media.mobile]: {
      alignSelf: 'center',
      justifySelf: 'end',
      margin: '1rem 0.75rem 0 0',
    },
    [media.desktop]: {
      alignSelf: 'start',
      marginTop: '0.25rem',
    },
  },
  intersection: {
    gridArea: 'b',
    fontSize: '1.125rem',
    [media.mobile]: {
      alignSelf: 'center',
    },
    [media.desktop]: {
      alignSelf: 'end',
      marginBottom: '0.25rem',
    },
  },
  items: {
    gridArea: 'd',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    [media.mobile]: {
      width: '90%',
      justifySelf: 'center',
      margin: '0.5rem 0 1rem 0',
    },
    [media.desktop]: {
      margin: '1rem 0',
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '& > span:nth-child(1)': {
      fontWeight: 500,
    },
    '& > span:nth-child(2)': {
      fontSize: '0.75rem',
      margin: '0 0.5rem',
    },
    '& > span:nth-child(3)': {
      fontWeight: 400,
    },
  },
  price: {
    gridArea: 'e',
    alignSelf: 'center',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  total: {
    gridArea: 'e',
    width: '100%',
    height: '3rem',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 500,
    justifySelf: 'center',
    marginBottom: '1.5rem',
    backgroundColor: '#F3F3F3',
    '& > span': {
      margin: '0 1rem 0 5%',
    },
  },
  accept: {
    ...button.white,
    gridArea: 'g',
    justifySelf: 'end',
    alignSelf: 'center',
    [media.mobile]: {
      justifySelf: 'center',
      marginBottom: '1.5rem',
      fontWeight: 600,
      width: '70%',
    },
  },
};
