import colors from '../colors';

const styles = theme => ({
  shell: {
    display: 'flex',
    backgroundColor: colors.shellBackground,
    width: '100vw',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  contentWrap: {
    display: 'inline-block',
    minWidth: '100%',
    padding: theme.spacing.unit * 3,
  },
});

export default styles;
