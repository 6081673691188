import React, { useCallback } from 'react';
import { getIn } from 'formik';
import {
  TextField,
  Checkbox,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import styles from '../../../../styles/Admin/AddProduct/priceMenuItemField';
import { InputComponent } from '../../../atoms/Commons/Admin/FormikNumberInputField';
import { InputProps as PriceInputProps } from './PriceInputField';

const _PriceMenuItemField = ({
  form: { touched, errors, setFieldValue, setFieldTouched },
  field: { value, name, onBlur },
  label,
  classes,
  autoFocus,
  isLast,
  InputProps,
}) => {
  const onChange = useCallback(
    e => {
      if (e.target.value == '') {
        setFieldValue(name, undefined);
        setFieldTouched(name, false);
      } else {
        setFieldValue(name, { ...value, value: e.target.value });
        setFieldTouched(name, true);
      }
    },
    [setFieldValue, setFieldTouched, name, value],
  );
  const toogle = useCallback(
    () => {
      if (value) {
        if (value.value) {
          value.disabled = !value.disabled;
          setFieldValue(name, value);
        } else {
          setFieldValue(name, undefined);
          setFieldTouched(name, false);
        }
      } else {
        setFieldValue(name, {});
      }
    },
    [setFieldValue, setFieldTouched, value],
  );
  const onKeyDown = useCallback(
    e => {
      switch (e.key) {
        case 'Escape':
          break;
        case 'Tab':
          // it makes possible to navigate with tab between items
          // close menu with tab only from the last item
          if (isLast) {
            break;
          }
        default:
          e.stopPropagation();
          break;
      }
    },
    [isLast],
  );
  const error = getIn(touched, name) && getIn(errors, name);

  return (
    <li key={`item_${name}`} className={classes.container}>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={!!value && !value.disabled}
            onClick={toogle}
            tabIndex={-1}
          />
        }
        label={label}
      />
      <TextField
        error={!!error}
        id={name}
        autoFocus={autoFocus}
        value={value == null ? '' : value.value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onBlur={onBlur}
        className={classes.input}
        InputProps={{
          inputComponent: InputComponent,
          ...PriceInputProps,
          ...InputProps,
        }}
      />
    </li>
  );
};

export const PriceMenuItemField = withStyles(styles)(_PriceMenuItemField);
