import { api } from 'services/api';
import { actions } from 'store/actions';
import { push } from 'connected-react-router';
import { removeActiveOrder } from 'utils';

export const rejectOrder = id => async dispatch => {
  try {
    await api.rejectOrder(id);
    dispatch(actions.closeModal.act('cancelOrder'));
    removeActiveOrder();
    dispatch(actions.setData.act(['activeOrder'], null));
    dispatch(push('/orderboard'));
  } catch (e) {
    return e;
  }
};
