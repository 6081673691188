import { createSelector } from 'reselect';

const getInventory = inventory =>
  inventory.reduce((prev, subCategory) => {
    subCategory.get('items').forEach(item => {
      if (item.get('quantity') > 0) {
        prev.push({
          id: item.get('id'),
          productId: item.get('productId'),
          quantity: item.get('quantity'),
        });
      }
    });
    return prev;
  }, []);

export const getInventoryForUpdate = createSelector(
  state => state.getIn(['app', 'inventory']),
  inventory => getInventory(inventory),
);
