import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { values: { firstName, lastName, phone, email } }) =>
  state.withMutations(next =>
    next
      .setIn(['user', 'firstName'], firstName)
      .setIn(['user', 'lastName'], lastName)
      .setIn(['user', 'phone'], phone)
      .setIn(['user', 'email'], email),
  );

export const editCustomerInfo = createAction({
  action: ['editCustomerInfo', 'values'],
  handler,
});
