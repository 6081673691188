import React from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { withStyles, Drawer, Typography } from '@material-ui/core';
import styles from '../../../../styles/Admin/NavBar';
import { NavLink } from '../../../atoms/Commons/Admin/NavLink';
import { Logout } from '../Logout/Logout';

const _NavBar = ({ classes, t }) => (
  <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }} open>
    <Typography
      component="h6"
      variant="h6"
      classes={{ root: classes.titleRoot }}
    >
      {t('Admin Menu')}
    </Typography>
    <NavLink to="/admin/customers" text={t('Customers')} />
    <NavLink to="/admin/drivers" text={t('Drivers')} />
    <NavLink to="/admin/orders" text={t('Orders')} />
    <NavLink to="/admin/transactions" text={t('Transactions')} />
    <NavLink to="/admin/products" text={t('Products')} />
    <NavLink to="/admin/deliveryzones" text={t('Delivery Zones')} />
    <NavLink to="/admin/time-settings" text={t('Time Settings')} />
    <NavLink to="/admin/changepassword" text={t('Change password')} />
    <Logout />
  </Drawer>
);

export const NavBar = compose(
  withNamespaces('admin'),
  withStyles(styles),
)(_NavBar);
