import {privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const editDriver = (
  id,
  {
    driverLicense,
    email,
    phone,
    password,
    firstName,
    lastName,
    smartserve,
  },
) =>
  privateAxios({
    method: 'put',
    baseURL: adminAPI,
    url: `/driver/${id}`,
    data: {
      driverLicense,
      email,
      phone,
      password,
      firstName,
      lastName,
      smartserve,
    },
  });
