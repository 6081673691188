import qs from 'qs';

export const getOrderId = order => {
  if (typeof order === 'string') {
    return +order.split('?')[0];
  }
  return order;
};

export const getOrderSecret = order =>
  qs.parse(order, { ignoreQueryPrefix: true }).secret;

export const getOrderWithSecret = (id, secret) =>
  secret ? `${id}?secret=${secret}` : id;
