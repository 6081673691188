import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'store/actions';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { errorModal } from 'ui/styles/Commons/errorModal';

const mapStateToProps = state => ({
  isOpen: state.getIn(['app', 'modals', 'error']),
  errorMessage: state.getIn(['app', 'error']),
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(actions.closeModal.act('error')),
});

const _ErrorModal = ({ classes, isOpen, close, errorMessage }) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
    onClose={close}
  >
    <p className={classes.errorMessage}>{errorMessage}</p>
    <button type="button" onClick={close} className={classes.ok}>
      Close
    </button>
  </Modal>
);

export const ErrorModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(errorModal),
)(_ErrorModal);
