import { palette, media } from 'ui/styles/theme';

export const checkbox = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxWrapper: {
    height: '1.25rem',
    width: '1.25rem',
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    flexShrink: 0,
  },
  fakeBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid black',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 300,
    color: palette.grey500,
    marginLeft: '1rem',
    [media.mobile]: {
      fontSize: '0.8rem',
    },
  },
};
