import { input } from '../../Commons/input';
import { media, palette } from '../../theme';
import { button } from '../../Commons/button';

export const editAddressForm = {
  container: {
    width: '100%',
    display: ({ status }) => (status === 'entered' ? 'flex' : 'none'),
    flexDirection: 'column',
    alignItems: 'center',
    [media.desktop]: {
      gridArea: 'b',
      alignSelf: 'center',
      display: ({ status }) => (status === 'entered' ? 'grid' : 'none'),
      gridTemplateColumns: '5% 65% 5% auto',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
      '. a . b'
    `,
    },
  },
  form: {
    gridArea: 'a',
    width: '90%',
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      height: 'auto',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1.5rem 2.25rem 1.25rem',
      gridTemplateAreas: `
      'a'
      'b'
      'c'
     `,
    },
  },
  inputContainerWithoutLabel: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      height: 'auto',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '2.25rem 1.25rem',
      gridTemplateAreas: `
      'b'
      'c'
     `,
    },
  },
  inputLabel: {
    ...input.label,
    fontWeight: 500,
    lineHeight: '1.4rem',
  },
  input: {
    ...input.input,
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  inputError: { ...input.error },
  textareaContainer: {
    ...input.container,
    height: '8rem',
    gridTemplateRows: '2.25rem auto 1.75rem',
    [media.desktop]: {
      ...input.container[media.desktop],
      height: '10rem',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '2.25rem auto 1.75rem',
      gridTemplateAreas: `
      'a'
      'b'
      'c'
      `,
    },
  },
  textarea: {
    ...input.input,
    padding: '0.5rem',
    resize: 'none',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  buttons: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0',
    [media.desktop]: {
      gridArea: 'b',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  save: {
    ...button.yellow,
    fontWeight: 600,
    /*
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',
    */

    [media.desktop]: {
      ...button.yellow[media.desktop],
      marginBottom: '1.5rem',
      height: '2.5rem', // 40px
      width: '9.375rem', // 150px
    },
  },
  cancel: {
    ...button.yellow,
    fontWeight: 600,
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',

    [media.desktop]: {
      ...button.yellow[media.desktop],
      marginBottom: '1.5rem',
      height: '2.5rem', // 40px
      width: '9.375rem', // 150px
    },
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
  suggestionContainer: {
    gridArea: 'c',
    zIndex: 50,
    backgroundColor: 'white',
    border: `1px solid ${palette.yellow}`,
    marginTop: '4rem',
    padding: '0.5rem',
    position: 'absolute',
    // width: '90%',
  },
  suggestion: {
    margin: '0.5rem',
    fontFamily: 'Roboto',
    lineHeight: '1.5rem',
    cursor: 'pointer',
  },
  activeSuggestion: {
    composes: '$suggestion',
    color: palette.yellow,
  },
};
