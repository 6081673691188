import React, { Component } from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withStyles, TableRow, TableCell } from '@material-ui/core';

import StarRatings from 'react-star-ratings';

import { DriverActions } from 'ui/components/molecules/Admin/DriverActions/DriverActions';
import { NameCell } from '../../../atoms/Commons/Admin/NameCell';
import { customerRow } from 'ui/styles/Admin/TableRow';
import { thunks } from '../../../../../services/thunks';

const mapDispatchToProps = dispatch => ({
  setRating: (id, rate) => dispatch(thunks.setDriverRating(id, rate)),
});

class _DriverRow extends Component {
  changeRating = rate => {
    this.props.setRating(this.props.item.id, rate);
  };

  render() {
    const {
      item: { id, firstName, lastName, rate, debt, banReason, status, isOnline, deliveryZoneName },
      classes: { wrap, debtCell },
    } = this.props;

    const name = `${firstName} ${lastName}`;
    const isActive = status === 'Active';
    const isBlocked = status === 'Blocked';
    const isSuspend = status === 'Suspended';
    const isNew = status === 'New';

    return (
      <TableRow>
        <TableCell>
          <NameCell
            name={name}
            blocked={isBlocked}
            suspend={isSuspend}
            reason={banReason}
            isNew={isNew}
            isOnline={isOnline}
            onlineTooltipText={deliveryZoneName}
          />
        </TableCell>
        <TableCell classes={{ root: wrap }}>
          <StarRatings
            rating={rate}
            starDimension="20px"
            starSpacing="5px"
            starRatedColor="orange"
            changeRating={!isBlocked && !isSuspend ? this.changeRating : null}
          />
        </TableCell>
        <TableCell classes={{ root: debtCell }}>{`${debt} $`}</TableCell>
        <DriverActions
          id={id}
          isBlocked={isBlocked}
          isSuspend={isSuspend}
          isActive={isActive}
          isNew={isNew}
          name={name}
        />
      </TableRow>
    );
  }
}

const DriverRow = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(customerRow),
)(_DriverRow);
export default DriverRow;
