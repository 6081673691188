import React from 'react';
import LinkButton from './LinkButton';

const links = {
  order: 'Order\xa0Now',
  wine: 'Wine',
  beer: 'Beer',
  spirits: 'Spirits',
};

export default ({ editorState, onChange }) => (
  <div
    className="rdw-custom-wrapper rdw-anchor-links-wrapper"
    aria-label="rdw-anchor-links-control"
  >
    {Object.entries(links).map(([key, title]) => {
      const href = `#${key}`;
      return (
        <LinkButton
          key={key}
          href={href}
          title={title}
          editorState={editorState}
          onChange={onChange}
        />
      );
    })}
  </div>
);
