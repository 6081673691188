import { api } from 'services/api';
import { push } from 'connected-react-router';
import { actions } from 'store/actions';
import { setErrorToStore } from 'services/utils';
import { getProduct } from './getProduct';

export const getProductForEdit = id => async dispatch => {
  try {
    await dispatch(getProduct(id));
    dispatch(actions.closeModal.act(`operationTime-${id}`));
    dispatch(push(`/admin/products/edit/${id}`));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
