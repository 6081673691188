import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { user: { id, firstName, lastName, email } }) =>
  state.withMutations(next =>
    next
      .set('authorized', true)
      .setIn(['user', 'id'], id)
      .setIn(['user', 'firstName'], firstName)
      .setIn(['user', 'lastName'], lastName)
      .setIn(['user', 'email'], email),
  );

export const signIn = createAction({
  action: ['signIn', 'user'],
  handler,
});
