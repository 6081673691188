import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const setDriverRating = (id, rating) =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: `/driver/setrating/${id}`,
    data: {
      rating,
    },
  });
