import React, { useImperativeHandle } from 'react';
import { compose } from 'redux';
import { withFormik, FieldArray, Field, Form } from 'formik';
import injectSheet from 'react-jss';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

import { OperationalHours } from './OperationalHours';
import { scheduleConfig } from 'ui/configs/formik/scheduleConfig';
import { TimePicker } from 'ui/components/atoms/Commons/Admin/TimePicker';
import { timeSettingsForm } from 'ui/styles/Admin/TimeSettings/timeSettingsForm';
import { FormikInputField } from '../../../atoms/Commons/Admin/FormikInputField';

const _TimeSettingsForm = ({
  classes,
  errors,
  touched,
  resetForm,
  schedule,
  dirty,
  submitForm,
  formRef,
}) => {
  useImperativeHandle(formRef, () => ({ dirty, submitForm }), [
    dirty,
    submitForm,
  ]);

  return (
    <Form className={classes.form} id="scheduleForm">
      <Field
        name="isClosed"
        render={({ field: { name, value, onChange } }) => {
          return (
            <div className={classes.wrapper}>
              <div
                className={`${classes.description} ${classes.descriptionTop}`}
              >
                <FormControlLabel
                  className={classes.checkBox}
                  control={
                    <Checkbox
                      name={name}
                      checked={!!value}
                      onChange={onChange}
                    />
                  }
                  label="Temporarily Closed"
                />
              </div>
              <Field
                component={FormikInputField}
                label="Reason"
                name="closedReason"
                multiline
                rows={2}
                rowsMax={4}
                className={classes.inputField}
                disabled={!value}
              />
            </div>
          );
        }}
      />

      <div className={classes.wrapper}>
        <div className={`${classes.description} ${classes.descriptionTop}`}>
          <span>Operational Hours</span>
        </div>
        <FieldArray name="operationalHours" component={OperationalHours} />
      </div>
      {/* <Payday classes={classes} /> */}
      <div className={classes.wrapper}>
        <div className={classes.description}>
          <span>Daily Driver Logout</span>
        </div>
        <Field
          component={TimePicker}
          name="dailyDriversLogoutTime"
          label="At"
          className={classes.timePicker}
        />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.description}>
          <span>Order Timeout</span>
        </div>
        <Field
          name="orderTimeout"
          render={({ field: { name, value, onChange, onBlur } }) => {
            const error = touched[name] && errors[name];
            return (
              <TextField
                error={!!error}
                type="number"
                name={name}
                value={value}
                variant="outlined"
                label={error || 'Minutes'}
                className={classes.timePicker}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.description}>
          <span>Delivery Time</span>
        </div>
        <Field
          name="deliveryTime"
          render={({ field: { name, value, onChange, onBlur } }) => {
            const error = touched[name] && errors[name];
            return (
              <TextField
                error={!!error}
                type="number"
                name={name}
                value={value}
                variant="outlined"
                label={error || 'Minutes'}
                className={classes.timePicker}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          type="button"
          variant="contained"
          form="scheduleForm"
          className={classes.cancel}
          onClick={() => resetForm(schedule)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          form="scheduleForm"
          className={classes.save}
        >
          Save
        </Button>
      </div>
      <div className={classes.backError}>{errors.backError}</div>
    </Form>
  );
};

export const TimeSettingsForm = compose(
  withFormik(scheduleConfig),
  injectSheet(timeSettingsForm),
)(_TimeSettingsForm);
