import React from 'react';
import { NavLink } from 'react-router-dom';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { validateOrderModal } from 'ui/styles/Customer/CustomerHome/validateOrderModal';

const _ValidateOrderModal = ({ classes, isOpen, close, error }) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
    onClose={close}
  >
    <h1 className={classes.titleContainer}>
      <span>Ooops, </span>
      <span>we love your order but...</span>
    </h1>
    <div className={classes.errors}>
      {error.map(err => (
        <p key={err}>{err}</p>
      ))}
    </div>
    <div className={classes.instructions}>
      <h2>You can do one of the following:</h2>

      <p>
        <span>1. </span>
        Go back and change your order
      </p>
      <span>OR</span>
      <p>
        <span>2. </span>
        Proceed and contact your driver for more options
      </p>
    </div>
    <div className={classes.buttons}>
      <button type="button" onClick={close} className={classes.cancel}>
        Go Back
      </button>
      <NavLink to="/complete-order">
        <button type="button" onClick={close} className={classes.ok}>
          Proceed
        </button>
      </NavLink>
    </div>
  </Modal>
);

export const ValidateOrderModal = injectSheet(validateOrderModal)(
  _ValidateOrderModal,
);
