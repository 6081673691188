import { media } from '../../theme';

export const signUpCard = {
  container: {
    gridArea: 'a',
    [media.mobile]: {
      '& h3': {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '1rem',
        userSelect: 'none',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: '1rem',
      },
    },
  },
};
