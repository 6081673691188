import * as Yup from 'yup';

const day = 24 * 60;
const week = 7 * day;

const operationalPeriod = Yup.object().test({
  name: 'checkPeriod',
  test: item =>
    item.from.hours !== item.to.hours || item.from.minutes !== item.to.minutes,
  message: 'Invalid period of time selected',
});

export const operationalHours = Yup.array()
  .of(operationalPeriod)
  .test({
    name: 'checkForIntersections',
    test: function(periods) {
      var errors = periods
        .map(x => {
          const offset = {
            from: (x.dayOfWeek * 24 + x.from.hours) * 60 + x.from.minutes,
            to: (x.dayOfWeek * 24 + x.to.hours) * 60 + x.to.minutes,
          };
          if (offset.to < offset.from) {
            offset.to += day;
          }
          return offset;
        })
        .reduce((prev, x, idx, all) => {
          all.slice(idx + 1).reduce((prev, y, idx2) => {
            if (
              (x.to > y.from && x.from < y.to) ||
              (x.to > week && (x.to - week > y.from && x.from - week < y.to)) ||
              (y.to > week && (x.to > y.from - week && x.from < y.to - week))
            ) {
              prev[idx] = this.createError({ path: `${this.path}[${idx}]` });
              const targetIdx = idx + 1 + idx2;
              prev[targetIdx] = this.createError({
                path: `${this.path}[${targetIdx}]`,
              });
            }
            return prev;
          }, prev);
          return prev;
        }, []);

      return errors.length ? new Yup.ValidationError(errors) : true;
    },
    message: 'There is an intersection of periods',
  });
