import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const addDriver = data =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: '/driver',
    data,
  });
