import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const categories = [
  { value: 1, label: 'Spirits' },
  { value: 2, label: 'Beer' },
  { value: 3, label: 'Wine' },
];

export const CategoryField = ({
  field: { name, value, onChange },
  form: { setFieldValue },
  className,
  label,
}) => {
  const callback = useCallback(
    x => {
      setFieldValue('prices', {});
      if (x.target.value === 2) {
        setFieldValue('unit', '');
      }
      onChange(x);
    },
    [onChange, setFieldValue],
  );

  return (
    <TextField
      className={className}
      name={name}
      select
      label={label}
      value={value}
      onChange={callback}
      variant="outlined"
    >
      {categories.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
