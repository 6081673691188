import React from 'react';
import visa from 'payment-icons/min/single/visa.svg';
import mastercard from 'payment-icons/min/single/mastercard.svg';
import defaultCard from 'payment-icons/min/flat/default.svg';

const getIcon = brand => {
  switch (brand) {
    case 'Visa':
      return visa;
    case 'MasterCard':
      return mastercard;
    default:
      return defaultCard;
  }
};

const _PaymentIcon = ({ className, brand }) => (
  <img className={className} src={getIcon(brand)} alt="credit card" />
);

export const PaymentIcon = _PaymentIcon;
