import React from 'react';

export const UsersIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 70 65">
    <g>
      <path d="M47.8,50.7L36.7,45c-1.1-0.5-1.7-1.6-1.7-2.8v-4c0.3-0.3,0.5-0.7,0.8-1.1c1.4-2.1,2.6-4.4,3.4-6.9   c1.6-0.8,2.7-2.4,2.7-4.3v-4.7c0-1.1-0.4-2.2-1.2-3.1v-6.3c0.1-0.6,0.3-4.5-2.4-7.7C36,1.4,32.1,0,26.8,0s-9.2,1.4-11.6,4.2   c-2.8,3.2-2.5,7.1-2.4,7.7v6.3c-0.7,0.9-1.2,2-1.2,3.1V26c0,1.4,0.6,2.8,1.7,3.7c1.1,4.3,3.3,7.5,4.1,8.6v3.9   c0,1.1-0.6,2.2-1.6,2.7L5.5,50.6C2.1,52.5,0,56.1,0,60v5h53.7v-4.8C53.7,56.2,51.4,52.5,47.8,50.7z" />
      <path d="M64.7,52l-11.3-5c-0.3-0.1-0.6-0.5-0.8-0.9h7.6c0,0,0.4,0,1.1,0c1.3,0,3.1-0.1,4.7-0.8c1-0.4,1.7-1.2,1.9-2.3   c0.3-1,0.1-2.1-0.5-3c-2.2-3-7.3-11.2-7.4-19.5c0-0.1-0.5-14.3-14.2-14.4c-1.4,0-2.7,0.2-3.9,0.5c0.9,2.5,0.8,4.7,0.8,5.4v5.6   c0.8,1.1,1.2,2.4,1.2,3.7V26c0,2.3-1.2,4.3-3,5.5c-0.9,2.4-2,4.6-3.4,6.6c-0.2,0.3-0.3,0.5-0.5,0.7v3.4c0,0.5,0.3,1,0.7,1.2   L48.8,49c4.2,2.1,6.8,6.4,6.8,11.1V65H70v-4.4C70,57,68,53.7,64.7,52z" />
    </g>
  </svg>
);
