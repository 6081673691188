import React, { Component } from 'react';

import { withStyles, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { SearchForm } from '../../../atoms/Admin/Search/SearchForm';
import { SearchInput } from '../../../atoms/Admin/Search/SearchInput';

import styles from '../../../../styles/Admin/Search/pageSearch';

class _PageSearch extends Component {
  shouldComponentUpdate(nextProps) {
    const { value } = this.props;

    return nextProps.value !== value;
  }

  render() {
    const { handleSearch, value, onChange, classes, autoFocus } = this.props;

    return (
      <SearchForm onSubmit={handleSearch}>
        <div className={classes.wrap}>
          <div className={classes.searchWrap}>
            <SearchInput
              value={value}
              onChange={onChange}
              autoFocus={autoFocus}
            />
          </div>

          <div className={classes.buttonWrap}>
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </div>
        </div>
      </SearchForm>
    );
  }
}

export const PageSearch = withStyles(styles)(_PageSearch);
