import React, { Fragment } from 'react';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import { AppTopBar } from 'ui/components/ecosystems/Customer/AppTopBar';
import { BackButton } from 'ui/components/atoms/Commons/Client/BackButton';
import { withContainer } from 'ui/hoc/withContainer';
import { terms } from 'ui/styles/Driver/Terms/terms';

const _Terms = ({ classes }) => (
  <Fragment>
    <AppTopBar login signup />
    <BackButton />
    <div className={classes.container}>
      <h1>Boo Zapp Independent Contractor Driver Agreement</h1>
      <p>
        Welcome to Boo Zapp. Boo Zapp and/or its affiliates ("Boo Zapp" we, us or
        our) provide a connection between customers who wish to have products
        delivered to them and drivers who can provide these delivery services.
        By using Boo Zapp Services as an independent contractor driver, (you)
        agree to the following terms and conditions.
      </p>
      <p>
        Your access and use of the Services constitutes your agreement to be
        bound by the Independant Contractor Driver Agreement, which establishes
        a contractual relationship between you and Boo Zapp. If you do not agree
        to these terms, you may not access or use the services. Boo Zapp may
        amend the terms related to the services from time to time. Amendments
        will be effective upon Boo Zapp’s posting of such updated Terms at this
        location or the amended policies or supplemental terms on the applicable
        Service. Your continued access or use of the Services after such posting
        constitutes your consent to be bound by the Terms, as amended.
      </p>
      <p>
        DRIVER REQUIRMENTS. As an independent contractor acting as a driver you
        must be 19 years of age, have your own vehicle with insurance, a valid
        driver's license, and a web-capable mobile device. You must provide any
        other equipment needed to perform deliveries.
      </p>
      <p>
        DRIVER STATUS. As a driver who is interested in performing delivery
        services for our customers, you will be an independent contractor, not
        an employee of Boo Zapp. Accordingly, you will have no authority to act
        for or on behalf of Boo Zapp to bind us without our express written
        consent. As an independent contractor, you are not entitled to workers
        compensation or other employee benefits. You are also not eligible to
        receive unemployment compensation benefits through Boo Zapp when you stop
        performing driver services for our customers. You understand you are
        responsible for your own federal, provincial and local income, social
        insurance, unemployment, sales, disability and any other applicable
        local, provincial or federal taxes arising out of your performance of
        services. All sales are between the driver (independant contractor) and
        the customer and Boo Zapp functions solely as a liason between the two.
        Payment is collected by drivers (independant contractors) and Boo Zapp is
        compensated for forwarding the order from customer to driver.
      </p>
      <p>
        You are able to perform driving and delivery services for entities other
        than Boo Zapp. However, you warrant and represent that there is no
        conflict of interest between your performance of driving services for
        Boo Zapp customers and the performance of your services under other
        contracts for services or as an employee of other entities and will
        ensure that no such conflict arises when you are performing services for
        us.
      </p>
      <p>
        BECOMING A DRIVER. In order to become a driver using the Boo Zapp
        application you must establish an account and have it activated. You may
        sign up for an account by completing the online order forms and
        following the directions to submit a request to become a driver and set
        up an account. As part of the account activation you must certify that
        you meet the requirements laid out above (licensed driver, over 19 years
        of age etc).When you register for an account, you will be asked to
        create a unique user name and password. You will be asked whether you
        have read and agree to abide by these Terms of Service and any
        additional terms set forth on the account set up page. If you answer “I
        agree” and you otherwise are eligible, your account may be activated. If
        you answer “I Do Not Agree” or otherwise are not eligible, your account
        will not be activated and you will not be permitted to access the
        services online.
      </p>
      <p>
        ACCOUNT ACTIVATION. Boo Zapp reserves the right to decline to activate an
        account for any person for any reason. If your account is activated, you
        will receive a notification after you register. Once you have confirmed,
        you will be permitted to log in, view, load, and use the services and
        the specific features of the services for which you have been
        registered. By making such confirmation, you acknowledge that you have
        read these Terms And Conditions, understand your rights and obligations,
        and agree to be bound by these Terms And Conditions.
      </p>
      <p>
        PASSWORD PROTECTION. You must have a password to access the services
        online via your account. You are responsible for maintaining the secrecy
        of your password and protecting against unauthorized use of your
        password. You also are responsible for all activities that occur under
        your password and user name or your account made by you or others. You
        will immediately notify Boo Zapp if your password is lost, stolen,
        disclosed to an unauthorized third party or otherwise has been
        compromised or if there is any other breach of security. You will be
        permitted to access only those portions of the services and the
        application for which you have been granted permission to use by
        Boo Zapp.
      </p>
      <p>
        ACCOUNT REQUIRMENTS. In consideration of your use of the Sites and the
        Services, you agree to: (i) provide true, accurate, current, and
        complete information about yourself as prompted by our account
        activation form; and (ii) maintain and promptly update such account
        information to keep it true, accurate, current, and complete. If you
        provide any information that is untrue, inaccurate, not current or
        incomplete, or we have reasonable grounds to suspect that such account
        information is untrue, inaccurate, not current or incomplete, we reserve
        the right to suspend or terminate your account and refuse any and all
        current or future use of the application or the services or any portion
        thereof.
      </p>
      <p>
        TERMS. You shall be responsible for providing your own vehicle in
        connection with performing the services. You are also responsible for
        all costs of vehicle insurance as required by your local jurisdiction
        and as appropriate for the delivery activities you choose to perform,
        and vehicle maintenance, but in no event with limits less than: (x)
        $100,000 for personal auto insurance; and (y) to the extent you are
        utilizing your personal vehicle in performing driving and/or delivery
        services, $100,000 per occurrence in commercial general liability
        insurance, insuring against any and all liability for injury to or death
        of a person or persons and for damage or destruction of property
        occasioned by or arising out of or in connection with your driving
        and/or delivery services to be performed hereunder, including coverage
        for losses due to theft, hijacking or damages in transit. You hereby
        agree and covenant to maintain all insurance coverages required
        hereunder, including on any vehicles used by you in connection with
        providing driving or delivery services hereunder, in amounts specified
        hereunder at all times from and after the date hereof until you no
        longer perform any driving or delivery services hereunder. Boo Zapp shall
        have no liability to you or any third party for your failure to maintain
        any such insurance, or for any losses caused by your failure to carry
        such insurance. DeliveryCircle does not provide any materials or tools
        related to the performance of driver or other independent contractor
        services, and does not reimburse drivers or other independent
        contractors for any expenses incurred in connection with the performance
        of services. By entering into this agreement, you represent that you
        have the requisite expertise, ability and legal right to render the
        services for our customers, and will perform the services in an
        efficient manner. You will abide by all laws, rules and regulations that
        apply to the performance of the services, including applicable laws
        governing operation of a motor vehicle. By entering into this
        DeliveryCircle Independent Contractor Driver Agreement, you are
        authorizing Boo Zapp to run a full background check on you and/or your
        affiliates prior to you starting as an independent contractor driver.
      </p>
      <p>
        INTELLECTUAL PROPERTY. All graphics, logos, page headers, button icons,
        scripts and service names included in or made available through Boo Zapp
        are trademarks or trade dress of Boo Zapp in Canada and other countries.
        Boo Zapp logos and trade dress may not be used in connection with any
        product or service that is not Boo Zapp, in any manner that is likely to
        cause confusion among consumers, or in any manner that disparages or
        discredits Boo Zapp. All other trademarks not owned by Boo Zapp that
        appear in any Boo Zapp Service are the property of their respective
        owners, who may or may not be affiliated with, connected to, or
        sponsored by Boo Zapp. If Boo Zapp discloses any of its trade secrets to
        you, you agree to treat Boo Zapp trade secrets with the same degree of
        care as Boo Zapp would protect such trade secrets, but in no event will
        you use less than a reasonable degree of care. Without Boo Zapps prior
        written consent, you agree not to disclose Boo Zapps trade secrets or
        other confidential information, make copies of such trade secrets or
        confidential information, or make any commercial use of such trade
        secrets or confidential information.
      </p>
      <p>
        LICENSE AND ACCESS; RESTRICTIONS. You acknowledge and agree that the
        Boo Zapp Services are provided under license, and not sold, to you.
        Subject to your compliance with this Boo Zapp Independent Contractor
        Driver Agreement and your payment of any applicable fees, Boo Zapp or its
        content providers grant you a limited, nonexclusive, nontransferable,
        non sublicensable license to access and make personal and noncommercial
        use of the Boo Zapp Services. You may not: reverse engineer, disassemble,
        decompile, decode or otherwise attempt to derive or gain access to the
        source code of the Boo Zapp Service or any part thereof; resell or engage
        in any commercial use of any Boo Zapp Service, or its contents. copy the
        Boo Zapp Services, except as expressly permitted by this license; collect
        and use of any product listings, descriptions or prices make any
        derivative use of any Boo Zapp Services or its contents, or modify,
        translate, adapt or otherwise create derivative works or improvements,
        whether or not patentable, of Boo Zapp Services download or copy account
        information for the benefit of another business, or use data mining,
        robots, or similar data gathering and extraction tools.
      </p>
      <p>
        Boo Zapp shall not be liable for loss, injury, claim, liability, or
        damage of any kind resulting in any way due to the following
        circumstances: any errors or omissions from the application, the
        unavailability or interruption of the application or any features
        thereof, your use of the application, or any delays or failures in
        performance beyond the control of Boo Zapp.
      </p>
      <p>
        All rights not expressly granted to you in this Boo Zapp Independent
        Contractor Driver Agreement are reserved and retained by Boo Zapp or its
        affiliates, licensors, suppliers, publishers, rights holders, or other
        content providers. No Boo Zapp Service, nor any part of any Boo Zapp
        Service, may be reproduced, duplicated, copied, sold, resold, visited,
        or otherwise exploited for any commercial purpose without prior written
        consent of Boo Zapp. You may not frame or utilize framing techniques to
        enclose any trademark, logo, or other proprietary information (including
        images, text, page layout, or form) of Boo Zapp without the prior written
        consent of Boo Zapp. You may not use any Meta tags or any other hidden
        text utilizing Boo Zapp name or trademarks without the prior written
        consent of Boo Zapp. You may not misuse the Boo Zapp Services. You may not
        impersonate any person or entity when using the Boo Zapp Services. You
        may use the Boo Zapp Services only as permitted by law. The licenses
        granted by Boo Zapp shall automatically terminate if you do not comply
        with this Boo Zapp Independent Contractor Driver Agreement. You agree
        that Boo Zapp may, in its sole discretion and without prior notice,
        terminate any Boo Zapp Service and/or your status as an independent
        contractor driver hereunder, at any time and for any reason (or no
        reason) without any liability to you or any of your affiliates or
        insurers.
      </p>
      <p>
        REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT. You hereby agree,
        acknowledge and consent to any of our customers posting reviews,
        comments, and other content and other communications and submit
        suggestions, ideas, comments, questions, or other information about you
        or your affiliates, so long as the content is not illegal, obscene,
        threatening, defamatory, invasive of privacy, infringing of intellectual
        property rights, or otherwise injurious to third parties or
        objectionable and does not consist of or contain software viruses,
        political campaigning, commercial solicitation, chain letters, mass
        mailings, or any form of spam. Boo Zapp reserves the right (but not the
        obligation) to monitor, remove or edit such content, but does not
        regularly review or monitor posted content. Boo Zapp does not currently
        contemplate that you will have the ability or right to review or comment
        on our customers, but in the event this changes and you are permitted
        to, and you do, post content or submit material, and unless we indicate
        otherwise: (i) you grant Boo Zapp a nonexclusive, royalty free,
        perpetual, irrevocable, and fully sublicensable right to use, reproduce,
        modify, adapt, publish, translate, create derivative works from,
        distribute, display and otherwise exploit such content throughout the
        world in any media; (ii) you grant Boo Zapp and sub licensees the right
        to use the name that you submit in connection with such content, if they
        choose; and (iii) you represent and warrant that: (a) you own or
        otherwise control all of the rights to the content that you post? (b)
        that the content is accurate and not illegal, obscene, threatening,
        defamatory, invasive of privacy, infringing of intellectual property
        rights, or otherwise injurious to third parties or objectionable and
        does not consist of or contain software viruses, political campaigning,
        commercial solicitation, chain letters, mass mailings, or any form of
        spam. (c) that use of the content you supply does not violate this
        policy and will not cause injury to any person or entity? and (d) that
        you will indemnify Boo Zapp for all claims resulting from content you
        supply. DeliveryCircle has the right but not the obligation to monitor
        and edit or remove any activity or content. Boo Zapp takes no
        responsibility and assumes no liability for any content posted by you or
        any third party.
      </p>
      <p>
        DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY. BOO ZAPP
        SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
        SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
        YOU THROUGH THE BOO ZAPP SERVICES ARE PROVIDED BY BOO ZAPP ON AN AS IS AND
        AS AVAILABLE BASIS. BOO ZAPP MAKES NO REPRESENTATIONS OR WARRANTIES OF
        ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE BOO ZAPP
        SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
        SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
        YOU THROUGH THE BOO ZAPP SERVICES. YOU EXPRESSLY AGREE THAT YOUR USE OF
        THE BOO ZAPPS SERVICES IS AT YOUR SOLE RISK. TO THE FULL EXTENT
        PERMISSIBLE BY APPLICABLE LAW, BOO ZAPP DISCLAIMS ALL WARRANTIES, EXPRESS
        OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
        BOO ZAPP DOES NOT WARRANT THAT THE BOO ZAPP SERVICES, INFORMATION,
        CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
        INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE BOO ZAPP
        SERVICES, BOO ZAPP SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM BOO ZAPP
        ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
      </p>
      <p>
        BOO ZAPP WILL NOT BE LIABLE FOR ANY COSTS, FEES, EXPENSES, LOSSES,
        DAMAGES OR OTHER LIABILITY OF ANY KIND ARISING FROM THE USE OF ANY
        BOO ZAPP SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS
        (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
        AVAILABLE TO YOU THROUGH ANY BOO ZAPP SERVICE, INCLUDING, BUT NOT LIMITED
        TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES,
        INCLUDING, WITHOUT LIMITATION, LOSS OF BUSINESS, LOSS OF PROFITS,
        BUSINESS INTERRUPTION, BREACH OF SECURITY, OR LOST OR DAMAGED DATA.
        NOTWITHSTANDING THE GENERALITY OF THE IMMEDIATELY PRECEDING SENTENCE, IN
        NO EVENT WILL BOO ZAPP BE LIABLE TO YOU OR TO ANY OTHER THIRD PARTY FOR
        ANY PERSONAL INJURY (INCLUDING, WITHOUT LIMITATION, DEATH) OR PROPERTY
        DAMAGE SUFFERED OR INCURRED BY YOU OR BY ANY THIRD PARTY IN CONNECTION
        WITH ANY IN-HOME DELIVERY (I.E., ANY DELIVERY REQUEST IN WHICH YOU OR
        ANY THIRD PARTY ARE REQUESTED (WHETHER ORALLY OR PURSUANT TO THE
        PLATFORM) TO ENTER ANY PERSONAL RESIDENCE OR COMMERCIAL REAL PROPERTY
        OWNED OR OCCUPIED BY ANY BOO ZAPP CUSTOMER), WHETHER ANY CONDITIONS,
        FACTS OR CIRCUMSTANCES CAUSING SUCH INJURY OR DAMAGE ARE KNOWN OR
        UNKNOWN.
      </p>
      <p>
        BOO ZAPP IS NOT A TRANSPORTATION SERVICE PROVIDER OR FREIGHT FORWARDER.
        THE BOO ZAPP SERVICES ARE A TOOL THAT CAN BE USED TO SCHEDULE LOCAL
        DELIVERIES USING INDEPENDENT CONTRACTOR THIRD PARTY DELIVERY DRIVERS.
        YOU UNDERSTAND AND AGREE THAT: (I) AS A DRIVER WHO PERFORMS SERVICES FOR
        BOO ZAPP CUSTOMERS, YOU ARE AN INDEPENDENT CONTRACTOR AND NOT A BOO ZAPP
        AGENT OR EMPLOYEE; (II) IN NO EVENT WILL BOO ZAPP BE LIABLE OR
        RESPONSIBLE FOR YOUR MISTAKES, NEGLIGENCE, ACTS, OMISSIONS, MISCONDUCT,
        OR ANY PERSONAL INJURY OR DEATH CAUSED BY YOU; AND (III) BOO ZAPP DOES
        NOT OBTAIN OR PROVIDE INSURANCE FOR YOU.
      </p>
      <p>
        You represent and warrant to Boo Zapp that: (i) you have the requisite
        skill, experience and qualifications to perform the delivery and/or
        driving services hereunder and that you will do so in a professional and
        workmanlike manner in accordance with generally recognized industry
        standards for similar services; and (ii) you shall perform the delivery
        and/or driving services in compliance with all applicable laws.
      </p>
      <p>
        INDEMNITY. You agree to indemnify and hold Boo Zapp, its affiliates and
        its and their officers, directors, managers, equity holders, employees,
        agents, suppliers, licensors, successors and permitted assigns
        (collectively, Boo Zapp Personnel), harmless from any demands, loss,
        liability, claims or expenses (including attorneys fees), made against
        Boo Zapp and/or any Boo Zapp Personnel by any third party due to or
        arising out of or in connection with (i) bodily injury, death of any
        person or damage to real or tangible, personal property resulting from
        the performance of driving and/or delivery Services by you or any
        subcontractor or affiliate thereof; (ii) breach of any representation,
        warranty or obligation of you set forth in this Agreement; (iii) claims
        made by employees or agents of you, any subcontractor thereof or any
        affiliate of any of the foregoing related to the provision of any
        driving /or delivery services to be performed by any of the foregoing,
        including any claim by such personnel that they are Boo Zapp employees
        for any purpose; (d) negligence, recklessness, willful misconduct or
        violation of law by you or any subcontractor or any affiliate of any of
        the foregoing; and/or (e) any act or omission by you or any
        subcontractor or any affiliate of any of the foregoing.
      </p>
      <p>
        BINDING ARBITRATION. This Boo Zapp Independent Contractor Driver
        Agreement provides that all disputes between you and Boo Zapp will be
        resolved by BINDING ARBITRATION. ACCORDINGLY, YOU AGREE TO GIVE UP YOUR
        RIGHT TO GO TO COURT (INCLUDING IN A CLASS ACTION PROCEEDING AND/OR
        TRIAL BY JURY) to assert or defend your rights under this Boo Zapp
        Independent Contractor Driver Agreement. Your rights will be determined
        by a NEUTRAL ARBITRATOR and NOT a judge or jury and your claims cannot
        be brought as a class action.
      </p>
      <p>
        GENERAL. If any provision of this Boo Zapp Independent Contractor Driver
        Agreement is or becomes invalid or non-binding, the parties shall remain
        bound by all other provisions hereof. In that event, the parties shall
        replace the invalid or non- binding provision with provisions that are
        valid and binding and that have, to the greatest extent possible, a
        similar effect as the invalid or non-binding provision, given the
        contents and purpose of this Boo Zapp Independent Contractor Driver
        Agreement. You agree that this Boo Zapp Independent Contractor Driver
        Agreement and all incorporated agreements (and any rights or obligations
        hereunder or thereunder) may be assigned by Boo Zapp, in our sole
        discretion (including without limitation in connection with any sale of
        all or substantially all of our assets, merger or consolidation or
        change of control), and this Boo Zapp Independent Contractor Driver
        Agreement and/or any incorporated agreements (and/or any rights or
        obligations hereunder or thereunder) may not be assigned or transferred
        by you without our prior written consent; any assignment made in
        contravention with the terms hereof shall be null and void. Any notices
        to you shall be provided to you through the Boo Zapp Services or given to
        you via the email address or physical you provide to Boo Zapp during the
        registration process. Headings are for reference purposes only and in no
        way define, limit, construe or describe the scope or extent of such
        section. A partys failure to act with respect to a breach by the other
        party does not constitute a waiver of the partys right to act with
        respect to subsequent or similar breaches. This Boo Zapp Independent
        Contractor Driver Agreement, as amended, updated or revised from time to
        time, sets forth the entire understanding and agreement between you and
        Boo Zapp with respect to the subject matter hereof and supersedes all
        previous understandings and agreements between the parties, whether oral
        or written. This Boo Zapp Independent Contractor Driver Agreement shall
        be binding upon the parties successors and permitted assigns.
      </p>
    </div>
  </Fragment>
);

export const DriverTerms = compose(
  withContainer(),
  injectSheet(terms),
)(_Terms);
