import { api } from 'services/api';
import { actions } from 'store/actions';
import  { setErrorToStore } from 'services/utils';

export const createStripeClient = (
  stripe,
  setError,
  startLoading,
  endLoading,
) => async dispatch => {
  startLoading();
  try {
    const response = await stripe.createSource({
      type: 'card',
    });
    if (response.source) {
      const {
        id,
        card: { last4, brand },
      } = response.source;
      await api.setPaymentToken(id, last4, brand);
      dispatch(actions.setCard.act(last4, brand));
    }
    if (response.error) {
      setError(response.error.message);
    }
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
  endLoading();
};
