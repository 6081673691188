import React from 'react';
import injectSheet from 'react-jss';
import { tooltipPriceList } from 'ui/styles/Admin/Products/tooltipPriceList';
import { getProductZonePrice } from '../../../../../selectors/getProductRowPrice';

const _TooltipPriceList = ({ classes, prices }) => {
  const chunk = 20;
  const pages = [];

  for (let i = 0, p = 0; i < prices.length; i += 20, p++) {
    pages.push(
      <div key={`page_${p}`} className={classes.tooltipPrices}>
        {prices.slice(i, i + chunk).map(item => (
          <span key={item.id}>
            {item.name}: {getProductZonePrice(item.prices)}
          </span>
        ))}
      </div>,
    );
  }

  return <>{pages}</>;
};

export const TooltipPriceList = injectSheet(tooltipPriceList)(
  _TooltipPriceList,
);
