import React from 'react';

import { withStyles, IconButton, Snackbar as Snack } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from '../../../../styles/Admin/commons/snackbar';

const _Snackbar = ({
  open,
  autoHideDuration,
  onClose,
  message,
  onClick,
  classes: { root, close },
}) => (
  <Snack
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    classes={{ root }}
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    message={message}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={close}
        onClick={onClick}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

_Snackbar.defaultProps = {
  message: null,
  autoHideDuration: null,
};

export const Snackbar = withStyles(styles)(_Snackbar);
