import { createSelector } from 'reselect';

const handler = user => {
  if (user.get('suspended')) return 'Suspended';
  return user.get('status');
};

export const getDriverStatus = createSelector(
  user => user,
  user => handler(user),
);
