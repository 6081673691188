import { media } from 'ui/styles/theme';

export const category = {
  container: {
    width: '500px',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem 0',
    [media.mobile]: {
      width: '90%',
    },
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '3rem',
    textTransform: 'uppercase',
  },
  items: {
    width: '100%',
    boxShadow: '0 0 35px 2px rgba(115,115,131,0.27)',
    padding: '1rem',
  },
  item: {
    width: '100%',
    lineHeight: '2.5rem',
    display: 'grid',
    gridTemplateColumns: '2.5rem 3% auto 18%',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
      'a . b c'
    `,
    '& > span:nth-child(1)': {
      backgroundColor: 'rgba(239, 217, 139, 0.3)',
      textAlign: 'center',
    },
    '& > span:nth-child(2)': {
      gridArea: 'b',
    },
    '& > span:nth-child(3)': {
      gridArea: 'c',
      [media.mobile]: {
        fontWeight: 400,
      },
    },
  },
};
