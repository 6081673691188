import { fromJS } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { values, id }) =>
  state.withMutations(next =>
    next.updateIn(
      [
        'addresses',
        state.get('addresses').findIndex(item => item.get('id') === id),
      ],
      address => address.merge(fromJS(values)),
    ),
  );

export const editCustomerAddress = createAction({
  action: ['editCustomerAddress', 'values', 'id'],
  handler,
});
