import { media } from 'ui/styles/theme';

export const orderAccountInfo = {
  icon: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'center',
    height: '15rem',
    fill: 'white',
    '& > circle': {
      fill: '#E1E1E1',
    },
    [media.mobile]: {
      height: '5rem',
    },
    [media.desktop]: {
      height: '15rem',
      marginTop: '1.8rem',
    },
  },
  rating: {
    gridArea: 'g',
    [media.mobile]: {
      marginTop: '0.5rem',
    },
    [media.desktop]: {
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  name: {
    gridArea: 'b',
    fontWeight: 500,
    textTransform: 'uppercase',
    [media.mobile]: {
      alignSelf: ({ forDriver }) => (forDriver ? 'end' : 'center'),
    },
    [media.desktop]: {
      fontSize: '1.5rem',
    },
  },
  leftSide: {
    gridArea: 'c',
    fontSize: '1rem',
    fontWeight: 300,
    display: 'flex',
    [media.mobile]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      lineHeight: '1.5rem',
    },
    [media.desktop]: {
      width: '80%',
      flexDirection: 'column',
      lineHeight: '2rem',
    },
  },
  address: {
    [media.mobile]: {
      width: '75%',
      order: 1,
    },
  },
  boozer: {
    [media.mobile]: {
      width: '100%',
      order: 3,
    },
  },
  intersection: {
    width: '100%',
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '2.5rem',
    [media.mobile]: {
      order: 4,
    },
    [media.desktop]: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '2.5rem',
    },
  },
  instructions: {
    gridArea: 'd',
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: '2rem',
    [media.mobile]: {
      alignSelf: 'center',
    },
    '& > span': {
      fontSize: '1.125rem',
    },
    [media.desktop]: {
      width: '90%',
    },
  },
};
