import { actions } from 'store/actions';

export const orderBoardNotification = store => (
  message,
  page,
  deliveryZoneId,
) => {
  console.log(message, page, deliveryZoneId);
  store.dispatch(actions.updateActiveOrders.act(message));
};
