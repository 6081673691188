import { api } from '../../api';
import { setErrorToStore } from 'services/utils';

export const checkOperationHours = deliveryZoneId => async dispatch => {
  try {
    const message = (await api.checkOperationHours(deliveryZoneId)).data;
    if (message) {
      setErrorToStore(message, dispatch);
    }
    return !message;
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
