import axios from 'axios';
import { publicAPI } from 'services/api/apiUrl';

export const setNewPassword = ({ password }, token, userId) =>
  axios({
    method: 'post',
    url: `${publicAPI}/account/resetpassword`,
    data: {
      password,
      token,
      userId,
    },
  });
