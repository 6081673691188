import { input } from '../../Commons/input';
import { button } from '../../Commons/button';
import { media } from '../../theme';

export const addAddressForm = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  container: {
    width: '90%',
    height: '500px',
    maxWidth: '400px',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    gridTemplateRows: '5% 5% auto 15%',
    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. b .'
      '. c .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    [media.desktop]: {
      width: '700px',
      maxWidth: '700px',
      height: '575px',
      gridTemplateColumns: '10% 80% 10%',
      gridTemplateRows: '10% 12.5% auto 20%',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'start',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'uppercase',
    [media.desktop]: {
      textTransform: 'none',
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
    },
  },
  form: {
    gridArea: 'b',
    justifySelf: 'center',
    alignSelf: 'start',
    width: '100%',
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: '7.5rem auto',
    },
  },
  inputLabel: { ...input.label },
  input: { ...input.input },
  inputError: { ...input.error },
  textareaContainer: {
    ...input.container,
    height: '8rem',
    gridTemplateRows: '2.25rem auto 1.75rem',
    [media.desktop]: {
      ...input.container[media.desktop],
      height: '8rem',
      gridTemplateColumns: '7.5rem auto',
      gridTemplateAreas: `
      'a b'
      '. b'
      '. c'
      `,
    },
  },
  textarea: {
    ...input.input,
    padding: '0.5rem',
    resize: 'none',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  buttons: {
    gridArea: 'c',
    display: 'flex',
    justifyContent: 'space-around',
  },
  cancel: {
    ...button.white,

    alignSelf: 'start',
    justifySelf: 'start',
  },
  add: {
    ...button.yellow,
    alignSelf: 'start',
    justifySelf: 'end',
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
