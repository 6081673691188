import { api } from 'services/api';
import { setErrorToStore } from 'services/utils';

export const resendLink = id => async dispatch => {
  try {
    const response = await api.sendVerificationCode(id);
    console.log(response.data);
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
