import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { addReasonConfig } from '../../../../configs/formik/addReasonConfig';
import { TextArea } from '../../../atoms/Commons/Client/TextArea';
import { Modal } from '../../Commons/Modal';
import { ModalWithButtons } from 'ui/components/organisms/Admin/Commons/ModalWithButtons';
import injectSheet from 'react-jss';
import { addReasonModal } from 'ui/styles/Admin/commons/addReasonModal';

class _AddReasonModal extends PureComponent {
  render() {
    const {
      touched,
      errors,
      classes,
      placeholder,
      header,
      closeModal,
      isOpenModal,
      handleSubmit,
    } = this.props;

    return (
      <ModalWithButtons
        $container={classes.container}
        onClose={closeModal}
        isOpen={isOpenModal}
        onClick={handleSubmit}
      >
        <h1 className={classes.title}>{header}</h1>
        <Form className={classes.form}>
          <Field
            component={TextArea}
            type="text"
            name="reason"
            placeholder={placeholder}
            error={touched.reason && errors.reason}
            $container={classes.textareaContainer}
            $textarea={classes.textarea}
            $error={classes.inputError}
          />
        </Form>
      </ModalWithButtons>
    );
  }
}

export const AddReasonModal = compose(
  withFormik(addReasonConfig),
  injectSheet(addReasonModal),
)(_AddReasonModal);
