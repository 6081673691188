import React from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withNamespaces } from 'react-i18next';
import { thunks } from 'services/thunks';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { checkPhonePopup } from 'ui/styles/Customer/CompleteOrder/checkPhonePopup';
import { formatPhoneNumber } from 'react-phone-number-input';

const mapStateToProps = state => ({
  isOpen: state.getIn(['app', 'modals', 'checkCustomerPhone']),
});

const mapDispatchToProps = (dispatch, { orderId }) => ({
  cancelOrder: () => dispatch(thunks.cancelOrder(orderId)),
  sendVerificationCode: () => dispatch(thunks.sendVerificationCode()),
});

const _CheckPhonePopup = ({
  classes,
  cancelOrder,
  isOpen,
  sendVerificationCode,
  value,
}) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
  >
    <h1 className={classes.title}>Please tap on texted link!</h1>
    <p className={classes.text}>
      We will now <strong>text {formatPhoneNumber(value)}</strong> to confirm
      your number.
      <br />
      Hit OK if the number is correct.
    </p>
    <button type="button" onClick={cancelOrder} className={classes.cancel}>
      Go Back
    </button>
    <button type="button" onClick={sendVerificationCode} className={classes.ok}>
      OK
    </button>
  </Modal>
);

export const CheckPhonePopup = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  injectSheet(checkPhonePopup),
)(_CheckPhonePopup);
