import React from 'react';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { confirmModal } from 'ui/styles/Commons/confirmModal';

const _ConfirmModal = ({ classes, isOpen, close, onClick, actionText }) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
    onClose={close}
    renderIn="confirm-modal"
  >
    <h1 className={classes.title}>{actionText}</h1>
    <button type="button" onClick={close} className={classes.cancel}>
      No
    </button>
    <button type="button" onClick={onClick} className={classes.ok}>
      Yes
    </button>
  </Modal>
);

export const ConfirmModal = injectSheet(confirmModal)(_ConfirmModal);
