import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const addProduct = ({
  productName,
  unit,
  order,
  uploadImage,
  prices,
  category,
}) =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: '/product',
    data: {
      name: productName,
      unit,
      order,
      categoryId: category,
      imageBase64: uploadImage,
      prices,
    },
  });
