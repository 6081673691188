import React from 'react';
import { Link } from 'react-router-dom';

export const PageNotFound = () => (
    <div>
    <br/>
    <br/>
    <br/>
    <h1 align="center">Ooops! Zapp!</h1> 
    <h1 align="center">Sorry, Page Not Found</h1>
    <br/>
    <br/>
    <center>
    <span style={{ color: 'blue'}}><Link  align="center" to="/">Back to Boo Zapp</Link></span>
    </center>
    </div>
);
