import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data: { firstName, lastName, phone, email } }) =>
  state.withMutations(next =>
    next
      .setIn(['user', 'firstName'], firstName)
      .setIn(['user', 'lastName'], lastName)
      .setIn(['user', 'phone'], phone)
      .setIn(['user', 'email'], email),
  );

export const setCustomerInfo = createAction({
  action: ['setCustomerInfo', 'data'],
  handler,
});
