import * as Yup from 'yup';

import { validators } from './validators';

export const scheduleConfig = {
  mapPropsToValues: ({ schedule }) => schedule,
  validationSchema: Yup.object().shape({
    operationalHours: validators.operationalHours,
    orderTimeout: Yup.number()
      .typeError('Invalid number')
      .required('Required')
      .min(0, 'Must be between 0 and 120')
      .max(120, 'Must be between 0 and 120'),
    deliveryTime: Yup.number()
      .typeError('Invalid number')
      .required('Required')
      .min(0, 'Must be between 0 and 120')
      .max(120, 'Must be between 0 and 120'),
  }),
  handleSubmit: (
    values,
    { props: { editSchedule, deliveryZoneId, onSave }, resetForm },
  ) => {
    const data = {
      deliveryZoneId,
      isClosed: values.isClosed,
      closedReason: values.closedReason,
      operationalHours: values.operationalHours,
      dailyDriversLogoutTime: {
        hours: values.dailyDriversLogoutTime.get('hour'),
        minutes: values.dailyDriversLogoutTime.get('minute'),
      },
      payday: {
        dayOfWeek: values.payDayDay,
        time: {
          hours: values.payDayTime.get('hour'),
          minutes: values.payDayTime.get('minute'),
        },
      },
      orderTimeout: values.orderTimeout,
      deliveryTime: values.deliveryTime,
    };
    editSchedule(data, () => {
      resetForm(values);
      onSave && onSave();
    });
  },
  displayName: 'EditScheduleForm',
};
