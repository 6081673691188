import { createUseStyles } from 'react-jss';
import { palette } from '../theme';
import downChevron from '../../images/icon_chevron-down.png';

export default createUseStyles({
  link: {
    '&.button': {
      fontSize: '10pt',
      display: 'inline-block',
      background: palette.yellow,
      color: palette.white,
      padding: '1em 1.5em',
      marginTop: 11,
      cursor: 'pointer',
    },
    '&.anchor:after': {
      content: '" "',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: 8,
      width: 16,
      height: 12,
      backgroundSize: 16,
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${downChevron})`,
    },
  },
});
