import createAction from '../../../utils/redux-helpers/createAction';

const handler = (
  state,
  {
    values: {
      firstName,
      lastName,
      phone,
      email,
      driverLicense,
      smartserve,
      checkCertification,
    },
  },
) =>
  state.withMutations(next =>
    next
      .setIn(['user', 'firstName'], firstName)
      .setIn(['user', 'lastName'], lastName)
      .setIn(['user', 'phone'], phone)
      .setIn(['user', 'email'], email)
      .setIn(['user', 'driverLicense'], driverLicense)
      .setIn(['user', 'smartserve'], smartserve)
      .setIn(['user', 'checkCertification'], checkCertification),
  );

export const editDriverInfo = createAction({
  action: ['editDriverInfo', 'values'],
  handler,
});
