import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withNamespaces } from 'react-i18next';

import { thunks } from '../../../../services/thunks';
import { Dashboard } from './Dashboard';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { PaddedPaperShort } from '../../atoms/Commons/Admin/PaddedPaper';
import { ChangePasswordForm } from '../../organisms/Admin/ChangePassword/ChangePasswordForm';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword: thunks.changePassword,
    },
    dispatch,
  );

const _AdminChangePassword = ({ t, changePassword }) => (
  <Dashboard>
    <PageRow>
      <PageTitle title={t('Change Password')} />
    </PageRow>

    <PageRow>
      <PaddedPaperShort>
        <ChangePasswordForm changePassword={changePassword} />
      </PaddedPaperShort>
    </PageRow>
  </Dashboard>
);

export const AdminChangePassword = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_AdminChangePassword);
