import { maxWidth, media } from 'ui/styles/theme';

export const orderBoard = {
  container: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#E1E1E1',
  },
  wrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [media.mobile]: {
      maxWidth: maxWidth.mobile,
    },
    [media.desktop]: {
      maxWidth: maxWidth.desktop,
    },
  },
};
