import { media, palette } from '../../theme';

export const productWithDropdown = {
  container: {
    height: '30rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    gridTemplateRows: '5% 55% 5% 10% 15% 5%',
    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. d .'
      '. h .'
      '. b .'
      '. c .'
    `,
    backgroundColor: 'white',
    marginBottom: '2rem',
    flexShrink: 0,
    '& > img': {
      gridArea: 'a',
      justifySelf: 'center',
      height: '100%',
    },
    [media.desktop]: {
      width: '30%',
      '&:nth-child(1 + 2n)': {
        backgroundColor: 'orange',
      },
    },
  },
  wrapper: {
    gridArea: 'b',
    justifySelf: 'center',
    alignSelf: 'start',
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceLead: {
    gridArea: 'd',
    justifySelf: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
  },
  price: {
    gridArea: 'h',
    justifySelf: 'center',
    alignSelf: 'top',
    fontFamily: 'Roboto',
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  capacity: {
    width: '60px',
    overflow: 'hidden',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
  },
  productName: {
    gridArea: 'c',
    justifySelf: 'center',
    fontFamily: 'Roboto',
    fontWeight: '300',
    fontSize: '1.375rem',
    textAlign: 'center',
  },
};

export const dropDown = {
  container: styles => ({
    ...styles,
    gridArea: 'b',
    alignSelf: 'start',
    justifySelf: 'center',
    width: '120px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    width: '45px',
    height: '45px',
    backgroundColor: palette.black,
    justifyContent: 'center',
  }),
  control: (styles, state) => ({
    ...styles,
    border: state.isFocused
      ? '1px solid black!important'
      : '1px solid black!important',
    borderRadius: 0,
    boxShadow: state.isFocused ? 0 : 0,
    cursor: 'pointer',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0.75rem',
  }),
  menu: provided => ({
    ...provided,
    width: 'calc(100% - 45px)',
    marginTop: 0,
    border: '1px solid black',
    borderTop: 0,
    borderRadius: 0,
  }),
  menuList: styles => ({
    ...styles,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: state.isSelected ? 500 : 300,
    color: state.isSelected ? palette.yellow : '#444343',
    backgroundColor: state.isSelected ? 0 : 0,
    cursor: 'pointer',
  }),
  singleValue: styles => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '400',
    color: palette.black,
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#444343',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
};
