import React from 'react';
import { compose } from 'redux';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { LoadButton } from 'ui/components/atoms/Commons/Client/LoadButton';
import injectSheet from 'react-jss';
import { cardModal, cardElement } from 'ui/styles/Driver/Profile/cardModal';

class _CardModal extends React.Component {
  state = {
    error: '',
    loading: false,
  };

  componentWillUnmount() {
    this.setError('');
  }

  setError = error => {
    this.setState({ error });
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  endLoading = () => {
    this.setState({ loading: false });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createStripeClient, stripe, closeCardModal } = this.props;
    createStripeClient(
      stripe,
      this.setError,
      this.startLoading,
      this.endLoading,
      closeCardModal,
    );
  };

  render() {
    const { classes, isOpen, closeCardModal } = this.props;
    const { error, loading } = this.state;
    return (
      <Modal
        $backstage={classes.backstage}
        $container={classes.container}
        isOpen={isOpen}
        onClose={closeCardModal}
      >
        <form onSubmit={this.handleSubmit} className={classes.cardForm}>
          <p>Enter credit or debit card</p>
          <CardElement
            hidePostalCode
            style={cardElement}
            classes={{ base: classes.cardElement }}
          />
          <span className={classes.error}>{error}</span>
          <div className={classes.buttons}>
            <button
              type="button"
              className={classes.cancel}
              onClick={closeCardModal}
            >
              Cancel
            </button>
            <LoadButton
              type="submit"
              className={classes.ok}
              isLoading={loading}
            >
              Save
            </LoadButton>
          </div>
        </form>
        {/*<button className={classes.deleteCard}>Delete Card</button>*/}
      </Modal>
    );
  }
}
export const CardModal = compose(injectSheet(cardModal))(
  injectStripe(_CardModal),
);
