import get from 'lodash/get';
import { api } from '../../api';

export const resetPassword = (
  values,
  close,
  role,
  setErrors,
  setSubmitting,
  setStatus,
  resetForm,
) => async () => {
  try {
    await api.sendRestorePasswordEmail(values, role);
    resetForm({ resetPasswordEmail: '' });
    setStatus({ success: true });
    close();
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
