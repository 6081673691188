import { api } from '../../api';
import { actions } from '../../../store/actions';
import { setErrorToStore } from 'services/utils';

export const addAddress = (values, closeModal) => async dispatch => {
  try {
    const response = await api.addAddress(values);
    dispatch(actions.addAddress.act({ ...values, id: response.data }));
    closeModal();
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
