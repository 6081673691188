import { push } from 'connected-react-router';
import { api } from 'services/api';
import { actions } from 'store/actions';
import { removeActiveOrder as removeLS } from 'utils';
// TODO отпиміцація dispatch
export const rateDriver = (orderId, rating, comment) => async dispatch => {
  try {
    await api.rateDriver(orderId, rating, comment);
    dispatch(actions.closeModal.act('rateDriver'));
    removeLS();
    dispatch(actions.setData.act(['activeOrder'], false));
    dispatch(push('/'));
  } catch (e) {
    return e;
  }
};
