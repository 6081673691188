import React, { Fragment } from 'react';
import { ConfirmModal } from 'ui/components/molecules/Commons/Client/ConfirmModal';

export const ButtonWithConfirm = ({
  className,
  text,
  onClick,
  confirmText,
  isConfirmOpen,
  openConfirmModal,
  closeConfirmModal,
}) => (
  <Fragment>
    <button type="button" className={className} onClick={openConfirmModal}>
      {text}
    </button>
    <ConfirmModal
      isOpen={isConfirmOpen}
      actionText={confirmText}
      close={closeConfirmModal}
      onClick={onClick}
    />
  </Fragment>
);
