import { api } from 'services/api';
import { actions } from 'store/actions';

export const getCompletedOrders = (page, endLoading) => async dispatch => {
  try {
    const response = await api.getCompletedOrders(page);
    dispatch(actions.setCompletedOrders.act(response.data));
    endLoading();
  } catch (e) {
    return e;
  }
};
