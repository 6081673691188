import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

export const editCustomer = (id, data) => async dispatch => {
  try {
    await api.editCustomer(id, data);
    dispatch(actions.editCustomer.act({ id, ...data }));
    dispatch(actions.closeModal.act(`editCustomer-${id}`))
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
