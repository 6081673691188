import React from 'react';
import { compose } from 'redux';
import { withNamespaces, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import injectSheet from 'react-jss';
import { PromoCarIcon } from '../../atoms/icons/PromoCarIcon';
import { PromoMoneyIcon } from '../../atoms/icons/PromoMoneyIcon';
import { PromoTimeIcon } from '../../atoms/icons/PromoTimeIcon';
import { driverPromoPage } from '../../../styles/Driver/DriverPromoPage/driverPromoPage';

const pathToSignUp = { pathname: '/signup', state: { openTab: 'driver' } };

const pathToLogin = { pathname: '/login', state: { openTab: 'driver' } };

const _DriverPromoPage = ({ classes, t }) => (
  <div className={classes.container}>
    <div className={classes.wrapper}>
      <div className={classes.topBar}>
        <NavLink className={classes.linkToHome} to="/">
          Boo Zapp
        </NavLink>
        <a href="http://mobilevendor.info" className={classes.becomeDriver}>
        <button type="button" className={classes.faq}>
          FAQ
        </button>
        </a>  
      </div>
      <div className={classes.background} />
      <div className={classes.promo}>
        <p className={classes.driveWith}>
          <span>{t('Drive with')}</span>
          <span>{t('Boo Zapp')}</span>
          <span>{t('& make great money')}</span>
        </p>
        <p className={classes.readyTo}>
          <Trans>
            Ready to make money?
            <br />
            The first step is to sign up online
          </Trans>
        </p>
        <NavLink to={pathToSignUp} className={classes.signUpLink}>
          <button type="button" className={classes.signUp}>
            {t('Sign up to drive')}
          </button>
        </NavLink>
        <div className={classes.loginWrapper}>
          <p className={classes.haveAcc}>{t('Already have an account?')}</p>
          <NavLink className={classes.login} to={pathToLogin}>
            {t('Log in')}
          </NavLink>
        </div>
      </div>
      <div className={classes.advantages}>
        <div className={classes.advantage}>
          <PromoMoneyIcon className={classes.icon} />
          <h2>{t('Make More Money')}</h2>
          <p>
            {t(
              'For each transaction you keep at least 80% of the proceeds. This is a much better deal than any current delivery or ride-sharing service. Boo Zapp will take only a fixed, small fee per transaction plus you keep the tips!',
            )}
          </p>
        </div>
        <div className={classes.advantage}>
          <PromoTimeIcon className={classes.icon} />
          <h2>{t('Driving for Uber or Lyft?')}</h2>
          <p>
            {t(
              'Not to worry, add Boo Zapp to your delivery  or ride-sharing mix. Take orders when you are ready, when the fares are low, during down time, whenever! You can go full inventory or just carry a few items. Play it your way!',
            )}
          </p>
        </div>
        <div className={classes.advantage}>
          <PromoCarIcon className={classes.icon} />
          <h2>{t("We're on Your Side")}</h2>
          <p>
            {t(
              'Other services impose heavy restrictions on drivers and take a significant cut of transactions. We believe you deserve good compensation for your efforts and the freedom to carry out transactions on your own.',
            )}
          </p>
        </div>
      </div>
      <div className={classes.moreInfo}>
        <span>Are you interested to know more?</span>
        <a href="http://mobilevendor.info" className={classes.becomeDriver}>
        <button type="button" className={classes.buttonMoreInfo}>
          More Info
        </button>
        </a>  
      </div>
    </div>
    <div className={classes.terms}>
      <NavLink to="/driver/terms">Terms & Privacy</NavLink>
    </div>
  </div>
);

export const DriverPromoPage = compose(
  withNamespaces('driverPromoPage'),
  injectSheet(driverPromoPage),
)(_DriverPromoPage);
