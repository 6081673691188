import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import injectSheet from 'react-jss';
import { FormikPersist } from 'ui/components/atoms/Commons/Client/FormikPersist';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { thunks } from '../../../../../services/thunks';
import { signUpDriverConfig } from '../../../../configs/formik/signUpDriverConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { FormCheckbox } from '../../../molecules/Commons/Client/FormCheckbox';
import { signUpDriverForm } from '../../../../styles/General/SignUp/signUpDriverForm';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signUpDriver: thunks.signUpDriver,
    },
    dispatch,
  );

const _SignUpDriverForm = ({ classes, errors, touched, isMobile }) => {
  const termsText = (
    <span>
      I agree with&nbsp;
      <NavLink to="/driver/terms" className={classes.termsLink}>
        Terms and Conditions
      </NavLink>
    </span>
  );
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Requires a valid driver&apos;s license</h2>
      <Form className={classes.form}>
        <Field
          component={Input}
          type="text"
          name="firstName"
          placeholder={isMobile ? 'John' : 'First Name'}
          labelText={isMobile ? 'First Name' : 'Name'}
          error={touched.firstName && errors.firstName}
          $container={classes.inputHalfContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="text"
          name="lastName"
          placeholder={isMobile ? 'Doe' : 'Last Name'}
          labelText={isMobile && 'Last Name'}
          required
          error={touched.lastName && errors.lastName}
          $container={classes.inputHalfContainerWithoutLabel}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <Field
          component={PhoneInput}
          type="text"
          name="phone"
          labelText="Phone"
          error={touched.phone && errors.phone}
          $container={classes.inputHalfContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="text"
          name="email"
          placeholder="example@gmail.com"
          labelText="Email"
          error={touched.email && errors.email}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="text"
          name="driverLicense"
          labelText="Driver's License Number"
          error={touched.driverLicense && errors.driverLicense}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="password"
          name="password"
          placeholder="Setup a password"
          labelText="Password"
          error={touched.password && errors.password}
          $container={classes.inputHalfContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="password"
          name="confirmPassword"
          placeholder="Confirm password"
          error={touched.confirmPassword && errors.confirmPassword}
          $container={classes.inputHalfContainerWithoutLabel}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <Field
          component={FormCheckbox}
          name="terms"
          error={touched.terms && errors.terms}
          text={termsText}
          $container={classes.termsContainer}
          $error={classes.termsError}
        />
        <button type="submit" className={classes.submit}>
          Submit
        </button>
        <div className={classes.backError}>{errors.backError}</div>
        <FormikPersist name="signUpDriver" />
      </Form>
    </div>
  );
};

export const SignUpDriverForm = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withFormik(signUpDriverConfig),
  withMobileDetect(),
  injectSheet(signUpDriverForm),
)(_SignUpDriverForm);
