const styles = theme => ({
  search: {
    position: 'relative',
    borderRadius: 4,
    width: '100%',
    paddingTop: '1px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing.unit,
    '&:after': {
      display: 'none',
    },
  },
});

export default styles;
