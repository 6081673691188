import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import StarRatings from 'react-star-ratings';
import { RatingStar } from 'ui/components/atoms/icons/RatingStar';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { ratePopup } from 'ui/styles/Commons/ratePopup';
import { TextField } from '@material-ui/core';

class _RatePopup extends PureComponent {
  state = { rating: 3, comment: '' };

  changeRating = newRating => this.setState({ rating: newRating });

  setComment = e => this.setState({ comment: e.target.value });

  sendRating = () => {
    const { orderId, rate } = this.props;
    rate(orderId, this.state.rating, this.state.comment);
  };

  render() {
    const {
      classes,
      cancel,
      isOpen,
      text,
      isMobile,
      allowComment,
    } = this.props;
    return (
      <Modal
        $backstage={classes.backstage}
        $container={classes.container}
        isOpen={isOpen}
      >
        <h1 className={classes.title}>{text}</h1>
        <div className={classes.ratingWrapper}>
          <StarRatings
            rating={this.state.rating}
            starRatedColor="#FFC80A"
            starHoverColor="#FFC80A"
            changeRating={this.changeRating}
            numberOfStars={5}
            name="rating"
            svgIconPath={RatingStar}
            starDimension={isMobile ? '32px' : '50px'}
            svgIconViewBox="0 0 24 24"
            starSpacing={isMobile ? '8px' : '12px'}
          />
        </div>
        {allowComment && (
          <TextField
            className={classes.comment}
            multiline={true}
            rows={1}
            rowsMax={10}
            placeholder="Enter a comment (optional)"
            value={this.state.comment}
            onChange={this.setComment}
            inputProps={{}}
          />
        )}
        <div className={classes.buttons}>
          <button type="button" onClick={cancel} className={classes.cancel}>
            Cancel
          </button>
          <button
            type="button"
            onClick={this.sendRating}
            className={classes.ok}
          >
            Ok
          </button>
        </div>
      </Modal>
    );
  }
}

export const RatePopup = compose(
  withMobileDetect(),
  injectSheet(ratePopup),
)(_RatePopup);
