import { createSelector } from 'reselect';
import { commonAPI } from 'services/api/apiUrl';

const handler = product =>
  product && {
    id: product.get('id'),
    productName: product.get('name'),
    unit: product.get('unit'),
    order: product.get('order'),
    category: product.get('categoryId'),
    image: `${commonAPI}${product.get('imagePath')}`,
    prices: product.get('prices').toJS(),
  };

export const geProductFormValues = createSelector(
  state => state.getIn(['app', 'admin', 'product']),
  product => handler(product),
);
