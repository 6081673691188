import axios from 'axios';
import { publicAPI } from 'services/api/apiUrl';

export const signUpCustomer = ({
  firstName,
  lastName,
  phone,
  email,
  address,
  postalCode,
  latitude,
  longitude,
  boozer,
  intersection,
  instructions,
  password,
}) =>
  axios({
    method: 'post',
    url: `${publicAPI}/registration/customer`,
    data: {
      firstName,
      lastName,
      phone,
      email,
      address,
      postalCode,
      latitude,
      longitude,
      boozer,
      nearestIntersection: intersection,
      instructions,
      password,
    },
  });
