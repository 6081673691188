import { media, palette } from 'ui/styles/theme';

export const customerTermsForm = {
  container: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2rem',
    paddingBottom: '2rem',
    borderBottom: '1px solid #E1E1E1',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    [media.mobile]: {
      width: '90%',
    },
    [media.desktop]: {
      width: '60%',
      marginBottom: '2rem',
      paddingBottom: '2rem',
      borderBottom: '1px solid #E1E1E1',
    },
  },
  termsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    [media.mobile]: {
      height: '4rem',
    },
    [media.desktop]: {
      height: '3rem',
    },
  },
  termsLink: {
    color: palette.yellow,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  termsError: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: '300',
    color: 'red',
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
