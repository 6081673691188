import { createSelector } from 'reselect';

const handler = (deliveryZones, activeId) => {
  if (!deliveryZones || !activeId) return null;

  const activeZone = deliveryZones.find(zone => +zone.get('id') === +activeId);
  return activeZone
    ? {
        value: activeZone.get('id'),
        label: activeZone.get('name'),
      }
    : null;
};

export const getDriverWorkingZone = createSelector(
  state => state.getIn(['app', 'deliveryZones']),
  state => state.getIn(['app', 'user', 'deliveryZoneId']),
  (deliveryZones, activeId) => handler(deliveryZones, activeId),
);
