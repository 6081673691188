import get from 'lodash/get';
import { push } from 'connected-react-router';
import { api } from '../../api';
import { actions } from '../../../store/actions';
import { getUserInfo, setErrorToStore, setUserData } from '../../utils';
// Todo Make refactor and remove multiple dispatch
export const loginDriver = (
  values,
  role,
  setErrors,
  setSubmitting,
) => async dispatch => {
  dispatch(actions.startLoading.act('submitButton'));
  try {
    const login = await api.login(values, role);
    setUserData(login.data);
    const info = await api.getDriverAccountInfo();
    dispatch(actions.setDriverInfo.act(info.data));
    dispatch(actions.login.act(getUserInfo()));
    const route =
      info.data.status === 'Active'
        ? { pathname: '/inventory', state: { blockRouter: true } }
        : 'driver/profile';
    dispatch(push(route));
  } catch (err) {
    dispatch(actions.endLoading.act('submitButton'));
    if (err.response.data.errorCode === 2) {
      // if acc was blocked
      setErrorToStore(
        `Sorry, but your account was blocked.
        Please, contact with us by email: shitomi@protonmail.com`,
        dispatch,
      );
    } else {
      setErrors({
        backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
      });
    }
    setSubmitting(false);
  }
};
