import { button } from 'ui/styles/Commons/button';
import { input } from 'ui/styles/Commons/input';
import { media } from 'ui/styles/theme';

export const refuseOrderModal = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. b .'
      '. c .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 5,
    fontFamily: 'Roboto',
    [media.mobile]: {
      width: '90%',
      height: '470px',
      maxWidth: '400px',
      gridTemplateColumns: '5% 90% 5%',
      gridTemplateRows: '3rem 15% auto auto',
    },
    [media.desktop]: {
      width: '650px',
      height: '510px',
      gridTemplateColumns: '7% auto 7%',
      gridTemplateRows: '60px 15% auto auto',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'start',
    fontSize: '1.3rem',
    textAlign: 'center',
    fontWeight: 500,
    [media.desktop]: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
    },
  },
  form: {
    gridArea: 'b',
    justifySelf: 'center',
    alignSelf: 'start',
    width: '90%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkbox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    [media.mobile]: {
      height: '2rem',
    },
    [media.desktop]: {
      height: '2.5rem',
    },
  },
  textareaContainer: {
    ...input.container,
    [media.mobile]: {
      height: '10rem',
      gridTemplateRows: '2.25rem auto 1.75rem',
    },
    [media.desktop]: {
      ...input.container[media.desktop],
      height: '7.25rem',
      gridTemplateColumns: '100%',
      gridTemplateRows: 'auto 1.75rem',
      gridTemplateAreas: `
        'b'
        'c'
      `,
      marginTop: '1rem',
    },
  },
  textarea: {
    ...input.input,
    padding: '0.5rem',
    resize: 'none',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  inputError: {
    ...input.error,
  },
  buttons: {
    gridArea: 'c',
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
    alignSelf: 'start',
    justifySelf: 'center',
  },
  cancel: {
    ...button.white,
    [media.mobile]: {
      ...button.yellow[media.mobile],
      width: '6rem',
    },
  },
  ok: {
    ...button.yellow,
    [media.mobile]: {
      ...button.yellow[media.mobile],
      width: '8rem',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      width: '13rem',
    },
  },
  disabled: {
    backgroundColor: 'grey',
    borderColor: 'grey',
    cursor: 'default',
  },
};
