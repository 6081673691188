import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import Option from './Option';
import { EditorState, Modifier } from 'draft-js';
import { getEntityRange, getSelectionEntity } from 'draftjs-utils';

import { createUseStyles } from 'react-jss';
import useLinkStyles from '../../../../../../styles/Commons/links';

const LINK_CLASS = 'button anchor';

const useStyles = createUseStyles({
  scaled: {
    transform: 'scale(0.5)',
    display: 'inline-flex !important',
    alignItems: 'flex-end',
    margin: '0 -50% !important',
  },
});

export default ({ editorState, onChange, href, title }) => {
  const classes = useStyles();
  const linkClasses = useLinkStyles();
  const ref = useRef();
  const [scaledStyle, setScaledStyle] = useState();

  useEffect(
    () =>
      setScaledStyle({
        width: ref.current ? ref.current.offsetWidth / 2 : 0,
      }),
    [ref.current],
  );

  const addLink = (url, linkTitle) => () => {
    let selection = editorState.getSelection();
    const currentEntity = getSelectionEntity(editorState);

    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
    }

    const entityKey = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { url, className: LINK_CLASS })
      .getLastCreatedEntityKey();

    let contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      linkTitle,
      editorState.getCurrentInlineStyle(),
      entityKey,
    );
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-characters',
    );

    // insert a blank space after link
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get('anchorOffset') + linkTitle.length,
      focusOffset: selection.get('anchorOffset') + linkTitle.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      selection,
      ' ',
      newEditorState.getCurrentInlineStyle(),
      undefined,
    );
    onChange(
      EditorState.push(newEditorState, contentState, 'insert-characters'),
    );
  };

  return (
    <Option onClick={addLink(href, title)} title={title}>
      <span style={scaledStyle}>
        <a
          ref={ref}
          href={href}
          className={clsx(
            classes.scaled,
            linkClasses.link,
            linkClasses.disabled,
            LINK_CLASS,
          )}
        >
          {title}
        </a>
      </span>
    </Option>
  );
};
