import { input } from '../../Commons/input';
import { media } from '../../theme';
import { button } from '../../Commons/button';

export const editCustomerInfo = {
  container: {
    gridArea: 'b',
    display: ({ status }) => (status === 'entered' ? 'grid' : 'none'),
  },
  form: {
    width: '80%',
    display: 'flex',
    flexWrap: 'wrap',
    [media.mobile]: {
      width: '90%',
      padding: '5%',
    },
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      height: 'auto',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1.5rem 2.25rem 1.25rem',
      gridTemplateAreas: `
      'a'
      'b'
      'c'
     `,
    },
  },
  inputHalfContainer: {
    ...input.container,

    [media.desktop]: {
      ...input.container[media.desktop],
      width: '45%',
      height: 'auto',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1.5rem 2.25rem 1.25rem',
      gridTemplateAreas: `
      'a'
      'b'
      'c'
     `,
    },
  },
  inputHalfContainerRight: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      height: 'auto',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1.5rem 2.25rem 1.25rem',
      gridTemplateAreas: `
      'a'
      'b'
      'c'
     `,
      width: '45%',
      marginLeft: '10%',
    },
  },
  inputLabel: {
    ...input.label,
    lineHeight: '1.4rem',
  },
  input: {
    ...input.input,
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  inputError: { ...input.error },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0',
  },
  submit: {
    ...button.yellow,
    [media.mobile]: {
      width: '8rem',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      width: '13rem',
    },
  },
  cancel: {
    ...button.white,
    color: 'black',
    borderColor: 'black',
    [media.desktop]: {
      ...button.yellow[media.desktop],
      width: '10rem',
    },
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
    marginBottom: '1rem',
  },
};
