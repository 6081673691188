import { button } from '../../Commons/button';
import { media } from '../../theme';
import desktopBackground from '../../../images/driver-promopage-bg-desktop.png';
import mobileBackground from '../../../images/driver-promo-page-bg-mobile.png';

export const driverPromoPage = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    [media.mobile]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  wrapper: {
    [media.mobile]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '500px',
    },
    [media.desktop]: {
      width: '100vw',
      display: 'grid',
      gridTemplateColumns: 'auto 1200px auto',
      gridTemplateRows: '80px 600px auto auto auto',
      gridTemplateAreas: `
        '. c .' 
        '. a .' 
        '. b .' 
        '. g .' 
        '. t .' 
      `,
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [media.mobile]: {
      width: '90%',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
    },
    [media.desktop]: {
      gridArea: 'c',
      zIndex: 15,
    },
  },
  linkToHome: {
    fontFamily: 'Righteous',
    textTransform: 'uppercase',
    [media.mobile]: {
      // marginLeft: '5%',
      fontSize: '20px',
      color: '#131313',
    },
    [media.desktop]: {
      fontSize: '30px',
      color: 'white',
      display: 'block',
      zIndex: 15,
      cursor: 'pointer',
    },
  },
  faq: {
    // ...button.white,
    [media.mobile]: {
      // ...button.white[media.mobile],
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '0.8rem',
      cursor: 'pointer',
      textTransform: 'uppercase',
      border: 'none',
      color: 'black',
      fontWeight: 600,
    },
    [media.desktop]: {
      ...button.white,
      // ...button.white[media.desktop],
      borderColor: 'white',
      backgroundColor: 'none',
      color: 'white',
      width: '100px!important',
    },
  },
  background: {
    [media.desktop]: {
      gridArea: '1 / 1 / 3 / 4',
      background: `url(${desktopBackground}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  promo: {
    [media.mobile]: {
      width: '100%',
      height: '550px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: `url(${mobileBackground}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: 'white',
      textAlign: 'center',
    },
    [media.desktop]: {
      gridArea: 'a',
      display: 'grid',
      gridTemplateColumns: '45% 50%',
      gridTemplateRows: '22% 31% 10% 10% auto',
      gridTemplateAreas: `
        '. .' 
        '. a'
        '. b'
        '. c'
        '. d'
      `,
      color: 'white',
      textAlign: 'center',
      zIndex: 10,
    },
  },
  driveWith: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    textTransform: 'uppercase',
    '& > span:nth-child(2)': {
      fontFamily: 'Righteous',
      fontWeight: 700,
      color: '#FFC80A',
    },
    [media.mobile]: {
      fontSize: '1.375rem',
      marginTop: '2rem',
      '& > span:nth-child(2)': {
        display: 'block',
        fontSize: '3rem',
        lineHeight: '5rem',
      },
    },
    [media.desktop]: {
      gridArea: 'a',
      fontSize: '3rem',
      textAlign: 'start',
      '& > span:nth-child(2)': {
        lineHeight: '6rem',
        marginLeft: '1rem',
      },
      '& > span:nth-child(3)': {
        display: 'block',
      },
    },
  },
  readyTo: {
    [media.mobile]: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '1rem',
      marginTop: '5rem',
      lineHeight: '1.5rem',
    },
    [media.desktop]: {
      display: 'none',
    },
  },
  signUpLink: {
    [media.mobile]: {
      width: '60%',
      margin: '3rem 0',
    },
    [media.desktop]: {
      gridArea: 'b',
      justifySelf: 'center',
      width: '40%',
    },
  },
  signUp: {
    ...button.yellow,
    [media.mobile]: {
      width: '100%',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      width: '100%',
      backgroundColor: '#FFC80A',
    },
  },
  loginWrapper: {
    [media.desktop]: {
      gridArea: 'c',
      display: 'flex',
      justifySelf: 'center',
    },
  },
  haveAcc: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '2rem',
  },
  login: {
    fontFamily: 'Roboto',
    color: '#FFC80A',
    [media.mobile]: {
      fontWeight: 500,
    },
    [media.desktop]: {
      fontWeight: 400,
      lineHeight: '2rem',
      marginLeft: '0.25rem',
    },
  },
  advantages: {
    [media.mobile]: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [media.mobile]: {
        margin: '2rem 0 2rem',
      },
      [media.desktop]: {
        margin: '2rem 0 5rem',
      }
    },
    [media.desktop]: {
      gridArea: 'b',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '7rem',
    },
  },
  advantage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto',
    '& > h2': {
      fontWeight: 500,
      fontSize: '1.375rem',
      margin: '2.5rem 0 1.875rem 0',
      textTransform: 'uppercase',
    },
    '& > p': {
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      marginBottom: '3.75rem',
      [media.desktop]: {
        marginBottom: '3.75rem',
      },
    },
    [media.mobile]: {
      textAlign: 'center',
    },
    [media.desktop]: {
      width: '30%',
      textAlign: 'start',
    },
  },
  icon: {
    fill: '#FFC80A',
    [media.mobile]: {
      height: '4rem',
    },
    [media.desktop]: {
      height: '6.5rem',
    },
  },
  moreInfo: {
    gridArea: 'g',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > span': {
      fontSize: '1.5rem',
      textAlign: 'center',
    },
    [media.mobile]: {
      marginBottom: '90px',
    },
    [media.desktop]: {
      margin: '2rem 0 90px 0',
    },
  },
  buttonMoreInfo: {
    ...button.yellow,
    marginTop: '30px',
    [media.mobile]: {
      ...button.yellow[media.mobile],
      width: '230px',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      width: '230px',
    },
  },
  terms: {
    gridArea: 't',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    backgroundColor: 'black',
    border: '1px solid black',
  }
};
