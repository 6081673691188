const styles = () => ({
  wrap: {
    display: 'inline-block',
  },
  searchWrap: {
    flexGrow: 1,
  },
  buttonWrap: {
    width: '52px',
    paddingLeft: '4px',
  },
  icon: {
    position: 'relative',
    top: '15px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export default styles;
