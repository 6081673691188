import 'utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { thunks } from 'services/thunks';
import { store } from 'store';
import { history } from 'store/reducer';
import { App } from 'ui/App';
import 'ui/locales/i18n';
import 'ui/styles/index.css';

/*eslint-disable*/
const render = Component => {
  store.dispatch(thunks.setLocalDataToStore());
  ReactDOM.render(
    <Provider store={store}>
      <Component history={history} />
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('ui/App', () => {
    const { App } = require('ui/App');
    render(App);
  });
}
