export const orderStatus = {
  pendingVerification: 0,
  new: 1,
  accepted: 2,
  refused: 3,
  rejected: 4, //like new, but was rejected by driver
  completed: 5,
  canceled: 6,
  expired: 7,
};

const statusList = Object.fromEntries(
  Object.entries(orderStatus).map(([key, val]) => [val, key]),
);

export const getOrderStatus = id => statusList[id];
