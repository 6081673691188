import React from 'react';
import NumericInput from 'react-numeric-input';
import injectSheet from 'react-jss';
import { inventoryItem } from '../../../../styles/Driver/Inventory/inventoryItem';
import { numberInput } from '../../../../styles/Customer/CustomerHome/spiritProduct';

class _InventoryItem extends React.PureComponent {
  onChange = value => {
    const { categoryIndex, itemIndex, changeItemQuantity } = this.props;
    changeItemQuantity(value, categoryIndex, itemIndex);
  };

  render() {
    const { classes, data, categoryName } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.names}>
          <span>{data.name}</span>
          {categoryName !== 'Beer' && (
            <span className={classes.unit}>{data.unit}</span>
          )}
        </div>
        <NumericInput
          mobile={false}
          style={numberInput}
          min={0}
          max={72}
          strict
          value={data.quantity}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export const InventoryItem = injectSheet(inventoryItem)(_InventoryItem);
