import { input } from '../../Commons/input';
import { button } from '../../Commons/button';
import { media } from '../../theme';

export const loginForm = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    fontFamily: 'Roboto',
    fontWeight: '300',
    [media.mobile]: {
      fontSize: '1rem',
      margin: '2rem 0',
    },
    [media.desktop]: {
      margin: '7rem 0 3rem 0',
      fontSize: '1.375rem',
    },
  },
  form: {
    gridArea: 'b',
    width: '40%',
    display: 'flex',
    flexWrap: 'wrap',
    [media.mobile]: {
      width: '90%',
    },
    [media.desktop]: {
      width: '40%',
    },
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `
        'b'
        'c'
      `,
    },
  },
  inputLabel: {
    ...input.label,
    [media.desktop]: {
      display: 'none',
    },
  },
  input: {
    ...input.input,
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  inputError: { ...input.error },
  resetPassword: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  signUpOnLogin: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    cursor: 'pointer',
  },
  submit: {
    ...button.yellow,
    margin: '2rem auto 2rem auto',
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
