import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { goBack } from 'connected-react-router';
import injectSheet from 'react-jss';
import { bindActionCreators, compose } from 'redux';
import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon';
import { backButton } from '../../../../styles/Commons/backButton';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goBackRoute: goBack,
    },
    dispatch,
  );

const _BackButton = ({ classes, $container, goBackRoute }) => (
  <span className={$container || classes.container}>
    <button type="button" className={classes.link} onClick={goBackRoute}>
      <ArrowLeftIcon className={classes.icon} />
      <span className={classes.text}>Back</span>
    </button>
  </span>
);

export const BackButton = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  injectSheet(backButton),
)(_BackButton);
