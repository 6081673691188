import { media } from '../../theme';
import { palette } from 'ui/styles/theme';

export const scrollToOrderList = {
  orderList: {
    position: 'fixed',
    bottom: '2%',
    right: '4%',
    textAlign: 'center',
    [media.desktop]: {
      display: 'none',
    },
  },
  icon: {
    height: '3rem',
    fill: '#E0B317',
    '& > g': {
      fill: 'white',
    },
  },
  scrollButton: {
  	textDecoration: 'none',
    color: palette.yellow,
    fontWeight: 500,
    padding: '0.5rem',
    display: 'block',
 },
 linkToProducts: {
    textDecoration: 'none',
    color: palette.white,
    fontWeight: 500,
    fontFamily: 'Roboto',
    margin: 'auto 0.25em',
    display: 'flex',
    padding: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
};
