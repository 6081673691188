import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import Select, { components } from 'react-select';
import injectSheet from 'react-jss';
import { actions } from '../../../../../store/actions';
import { ArrowDownFillIcon } from '../../../atoms/icons/ArrowDownFillIcon';
import { commonAPI } from '../../../../../services/api/apiUrl';
import {
  productWithDropdown,
  dropDown,
} from '../../../../styles/Customer/CustomerHome/productWithDropdown';
import { formatPrice } from '../../../../../utils';

const mapStateToProps = (state, { id }) => ({
  data: state.getIn(['app', 'products', 'items', id]),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProductDropdownQuantity: actions.setProductDropdownQuantity.act,
    },
    dispatch,
  );

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <ArrowDownFillIcon />
    </components.DropdownIndicator>
  );

const getOptions = list =>
  list.map(item => ({
    value: item.get('quantity'),
    label: item.get('quantity'),
  }));

class _ProductWithDropdown extends PureComponent {
  onChange = ({ value }) => {
    const { setProductDropdownQuantity, id } = this.props;
    setProductDropdownQuantity(id, value);
  };

  render() {
    const { classes, data } = this.props;
    const activeOption = data.get('activeOption');
    const options = getOptions(data.get('productPrices'));
    const value = options.get(activeOption);
    return (
      <div className={classes.container}>
        <img
          src={`${commonAPI}${data.get('imagePath')}`}
          alt={data.get('name')}
        />
        <span className={classes.priceLead}>Starting at</span>
        <span className={classes.price}>
          {formatPrice(data.getIn(['productPrices', activeOption, 'price']))}
        </span>
        <Select
          isSearchable={false}
          components={{ DropdownIndicator }}
          onChange={this.onChange}
          options={options}
          value={value}
          styles={dropDown}
        />
        <span className={classes.productName}>{data.get('name')}</span>
      </div>
    );
  }
}

export const ProductWithDropdown = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(productWithDropdown),
)(_ProductWithDropdown);
