import { media, palette } from 'ui/styles/theme';

export const tab = {
  container: {
    width: '48%',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateAreas: ` 'a b' `,
    fontFamily: 'Roboto',
    fontWeight: '500',
    userSelect: 'none',
    '& span': {
      gridArea: 'b',
      textTransform: 'uppercase',
    },
    [media.mobile]: {
      width: '50%',
      gridTemplateColumns: '25% 75%',
      fontSize: '1rem',
      lineHeight: '2.5rem',
      borderBottom: ({ active }) => (active ? `2px solid${palette.yellow}` : 0),
    },
    [media.desktop]: {
      width: '48%',
      gridTemplateColumns: '20% 80%',
      fontSize: '1.75rem',
      lineHeight: '3.5rem',
      cursor: 'pointer',
      borderBottom: ({ active }) => (active ? `6px solid${palette.yellow}` : 0),
    },
  },
  icon: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'center',
    height: '2.5rem',
    [media.mobile]: {
      height: '1.5rem',
    },
  },
};
