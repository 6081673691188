import { actions } from 'store/actions';
import { api } from '../../api';
import { setErrorToStore } from 'services/utils';

export const cancelOrder = (id = null) => async dispatch => {
  try {
    await api.cancelOrder(id);
    dispatch(actions.closeModal.act('checkCustomerPhone'));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
