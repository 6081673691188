import * as Yup from 'yup';

export const otherReason = Yup.string().when(
  [
    'drunk',
    'underAge',
    'noShow',
    'noEnoughMoney',
    'abusiveOrViolent',
    'atTreatmentFacility',
  ],
  {
    is: (
      drunk,
      underAge,
      noShow,
      noEnoughMoney,
      abusiveOrViolent,
      atTreatmentFacility,
    ) =>
      drunk ||
      underAge ||
      noShow ||
      noEnoughMoney ||
      abusiveOrViolent ||
      atTreatmentFacility,
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .trim()
      .required('Please enter the reason for the refusal of this order')
      .min(5, 'Refusal message should be more than 5 characters'),
  },
);
