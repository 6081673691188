import { media } from 'ui/styles/theme';

export const inventeryCategory = {
  container: {
    width: '100%',
    borderBottom: '1px solid grey',
    '& > h3': {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '1.125rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginTop: '1.5rem',
      [media.desktop]: {
        fontSize: '1.5rem',
        marginTop: '2rem',
      },
    },
    [media.desktop]: {
      width: '800px',
    },
  },
};
