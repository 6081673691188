import React, { PureComponent } from 'react';
import { compose } from 'redux';
import moment from 'moment';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { LoadButton } from 'ui/components/atoms/Commons/Client/LoadButton';
import { UserRoundIcon } from 'ui/components/atoms/icons/UserRoundIcon';
import injectSheet from 'react-jss';
import { openOrderTableRow } from 'ui/styles/Driver/OrderBoard/openOrderTableRow';

class _OrdersTableRow extends PureComponent {
  acceptOrder = () => {
    const { acceptOrder, data, startLoading, endLoading } = this.props;
    startLoading();
    acceptOrder(data.get('orderId'), endLoading);
  };

  render() {
    const { classes, data, isLoading, isMobile } = this.props;
    // Todo: time винести в селектор
    const time = moment(data.get('createdAt')).format('LT');
    return (
      <div className={classes.container}>
        <UserRoundIcon className={classes.icon} />
        <div className={classes.time}>{time}</div>
        <div className={classes.intersection}>{data.get('intersection')}</div>
        <div className={classes.items}>
          {data.get('orderItems').map((item, index) => (
            <div
              key={`${item.get('name')}-${index + 1}`}
              className={classes.item}
            >
              <span>{item.get('quantity')}</span>
              <span>&#10005;</span>
              <span>{item.get('name')}</span>
            </div>
          ))}
        </div>
        {isMobile ? (
          <div className={classes.total}>
            <span>Total:</span>
            <span>{`$${data.get('total')}`}</span>
          </div>
        ) : (
          <div className={classes.price}>{`$${data.get('total')}`}</div>
        )}

        <LoadButton
          onClick={this.acceptOrder}
          className={classes.accept}
          isLoading={isLoading}
        >
          Accept
        </LoadButton>
      </div>
    );
  }
}

export const OrdersTableRow = compose(
  withCommonLoading({ initLoading: false }),
  injectSheet(openOrderTableRow),
)(_OrdersTableRow);
