import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const blockDriver = ({ id, reason }) => async dispatch => {
  try {
    await api.blockDriver(id, reason);
    dispatch(
      actions.updateDriver.act({ id, status: 'Blocked', banReason: reason }),
    );
  } catch (e) {
    return e;
  }
};
