export const productPriceField = {
  container: {
    width: '80%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2rem',
    position: 'relative',
  },
  fixedPrice: {
    width: '80%',
    marginTop: '2rem',
  },
  zone: {
    width: '55%',
  },
  input: {
    width: '35%',
  },
  deleteRow: {
    position: 'absolute',
    right: '-13%',
  },
  add: {
    padding: '0.75rem 1.25rem',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#388e3c',
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  delete: {
    color: '#f34b73',
    '&:hover': {
      color: '#f31821',
    },
  },
  error: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#f31821',
    lineHeight: '2rem',
  },
};
