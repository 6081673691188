import React from 'react';

export const TextArea = ({
  field: { name, value, onChange, onBlur },
  labelText,
  error,
  placeholder,
  $container,
  $label,
  $textarea,
  $error,
}) => (
  <div className={$container}>
    {labelText && (
      <label className={$label} htmlFor={name}>
        {labelText}
      </label>
    )}
    <textarea
      id={name}
      className={$textarea}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
    />
    {error && <span className={$error}>{error}</span>}
  </div>
);
