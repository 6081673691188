import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const editCustomer = (id, data) =>
  privateAxios({
    method: 'put',
    baseURL: adminAPI,
    url: `/customer/${id}`,
    data,
  });
