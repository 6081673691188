export const errorCodes =
{
    GeneralError: 0,
    ValidationError: 1,
    AccountBlocked: 2,
    InsufficientItems: 3,
    NotFound: 4,
    InvalidCredentials: 5,
    HasOrder: 6,
    CanNotFiundSchedulItem: 7,
    OptionalTimeout: 8,
    AccountSuspended: 9,
    AccountNew: 10,
}
