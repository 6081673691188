import { api } from 'services/api';
import { push } from 'connected-react-router';
import { thunks } from 'services/thunks/index';
import { actions } from '../../../store/actions';
import { getLocalData, setErrorToStore } from '../../utils';
import {
  getActiveOrder,
  getPendingOrder,
  removePendingOrder,
  removeActiveOrder,
  setActiveOrder,
  getOrderWithSecret,
} from 'utils';
import { getOrderStatus } from 'ui/constants';

const handleForDriver = (dispatch, id, orderWithSecret, status) => {
  const map = {
    accepted: () => {
      dispatch({ type: 'subscribeForOrder', id });
      dispatch(push(`/driver/order/${orderWithSecret}`));
    },
    rejected: () => {
      removeActiveOrder();
      dispatch(actions.setData.act(['activeOrder'], null));
      dispatch(push('/orderboard'));
    },
    completed: () => {
      removeActiveOrder();
      dispatch(actions.setData.act(['activeOrder'], null));
      dispatch(push('/orderboard'));
    },
    canceled: () => {
      removeActiveOrder();
      dispatch(actions.setData.act(['activeOrder'], null));
      dispatch(push('/orderboard'));
    },
  };
  if (typeof map[status] === 'function') map[status]();
};

const handleForCustomer = (
  dispatch,
  id,
  orderWithSecret,
  status,
  driverRated,
) => {
  const map = {
    pendingVerification: () => {
      dispatch({ type: 'subscribeForOrder', id });
      dispatch(push('/complete-order'));
    },
    new: () => {
      dispatch({ type: 'subscribeForOrder', id });
      removePendingOrder();
      setActiveOrder(orderWithSecret);
      dispatch(actions.setData.act(['pendingOrder'], false));
      dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    },
    accepted: () => {
      dispatch({ type: 'subscribeForOrder', id });
      removePendingOrder();
      setActiveOrder(orderWithSecret);
      dispatch(actions.setData.act(['pendingOrder'], false));
      dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    },
    rejected: () => {
      dispatch({ type: 'subscribeForOrder', id });
      removePendingOrder();
      setActiveOrder(orderWithSecret);
      dispatch(actions.setData.act(['pendingOrder'], false));
      dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    },
    completed: () => {
      dispatch(thunks.removeActiveOrder(driverRated));
    },
    canceled: () => {
      dispatch(thunks.removeActiveOrder());
    },
    expired: () => {
      dispatch(thunks.removeActiveOrder());
    },
  };
  if (typeof map[status] === 'function') map[status]();
};

export const handleOrderStatus = async (orderWithSecret, role, dispatch) => {
  try {
    const { id, statusId, driverRated } = (await api.getOrder(
      orderWithSecret,
    )).data;
    const orderStatus = getOrderStatus(statusId);
    if (role === 'driver') {
      handleForDriver(dispatch, id, orderWithSecret, orderStatus);
    } else {
      handleForCustomer(
        dispatch,
        id,
        orderWithSecret,
        orderStatus,
        driverRated,
      );
    }
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};

const handleCustomerStatus = async dispatch => {
  const { data } = await api.isCustomerBlocked();
  if (data) dispatch(thunks.logout({ isBlocked: true }));

  const {
    data: {
      hasActiveOrder,
      activeOrder,
      orderSecret
    },
  } = await api.getCustomerStatus();

  if (hasActiveOrder) {
    const orderWithSecret = getOrderWithSecret(activeOrder, orderSecret);
    setActiveOrder(orderWithSecret);
    dispatch({ type: 'subscribeForOrder', id: activeOrder });
    dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    dispatch(push(`/order/${orderWithSecret}`));
  } else {
    dispatch(thunks.removeActiveOrder());
  }
};

const handleDriverStatus = async dispatch => {
  const {
    data: {
      hasActiveOrder,
      activeOrder,
      activeOrderSecret,
      suspended,
      statusId,
    },
  } = await api.getDriverStatus();

  if (hasActiveOrder) {
    const orderWithSecret = getOrderWithSecret(activeOrder, activeOrderSecret);
    setActiveOrder(orderWithSecret);
    dispatch({ type: 'subscribeForOrder', id: activeOrder });
    dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    dispatch(push(`/driver/order/${orderWithSecret}`));
  } else {
    dispatch(thunks.removeActiveOrder());
  }

  if (suspended) {
    dispatch(push('/driver/profile'));
  }

  if (statusId === 3) {
    // If Driver is Blocked
    dispatch(thunks.logout({ isBlocked: true }));
  }
};

const isUserBlocked = async (role, dispatch) => {
  if (role === 'customer') await handleCustomerStatus(dispatch);
  if (role === 'driver') await handleDriverStatus(dispatch);
};

export const checkAuth = () => async dispatch => {
  const localData = getLocalData();
  await isUserBlocked(localData.role, dispatch);
  // Check if user have any order in progress
  const pendingOrder = getPendingOrder();
  const activeOrder = getActiveOrder();
  if (pendingOrder)
    await handleOrderStatus(pendingOrder, localData.role, dispatch);
  if (activeOrder)
    await handleOrderStatus(activeOrder, localData.role, dispatch);
};
