import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { palette } from '../../../../../styles/theme';
import useLinkStyles from '../../../../../styles/Commons/links';

const useStyles = createUseStyles({
  wrapper: {
    display: 'inline-block',

    '& a': {
      pointerEvents: 'none',
      cursor: 'inherit !important',
    },
  },
  externalLinkWrapper: {
    position: 'relative',
    verticalAlign: 'top',
  },
  externalLink: {
    right: 0,
    width: 15,
    height: 15,
    margin: 2,
    position: 'absolute',
    filter: `drop-shadow(1px 1px 1px ${palette.black})`,
    cursor: 'pointer',
  },
});

export const Decorator = ({ entityKey, children, contentState }) => {
  const classes = useStyles();
  const linkClasses = useLinkStyles();
  const [showPopOver, setShowPopOver] = useState(false);
  const { readOnly } = children[0].props.customStyleMap;

  const toggle = useCallback(x => () => setShowPopOver(x), []);

  const { url, targetOption, className } = contentState
    .getEntity(entityKey)
    .getData();

  const openLink = useCallback(
    () => {
      const linkTab = window.open(
        !readOnly && url.startsWith('#') ? `/${url}` : url,
        readOnly ? targetOption : 'blank',
        undefined,
        !!readOnly,
      ); // eslint-disable-line no-undef
      // linkTab can be null when the window failed to open.
      if (linkTab) {
        linkTab.focus();
      }
    },
    [url, targetOption],
  );

  return readOnly ? (
    <a
      href={url}
      className={clsx(linkClasses.link, className)}
    >
      {children}
    </a>
  ) : (
    <span
      className={classes.wrapper}
      onMouseEnter={toggle(true)}
      onMouseLeave={toggle(false)}
    >
      <a
        href={url}
        target={targetOption}
        className={clsx(linkClasses.link, className)}
      >
        {children}
      </a>
      {!readOnly && showPopOver && (
        <span className={classes.externalLinkWrapper}>
          <svg
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
            onClick={openLink}
            fill={palette.white}
            className={classes.externalLink}
          >
            <g>
              <path
                d="M18,17.759v3.366C18,22.159,17.159,23,16.125,23H4.875C3.841,23,3,22.159,3,21.125V9.875
                C3,8.841,3.841,8,4.875,8h3.429l3.001-3h-6.43C2.182,5,0,7.182,0,9.875v11.25C0,23.818,2.182,26,4.875,26h11.25
                C18.818,26,21,23.818,21,21.125v-6.367L18,17.759z"
              />
              <path
                d="M22.581,0H12.322c-1.886,0.002-1.755,0.51-0.76,1.504l3.22,3.22l-5.52,5.519
                 c-1.145,1.144-1.144,2.998,0,4.141l2.41,2.411c1.144,1.141,2.996,1.142,4.14-0.001l5.52-5.52l3.16,3.16
                 c1.101,1.1,1.507,1.129,1.507-0.757L26,3.419C25.999-0.018,26.024-0.001,22.581,0z"
              />
            </g>
          </svg>
        </span>
      )}
    </span>
  );
};

export const strategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export default {
  strategy,
  component: Decorator,
};
