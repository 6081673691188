import createAction from 'utils/redux-helpers/createAction';

const handler = (state, { value, categoryIndex, itemIndex }) =>
  state.setIn(
    ['inventory', categoryIndex, 'items', itemIndex, 'quantity'],
    value,
  );

export const changeItemQuantity = createAction({
  action: ['changeItemQuantity', 'value', 'categoryIndex', 'itemIndex'],
  handler,
});
