import React, { PureComponent } from 'react';
import { compose } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import injectSheet from 'react-jss';
import { pagination } from 'ui/styles/Driver/OrderBoard/pagination';

class _Pagination extends PureComponent {
  iconClasses = { root: this.props.classes.iconRoot };

  goToFirstPage = () => {
    const { getActiveOrders } = this.props;
    getActiveOrders(1, 0);
  };

  goToPrevPage = () => {
    const { getActiveOrders, activeOrders } = this.props;
    const currentPage = activeOrders.get('page');
    getActiveOrders(currentPage - 1, currentPage);
  };

  goToNextPage = () => {
    const { getActiveOrders, activeOrders } = this.props;
    const currentPage = activeOrders.get('page');
    getActiveOrders(currentPage + 1, currentPage);
  };

  goToLastPage = () => {
    const { getActiveOrders, activeOrders } = this.props;
    const lastPage = activeOrders.get('totalPages');
    const currentPage = activeOrders.get('page');
    getActiveOrders(lastPage, currentPage);
  };

  render() {
    const { classes, activeOrders } = this.props;
    return (
      <div className={classes.container}>
        <IconButton
          onClick={this.goToFirstPage}
          disabled={!activeOrders.get('hasPrevious')}
          aria-label="First Page"
          color="inherit"
          classes={this.iconClasses}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={this.goToPrevPage}
          disabled={!activeOrders.get('hasPrevious')}
          aria-label="Prev Page"
          color="inherit"
          classes={this.iconClasses}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <div className={classes.pageCount}>
          {`${activeOrders.get('page')} / ${activeOrders.get('totalPages')}`}
        </div>
        <IconButton
          onClick={this.goToNextPage}
          disabled={!activeOrders.get('hasNext')}
          aria-label="Next Page"
          color="inherit"
          classes={this.iconClasses}
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.goToLastPage}
          disabled={!activeOrders.get('hasNext')}
          aria-label="Last Page"
          color="inherit"
          classes={this.iconClasses}
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

export const Pagination = compose(injectSheet(pagination))(_Pagination);
