import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const cardModal = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 5,
    fontFamily: 'Roboto',
    [media.mobile]: {
      width: '95%',
      height: '250px',
      maxWidth: '400px',
    },
    [media.desktop]: {
      width: '600px',
      height: '310px',
    },
  },
  cardForm: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
    '& > p': {
      fontSize: '1.75rem',
      fontWeight: 500,
      [media.mobile]: {
        fontSize: '1.40rem',
      },
    },
    [media.mobile]: {
      width: '95%',
      marginTop: '2rem',
    },
  },
  cardElement: {
    border: '1px solid black',
    padding: '10px',
    margin: '3rem 0 1rem 0',
    fontFamily: 'Roboto',
    width: 'calc(100% - 20px)',
  },
  error: {
    color: 'red',
    marginBottom: '1rem',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignSelf: 'start',
    justifySelf: 'center',
  },
  cancel: {
    ...button.white,
    [media.mobile]: {
      ...button.yellow[media.mobile],
      width: '8rem',
    },
    [media.desktop]: {
      ...button.white[media.desktop],
      width: '10rem',
    }
  },
  ok: {
    ...button.yellow,
    [media.mobile]: {
      ...button.yellow[media.mobile],
      width: '8rem',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      width: '10rem',
    }
  },
  deleteCard: {
      marginTop: '1.5rem',
    fontSize: '0.8rem',
    color: '#757575',
  },
};

export const cardElement = {
  base: {
    // fontSize: '18px',
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
};
