import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const setCustomerRating = (id, rate) => async dispatch => {
  try {
    await api.setCustomerRating(id, rate);
    dispatch(actions.updateCustomer.act({ id, rate }));
  } catch (e) {
    return e;
  }
};
