import { List } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = state =>
  state.withMutations(next =>
    next
      .set('orderList', new List())
      .updateIn(['products', 'items'], list =>
        list.map(x => x
          .set('currentQuantity', 0)
          .set('activeOption', 0)),
      ),
  );

export const clearOrderList = createAction({
  action: ['clearOrderList'],
  handler,
});
