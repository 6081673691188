export const pagination = {
  container: {
    width: '100%',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#555555',
    color: 'white',
  },
  pageCount: {
    fontFamily: 'Roboto',
    margin: '0 0.75rem',
  },
  iconRoot: {
    '&:hover': {
      backgroundColor: '#ffffff1b',
    },
  },
};
