import { push } from 'connected-react-router';
import { removeActiveOrder as removeLS, removePendingOrder } from 'utils';
import { actions } from 'store/actions';

export const removeActiveOrder = (redirect = true) => async dispatch => {
  removePendingOrder();
  removeLS();
  dispatch(actions.setData.act(['activeOrder'], null));
  dispatch(actions.setData.act(['pendingOrder'], false));
  dispatch(actions.setData.act(['modals', 'confirmModal'], false));
  if (redirect) {
    dispatch(push('/'));
  }
};
