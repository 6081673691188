import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getDriverById = id => async dispatch => {
  try {
    const response = await api.getDriverById(id);
    dispatch(actions.setDriver.act({ id, ...response.data }));
  } catch (e) {
    return e;
  }
};
