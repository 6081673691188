import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import { websocketMiddleware } from 'store/middlewares/websocketMiddleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { reducer, history } from 'store/reducer';

const aa = composeWithDevTools({ trace: true, traceLimit: 25 });
/*eslint-disable*/
const configureStore = () => {
  const store = createStore(
    reducer,
    aa(
      applyMiddleware(routerMiddleware(history), thunk, websocketMiddleware),
    ),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('store/reducer', () => {
        store.replaceReducer(reducer);
      });
    }
  }

  return store;
};

export const store = configureStore();
