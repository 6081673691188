import { input } from 'ui/styles/Commons/input';
import { button } from 'ui/styles/Commons/button';
import { media, palette } from 'ui/styles/theme';

export const anonymousAddressForm = {
  container: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2rem',
    paddingBottom: '2rem',
    borderBottom: '1px solid #E1E1E1',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    [media.mobile]: {
      width: '90%',
    },
    [media.desktop]: {
      width: '60%',
      marginBottom: '2rem',
      paddingBottom: '2rem',
      borderBottom: '1px solid #E1E1E1',
    },
  },
  inputContainer: {
    ...input.container,
    height: '6rem',
    gridTemplateRows: '1.5rem 3.25rem 1.25rem',
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: '7.5rem auto',
      height: '5rem',
      gridTemplateRows: '3.25rem auto',
    },
  },
  inputHalfContainer: {
    ...input.container,
    height: '6rem',
    gridTemplateRows: '1.5rem 3.25rem 1.25rem',
    [media.desktop]: {
      ...input.container[media.desktop],
      width: '55%',
      gridTemplateColumns: '7.5rem auto',
      height: '5rem',
      gridTemplateRows: '3.25rem auto',
    },
  },
  inputHalfContainerWithoutLabel: {
    ...input.container,
    height: '6rem',
    gridTemplateRows: '1.5rem 3.25rem 1.25rem',
    [media.desktop]: {
      ...input.container[media.desktop],
      width: '45%',
      gridTemplateColumns: '2.5rem auto',
      height: '5rem',
      gridTemplateRows: '3.25rem auto',
    },
  },
  inputLabel: {
    ...input.label,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10pt',
    lineHeight: '1.4rem',
  },
  input: {
    ...input.input,
    background: '#f5f5f5',
    borderColor: '#a1a1a1',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  inputError: { ...input.error, alignSelf: 'auto', marginTop: '4px', },
  textareaContainer: {
    ...input.container,

    [media.mobile]: {
      height: '10rem',
      gridTemplateRows: '2.25rem auto 1.75rem',
    },
    [media.desktop]: {
      ...input.container[media.desktop],
      height: '8rem',
      gridTemplateColumns: '7.5rem auto',
      gridTemplateRows: '2.25rem auto 1.75rem',
      gridTemplateAreas: `
      'a b'
      '. b'
      '. c'
      `,
    },
  },
  textarea: {
    ...input.input,
    background: '#f5f5f5',
    borderColor: '#a1a1a1',
    padding: '0.5rem',
    resize: 'none',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  termsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    [media.mobile]: {
      height: '4rem',
    },
    [media.desktop]: {
      height: '3rem',
    },
  },
  termsLink: {
    color: palette.yellow,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  termsError: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: '300',
    color: 'red',
  },
  submit: {
    ...button.yellow,
    margin: '2rem auto 2rem auto',
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
