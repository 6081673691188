import React from 'react';

export const LoadButton = ({
  className,
  children,
  title,
  type = 'button',
  isLoading,
  onClick,
  disabled = false,
}) => (
  <button
    className={className}
    onClick={onClick}
    disabled={disabled}
    title={title}
    type={type}
  >
    {isLoading ? 'Loading...' : children}
  </button>
);
