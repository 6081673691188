import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const changePasswordConfig = {
  mapPropsToValues: () => ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  }),
  validationSchema: Yup.object().shape({
    oldPassword: validators.oldPassword,
    newPassword: validators.newPassword,
    newPasswordConfirm: validators.confirmNewPassword,
  }),
  handleSubmit: (
    values,
    { props: { changePassword }, setErrors, setSubmitting },
  ) => {
    changePassword(values, setErrors, setSubmitting);
  },
  displayName: 'ChangePassword',
};
