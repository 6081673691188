import { api } from 'services/api';
import { setErrorToStore } from 'services/utils';
import { setActiveDeliveryZone } from '../general/setActiveDeliveryZone';

export const changeDeliveryZone = deliveryZoneId => async dispatch => {
  try {
    await api.setDeliveryZone(deliveryZoneId);
    dispatch(setActiveDeliveryZone(deliveryZoneId));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
