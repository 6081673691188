import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const editProduct = ({
  id,
  productName,
  unit,
  order,
  uploadImage,
  prices,
  category,
}) =>
  privateAxios({
    method: 'put',
    baseURL: adminAPI,
    url: `/product/${id}`,
    data: {
      name: productName,
      unit: category === 2 ? null : unit,
      order,
      categoryId: category,
      imageBase64: uploadImage || null,
      prices,
    },
  });
