export const uploadImage = {
  container: {
    width: '80%',
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  label: {
    width: '55%',
  },
  input: {
    display: 'none',
  },
  upload: {
    width: '100%',
    padding: '0.75rem 2.25rem',
    fontSize: '1rem',
    color: 'white',
    boxShadow: 'none',
    // backgroundColor: '#03a9f4',
    backgroundColor: '#673ab7',
    '&:hover': {
      // backgroundColor: '#2F80EC',
      backgroundColor: '#512da8',
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  icon: {
    marginLeft: '0.5rem',
  },
  delete: {
    width: '35%',
    composes: '$upload',
    backgroundColor: '#f34b73',
    '&:hover': {
      backgroundColor: '#f31821',
    },
  },
  image: {
    height: '265px',
    width: '200px',
    marginTop: '2rem',
  },
  error: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#f31821',
    lineHeight: '2rem',
  },
};
