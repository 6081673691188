import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getDriversList = ({ ...data }) => async dispatch => {
  try {
    const response = await api.getDriversList(data);
    dispatch(actions.setDrivers.act(response.data));
    dispatch(actions.needToUpdateData.act(false));
  } catch (e) {
    return e;
  }
};
