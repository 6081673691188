import React from 'react';

import { withStyles, Paper } from '@material-ui/core';
import styles from '../../../../styles/Admin/commons/paddedPaper';

const _PaddedPaper = ({ classes: { paper }, children }) => (
  <Paper classes={{ root: paper }}>{children}</Paper>
);

const _PaddedPaperShort = ({ classes: { paperShort }, children }) => (
  <Paper classes={{ root: paperShort }}>{children}</Paper>
);

export const PaddedPaper = withStyles(styles)(_PaddedPaper);

export const PaddedPaperShort = withStyles(styles)(_PaddedPaperShort);
