import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';
import { formatPhone } from 'utils';

export const anonymousAddressConfig = {
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    postalCode: '',
    validDeliveryArea: true,
    latitude: '',
    longitude: '',
    boozer: '',
    nearestIntersection: '',
    instructions: '',
    terms: false,
  }),
  validationSchema: Yup.object().shape({
    firstName: validators.firstName,
    lastName: validators.lastName,
    phone: validators.phone,
    email: Yup.string()
      .strict()
      .trim('Please remove spaces before and after email')
      .email('Email is not valid')
      .max(50, 'Email should be less than 50 symbols'),
    address: validators.address,
    validDeliveryArea: Yup.bool(),
    postalCode: validators.addressPostalCode,
    boozer: validators.boozer,
    nearestIntersection: validators.intersection,
    instructions: validators.instructions,
    terms: validators.terms,
  }),
  handleSubmit: (
    values,
    { props: { sendAnonymousOrder }, setErrors, setSubmitting },
  ) => {
    sendAnonymousOrder(
      { ...values, phone: formatPhone(values.phone) },
      setErrors,
      setSubmitting,
    );
  },
  displayName: 'AnonymousAddressForm',
};
