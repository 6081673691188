import { signIn } from 'store/actions/general/signIn';
import { openModal } from 'store/actions/commons/openModal';
import { closeModal } from 'store/actions/commons/closeModal';
import { setDeliveryZone } from 'store/actions/general/setDeliveryZone';
import { setDeliveryZoneHeader } from 'store/actions/general/setDeliveryZoneHeader';
import { login } from 'store/actions/general/login';
import { logout } from 'store/actions/general/logout';
import { setCustomerInfo } from 'store/actions/customer/setCustomerInfo';
import { setCustomerAddresses } from 'store/actions/customer/setCustomerAddresses';
import { editCustomerAddress } from 'store/actions/customer/editCustomerAddress';
import { editCustomerInfo } from 'store/actions/customer/editCustomerInfo';
import { addAddress } from 'store/actions/customer/addAddress';
import { deleteAddress } from 'store/actions/customer/deleteAddress';
import { setProducts } from 'store/actions/customer/setProducts';
import { commons } from 'store/actions/commons';
import { setInventory } from 'store/actions/driver/setInventory';
import { changeItemQuantity } from 'store/actions/driver/changeItemQuantity';
import { setDriverInfo } from 'store/actions/driver/setDriverInfo';
import { setActiveDeliveryZone } from 'store/actions/driver/setActiveDeliveryZone';
import { setProductDropdownQuantity } from 'store/actions/customer/setProductDropdownQuantity';
import { setProductQuantity } from 'store/actions/customer/setProductQuantity';
import { clearOrderList } from 'store/actions/customer/clearOrderList';
import { selectAddress } from 'store/actions/customer/selectAddress';
import { openVerificationModal } from 'store/actions/customer/openVerificationModal';
import { setSingleOrderData } from 'store/actions/customer/setSingleOrderData';
import { setActiveOrders } from 'store/actions/driver/setActiveOrders';
import { setDriverOrder } from 'store/actions/driver/setDriverOrder';
import { setCompletedOrders } from 'store/actions/driver/setCompletedOrders';
import { hideResentLink } from 'store/actions/customer/hideResentLink';
import { editDriverInfo } from 'store/actions/driver/editDriverInfo';
import { setCard } from 'store/actions/driver/setCard';
import { payDebt } from 'store/actions/driver/payDebt';
import { setError } from 'store/actions/commons/setError';
// admin
import { setDeliveryZones } from 'store/actions/admin/deliveryZone/setDeliveryZones';
import { setDrivers } from 'store/actions/admin/driver/setDrivers';
import { setCustomers } from 'store/actions/admin/customer/setCustomers';
import { setPostalCodes } from 'store/actions/admin/deliveryZone/setPostalCodes';
import { deleteDeliveryZone } from 'store/actions/admin/deliveryZone/deleteDeliveryZone';
import { deleteDriver } from './admin/driver/deleteDriver';
import { deleteCustomer } from './admin/customer/deleteCustomer';
import { needToUpdateData } from './admin/common/needToUpdateData';
import { updateCustomer } from './admin/customer/updateCustomer';
import { updateDriver } from './admin/driver/updateDriver';
import { editDeliveryZone } from './admin/deliveryZone/editDeliveryZone';
import { editCustomer } from './admin/customer/editCustomer';
import { editDriver } from './admin/driver/editDriver';
import { setCustomer } from './admin/customer/setCustomer';
import { setDeliveryZoneData } from './admin/deliveryZone/setDeliveryZoneData';
import { setDriver } from './admin/driver/setDriver';
import { setOrders } from './admin/order/setOrders';
import { adminCancelOrder } from 'store/actions/admin/order/adminCancelOrder';
import { setTransactions } from './admin/transaction/setTransactions';
import { adminSetProducts } from 'store/actions/admin/products/adminSetProducts';
import { setValidateOrderError } from 'store/actions/customer/setValidateOrderError';
import { setLocalDataToStore } from 'store/actions/commons/setLocalDataToStore';
import { setData } from 'store/actions/commons/setData';
import { updateActiveOrders } from 'store/actions/driver/updateActiveOrders';
import { toggleCallTo } from 'store/actions/commons/toggleCallTo';
import { onLoadMainPage } from 'store/actions/customer/onLoadMainPage';

export const actions = {
  onLoadMainPage,
  toggleCallTo,
  updateActiveOrders,
  setData,
  setLocalDataToStore,
  setValidateOrderError,
  adminSetProducts,
  setError,
  payDebt,
  setCard,
  editDriverInfo,
  hideResentLink,
  setCompletedOrders,
  setDriverOrder,
  setActiveOrders,
  setSingleOrderData,
  openVerificationModal,
  selectAddress,
  signIn,
  openModal,
  closeModal,
  setDeliveryZone,
  setDeliveryZoneHeader,
  login,
  logout,
  setCustomerInfo,
  setCustomerAddresses,
  editCustomerAddress,
  editCustomerInfo,
  addAddress,
  deleteAddress,
  setProducts,
  setInventory,
  ...commons,
  changeItemQuantity,
  setDriverInfo,
  setActiveDeliveryZone,
  setProductDropdownQuantity,
  setProductQuantity,
  clearOrderList,
  setDeliveryZones,
  setDrivers,
  setCustomers,
  setPostalCodes,
  deleteDeliveryZone,
  deleteDriver,
  deleteCustomer,
  needToUpdateData,
  editDriver,
  editCustomer,
  editDeliveryZone,
  updateDriver,
  updateCustomer,
  setDriver,
  setCustomer,
  setDeliveryZoneData,
  setOrders,
  adminCancelOrder,
  setTransactions,
};
