import React, { Component } from 'react';
import moment from 'moment';
import { TableRow, TableCell, Button, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withNamespaces } from 'react-i18next';
import { TooltipOrderItemsList } from 'ui/components/atoms/Admin/Orders/TooltipOrderItemsList';
import { InfoIconWithTooltip } from 'ui/components/atoms/Commons/Admin/InfoIconWithTooltip';
import { thunks } from '../../../../../services/thunks';
import { ConfirmModal } from 'ui/components/organisms/Admin/Commons/ConfirmModal';
import { orderRow } from 'ui/styles/Admin/Order/orderRow';

const mapDispatchToProps = (dispatch, { getPageData }) => ({
  cancelOrder: id => dispatch(thunks.adminCancelOrder(id, getPageData)),
});

class _OrderRow extends Component {
  state = {
    isModalOpen: false,
  };

  cancelOrder = () => {
    this.props.cancelOrder(this.props.item.id);
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState(prevState => {
      return {
        isModalOpen: !prevState.isModalOpen,
      };
    });
  };

  render() {
    const {
      item: {
        id,
        driverFirstName,
        driverLastName,
        customerFirstName,
        customerLastName,
        customerPhoneNumber,
        deliveryAddress,
        createdAt,
        confirmedAt,
        acceptedAt,
        finishedAt,
        status,
        total,
        itemsCount,
        orderItems,
        refuseReason,
        statusId,
      },
      classes,
    } = this.props;
    const canCancelOrder =
      statusId === 0 || statusId === 1 || statusId === 2 || statusId === 4;
    return (
      <TableRow>
        <TableCell>
          {`${driverFirstName || ''} ${driverLastName || ''}`}
        </TableCell>
        <TableCell>
          <div className={classes.customerInfo}>
            <span>{`${customerFirstName || ''} ${customerLastName ||
              ''}`}</span>

            <InfoIconWithTooltip
              tooltipMessage={
                <div className={classes.deliverAddressInfo}>
                  <span>{customerPhoneNumber || ''}</span>

                  {deliveryAddress && deliveryAddress.address && (
                    <span>{deliveryAddress.address}</span>
                  )}
                  {deliveryAddress && deliveryAddress.nearestIntersection && (
                    <span>{deliveryAddress.nearestIntersection}</span>
                  )}
                  {deliveryAddress && deliveryAddress.boozer && (
                    <span>{deliveryAddress.boozer}</span>
                  )}
                  {deliveryAddress && deliveryAddress.instructions && (
                    <span>{deliveryAddress.instructions}</span>
                  )}
                  <span>({id || ''})</span>
                </div>
              }
            />
          </div>
        </TableCell>

        <TableCell>
          <div className={classes.dateContainer}>
            <div className={classes.createdAt}>
              {/* Just escape [at] https://stackoverflow.com/questions/28241002/moment-js-include-text-in-middle-of-date-format */}
              <span>{moment(createdAt).format('YYYY-MM-DD [at] HH:mm')}</span>
            </div>

            <InfoIconWithTooltip
              classForIcon={classes.dateTooltip}
              tooltipMessage={
                <div className={classes.acceptedAt}>
                  <span>
                    {`Created at ${moment(createdAt).format(
                      'YYYY-MM-DD [at] HH:mm',
                    )}`}
                  </span>
                  {confirmedAt && (
                    <span>
                      {`Confirmed at ${moment(confirmedAt).format(
                        'YYYY-MM-DD [at] HH:mm',
                      )}`}
                    </span>
                  )}
                  {acceptedAt && (
                    <span>
                      {`Accepted at ${moment(acceptedAt).format(
                        'YYYY-MM-DD [at] HH:mm',
                      )}`}
                    </span>
                  )}
                  {finishedAt && (
                    <span>
                      {`Finished at ${moment(finishedAt).format(
                        'YYYY-MM-DD [at] HH:mm',
                      )}`}
                    </span>
                  )}
                </div>
              }
            />
          </div>
        </TableCell>

        <TableCell>
          {finishedAt &&
            confirmedAt &&
            `${moment(finishedAt).diff(moment(confirmedAt), 'minute')} min`}
        </TableCell>

        <TableCell>{`${total} $`}</TableCell>
        <TableCell>
          <div className={classes.orderItems}>
            <InfoIconWithTooltip
              tooltipMessage={<TooltipOrderItemsList items={orderItems} />}
            />
            <span>{itemsCount}</span>
          </div>
        </TableCell>
        <TableCell className={classes.relative}>
          {status}
          {canCancelOrder && (
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.toggleModal}
            >
              Cancel
            </Button>
          )}
          {statusId === 3 /* if order was rejected */ && (
            <InfoIconWithTooltip
              classForIcon={classes.icon}
              tooltipMessage={refuseReason}
            />
          )}
        </TableCell>

        {this.state.isModalOpen && (
          <ConfirmModal
            id={id}
            isOpenModal={this.state.isModalOpen}
            confirm={this.cancelOrder}
            closeModal={this.toggleModal}
            actionText="cancel the order?"
          />
        )}
      </TableRow>
    );
  }
}

export const OrderRow = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withStyles(orderRow),
)(_OrderRow);
