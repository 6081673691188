import { api } from '../../api';
import { actions } from '../../../store/actions';

export const editCustomerAddress = (
  values,
  id,
  toggleEdit,
) => async dispatch => {
  try {
    await api.editCustomerAddress(values, id);
    dispatch(actions.editCustomerAddress.act(values, id));
    toggleEdit();
  } catch (e) {
    return e;
  }
};
