import React from 'react';
import { PayNow } from 'ui/components/atoms/Driver/Profile/PayNow';
import { PaymentCard } from 'ui/components/organisms/Driver/Profile/PaymentCard';
import { Card } from 'ui/components/molecules/Driver/DriverProfile/Card';
import injectSheet from 'react-jss';
import { cardInfoBlock } from 'ui/styles/Driver/Profile/cardInfoBlock';

const _CardInfoBlock = ({ classes, card, status }) => (
  <div className={classes.container}>
    <Card last4={card.get('last4')} brand={card.get('brand')} />
    <PaymentCard className={classes.edit} text="Edit" />
    {status === 'Suspended' && <PayNow status={status} mobile />}
  </div>
);

export const CardInfoBlock = injectSheet(cardInfoBlock)(_CardInfoBlock);
