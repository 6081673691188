import { media } from 'ui/styles/theme';

export const noOrders = {
  container: {
    width: '100%',
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: '2.625rem',
    color: '#DADADA',
    textTransform: 'uppercase',
    [media.mobile]: {
      fontSize: '1.25rem',
    },
  },
  icon: {
    height: '4rem',
    marginRight: '2rem',
    fill: '#DADADA',
    [media.mobile]: {
      height: '3rem',
      marginRight: '1rem',
    },
  }
};
