import { fromJS } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.setIn(['driver', 'order'], fromJS(data));

export const setDriverOrder = createAction({
  action: ['setDriverOrder', 'data'],
  handler,
});
