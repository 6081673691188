import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { constants } from '../../../constants';
import { thunks } from '../../../../services/thunks';
import { Dashboard } from './Dashboard';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { PageSearch } from '../../molecules/Commons/Admin/PageSearch';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';
import { BaseTable } from '../../ecosystems/Admin/BaseTable';
import TransactionRow from '../../organisms/Admin/Transaction/TransactionRow';
import { DatePickerForm } from '../../molecules/Commons/Admin/DatePickerForm';
import { getTableData } from '../../../../selectors/getTableData';

// @TODO add snackbar
const mapDispatchToProps = dispatch => ({
  getTransactions: ({ ...data }) => dispatch(thunks.getTransactions(data)),
});

const mapStateToProps = state => {
  return {
    transactions: getTableData(state, [
      'app',
      'tables',
      'transactions',
      'items',
    ]),
    totalRecords: state.getIn([
      'app',
      'tables',
      'transactions',
      'totalRecords',
    ]),
  };
};

class _AdminTransactions extends Component {
  state = {
    keyWord: '',
    order: 'desc',
    sortColumn: 'createdate',
    pageSize: 10,
    pageIndex: 1,
    startDate: null,
    endDate: null,
  };

  handleChangeSearch = e => {
    this.setState({
      keyWord: e.target.value,
      pageIndex: 1,
    });
  };

  getData = () => {
    this.props.getTransactions({
      page: this.state.pageIndex,
      pageSize: this.state.pageSize,
      orderBy: this.state.sortColumn,
      orderDirection: this.state.order,
      search: this.state.keyWord,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  onChangePage = (data, value) => {
    this.setState({ pageIndex: value + 1 });
  };

  onChangeRowsPerPage = data => {
    this.setState({ pageSize: data.target.value });
  };

  onHandleRequestSort = (e, columnName) => {
    this.setState(prevState => {
      return {
        order: prevState.order === 'asc' ? 'desc' : 'asc',
        sortColumn: columnName,
      };
    });
  };

  onChangeFrom = e => {
    this.setState({ startDate: e.target.value });
  };

  onChangeTo = e => {
    this.setState({ endDate: e.target.value });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.order !== this.state.order ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.pageIndex !== this.state.pageIndex ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.keyWord !== this.state.keyWord ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.getData();
    }
  }

  render() {
    const { keyWord, order, sortColumn, pageIndex, pageSize } = this.state;
    const { t } = this.props;

    return (
      <Dashboard>
        <PageRow>
          <PageTitle title={t('Transactions')} />
        </PageRow>

        <PageRow>
          <PageSearch
            handleSearch={this.handleSearch}
            onChange={this.handleChangeSearch}
            value={keyWord}
          />

          <DatePickerForm
            onChangeFrom={this.onChangeFrom}
            onChangeTo={this.onChangeTo}
          />
        </PageRow>

        <BaseTable
          order={order}
          sortColumn={sortColumn}
          pageSize={pageSize}
          pageIndex={pageIndex}
          data={this.props.transactions}
          totalRecords={this.props.totalRecords}
          Row={TransactionRow}
          columnsData={constants.transactionTableColumns}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onHandleRequestSort={this.onHandleRequestSort}
        />
      </Dashboard>
    );
  }
}

export const AdminTransactions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_AdminTransactions);
