import React, { Fragment } from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import { ResetPassword } from 'ui/components/organisms/General/Login/ResetPassword';
import injectSheet from 'react-jss';
import { thunks } from '../../../../../services/thunks';
import { actions } from '../../../../../store/actions';
import { loginConfig } from '../../../../configs/formik/loginConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { LoadButton } from '../../../atoms/Commons/Client/LoadButton';
import { loginForm } from '../../../../styles/General/Login/loginForm';

const mapStateToProps = state => ({
  isLoading: state.getIn(['app', 'loading', 'submitButton']),
});

const mapDispatchToProps = (dispatch, { role }) => ({
  login: (...props) =>
    role === 'customer'
      ? dispatch(thunks.login(...props))
      : dispatch(thunks.loginDriver(...props)),
  openResetPassword: () => dispatch(actions.openModal.act('resetPassword')),
});

const _LoginForm = ({
  classes,
  errors,
  touched,
  openResetPassword,
  role,
  handleSubmit,
  isLoading,
}) => (
  <Fragment>
    <div className={classes.container}>
      <h2 className={classes.title}>Enter Information</h2>
      <Form className={classes.form}>
        <Field
          component={Input}
          type="text"
          name="email"
          placeholder="Email"
          labelText="Email"
          error={touched.email && errors.email}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <Field
          component={Input}
          type="password"
          name="password"
          placeholder="Password"
          labelText="Password"
          error={touched.password && errors.password}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <div
          role="presentation"
          onClick={openResetPassword}
          className={classes.resetPassword}
        >
          Forgot your password?
        </div><br/><br/>
        <div
          className={classes.signUpOnLogin}
        >
          Sign up for account <span style={{ color: 'blue'}}><a href="/signup">here</a></span>. No need to log in to <span style={{ color: 'blue'}}><a href="/">order!</a></span>
        </div>
        <LoadButton
          onClick={handleSubmit}
          className={classes.submit}
          isLoading={isLoading}
        >
          Log In
        </LoadButton>
        <div className={classes.backError}>{errors.backError}</div>
      </Form>
    </div>
    <ResetPassword role={role} />
  </Fragment>
);

export const LoginForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withFormik(loginConfig),
  injectSheet(loginForm),
)(_LoginForm);
