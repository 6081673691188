import React, { PureComponent } from 'react';
import { compose } from 'redux';
import moment from 'moment';
import { LoadButton } from 'ui/components/atoms/Commons/Client/LoadButton';
import { UserRoundIcon } from 'ui/components/atoms/icons/UserRoundIcon';
import { getTimeForCompletedOrders } from 'selectors/getTimeForCompletedOrders';
import injectSheet from 'react-jss';
import { completedOrderTableRow } from 'ui/styles/Driver/OrderBoard/completedOrderTableRow';

class _CompletedOrdersTableRow extends PureComponent {
  render() {
    const { classes, data, isMobile } = this.props;
    const time = getTimeForCompletedOrders(data.get('createdAt'));
    const finishedAt = moment(data.get('finishedAt')).fromNow();
    return (
      <div className={classes.container}>
        <UserRoundIcon className={classes.icon} />
        <div className={classes.time}>{time}</div>
        <div className={classes.intersection}>{data.get('intersection')}</div>
        <div className={classes.items}>
          {data.get('orderItems').map((item, index) => (
            <div
              key={`${item.get('name')}-${index + 1}`}
              className={classes.item}
            >
              <span>{item.get('quantity')}</span>
              <span>&#10005;</span>
              <span>{item.get('name')}</span>
            </div>
          ))}
        </div>
        <div className={classes.finishedAt}>{finishedAt}</div>
        {isMobile ? (
          <div className={classes.total}>
            <span>Total:</span>
            <span>{`$${data.get('total')}`}</span>
          </div>
        ) : (
          <div className={classes.price}>{`$${data.get('total')}`}</div>
        )}
        {/* TODO: Replace LoadButton to plain div with text */}
        <LoadButton disabled className={classes.accept}>
          {data.get('status')}
        </LoadButton>
      </div>
    );
  }
}

export const CompletedOrdersTableRow = compose(
  injectSheet(completedOrderTableRow),
)(_CompletedOrdersTableRow);
