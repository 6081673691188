import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const getProductsByAdmin = ({
  page = 1,
  pageSize = 10,
  search,
  orderBy = 'category',
  orderDirection,
} = {}) =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: '/product/list',
    data: {
      page,
      pageSize,
      search,
      orderBy,
      orderDirection,
    },
  });
