import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { withStyles, TableCell, Button } from '@material-ui/core';

import { actions } from '../../../../../store/actions';
import { thunks } from '../../../../../services/thunks';
import styles from '../../../../styles/Admin/RowActions';
import { ConfirmModal } from '../../../organisms/Admin/Commons/ConfirmModal';
import { AddReasonModal } from '../../../organisms/Admin/Commons/AddReasonModal';
import { AddEditDriver } from '../../../organisms/Admin/Driver/AddEditDriver';

const mapDispatchToProps = dispatch => ({
  deleteDriver: id => dispatch(thunks.deleteDriver(id)),
  blockDriver: data => dispatch(thunks.blockDriver(data)),
  suspendDriver: data => dispatch(thunks.suspendDriver(data)),
  activateDriver: id => dispatch(thunks.activateDriver(id)),
  editDriver: (id, data) => dispatch(thunks.editDriver(id, data)),
  getDriverById: id => dispatch(thunks.getDriverById(id)),
  clearSelectedDriver: () => dispatch(actions.setDriver.act(null)),
});

const mapStateToProps = state => ({
  selectedDriver: state.getIn(['app', 'selectedDriver']),
});

class _DriverActions extends Component {
  state = {
    action: null,
    isOpenConfirmModal: false,
    isOpenReasonModal: false,
    isOpenEditModal: false,
  };

  toggleModal = type => {
    this.setState(prevState => ({
      [type]: !prevState[type],
    }));
  };

  handleEdit = () => {
    this.props.getDriverById(this.props.id);
  };

  handleBlock = () => {
    this.setState({ action: 'block' });
    this.toggleModal('isOpenReasonModal');
  };

  handleSuspend = () => {
    this.setState({ action: 'suspend' });
    this.toggleModal('isOpenReasonModal');
  };

  handleDelete = () => {
    this.toggleModal('isOpenConfirmModal');
  };

  handleActivate = () => {
    this.props.activateDriver(this.props.id);
  };

  addReason = formData => {
    if (this.state.action === 'suspend') {
      this.props.suspendDriver({ id: this.props.id, reason: formData.reason });
    } else {
      this.props.blockDriver({ id: this.props.id, reason: formData.reason });
    }
    this.toggleModal('isOpenReasonModal');
  };

  closeEditModal = () => {
    this.props.clearSelectedDriver();
    this.toggleModal('isOpenEditModal');
  };

  submit = data => {
    this.props.editDriver(this.props.id, data);
    this.closeEditModal();
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.selectedDriver &&
      this.props.selectedDriver &&
      this.props.selectedDriver.id === this.props.id
    ) {
      this.toggleModal('isOpenEditModal');
    }
  }

  render() {
    const {
      classes: { button },
      id,
      t,
      deleteDriver,
      name,
      isBlocked,
      isSuspend,
      isActive,
      selectedDriver,
    } = this.props;

    const {
      isOpenConfirmModal,
      isOpenReasonModal,
      isOpenEditModal,
    } = this.state;

    return (
      <TableCell>
        <div>
          <Button
            variant="contained"
            disabled={isBlocked || isSuspend}
            className={button}
            onClick={this.handleEdit}
          >
            {t('Edit')}
          </Button>

          <Button
            variant="contained"
            className={button}
            onClick={this.handleBlock}
            disabled={isBlocked || isSuspend}
          >
            {t('Block')}
          </Button>
          {/*
            <Button
              variant="contained"
              className={button}
              disabled={isBlocked || isSuspend}
              onClick={this.handleSuspend}
            >
              {t('Suspend')}
            </Button>
          */}

          <Button
            variant="contained"
            className={button}
            onClick={this.handleDelete}
          >
            {t('Delete')}
          </Button>

          <Button
            variant="contained"
            className={button}
            onClick={this.handleActivate}
            disabled={isBlocked || isSuspend || isActive}
          >
            {t('Approve')}
          </Button>

          <Button
            variant="contained"
            className={button}
            onClick={this.handleActivate}
            disabled={/* isBlocked || */ isActive}
          >
            {t('Reactivate')}
          </Button>
          {isOpenConfirmModal && (
            <ConfirmModal
              id={id}
              isOpenModal={isOpenConfirmModal}
              confirm={deleteDriver}
              closeModal={() => this.toggleModal('isOpenConfirmModal')}
              actionText={`delete the driver ${name}?`}
            />
          )}

          {isOpenReasonModal && (
            <AddReasonModal
              id={id}
              addReason={this.addReason}
              closeModal={() => this.toggleModal('isOpenReasonModal')}
              isOpenModal={isOpenReasonModal}
              header="Indicate the reason for driver blocking"
            />
          )}

          {isOpenEditModal && (
            <AddEditDriver
              id={id}
              closeModal={this.closeEditModal}
              isOpenModal={isOpenEditModal}
              {...selectedDriver}
              submit={this.submit}
            />
          )}
        </div>
      </TableCell>
    );
  }
}

export const DriverActions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withStyles(styles),
)(_DriverActions);
