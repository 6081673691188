export const palette = {
  white: '#FFFFFF',
  black: '#000000',
  yellow: '#E0B317',
  grey500: '#444343',
  blue: '#1f30ff',
  red: '#EF3F44',
};

export const media = {
  mobile: '@media (max-width: 1200px)',
  desktop: '@media (min-width: 1200px)',
};

export const maxWidth = {
  mobile: '500px',
  desktop: '1200px',
};
