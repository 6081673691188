import React from 'react';

export const PromoCarIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 104 104">
    <path
      className="st1"
      d="M99.6,57.6c-0.2,0-0.3-0.1-0.3-0.3V45.5c0-3.5-2.3-6.5-5.4-7.6l-4.4-18c-1.7-7-6.9-11.7-12.9-11.7h-49   c-6,0-11.2,4.7-12.9,11.7l-4.4,18C7,39,4.7,42,4.7,45.5v11.8c0,0.2-0.1,0.3-0.3,0.3C2,57.6,0,59.6,0,62v16.5c0,2.5,2,4.5,4.5,4.5H8   v8.3c0,2.5,2,4.5,4.5,4.5h13.1c2.5,0,4.5-2,4.5-4.5v-8.3h44v8.3c0,2.5,2,4.5,4.5,4.5h13.1c2.5,0,4.5-2,4.5-4.5v-8.3h3.4   c2.5,0,4.5-2,4.5-4.5V62C104,59.6,102,57.6,99.6,57.6z M26,91.3c0,0.2-0.2,0.4-0.4,0.4H12.5c-0.2,0-0.4-0.2-0.4-0.4V83H26V91.3z    M92,91.3c0,0.2-0.2,0.4-0.4,0.4H78.5c-0.2,0-0.4-0.2-0.4-0.4V83H92V91.3z M18.5,20.9c1.2-5,4.9-8.6,9-8.6h49c4.1,0,7.8,3.5,9,8.6   l4,16.5H14.5L18.5,20.9z M4.4,61.7c2.4,0,4.4-2,4.4-4.4V45.5c0-2.2,1.8-4,4-4h78.6c2.2,0,4,1.8,4,4v11.8c0,2.4,2,4.4,4.4,4.4   c0.2,0,0.3,0.1,0.3,0.3v5.6H4.1V62C4.1,61.8,4.2,61.7,4.4,61.7z M99.5,78.9H4.5c-0.2,0-0.4-0.2-0.4-0.4v-6.8h95.9v6.8   C99.9,78.7,99.7,78.9,99.5,78.9z"
    />
    <path
      className="st1"
      d="M79.5,64.8c5.5,0,10-4.5,10-10s-4.5-10-10-10s-10,4.5-10,10S74,64.8,79.5,64.8z M79.5,48.8c3.3,0,6,2.7,6,6   s-2.7,6-6,6s-6-2.7-6-6S76.2,48.8,79.5,48.8z"
    />
    <path
      className="st1"
      d="M24.6,64.8c5.5,0,10-4.5,10-10s-4.5-10-10-10s-10,4.5-10,10S19,64.8,24.6,64.8z M24.6,48.8c3.3,0,6,2.7,6,6   s-2.7,6-6,6s-6-2.7-6-6S21.3,48.8,24.6,48.8z"
    />
    <path
      className="st1"
      d="M40.3,55.2h16.5c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H40.3c-1.1,0-2,0.9-2,2C38.2,54.3,39.1,55.2,40.3,55.2z"
    />
    <path
      className="st1"
      d="M63.9,58H47.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h16.5c1.1,0,2-0.9,2-2C65.9,58.9,65,58,63.9,58z"
    />
    <path
      className="st1"
      d="M63.9,55.3c1.1,0,2-0.9,2-2v-0.1c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v0.1C61.8,54.4,62.7,55.3,63.9,55.3z"
    />
    <path
      className="st1"
      d="M40.4,58.1c-1.1,0-2,0.9-2,2v0c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v0C42.4,59,41.5,58.1,40.4,58.1z"
    />
  </svg>
);
