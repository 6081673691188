import { api } from '../../api';
import { actions } from '../../../store/actions';
import { setErrorToStore } from 'services/utils';

export const getProducts = (deliveryZoneId) => async (dispatch) => {
  try {
    const [products, zones] = await Promise.all([
      api.getProducts(deliveryZoneId),
      api.getDeliveryZone(),
    ]);
    dispatch(
      actions.onLoadMainPage.act(
        products.data.reverse(),
        zones.data,
        deliveryZoneId,
      ),
    );
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
