export const dropDownTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#e0b3178f',
    primary50: '#e0b3178f',
    primary: '#E0B317',
    neutral20: '#444343',
    neutral30: 'black',
    neutral40: '#E0B317',
    neutral50: '#E0B317',
    neutral60: '#E0B317',
    neutral80: '#E0B317',
    neutral90: 'black',
  },
});

export const dropDownStyles = {
  container: provided => ({
    ...provided,
    gridArea: 'b',
    alignSelf: 'start',
  }),
  control: provided => ({
    ...provided,
    borderRadius: 0,
    cursor: 'pointer',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '3rem',
  }),
  menu: provided => ({
    ...provided,
    marginTop: 5,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  option: provided => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: '#444343',
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '500',
    color: '#E0B317',
  }),
  placeholder: provided => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: '#444343',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
};
