import { push } from 'connected-react-router';

import { actions } from 'store/actions';
import { thunks } from 'services/thunks';
import { getOrderStatus } from 'ui/constants';
import { removePendingOrder, removeActiveOrder, setActiveOrder } from 'utils';
import { getOrderWithSecret } from 'utils';

const handleForDriver = (store, message) => {
  const status = getOrderStatus(message.status);
  console.log('Driver', message, status);
  if (status === 'canceled') {
    removeActiveOrder();
    store.dispatch(actions.setData.act(['activeOrder'], null));
    store.dispatch(push('/orderboard'));
  }
};

const handleForCustomer = (store, message) => {
  const { orderId, secret } = message;
  const orderWithSecret = getOrderWithSecret(orderId, secret);
  const status = getOrderStatus(message.status);
  console.log(message, status);

  if (status === 'new') {
    console.log(`WebSocket: Set activeOrder ${orderId} to LS / Redux`);
    removePendingOrder();
    setActiveOrder(orderWithSecret);
    store.dispatch(actions.setData.act(['pendingOrder'], false));
    store.dispatch(actions.setData.act(['activeOrder'], orderWithSecret));
    store.dispatch(push(`/order/${orderWithSecret}`));
  }

  // TODO Замість надсилати запит получати його з ws
  if (status === 'accepted' || status === 'rejected') {
    store.dispatch(
      thunks.getCustomerSingleOrder(
        { params: { id: orderId } },
        () => {},
        () => {},
        secret,
      ),
    );
  }
  if (status === 'completed') {
    store.dispatch(actions.openModal.act('rateDriver'));
  }
  if (status === 'canceled' || status === 'refused' || status === 'expired') {
    const redirect =
      status !== 'canceled' ||
      orderId !== store.getState().getIn(['app', 'pendingOrder']);
    store.dispatch(thunks.removeActiveOrder(redirect));
  }
  if (status === 'refused') {
    store.dispatch(push('/'));
  }
};

export const orderStatusChanged = store => message => {
  const role = store.getState().getIn(['app', 'role']);
  if (role === 'driver') {
    handleForDriver(store, message);
  } else {
    handleForCustomer(store, message);
  }
};
