import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { id }) =>
  state.withMutations(next =>
    next
      .update('addresses', addresses =>
        addresses.filter(address => address.get('id') !== id),
      )
      .update('activeAddress', activeAddress =>
        activeAddress === id
          ? state.getIn(['addresses', 0, 'id'])
          : activeAddress,
      ),
  );

export const deleteAddress = createAction({
  action: ['deleteAddress', 'id'],
  handler,
});
