import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { thunks } from '../../../../services/thunks';
import { checkAge } from '../../../styles/General/CheckAge/checkAge';

const mapDispatchToProps = {
  acceptAgePolicy: thunks.acceptAgePolicy,
};

const _CheckAge = ({ classes, acceptAgePolicy, t }) => (
  <div className={classes.container}>
    <div className={classes.wrapper}>
      <NavLink to="/become-a-driver" className={classes.becomeDriver}>
        {t('Become a driver')}
      </NavLink>
      <h1 className={classes.boozApp}>Boo Zapp</h1>
      <hr className={classes.hr} />
      <h2 className={classes.welcome}>{t('Welcome')}</h2>
      <h2 className={classes.drinks}>{t('After Hours Drinks')}</h2>
      <h2 className={classes.hours}>
        <center>
          <strong>{t('Stay Safe! Stay Healthy!')}</strong>
        </center>
      </h2>
      <br />
      <p className={classes.question}>{t('Are you at least 19 years old?')}</p>
      <NavLink to="/underage" className={classes.buttonNo}>
        {t("No, I'm not 19")}
      </NavLink>
      <button
        type="button"
        onClick={acceptAgePolicy}
        className={classes.buttonYes}
      >
        {t("Yes, I'm over 19")}
      </button>
    </div>
  </div>
);

export const CheckAge = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces(),
  injectSheet(checkAge),
)(_CheckAge);
