import React, { Fragment, Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { thunks } from 'services/thunks';
import { actions } from 'store/actions';
import { RefuseOrderModal } from 'ui/components/molecules/Driver/Order/RefuseOrderModal';
import injectSheet from 'react-jss';
import { refuseOrder as refuseOrderStyles } from 'ui/styles/Driver/SingleOrder/refuseOrder';

const mapStateToProps = state => ({
  isRefuseOpen: state.getIn(['app', 'modals', 'refuseOrder']),
  isConfirmOpen: state.getIn(['app', 'modals', 'confirmAction']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refuseOrder: thunks.refuseOrder,
      openModal: actions.openModal.act,
      closeModal: actions.closeModal.act,
    },
    dispatch,
  );

class _RefuseOrder extends Component {
  openRefuseModal = () => {
    this.props.openModal('refuseOrder');
  };

  closeRefuseModal = () => {
    this.props.closeModal('refuseOrder');
  };

  openConfirmModal = () => {
    this.props.openModal('confirmAction');
  };

  closeConfirmModal = () => {
    this.props.closeModal('confirmAction');
  };

  render() {
    const {
      classes,
      isRefuseOpen,
      isConfirmOpen,
      id,
      refuseOrder,
    } = this.props;
    return (
      <Fragment>
        <button
          type="button"
          className={classes.button}
          onClick={this.openRefuseModal}
        >
          Refuse order
        </button>
        <RefuseOrderModal
          id={id}
          isOpen={isRefuseOpen}
          isConfirmOpen={isConfirmOpen}
          openConfirmModal={this.openConfirmModal}
          closeRefuseModal={this.closeRefuseModal}
          closeConfirmModal={this.closeConfirmModal}
          refuseOrder={refuseOrder}
        />
      </Fragment>
    );
  }
}

export const RefuseOrder = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(refuseOrderStyles),
)(_RefuseOrder);
