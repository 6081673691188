import React from 'react';
import { MapIcon } from 'ui/components/atoms/icons/MapIcon';
import { openNativeMap } from 'utils/googleMaps';
import injectSheet from 'react-jss';
import { openNativeMap as styles } from 'ui/styles/Driver/SingleOrder/openNativeMap';

const _OpenNativeMap = ({ classes, orderData }) => (
  <div
    className={classes.container}
    role="presentation"
    onClick={() => {
      openNativeMap(orderData.get('latitude'), orderData.get('longitude'));
    }}
  >
    <span>Map</span>
    <MapIcon className={classes.icon} />
  </div>
);

export const OpenNativeMap = injectSheet(styles)(_OpenNativeMap);
