import * as Yup from 'yup';

export const addressPostalCode = Yup.string().when('validDeliveryArea', {
  is: value => value,
  then: Yup.string().required(
    'Address is required. Please, start typing and select your address from suggestion list',
  ),
  otherwise: Yup.string().required(
    'Sorry, address not in delivery zone you selected. Please go back to menu (back button is above left) and select another zone.',
  ),
});
