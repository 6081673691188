import React, { PureComponent } from 'react';
import { InlineTimePicker } from 'material-ui-pickers';

export class TimePicker extends PureComponent {
  handleDateChange = date => {
    const { field, form, normalize } = this.props;
    form.setFieldValue(field.name, normalize ? normalize(date) : date);
  };

  render() {
    const { label, className, field } = this.props;
    return (
      <div className={className}>
        <InlineTimePicker
          ampm={false}
          variant="outlined"
          label={label}
          value={field.value}
          onChange={this.handleDateChange}
        />
      </div>
    );
  }
}
