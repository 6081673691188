import { privateAxios } from 'services/api/privateAxios';

export const editCustomerInfo = ({ firstName, lastName, phone, email }) =>
  privateAxios({
    method: 'post',
    url: '/customer/updateinfo',
    data: {
      firstName,
      lastName,
      email,
      phone,
    },
  });
