import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdminRoute } from 'ui/hoc/AdminRoute';
import { AdminSignIn } from 'ui/components/environments/Admin/AdminSignIn';
import { AdminCustomers } from 'ui/components/environments/Admin/AdminCustomers';
import { AdminChangePassword } from 'ui/components/environments/Admin/AdminChangePassword';
import { AdminDrivers } from 'ui/components/environments/Admin/AdminDrivers';
import { Products } from 'ui/components/environments/Admin/Products';
import { AddProduct } from 'ui/components/environments/Admin/AddProduct';
import { EditProduct } from 'ui/components/environments/Admin/EditProduct';
import { AdminOrders } from 'ui/components/environments/Admin/AdminOrders';
import { AdminTransactions } from 'ui/components/environments/Admin/AdminTransactions';
import { DeliveryZones } from 'ui/components/environments/Admin/DeliveryZones';
import { TimeSettings } from 'ui/components/environments/Admin/TimeSettings';
import { CssBaseline } from '@material-ui/core';
import { PageNotFound } from '../General/PageNotFound';

export const AdminShell = () => (
  <Fragment>
    <CssBaseline />
    <Switch>
      <Route exact path="/admin/signin" component={AdminSignIn} />
      <AdminRoute exact path="/admin/customers" component={AdminCustomers} />
      <AdminRoute exact path="/admin/drivers" component={AdminDrivers} />
      <AdminRoute exact path="/admin/orders" component={AdminOrders} />
      <AdminRoute exact path="/admin/products" component={Products} />
      <AdminRoute exact path="/admin/products/add" component={AddProduct} />
      <AdminRoute
        exact
        path="/admin/products/edit/:id"
        component={EditProduct}
      />
      <AdminRoute exact path="/admin/deliveryzones" component={DeliveryZones} />
      <AdminRoute exact path="/admin/time-settings" component={TimeSettings} />
      <AdminRoute
        exact
        path="/admin/transactions"
        component={AdminTransactions}
      />
      <AdminRoute
        exact
        path="/admin/changepassword"
        component={AdminChangePassword}
      />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </Fragment>
);
