import { palette } from '../../theme';

export const tooltipPriceList = () => ({
  tooltipPrices: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 4,
    marginLeft: 4,
  },
  quantity: {
    color: palette.grey500,
    opacity: 0.6,
    fontSize: 12,
  },
});
