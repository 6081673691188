import { privateAxios } from 'services/api/privateAxios';

export const editDriverInfo = ({
  firstName,
  lastName,
  phone,
  email,
  driverLicense,
  smartserve,
  checkCertification,
}) =>
  privateAxios({
    method: 'post',
    url: '/driver/updateinfo',
    data: {
      firstName,
      lastName,
      email,
      phone,
      driverLicense,
      smartserve,
      checkCertification,
    },
  });
