import get from 'lodash/get';
import { api } from '../../api';
import { actions } from '../../../store/actions';

export const editDriverInfo = (
  values,
  toggleEdit,
  setErrors,
  setSubmitting,
) => async dispatch => {
  try {
    await api.editDriverInfo(values);
    if (values.oldPassword) {
      await api.changePassword(values);
    }
    localStorage.setItem('firstName', values.firstName);
    localStorage.setItem('lastName', values.lastName);
    dispatch(actions.editDriverInfo.act(values));
    toggleEdit();
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
