const styles = theme => ({
  paper: {
    padding: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  childWrap: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

export default styles;
