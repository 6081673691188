import { input } from '../../Commons/input';
import { button } from '../../Commons/button';
import { backstage } from '../../Commons/backstage';
import { media } from '../../theme';

export const emailWhenAreaSupported = {
  backstage,
  container: {
    width: '90%',
    height: '365px',
    maxWidth: '400px',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    gridTemplateRows: '15% 15% 50% auto',
    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. b .'
      '. c .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    [media.desktop]: {
      width: '630px',
      maxWidth: '630px',
      gridTemplateColumns: '15% 70% 15%',
      gridTemplateRows: '15% 17% 40% auto',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'start',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    [media.desktop]: {
      fontSize: '1.75rem',
      textTransform: 'none',
    },
  },
  form: {
    gridArea: 'b',
    alignSelf: 'start',
  },
  inputContainer: { ...input.container },
  inputLabel: { ...input.label },
  input: { ...input.input },
  inputError: { ...input.error },
  cancel: {
    ...button.white,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'start',
  },
  ok: {
    ...button.yellow,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'end',
  },
};
