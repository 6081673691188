import React, { Component } from 'react';

import { compose } from 'redux';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';

import { withStyles, Button } from '@material-ui/core';

import { changePasswordConfig } from '../../../../configs/formik/changePasswordConfig';

import { InputField } from '../../../atoms/Commons/Admin/InputField';
import { Snackbar } from '../../../atoms/Commons/Admin/Snackbar';

import styles from '../../../../styles/Admin/LoginForm';

class _ChangePasswordForm extends Component {
  handleClose = () => this.props.setErrors({ backError: null });

  render() {
    const {
      classes: { inputWrap, submitWrap, submit },
      touched,
      t,
      errors,
      errors: { backError },
    } = this.props;

    return (
      <Form>
        <div className={inputWrap}>
          <Field
            name="oldPassword"
            component={InputField}
            type="password"
            labelText={t('Current Password')}
            error={touched.oldPassword && errors.oldPassword}
          />
        </div>

        <div className={inputWrap}>
          <Field
            name="newPassword"
            component={InputField}
            type="password"
            labelText={t('New Password')}
            error={touched.newPassword && errors.newPassword}
          />
        </div>

        <div>
          <Field
            name="newPasswordConfirm"
            component={InputField}
            type="password"
            labelText={t('Confirm Password')}
            error={touched.newPasswordConfirm && errors.newPasswordConfirm}
          />
        </div>

        <div className={submitWrap}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={submit}
          >
            {t('Save')}
          </Button>
        </div>

        <Snackbar
          open={!!backError}
          onClose={this.handleClose}
          onClick={this.handleClose}
          message={backError}
        />
      </Form>
    );
  }
}

export const ChangePasswordForm = compose(
  withNamespaces('admin'),
  withFormik(changePasswordConfig),
  withStyles(styles),
)(_ChangePasswordForm);
