export const multiSelectField = {
  creatableSelect: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    marginTop: '2rem',
  },
  error: {
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: '1rem',
  },
};

export const multiSelectStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '50px',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: provided => ({
    ...provided,
  }),
  option: provided => ({
    ...provided,
  }),
  singleValue: provided => ({
    ...provided,
  }),
  placeholder: provided => ({
    ...provided,
    fontWeight: 300,
  }),
  indicatorSeparator: styles => ({
    ...styles,
  }),
};
