import webBackground from '../../../images/BG_splash_screens_web.png';
import mobileBackground from '../../../images/Background_splash_screen_mobile.png';
import { palette, media } from '../../theme';

export const underage = {
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    background: `url(${mobileBackground}) no-repeat center `,
    backgroundSize: '100% 100%',
    '@media screen and (min-width: 500px)': {
      background: `url(${webBackground}) no-repeat center `,
      backgroundSize: '1920px 1080px',
    },
    [media.desktop]: {
      backgroundSize: '100% 100%',
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1rem 4rem 6rem 2rem 4rem 3rem 5rem auto',
    gridTemplateAreas: `
      '.'
      'a'
      'b'
      'c'
      'd'
      'e'
      'f'
      'g'
    `,
    [media.desktop]: {
      maxWidth: '1000px',
      gridTemplateRows: '2% 9% 24% 6% 12% 11% 12% auto',
    },
  },
  boozApp: {
    gridArea: 'b',
    justifySelf: 'center',
    fontFamily: 'Righteous, cursive',
    fontSize: '3rem',
    textTransform: 'uppercase',
    color: palette.yellow,
    margin: 0,
    [media.desktop]: {
      fontSize: '7.5rem',
      textTransform: 'none',
      fontWeight: '400',
    },
  },
  hr: {
    gridArea: 'c',
    justifySelf: 'center',
    width: '90%',
    border: 'none',
    height: '1px',
    backgroundColor: palette.white,
    margin: 0,
    [media.desktop]: {
      width: '70%',
    },
  },
  sorry: {
    gridArea: 'd',
    justifySelf: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '2rem',
    fontWeight: '500',
    color: palette.white,
    margin: 0,
    textTransform: 'uppercase',
    [media.desktop]: {
      fontSize: '4.125rem',
    },
  },
  explanation: {
    gridArea: 'e',
    justifySelf: 'center',
    width: '80%',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
    fontWeight: '300',
    color: palette.white,
    textTransform: 'uppercase',
    margin: 0,
    textAlign: 'center',
    lineHeight: '1.5rem',
    [media.desktop]: {
      fontSize: '2.25rem',
      width: '110%',
      lineHeight: '3rem',
    },
  },
};
