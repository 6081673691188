import React, { useState, useCallback, useRef } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import AsyncPaginate from 'react-select-async-paginate';
import defaultReduceOptions from 'react-select-async-paginate/lib/default-reduce-options';
import { Paper, Divider } from '@material-ui/core';
import injectSheet from 'react-jss';
import get from 'lodash/get';

import { ModalWithButtons } from 'ui/components/organisms/Admin/Commons/ModalWithButtons';
import { api } from 'services/api/index';
import { actions } from 'store/actions';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import { TimeSettingsForm } from 'ui/components/ecosystems/Admin/TimeSettings/TimeSettingsForm';
import { thunks } from 'services/thunks';
import { Dashboard } from 'ui/components/environments/Admin/Dashboard';
import { PageRow } from 'ui/components/atoms/Commons/Admin/PageRow';
import { PageTitle } from 'ui/components/atoms/Commons/Admin/PageTitle';
import { getScheduleFormValues } from 'selectors/getScheduleFormValues';
import styles from 'ui/styles/Admin/TimeSettings';
import { multiSelectStyles } from 'ui/styles/Admin/commons/select';

const modalName = 'saveTimeSettings';

const mapStateToProps = state => ({
  schedule: getScheduleFormValues(state),
  isOpenModal: state.getIn(['app', 'modals', modalName]),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSchedule: thunks.getSchedule,
      editSchedule: thunks.editSchedule,
      openModal: actions.openModal.act,
      closeModal: actions.closeModal.act,
    },
    dispatch,
  );

const loadZones = async (search, _prevOptions, { currentPage }) => {
  const {
    data: { items, page, hasNext },
  } = await api.getDeliveryZones({
    page: currentPage + 1,
    pageSize: 20,
    search,
    orderBy: 'name',
  });

  return {
    options: items,
    hasMore: hasNext,
    additional: { page },
  };
};

const _TimeSettings = ({
  t,
  isLoading,
  schedule,
  editSchedule,
  classes,
  getSchedule,
  startLoading,
  endLoading,
  openModal,
  closeModal,
  isOpenModal,
}) => {
  const [zone, setZone] = useState();
  const [newZone, setNewZone] = useState();
  const formRef = useRef(false);
  const onZoneChanged = useCallback(
    newZone => {
      if (get(newZone, 'id') === get(zone, 'id')) {
        return;
      }
      if (formRef.current.dirty) {
        setNewZone(newZone);
        openModal(modalName);
        return;
      }
      if (newZone) {
        getSchedule({ deliveryZoneId: newZone.id, startLoading, endLoading });
      }
      setZone(newZone);
    },
    [
      zone,
      setZone,
      newZone,
      setNewZone,
      getSchedule,
      endLoading,
      formRef,
      openModal,
    ],
  );

  const save = useCallback(
    () => {
      formRef.current.submitForm();
      closeModal(modalName);
    },
    [formRef, closeModal],
  );

  const onSave = useCallback(
    () => {
      if (newZone) {
        onZoneChanged(newZone);
        setNewZone(null);
      }
    },
    [newZone, setNewZone, onZoneChanged],
  );

  const reduceOptions = useCallback(
    (oldOption, newOptions, additional) => {
      // set initial zone
      if (!zone) {
        onZoneChanged(get(newOptions, 0));
      }
      return oldOption
        ? defaultReduceOptions(oldOption, newOptions, additional)
        : newOptions;
    },
    [zone, onZoneChanged],
  );

  return (
    <Dashboard>
      <PageRow>
        <PageTitle title={t('Time Settings')} />
      </PageRow>
      <div className={classes.container}>
        <Paper className={classes.card}>
          <header className={classes.header}>
            <div className={classes.wrapper}>
              <div className={classes.description}>
                <span>Delivery Zone</span>
              </div>
              <AsyncPaginate
                className={classes.select}
                styles={multiSelectStyles}
                getOptionLabel={x => x.name}
                getOptionValue={x => x.id.toString()}
                defaultOptions
                additional={{ currentPage: 0 }}
                debounceTimeout={300}
                value={zone}
                loadOptions={loadZones}
                reduceOptions={reduceOptions}
                onChange={onZoneChanged}
              />
            </div>
          </header>
          {zone && !isLoading && (
            <>
              <Divider className={classes.divider} />
              <TimeSettingsForm
                formRef={formRef}
                deliveryZoneId={zone.id}
                schedule={schedule}
                editSchedule={editSchedule}
                onSave={onSave}
              />
              <ModalWithButtons
                isOpen={isOpenModal}
                onClose={() => closeModal(modalName)}
                onClick={save}
              >
                <h1>Submit unsaved changes?</h1>
              </ModalWithButtons>
            </>
          )}
        </Paper>
      </div>
    </Dashboard>
  );
};

export const TimeSettings = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
  withCommonLoading(),
  injectSheet(styles),
)(_TimeSettings);
