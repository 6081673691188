import { media } from '../../theme';

export const address = {
  container: {
    width: '85%',
    maxWidth: '500px',
    height: ({ status, height }) =>
      status === 'entering' || status === 'entered' ? '28rem' : height,
    marginTop: '1rem',
    transition: '150ms ease-out',
    border: '1px solid #D7D7D7',
    display: 'flex',
    boxShadow: ({ status }) =>
      status === 'entering' || status === 'entered'
        ? '0 7px 20px 2px rgba(0,0,0,0.21)'
        : 'none',
    [media.desktop]: {
      width: '850px',
      maxWidth: '850px',
      marginTop: '2rem',
    },
  },
};
