export const confirmModal = {
  title: {
    fontFamily: 'Roboto',
    fontSize: '1.75rem',
    fontWeight: 500,
    marginTop: '3rem',
    textAlign: 'center',
    width: '80%',
    lineHeight: '2.5rem',
  },
};
