import { button } from 'ui/styles/Commons/button';
import { media } from '../../theme';

export const checkPhonePopup = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',

    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. b .'
      '. c .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    fontFamily: 'Roboto',
    [media.mobile]: {
      width: '90%',
      height: '250px',
      maxWidth: '400px',
      gridTemplateRows: '15% 15% 48% auto',
    },
    [media.desktop]: {
      width: '630px',
      height: '350px',
      gridTemplateColumns: '15% 70% 15%',
      gridTemplateRows: '15% 25% 30% auto',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'start',
    fontSize: '1.4rem',
    textAlign: 'center',
    fontWeight: 500,
    [media.desktop]: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
    },
  },
  text: {
    gridArea: 'b',
    width: '80%',
    alignSelf: 'start',
    justifySelf: 'center',
    fontWeight: 300,
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    [media.desktop]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  cancel: {
    ...button.white,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'start',
  },
  ok: {
    ...button.yellow,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'end',
  },
  singleButton: {
    ...button.yellow,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'center',
  },
  backError: {
    gridArea: 'c',
    marginTop: '3rem',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
