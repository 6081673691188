import React from 'react';

import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { withStyles, Grid, Typography } from '@material-ui/core';

import { LoginForm } from '../../organisms/Admin/Login/LoginForm';
import styles from '../../../styles/Admin/AdminSignIn';

const role = 'admin';

const _AdminSignIn = ({ classes, t }) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignContent="center"
    classes={{ container: classes.wrap }}
  >
    <Grid item xs={12}>
      <Typography classes={{ root: classes.title }} component="h1" variant="h1">
        {t('Sign in')}
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={10} sm={6} md={3}>
          <LoginForm role={role} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export const AdminSignIn = compose(
  withNamespaces('admin'),
  withStyles(styles),
)(_AdminSignIn);
