export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover': {
      backgroundColor: '#00000010',
    },
  },
  label: {
    margin: 0,
  },
  input: {
    margin: '0 16px',
    width: 140,
  },
};
