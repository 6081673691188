import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

export const getCustomerById = id => async dispatch => {
  try {
    const response = await api.getCustomerById(id);
    dispatch(actions.setCustomer.act({ ...response.data, id }));
    dispatch(actions.openModal.act(`editCustomer-${id}`, null, false));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
