import { media } from '../../theme';

export const spirits = {
  container: {
    width: '100%',
    maxWidth: '500px',
    marginTop: '3.75rem',
    borderBottom: '1px solid #E1E1E1',
    '&:last-child': {
      marginBottom: '3.75rem',
    },
    '& > h3': {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '1.75rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: '3rem',
    },
    [media.desktop]: {
      width: '1100px',
      maxWidth: '1100px',
    },
  },
  products: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
};
