import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { id }) =>
  state.updateIn(['tables', 'drivers', 'items'], drivers =>
    drivers.filter(el => el.get('id') !== id),
  );

export const deleteDriver = createAction({
  action: ['deleteDriver', 'id'],
  handler,
});
