import * as Yup from 'yup';

export const confirmNewPassword = Yup.string().when('newPassword', {
  is: value => value,
  then: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords should match')
    .min(8, 'Password should be more than 8 symbols')
    .max(15, 'Password should be less than 15 symbols')
    .required('Password is required'),
});
