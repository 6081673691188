import colors from '../colors';

const styles = () => ({
  link: {
    textDecoration: 'none',
  },
  activeLink: {
    backgroundColor: 'rgba(0,0,0, 0.3)',
  },
  text: {
    color: colors.light,
  },
});

export default styles;
