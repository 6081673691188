import React from 'react';
import injectSheet from 'react-jss';
import { withContainer as withContainerStyle } from 'ui/styles/Commons/withContainer';

export const withContainer = () => WrappedComponent => {
  const WithContainer = ({ classes, ...props }) => (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <WrappedComponent {...props} />
      </div>
    </div>
  );
  return injectSheet(withContainerStyle)(WithContainer);
};
