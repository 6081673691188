import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getOrders = ({ ...data }) => async dispatch => {
  try {
    const response = await api.getOrders(data);
    dispatch(actions.setOrders.act(response.data));
  } catch (e) {
    return e;
  }
};
