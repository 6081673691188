import React from 'react';

export const Input = ({
  field: { name, value, onChange, onBlur },
  type,
  labelText,
  error,
  placeholder,
  $container, // for compatibility
  $label, // for compatibility
  $input, // for compatibility
  $error, // for compatibility
  required,
  classes = {
    container: $container,
    label: $label,
    input: $input,
    error: $error,
  },
}) => (
  <div className={classes.container}>
    {labelText && (
      <label className={classes.label} htmlFor={name}>
        {labelText}
        {required && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
      </label>
    )}
    <input
      id={name}
      className={classes.input}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
    />
    {error && <span className={classes.error}>{error}</span>}
  </div>
);
