import { api } from '../../api';
import { actions } from '../../../store/actions';

export const getCustomerProfile = () => async dispatch => {
  try {
    const info = await api.getCustomerInfo();
    dispatch(actions.setCustomerInfo.act(info.data));
    const addresses = await api.getCustomerAddresses();
    dispatch(actions.setCustomerAddresses.act(addresses.data));
  } catch (err) {
    return err;
  }
};
