const styles = theme => ({
  root: {
    flexShrink: 0,
  },
  page: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
});

export default styles;
