import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { thunks } from 'services/thunks';
import { actions } from 'store/actions';
import { LoadButton } from 'ui/components/atoms/Commons/Client/LoadButton';
import { ValidateOrderModal } from 'ui/components/molecules/Customer/Main/ValidateOrderModal';
import { withCommonLoading } from 'ui/hoc/withCommonLoading';
import injectSheet from 'react-jss';
import { getOrderList } from '../../../../selectors/getOrderList';
import { orderSummary } from '../../../styles/Customer/CustomerHome/orderSummary';
import ReactGA from "react-ga";	
import { Event } from 'ui/components/tracking';
import { formatPrice } from '../../../../utils';

const mapStateToProps = state => ({
  orderList: getOrderList(state),
  isValidateOrder: state.getIn(['app', 'modals', 'validateOrderError']),
  validateError: state.getIn(['app', 'validateError']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      validateOrder: thunks.validateOrder,
      closeModal: actions.closeModal.act,
    },
    dispatch,
  );

const _OrderSummary = ({
  classes,
  orderList: { items, total },
  isLoading,
  startLoading,
  endLoading,
  validateOrder,
  isValidateOrder,
  closeModal,
  validateError,
}) => {
  if (items.length === 0) return null;
  return (
    <Fragment>
      <div className={classes.container}>
        <span className={classes.title}>Order Summary</span>
        {items.map(item => (
          <div key={item.name} className={classes.itemRow}>
            <span>{item.quantity}</span>
            <span>{item.name}</span>
            <span>{item.unit}</span>
            <span>{formatPrice(item.price)}</span>
          </div>
        ))}
        <div className={classes.paymentRow}>
          <div className={classes.total}>
            <span>Total:</span>
            <span>{formatPrice(total)}</span>
            <span style={{ fontSize: '9pt' }}>(Free Delivery)</span>
          </div>
        </div>
        <LoadButton
          isLoading={isLoading}
          className={classes.submit}
          onClick={() => { Event("Conversion", "User clicked submit on order summary page", "Submit_Order"); validateOrder(startLoading, endLoading)}}
        >
          Checkout
        </LoadButton>
      </div>
      <ValidateOrderModal
        isOpen={isValidateOrder}
        error={validateError}
        close={() => closeModal('validateOrderError')}
      />
    </Fragment>
  );
};

export const OrderSummary = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCommonLoading({ initLoading: false }),
  injectSheet(orderSummary),
)(_OrderSummary);
