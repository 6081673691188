import React, { PureComponent } from 'react';
import { StaticAddressData } from 'ui/components/molecules/Customer/CustomerProfile/StaticAddressData';
import injectSheet from 'react-jss';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { EditAddressForm } from '../../../organisms/Customer/Profile/EditAddressForm';
import { address } from '../../../../styles/Customer/CustomerProfile/address';
import { thunks } from '../../../../../services/thunks';
import { actions } from '../../../../../store/actions';

const mapStateToProps = (state, { id }) => ({
  isActive: state.getIn(['app', 'activeAddress']) === id,
});

const mapDispatchToProps = (dispatch, { admin }) =>
  bindActionCreators(
    {
      editCustomerAddress: admin
        ? thunks.adminEditAddress
        : thunks.editCustomerAddress,
      deleteAddress: admin ? thunks.adminDeleteAddress : thunks.deleteAddress,
      geocodeAddress: thunks.geocodeAddress,
      selectAddress: actions.selectAddress.act,
    },
    dispatch,
  );

class _Address extends PureComponent {
  componentDidMount() {
    this.props.setAddressHeight(this.address.getBoundingClientRect().height);
  }

  deleteAddress = e => {
    e.stopPropagation();
    const { deleteAddress, data } = this.props;
    deleteAddress(data.get('id'));
  };

  selectAddress = () => {
    const { selectAddress, data } = this.props;
    selectAddress(data.get('id'));
  };

  render() {
    const {
      classes,
      data,
      toggleEdit,
      status,
      editCustomerAddress,
      enableDelete,
      canSelect,
      isActive,
      geocodeAddress,
    } = this.props;
    return (
      <div
        ref={instance => (this.address = instance)}
        className={classes.container}
      >
        {status === 'exited' && (
          <StaticAddressData
            toggleEdit={toggleEdit}
            address={data.get('address')}
            nearestIntersection={data.get('nearestIntersection')}
            instructions={data.get('instructions')}
            status={status}
            enableDelete={enableDelete}
            deleteAddress={this.deleteAddress}
            selectAddress={this.selectAddress}
            canSelect={canSelect}
            isActive={isActive}
          />
        )}
        {status === 'entered' && (
          <EditAddressForm
            address={data.get('address')}
            postalCode={data.get('postalCode')}
            latitude={data.get('latitude')}
            longitude={data.get('longitude')}
            nearestIntersection={data.get('nearestIntersection')}
            instructions={data.get('instructions')}
            boozer={data.get('boozer')}
            id={data.get('id')}
            status={status}
            toggleEdit={toggleEdit}
            editCustomerAddress={editCustomerAddress}
            geocodeAddress={geocodeAddress}
          />
        )}
      </div>
    );
  }
}

export const Address = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(address),
)(_Address);
