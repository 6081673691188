import colors from '../colors';

export const customerRow = () => ({
  wrap: {
    minWidth: '250px',
  },
  debtCell: {
    color: colors.darkMainTheme,
  },
  postalCodes: {
    lineHeight: '20px',
  },
});
