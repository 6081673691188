import React, { Fragment } from 'react';
import { Element, scroller } from 'react-scroll';
import injectSheet from 'react-jss';
import { OrderListIcon } from '../../icons/OrderListIcon';
import { scrollToOrderList } from '../../../../styles/Customer/CustomerHome/scrollToOrderList';

import shoppingCart from '../../../../images/icon_shopping-cart.png';
import { palette } from '../../../../styles/theme';


class _ScrollTo extends React.Component {
  scrollTo = () => {
    scroller.scrollTo('orderList', {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOut',
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.placeOrderText}
        style={{
          position: 'fixed',
          bottom: '4%',
          background: palette.yellow,
          borderRadius: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '90%',
          width: '500px',
          zIndex: '10001'
        }}>
        	<a href='#spirits' className={classes.linkToProducts}>Spirits</a>
        	<a href='#beer' className={classes.linkToProducts}>Beer</a>
        	<a href='#wine' className={classes.linkToProducts}>Wine</a>
          <a href="#orderList" className={classes.linkToProducts} style={{
            borderLeft: '1px solid #fff',
          }}> <img 
                        src={shoppingCart} 
                        width={24}
                        style={{
                        marginRight: '8px'
                      }}></img>
                      </a>
        </div>
       {/* <a href="#orderList"> 
        <div
          role="presentation"
          className={classes.orderList}
        >
          <OrderListIcon className={classes.icon} />  
        </div>
        </a> */}
        <Element name="orderList" />
      </Fragment>
    );
  }
}

export const ScrollTo = injectSheet(scrollToOrderList)(_ScrollTo);
