import { fromJS, Map } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.set(
    'products',
    Map({
      categoryList: fromJS(data.map(category => category.id)),
      categoryMap: data.reduce(
        (prev, category) =>
          prev.set(
            category.id,
            Map({
              id: category.id,
              name: category.name,
              itemList: fromJS(category.items.map(item => item.id)),
            }),
          ),
        Map({}),
      ),
      items: data.reduce((prev, category) => {
        const categoryItems = category.items.reduce((pre, item) => {
          const existedItem = state.getIn(['products', 'items', item.id]);
          const currentQuantity =
            existedItem && existedItem.get('currentQuantity');
          const activeOption =
            existedItem && existedItem.get('currentQuantity');
          return pre.set(
            item.id,
            fromJS(item)
              .set('currentQuantity', currentQuantity || 0)
              .update('productPrices', list =>
                list.size > 1
                  ? list.unshift(
                      Map({ quantity: 0, price: list.getIn([0, 'price']) }),
                    )
                  : list,
              )
              .set('activeOption', activeOption || 0),
          );
        }, Map({}));
        return prev.merge(categoryItems);
      }, Map({})),
    }),
  );

export const setProducts = createAction({
  action: ['setProducts', 'data'],
  handler,
});
