export const numericInputStyle = {
  'input:not(.form-control)': {
    border: false,
    paddingLeft: false,
  },
  btn: {
    background: false,
    boxShadow: false,
    borderStyle: false,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    right: 3,
  },
  'btn:hover': {
    background: false,
    borderColor: 'rgba(0, 0, 0, 6)',
  },
  'btn:active': {
    background: false,
    boxShadow: false,
    borderColor: 'rgba(0, 0, 0, 0.3)',
  },
  arrowUp: {
    top: '65%',
    borderColor: 'transparent',
    borderBottomColor: 'inherit',
  },
  arrowDown: {
    top: '35%',
    borderColor: 'transparent',
    borderTopColor: 'inherit',
  },
};

export const styles = {
  root: {
    '& .react-numeric-input': {
      width: '100%',
    },
  },
};
