import { commonApi } from 'services/api/common';
import { publicApi } from 'services/api/public';
import { adminApi } from 'services/api/admin';
export { errorCodes } from './errorCodes';

export const api = {
  ...commonApi,
  ...publicApi,
  ...adminApi,
};
