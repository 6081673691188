import { api } from 'services/api';
import { actions } from 'store/actions';

export const getInventory = endLoading => async dispatch => {
  try {
    const response = await api.getInventory();
    dispatch(actions.setInventory.act(response.data));
    const deliveryZone = await api.getDeliveryZone();
    dispatch(actions.setDeliveryZone.act(deliveryZone.data));
    endLoading();
  } catch (e) {
    return e;
  }
};
