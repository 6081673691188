import { input } from '../../Commons/input';
import { button } from '../../Commons/button';
import { media } from '../../theme';

export const resetPassword = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',

    gridTemplateAreas: `
      '. . .'
      '. a .'
      '. b .'
      '. c .'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    [media.mobile]: {
      width: '90%',
      height: '250px',
      maxWidth: '400px',
      gridTemplateRows: '15% 21% 38% auto',
    },
    [media.desktop]: {
      width: '630px',
      height: '350px',
      gridTemplateColumns: '15% 70% 15%',
      gridTemplateRows: '15% 30% 25% auto',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'start',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    textAlign: 'center',
    [media.desktop]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  form: {
    gridArea: 'b',
    justifySelf: 'center',
    alignSelf: 'start',
    [media.mobile]: {
      width: '100%',
    },
    [media.desktop]: {
      width: '75%',
    },
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: '100%',
      gridTemplateRows: '2.25rem auto',
      gridTemplateAreas: `
        'b'
        'c'
      `,
    },
  },
  inputLabel: { ...input.label },
  input: { ...input.input },
  inputError: { ...input.error },
  cancel: {
    ...button.white,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'start',
  },
  ok: {
    ...button.yellow,
    gridArea: 'c',
    alignSelf: 'start',
    justifySelf: 'end',
  },
  backError: {
    gridArea: 'c',
    marginTop: '3rem',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
