import React, { Component } from 'react';
import moment from 'moment';
import { TableRow, TableCell } from '@material-ui/core';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

class _TransactionRow extends Component {
  render() {
    const {
      item: { id, firstName, lastName, createdAt, amount },
    } = this.props;

    return (
      <TableRow>
        <TableCell>{`${firstName || ''} ${lastName || ''}`}</TableCell>
        <TableCell>
          {createdAt && moment(createdAt).format('YYYY-MM-DD')}
        </TableCell>
        <TableCell>{`${amount} $`}</TableCell>
      </TableRow>
    );
  }
}

const TransactionRow = compose(withNamespaces('admin'))(_TransactionRow);

export default TransactionRow;
