import { button } from 'ui/styles/Commons/button';
import { media, palette } from 'ui/styles/theme';

export const anonymousOrder = {
  title: {
    fontFamily: 'Roboto',
    fontWeight: '300',
    [media.mobile]: {
      fontSize: '1rem',
      margin: '1.5rem 0 1rem 0',
    },
    [media.desktop]: {
      margin: '3rem 0',
      fontSize: '1.375rem',
    },
  },
  linkToLogin: {
    color: '#E0B317',
    fontWeight: 500,
  },
  title2: {
    fontFamily: 'Roboto',
    fontSize: '1.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '2rem',
    [media.mobile]: {
      margin: '2rem 0',
      fontSize: '1.5rem',
    },
  },
  submit: {
    ...button.yellow,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem auto 2rem auto',
  },
  resendLink: {
    fontFamily: 'Roboto',
    color: palette.yellow,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '4rem',
  },
};
