import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { id }) =>
  state.updateIn(['tables', 'deliveryZones', 'items'], zones =>
    zones.filter(el => el.get('id') !== id),
  );

export const deleteDeliveryZone = createAction({
  action: ['deleteDeliveryZone', 'id'],
  handler,
});
