export const addAddress = {
  container: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: '2rem',
    cursor: 'pointer',
  },
  icon: {
    height: '1.5rem',
    marginRight: '1rem',
  },
};
