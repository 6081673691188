import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSheet from 'react-jss';
import { getOrderList } from '../../../../../selectors/getOrderList';
import { orderSummary } from '../../../../styles/Customer/CustomerHome/orderSummary';
import { palette } from '../../../../styles/theme';
import { formatPrice } from '../../../../../utils';

const mapStateToProps = state => ({
  orderList: getOrderList(state),
});

const _OrderSummaryList = ({ classes, orderList: { items, total } }) => (
  <div className={classes.container}>
    {items.map(item => (
      <div key={item.name} className={classes.itemRow}>
        <span>{item.quantity}</span>
        <span>{item.name}</span>
        <span>{item.unit}</span>
        <span>{formatPrice(item.price)}</span>
      </div>
    ))}
    <div className={classes.paymentRow}>
      <div className={classes.payment} style={{
        textTransform: 'uppercase',
        textAlign: 'center'
      }}>
        <div>Payment Method:&nbsp;
        <span style={{
          color: palette.yellow,
          fontWeight: 'bold'
        }}>Cash or E-Transfer</span>
        <br />
        <span style={{
          color: palette.yellow,
          fontWeight: 'normal',
          fontSize: '10pt',
        }}>
        (contact driver <strong>after</strong> order is accepted)
        </span>
        </div>
      </div>
      <div className={classes.total}>
        <span>Total:</span>
        <span>{formatPrice(total)}</span>
      </div>
    </div>
  </div>
);

export const OrderSummaryList = compose(
  connect(mapStateToProps),
  injectSheet(orderSummary),
)(_OrderSummaryList);
