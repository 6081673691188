import axios from 'axios';
import qs from 'qs';
import { commonAPI } from 'services/api/apiUrl';
import { getRefreshToken } from 'services/utils';

export const refreshToken = () =>
  axios({
    method: 'post',
    url: `${commonAPI}/auth/token`,
    data: qs.stringify({
      access_scope: 'offline',
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken(),
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
