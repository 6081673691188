import React from 'react';
import { compose } from 'redux';
import injectSheet from 'react-jss';
import { orderItemsList } from 'ui/styles/Customer/SingleOrderPage/orderItemsList';
import { formatPrice } from '../../../../../utils';

const _OrderItemsList = ({ classes, orderData }) => (
  <div className={classes.container}>
    {orderData.get('items') &&
      orderData.get('items').map((item, index) => (
        <div
          key={`${item.get('name')}-${index + 1}`}
          className={classes.itemRow}
        >
          <span>{item.get('quantity')}</span>
          <span>{item.get('name')}</span>
          <span>{item.get('unit')}</span>
          <span>{formatPrice(item.get('totalPrice'))}</span>
        </div>
      ))}
    <div className={classes.paymentRow}>
      <div className={classes.total}>
        <div>
          <span>Total:</span>
          <span>{formatPrice(orderData.get('orderTotal'))}</span>
        </div>
        <div>
          <span>Payment Method:&nbsp;</span>
          <span>Cash or E-Transfer</span>
        </div>
      </div>
    </div>
  </div>
);

export const OrderItemsList = compose(injectSheet(orderItemsList))(
  _OrderItemsList,
);
