import { push } from 'connected-react-router';
import { actions } from 'store/actions';
import { removeActiveOrder } from 'utils';

export const cancelRateCustomer = () => async dispatch => {
  removeActiveOrder();
  dispatch(actions.setData.act(['activeOrder'], null));
  dispatch(actions.closeModal.act('rateCustomer'));
  dispatch(push('/orderboard'));
};
