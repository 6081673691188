import get from 'lodash/get';
import { getActiveDeliveryZone } from 'selectors/getActiveDeliveryZone';
import { getOrderListForApi } from 'selectors/getOrderListForApi';
import { setPendingOrder } from 'utils';
import { api } from '../../api';
import { actions } from 'store/actions';

export const sendAnonymousOrder = (values, setErrors, setSubmitting) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const activeZone = getActiveDeliveryZone(state);
  const orderItems = getOrderListForApi(state);
  try {
    if (+values.deliveryZoneId !== +activeZone.value) {
      setErrors({
        backError: `Sorry, your address is not in the ${activeZone.label}
        delivery zone. Go back to the main page to change your zone.`,
      });
      return false;
    }
    const { id, secret } = (await api.createOrder(values, orderItems)).data;
    const order = `${id}?secret=${secret}`;
    setPendingOrder(order);
    dispatch(actions.openVerificationModal.act(id));
  } catch (err) {
    setErrors({
      backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
    });
    setSubmitting(false);
  }
};
