import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const suspendDriver = ({ id, reason }) => async dispatch => {
  try {
    await api.suspendDriver(id, reason);
    dispatch(
      actions.updateDriver.act({ id, status: 'Suspended', banReason: reason }),
    );
  } catch (e) {
    return e;
  }
};
