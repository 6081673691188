import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const getPostalCodes = search =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: '/deliveryzone/findpostalcodes',
    data: {
      search,
    },
  });
