const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit * 2,
    padding: '5px',
    minHeight: '31px',
    fontSize: '12px',
  },
  delete: {
    minWidth: '64px',
    minHeight: '31px',
    fontSize: '12px',
    padding: '5px',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),' +
      '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
      '0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
});

export default styles;
