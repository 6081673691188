import { palette } from '../../theme';

export const products = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  placeOrderText: {
    fontFamily: 'Roboto',
    fontWeight: '300',
    lineHeight: '1.75rem',
    textAlign: 'center',
    marginTop: '1.5rem',
    '& > span': {
      fontSize: '1.375rem',
    },
  },
  linkToLogin: {
    textDecoration: 'none',
    color: palette.yellow,
    fontWeight: 500,
  },
  linkToProducts: {
    textDecoration: 'none',
    color: palette.yellow,
    fontWeight: 500,
    padding: '10px 30px',
    border: `2px solid ${palette.yellow}`, 
    margin: 5,
  },
};
