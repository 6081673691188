import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { id }) =>
  state.updateIn(['tables', 'customers', 'items'], customers =>
    customers.filter(el => el.get('id') !== id),
  );

export const deleteCustomer = createAction({
  action: ['deleteCustomer', 'id'],
  handler,
});
