import { media, palette } from 'ui/styles/theme';

export const input = {
  container: {
    height: '5rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1.5rem 2.25rem auto',
    gridTemplateAreas: `
      'a'
      'b'
      'c'
     `,
    [media.desktop]: {
      gridTemplateColumns: '25% 75%',
      gridTemplateRows: '2.25rem auto',
      gridTemplateAreas: `
      'a b'
      '. c'
    `,
      height: '4rem',
    },
  },
  label: {
    gridArea: 'a',
    alignSelf: 'center',
    color: palette.grey500,
    fontFamily: 'Roboto',
    fontWeight: '300',
  },
  input: {
    gridArea: 'b',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: palette.grey500,
    border: `1px solid ${palette.black}`,
    padding: '0 0.5rem',
    '&:focus': {
      outline: 'none',
      borderColor: '#FFC80A',
    },
  },
  error: {
    gridArea: 'c',
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: '300',
    color: 'red',
  },
};
