import { push } from 'connected-react-router';

import { actions } from 'store/actions';
import { isAuth, role } from 'services/utils';
import { setActiveDeliveryZone } from './setActiveDeliveryZone';

export const handleDeliveryZoneChoice = value => dispatch => {
  if (value.value === 'noArea') {
    return dispatch(actions.openModal.act('emailWhenAreaSupported'));
  }

  if (isAuth() && role() === 'driver') {
    dispatch(actions.closeModal.act('chooseArea'));
    dispatch(push('/inventory'));
  }

  if (value.value) {
    dispatch(setActiveDeliveryZone(value.value));
    dispatch(actions.closeModal.act('chooseArea'));
  }
};
