import { createSelector } from 'reselect';

const getDropDownData = deliveryZones =>
  deliveryZones
    ? deliveryZones
        .map(item => ({
          value: item.get('id'),
          label: item.get('name'),
        }))
        .toJS()
    : [];

export const getDeliveryZone = createSelector(
  state => state.getIn(['app', 'deliveryZones']),
  deliveryZones => getDropDownData(deliveryZones),
);
