import colors from '../colors';

const styles = theme => ({
  wrap: {
    minHeight: '100vh',
    backgroundColor: colors.light,
  },
  title: {
    marginBottom: theme.spacing.unit * 4,
    textAlign: 'center',
    color: colors.darkMainTheme,
  },
});

export default styles;
