import React, { Component } from 'react';
import { withStyles, TableRow, TableCell } from '@material-ui/core';
import { customerRow } from 'ui/styles/Admin/TableRow';
import { DeliveryZonesActions } from '../../../molecules/Admin/DeliveryZones/DeliveryZonesActions';

class _DeliveryZonesRow extends Component {
  render() {
    const {
      item: { name, postalCodes, id },
      classes,
    } = this.props;

    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell className={classes.postalCodes}>
          {postalCodes && postalCodes.join(', ')}
        </TableCell>
        <DeliveryZonesActions id={id} name={name} />
      </TableRow>
    );
  }
}

const DeliveryZonesRow = withStyles(customerRow)(_DeliveryZonesRow);
export default DeliveryZonesRow;
