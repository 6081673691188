import { button } from '../../Commons/button';
import { backstage } from '../../Commons/backstage';
import { media } from '../../theme';

export const thxForSubscribe = {
  backstage,
  container: {
    width: '90%',
    maxWidth: '400px',
    height: '250px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '20% 20% 30% auto',
    gridTemplateAreas: `
      '.'
      'a'
      'b'
      'c'
    `,
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    [media.desktop]: {
      width: '500px',
      height: '365px',
      maxWidth: '500px',
    },
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    alignSelf: 'start',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    [media.desktop]: {
      fontSize: '1.75rem',
      textTransform: 'none',
    },
  },
  text: {
    gridArea: 'b',
    justifySelf: 'center',
    alignSelf: 'start',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    lineHeight: '1.5rem',
    [media.desktop]: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
    },
  },
  gotIt: {
    ...button.yellow,
    gridArea: 'c',
    justifySelf: 'center',
    alignSelf: 'start',
  },
};
