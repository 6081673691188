import React from 'react';
import { compose } from 'redux';
import Divider from '@material-ui/core/Divider';
import injectSheet from 'react-jss';
import { orderCard } from 'ui/styles/Driver/SingleOrder/orderCard';
import { OrderItemsList } from 'ui/components/molecules/Customer/OrderPage/OrderItemsList';
import { AccountInfo } from 'ui/components/molecules/Customer/OrderPage/AccountInfo';
import { RefuseOrder } from 'ui/components/organisms/Driver/Order/RefuseOrder';
import { ButtonWithConfirm } from 'ui/components/atoms/Commons/Client/ButtonWithConfirm';

const _DriverOrderCard = ({
  classes,
  orderData,
  completeOrder,
  isCompleteOpen,
  openCompleteOrder,
  closeCompleteOrder,
  rejectOrder,
  getPhone,
  isCancelOpen,
  openCancelOrder,
  closeCancelOrder,
}) => (
  <div className={classes.container}>
    <AccountInfo orderData={orderData} forDriver />
    <Divider className={classes.diriver1} />
    <div className={classes.order}>
      <span className={classes.orderSummary}>Order Summary</span>
      <OrderItemsList orderData={orderData} />
    </div>
    <Divider className={classes.diriver2} />
    <div className={classes.buttons}>
      <button type="button" onClick={getPhone} className={classes.call}>
        Call the customer
      </button>
      <RefuseOrder id={orderData.get('id')} />
      <ButtonWithConfirm
        className={classes.cancel}
        text="Decline order"
        onClick={rejectOrder}
        confirmText="Are you sure want to decline order?"
        isConfirmOpen={isCancelOpen}
        openConfirmModal={openCancelOrder}
        closeConfirmModal={closeCancelOrder}
      />
      <ButtonWithConfirm
        className={classes.complete}
        text="Complete"
        onClick={completeOrder}
        confirmText="Are you sure want to complete order?"
        isConfirmOpen={isCompleteOpen}
        openConfirmModal={openCompleteOrder}
        closeConfirmModal={closeCompleteOrder}
      />
    </div>
  </div>
);

export const DriverOrderCard = compose(injectSheet(orderCard))(
  _DriverOrderCard,
);
