import { formatPriceRange } from '../utils/priceHelper';

export const getProductPrice = prices => {
  if (prices.length === 0) {
    return null;
  }

  prices = prices.map(x => +x).sort((x, y) => x - y);

  return formatPriceRange(prices[0], prices[prices.length - 1]);
};

export const getProductZonePrice = prices =>
  getProductPrice(prices.map(x => x.price));

export const getProductRowPrice = prices =>
  getProductZonePrice(prices.flatMap(x => x.prices));
