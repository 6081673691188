import { api } from 'services/api';
import { actions } from 'store/actions';
import { setErrorToStore } from 'services/utils';

export const payDebt = (startLoading) => async dispatch => {
  startLoading();
  try {
    await api.payDebt();
    const info = await api.getDriverAccountInfo();
    dispatch(actions.setDriverInfo.act(info.data));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
