import React from 'react';

import { withStyles } from '@material-ui/core';
import styles from '../../../../styles/Admin/Search/searchForm';

const _SearchForm = ({ classes, children, onSubmit }) => (
  <form className={classes.form} onSubmit={onSubmit}>
    {children}
  </form>
);

export const SearchForm = withStyles(styles)(_SearchForm);
