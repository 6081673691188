import { connectRouter } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux-immutable';
import { actions } from 'store/actions';
import { initState } from 'store/initState';
import { createReducer } from 'utils/redux-helpers/createReducer';

export const history = createBrowserHistory();

export const reducer = combineReducers({
  router: connectRouter(history),
  app: createReducer(initState, actions),
});
