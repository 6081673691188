import { formatPhone, getPhoneForForm } from 'utils';
import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const addEditDriverConfig = {
  mapPropsToValues: ({
    firstName,
    lastName,
    phone,
    email,
    driverLicense,
    smartserve,
  }) => ({
    firstName: firstName || '',
    lastName: lastName || '',
    phone: getPhoneForForm(phone),
    email: email || '',
    driverLicense: driverLicense || '',
    smartserve: smartserve || '',
    password: '',
    confirmPassword: '',
  }),
  validationSchema: ({ id }) =>
    Yup.object().shape({
      firstName: validators.firstName,
      lastName: validators.lastName,
      phone: validators.phone,
      email: validators.email,
      driverLicense: validators.driverLicense,
      smartserve: validators.smartserve,
      password: id ? validators.editDataPassword : validators.password,
      confirmPassword: validators.confirmPassword,
    }),
  handleSubmit: (values, { props: { submit }, setErrors, setSubmitting }) => {
    submit(
      { ...values, phone: formatPhone(values.phone) },
      setErrors,
      setSubmitting,
    );
  },
  displayName: 'AddEditDriverConfig',
};
