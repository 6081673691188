import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const loginConfig = {
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: Yup.object().shape({
    email: validators.email,
    password: validators.password,
  }),
  handleSubmit: (
    values,
    { props: { login, role }, setErrors, setSubmitting },
  ) => {
    login(values, role, setErrors, setSubmitting);
  },
  displayName: 'LoginForm',
};
