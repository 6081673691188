import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const getOrders = ({
  page,
  pageSize,
  search = '',
  orderBy,
  orderDirection,
  startDate,
  endDate,
}) =>
  privateAxios({
    method: 'post',
    baseURL: adminAPI,
    url: '/order/list',
    data: {
      page,
      pageSize,
      search,
      orderBy,
      orderDirection,
      startDate,
      endDate,
    },
  });
