import { push } from 'connected-react-router';
import { api } from 'services/api';
import { actions } from 'store/actions';
import { removeActiveOrder } from 'utils';

export const rateCustomer = (orderId, rating) => async dispatch => {
  console.log('rateCustomer');
  try {
    await api.rateCustomer(orderId, rating);
    removeActiveOrder();
    dispatch(actions.setData.act(['activeOrder'], null));
    dispatch(actions.closeModal.act('rateCustomer'));
    dispatch(push('/orderboard'));
  } catch (e) {
    return e;
  }
};
