import React from 'react';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { thunks } from '../../../../../services/thunks';
import { Modal } from '../../Commons/Modal';
import { resetPasswordConfig } from '../../../../configs/formik/resetPasswordConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { resetPassword } from '../../../../styles/General/Login/resetPassword';
import { actions } from '../../../../../store/actions';

const mapStateToProps = state => ({
  isOpen: state.getIn(['app', 'modals', 'resetPassword']),
});

const mapDispatchToProps = (dispatch, { name }) => ({
  close: () => dispatch(actions.closeModal.act('resetPassword')),
  resetPassword: (...props) => dispatch(thunks.resetPassword(...props, name)),
});

const _ResetPassword = ({ classes, errors, touched, close, isOpen }) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    onClose={close}
    isOpen={isOpen}
  >
    <h1 className={classes.title}>
      Please type your email and we will send you the email with next
      instructions
    </h1>
    <Form className={classes.form} id="emailAreaForm">
      <Field
        component={Input}
        type="text"
        name="resetPasswordEmail"
        placeholder="Enter your email"
        error={touched.resetPasswordEmail && errors.resetPasswordEmail}
        $container={classes.inputContainer}
        $label={classes.inputLabel}
        $input={classes.input}
        $error={classes.inputError}
      />
    </Form>
    <button type="button" onClick={close} className={classes.cancel}>
      Cancel
    </button>
    <button type="submit" form="emailAreaForm" className={classes.ok}>
      Ok
    </button>
    <div className={classes.backError}>{errors.backError}</div>
  </Modal>
);

export const ResetPassword = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withFormik(resetPasswordConfig),
  injectSheet(resetPassword),
)(_ResetPassword);
