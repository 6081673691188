import { createSelector } from 'reselect';

const selector = (state, path) => {
  if (path) {
    const data = state.getIn(path);
    return data ? data.toJS() : [];
  }
};

export const getTableData = createSelector(
  selector,
  data => data,
);
