import { push } from 'connected-react-router';
import get from 'lodash/get';

import { getOrderListForApi } from 'selectors/getOrderListForApi';
import { actions } from 'store/actions';
import { api } from 'services/api';
import { setErrorToStore } from 'services/utils';
import { checkOperationHours } from '../general/checkOperationHours';

export const validateOrder = (startLoading, endLoading) => async (
  dispatch,
  getState,
) => {
  startLoading();
  const state = getState();
  const orderItems = getOrderListForApi(state);
  const deliveryZoneId = state.getIn(['app', 'user', 'deliveryZoneId']);
  try {
    if (!(await checkOperationHours(deliveryZoneId)(dispatch))) {
      endLoading();
      return;
    }
//    await api.validateOrder(deliveryZoneId, orderItems); Temporarily disabling to reduce support burden 2021-05-18//
    dispatch(push(`/complete-order`));
  } catch (err) {
    const errData = get(err, ['response', 'data'], null);
    if (errData.errorCode === 3) {
      dispatch(actions.setValidateOrderError.act(errData.items));
    } else if (errData.errorCode === 1) {
      dispatch(actions.setValidateOrderError.act(errData.errorMsg));
    } else {
      setErrorToStore(err, dispatch);
    }
    endLoading();
  }
};
