import { formatPhone } from 'utils';
import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const signUpDriverConfig = {
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    driverLicense: '',
    smartserve: '',
    password: '',
    confirmPassword: '',
    terms: false,
  }),
  validationSchema: Yup.object().shape({
    firstName: validators.firstName,
    lastName: validators.lastName,
    phone: validators.phone,
    email: validators.email,
    driverLicense: validators.driverLicense,
    smartserve: validators.smartserve,
    password: validators.password,
    confirmPassword: validators.confirmPassword,
    terms: validators.terms,
  }),
  handleSubmit: (
    values,
    { props: { signUpDriver }, setErrors, setSubmitting },
  ) => {
    signUpDriver( { ...values, phone: formatPhone(values.phone) }, setErrors, setSubmitting);
  },
  displayName: 'SignUpDriverForm',
};
