import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  isAuth: state.getIn(['app', 'isAuth']),
  role: state.getIn(['app', 'user', 'role']),
});

const _AdminRoute = props =>
  props.role === 'admin' ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  );

export const AdminRoute = connect(mapStateToProps)(_AdminRoute);
