import { fromJS } from 'immutable';
import createAction from '../../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.setIn(['tables', 'transactions'], fromJS(data));

export const setTransactions = createAction({
  action: ['setTransactions', 'data'],
  handler,
});
