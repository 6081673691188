import { fromJS } from 'immutable';
import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

const prepUserDataForUpdate = (state, values, addressId) => {
  const customer = state.getIn(['app', 'selectedCustomer']);
  const customerAddresses = customer.customerAddresses.update(
    customer.customerAddresses.findIndex(item => item.get('id') === addressId),
    address => address.merge(fromJS(values)),
  ).toJS();
  return {
    ...customer,
    customerAddresses,
  };
};

export const adminEditAddress = (values, addressId, toggleEdit) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const data = prepUserDataForUpdate(state, values, addressId);
  const customerId = state.getIn(['app', 'selectedCustomer', 'id']);
  try {
    await api.editCustomer(customerId, data);
    const response = await api.getCustomerById(customerId);
    dispatch(actions.setCustomer.act({ ...response.data, id: customerId }));
    toggleEdit();
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
