import React, { Fragment } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators, compose } from 'redux';
import Transition from 'react-transition-group/Transition';
import injectSheet from 'react-jss';
import { thunks } from '../../../../services/thunks';
import { AppTopBar } from '../../ecosystems/Customer/AppTopBar';
import { CustomerProfileBlock } from '../../ecosystems/Customer/CustomerProfileBlock';
import { Addresses } from '../../ecosystems/Customer/Addresses';
import { customerProfile } from '../../../styles/Customer/CustomerProfile/customerProfile';

const mapStateToProps = state => ({
  userInfo: state.getIn(['app', 'user']),
  addresses: state.getIn(['app', 'addresses']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomerProfile: thunks.getCustomerProfile,
    },
    dispatch,
  );

class _CustomerProfile extends React.PureComponent {
  state = { isEdit: false };

  componentDidMount() {
    this.props.getCustomerProfile();
  }

  toggleEdit = () => {
    this.setState(prevState => ({ isEdit: !prevState.isEdit }));
  };

  render() {
    const { classes, userInfo, addresses } = this.props;
    return (
      <Fragment>
        <AppTopBar showLogout />
        <div className={classes.container}>
          <Transition in={this.state.isEdit} timeout={250}>
            {status => (
              <CustomerProfileBlock
                data={userInfo}
                status={status}
                toggleEdit={this.toggleEdit}
              />
            )}
          </Transition>
          <Addresses addresses={addresses} />
        </div>
      </Fragment>
    );
  }
}

export const CustomerProfile = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(customerProfile),
)(_CustomerProfile);
