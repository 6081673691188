export const productRow = () => ({
  pricesWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginRight: '0.5rem',
    },
  },
  lightTooltip: {
    maxWidth: 'none',
    display: 'flex',
  },
});
