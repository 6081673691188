import get from 'lodash/get';
import { push } from 'connected-react-router';
import { api } from '../../api';
import { actions } from '../../../store/actions';
import { setUserData, setErrorToStore, getLocalData } from '../../utils';

export const login = (
  values,
  role,
  setErrors,
  setSubmitting,
) => async dispatch => {
  try {
    const loginResponse = await api.login(values, role);
    setUserData(loginResponse.data);
    dispatch(actions.login.act(getLocalData()));
    dispatch(push('/'));
  } catch (err) {
    if (err.response.data.errorCode === 2) {
      // if acc was blocked
      setErrorToStore(
        `Sorry, but your account was blocked.
        Please, contact with us by email: shitomi@protonmail.com`,
        dispatch,
      );
    } else {
      setErrors({
        backError: get(err, ['response', 'data', 'errorMsg'], 'Unknown error'),
      });
    }
    setSubmitting(false);
  }
};
