const styles = theme => ({
  table: {
    height: '100%',
    overflow: 'auto',
  },
  postalCodes: {
    width: '45%',
  },
  fullname: {
    width: '27%',
  },
  customerFullName: {
    width: '35%',
  },
  rate: {
    width: '23%',
  },
  debt: {
    width: '10%',
  },
  time: {
    width: '12%',
  },
  quantiny: {
    width: '10%',
  },
  total: {
    width: '11%',
  },
  createdate: {
    width: '19%',
  },
  status: {
    width: '10%',
  },
  drivername: {
    width: '22%',
  },
  customername: {
    width: '22%',
  },
});

export default styles;
