import React from 'react';

import { withStyles, TextField } from '@material-ui/core';

import styles from '../../../../styles/Admin/commons/datepicker';

const _DatePicker = ({ value, onChange, classes, autoFocus, label, id }) => (
  <div className={classes.datepicker}>
    <TextField
      id={id}
      type="date"
      onChange={onChange}
      // className={classes.textField}
      // InputLabelProps={{
      //   shrink: true,
      // }}
    />
  </div>
);

export const DatePicker = withStyles(styles)(_DatePicker);
