import { media, palette } from '../../theme';

const getStatusColor = status => {
  switch (status) {
    case 'Active':
      return '#00A651';
    case 'Suspended':
      return palette.red;
    default:
      return 'black';
  }
};

export const personalInfoBlock = {
  container: {
    gridArea: 'b',
    width: '100%',
    height: '100%',
    display: 'grid',
    fontFamily: 'Roboto',
    [media.mobile]: {
      gridTemplateColumns: '5% 90% 5%',
      gridTemplateRows: '3rem 2rem 2rem 8rem 5rem auto 6rem',
      gridTemplateAreas: `
      '. a .'
      '. b .'
      '. f .'
      '. c .'
      '. d .'
      '. e .'
      '. g .'
    `,
    },
    [media.desktop]: {
      gridTemplateColumns: '100%',
      // gridTemplateRows: '3rem 2rem 13rem 5rem 6rem 7rem',
      gridTemplateRows: '3rem 2rem 9.5rem 5rem 6rem 7rem',
      gridTemplateAreas: `
        'a'
        'b'
        'c'
        'd'
        'e'
        'g'
      `,
    },
  },
  userName: {
    gridArea: 'b',
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    [media.desktop]: {
      gridArea: 'a',
      fontSize: '1.75rem',
    },
  },
  edit: {
    gridArea: 'a',
    justifySelf: 'end',
    [media.desktop]: {
      alignSelf: 'start',
      cursor: 'pointer',
      marginTop: '-0.5rem',
      '&:hover': {
        color: palette.yellow,
        fill: palette.yellow,
      },
    },
  },
  editContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& span': {
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: '300',
    },
  },
  editIcon: {
    height: '1.5rem',
    marginLeft: '0.5rem',
  },
  status: {
    gridArea: 'b',
    height: '1rem',
    display: 'flex',
    alignItems: 'center',
    color: ({ driverStatus }) => getStatusColor(driverStatus),
    '& > span': {
      height: '0.5rem',
      width: '0.5rem',
      borderRadius: '50%',
      marginRight: '0.5rem',
      backgroundColor: ({ driverStatus }) => getStatusColor(driverStatus),
    },
    [media.mobile]: {
      gridArea: 'f',
    },
  },
  suspendReason: {
    gridArea: 'b',
    alignSelf: 'end',
    color: ({ driverStatus }) => getStatusColor(driverStatus),
    fontSize: '0.8rem',
    lineHeight: '1rem',
    [media.mobile]: {
      gridArea: 'f',
    },
  },
  emailPhoneWrapper: {
    gridArea: 'c',
    display: 'flex',
    flexDirection: 'column',
    color: '#444343',
    fontSize: '1rem',
    fontWeight: '300',
    lineHeight: '2rem',
    [media.desktop]: {
      gridArea: 'c',
    },
  },
  chooseDeliveryZone: {
    gridArea: 'd',
    width: '100%',
    [media.desktop]: {
      width: '50%',
      alignSelf: 'flex-start',
    },
  },
  divider1: {
    gridArea: '5/1/5/2',
    [media.mobile]: {
      gridArea: '6/1/6/4',
    },
  },
  divider2: {
    gridArea: '6/1/6/2',
    [media.mobile]: {
      gridArea: '7/1/7/4',
    },
  },
  balanceWrapper: {
    gridArea: 'g',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      lineHeight: '2.5rem',
    },
    '& > div > span:first-child': {
      fontSize: '1.25rem',
    },
    '& > div > span:last-child': {
      fontSize: '2rem',
      fontWeight: 600,
    },
    [media.mobile]: {
      // marginTop: '2rem',
    },
  },
};
