import { input } from '../../Commons/input';
import { button } from '../../Commons/button';
import { media, palette } from '../../theme';

export const signUpCustomerForm = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  title: {
    gridArea: 'a',
    justifySelf: 'center',
    fontFamily: 'Roboto',
    fontWeight: '300',
    [media.mobile]: {
      fontSize: '1rem',
      margin: '1.5rem 0 1rem 0',
    },
    [media.desktop]: {
      margin: '3rem 0',
      fontSize: '1.375rem',
    },
  },
  form: {
    gridArea: 'b',
    width: '70%',
    display: 'flex',
    flexWrap: 'wrap',
    [media.mobile]: {
      width: '90%',
    },
    [media.desktop]: {
      width: '70%',
    },
  },
  inputContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      gridTemplateColumns: '7.5rem auto',
    },
  },
  inputHalfContainer: {
    ...input.container,
    [media.desktop]: {
      ...input.container[media.desktop],
      width: '55%',
      gridTemplateColumns: '7.5rem auto',
    },
  },
  inputHalfContainerWithoutLabel: {
    ...input.container,

    [media.desktop]: {
      ...input.container[media.desktop],
      width: '45%',
      gridTemplateColumns: '2.5rem auto',
    },
  },
  inputLabel: {
    ...input.label,
    lineHeight: '1.4rem',
  },
  input: {
    ...input.input,
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  inputError: { ...input.error },
  textareaContainer: {
    ...input.container,
    [media.mobile]: {
      height: '10rem',
      gridTemplateRows: '2.25rem auto 1.75rem',
    },
    [media.desktop]: {
      ...input.container[media.desktop],
      height: '8rem',
      gridTemplateColumns: '7.5rem auto',
      gridTemplateRows: '2.25rem auto 1.75rem',
      gridTemplateAreas: `
      'a b'
      '. b'
      '. c'
      `,
    },
  },
  textarea: {
    ...input.input,
    padding: '0.5rem',
    resize: 'none',
    '&::placeholder': {
      color: '#a9a9a9',
    },
  },
  termsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    [media.mobile]: {
      height: '4rem',
    },
    [media.desktop]: {
      height: '3rem',
    },
  },
  termsLink: {
    color: palette.yellow,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  termsError: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: '300',
    color: 'red',
  },
  submit: {
    ...button.yellow,
    margin: '2rem auto 2rem auto',
  },
  backError: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
