import { media } from 'ui/styles/theme';

export const changeDeliveryZone = {
  container: {
    width: '1030px',
    fontFamily: 'Roboto',
    marginTop: '1rem',
    '& > h4': {
      fontWeight: 400,
      lineHeight: '2.5rem',
    },
    [media.mobile]: {
      width: '100%',
    },
  },
  dropdown: {
    width: '100%',
    [media.desktop]: {
      width: '25.2%',
      alignSelf: 'flex-end',
    },
  },
};
