import React from 'react';

export const MapIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 29 28">
    <g>
      <path d="M27.2,11.6h-5.7c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h4.8l1.5,14.3H1.2L3,12.7h4.8c0.3,0,0.5-0.2,0.5-0.5   c0-0.3-0.2-0.5-0.5-0.5H2.1L0,28h29L27.2,11.6z" />
      <path d="M14,20.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.2-0.3,0.5-0.1,0.7c0.1,0.1,0.3,0.2,0.4,0.2   c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.4,0.2-0.7c0,0,0,0,0,0c1-1,4.2-4.8,5.5-11.1   c0.3-1.3,0.3-2.5,0.2-3.7C20.4,1.8,18.2,0,14.6,0C11,0,8.9,1.8,8.4,5.3C8.3,6.5,8.3,7.7,8.6,9C9.9,15.2,13.1,19,14,20.1z M9.4,5.4   c0.4-3,2.1-4.4,5.2-4.4c3.1,0,4.8,1.4,5.2,4.4c0.1,1,0.1,2.1-0.2,3.3c-1.2,5.7-4,9.3-5,10.4c-1-1.1-3.8-4.7-5-10.4   C9.4,7.6,9.3,6.5,9.4,5.4z" />
      <path d="M14.6,10c2.1,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S10.8,4,10.8,6.1S12.5,10,14.6,10z M14.6,3.3c1.6,0,2.8,1.3,2.8,2.8   s-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8S13.1,3.3,14.6,3.3z" />
      <path d="M16.6,19.1c-0.3,0.1-0.5,0.3-0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.4c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.1,1-0.2   c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.3-0.5-0.6-0.5C17.3,19,16.9,19,16.6,19.1z" />
      <path d="M19.3,19.3c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0l1.1,0c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.3-0.5-0.6-0.5l-1.1,0   C19.5,18.8,19.3,19,19.3,19.3z" />
      <path d="M11.8,21.7c-0.3,0.2-0.6,0.4-0.8,0.6c-0.2,0.2-0.3,0.5-0.2,0.7c0.1,0.2,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1   c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.1-0.7C12.4,21.6,12.1,21.6,11.8,21.7z" />
      <path d="M23.6,18.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.3,0.1-0.5,0.3-0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.4c0,0,0.1,0,0.1,0   c0.4-0.1,0.8-0.2,1.1-0.5l0.1-0.1c0.2-0.2,0.3-0.5,0.1-0.7C24.2,18.1,23.8,18.1,23.6,18.3L23.6,18.3z" />
      <path d="M4.7,22.4c0.1-0.3-0.1-0.6-0.4-0.6c-0.3-0.1-0.6,0.1-0.6,0.4c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1,0,0.2   c0,0.1,0,0.2,0,0.3c0,0.3,0.3,0.5,0.5,0.5c0,0,0,0,0.1,0c0.3,0,0.5-0.3,0.5-0.6c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1   C4.6,22.7,4.6,22.6,4.7,22.4z" />
      <path d="M23.6,14.2c-0.4-0.1-0.8,0-1.3,0.1c-0.3,0.1-0.4,0.4-0.4,0.7c0.1,0.2,0.3,0.4,0.5,0.4c0,0,0.1,0,0.1,0   c0.3-0.1,0.6-0.1,0.8-0.1c0.3,0,0.6-0.2,0.6-0.5C24.1,14.5,23.8,14.2,23.6,14.2z" />
      <path d="M24.6,16.8c-0.1,0.3,0.1,0.6,0.3,0.7c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0-0.1,0-0.1   c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.4-0.4-0.7-0.3c-0.3,0.1-0.4,0.4-0.3,0.7c0,0.1,0.1,0.2,0.1,0.4l0,0.1   C24.7,16.6,24.6,16.7,24.6,16.8z" />
      <path d="M9.2,23.3c-0.3,0.1-0.6,0.3-0.9,0.4C8,23.8,7.9,24.1,8,24.4c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0   c0.3-0.1,0.7-0.3,1-0.4c0.3-0.1,0.4-0.4,0.3-0.7C9.8,23.3,9.5,23.2,9.2,23.3z" />
      <path d="M5.4,21.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.6-0.5c0-0.3-0.2-0.6-0.5-0.6   c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.1-0.4,0.5-0.2,0.7C5,21,5.2,21.1,5.4,21.1z" />
      <path d="M5.3,25.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0C6.8,25.3,7,25,7,24.7c0-0.3-0.3-0.5-0.6-0.5   c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6,0.1-0.6,0.4C4.8,24.9,5,25.2,5.3,25.3z" />
    </g>
  </svg>
);
