import React, { Component } from 'react';

import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { withStyles, IconButton, Icon } from '@material-ui/core';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import styles from '../../../../styles/Admin/TablePaginationActions';

class _TablePaginationActions extends Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  renderIconButton = ({ action, disabled, label, icon }) => (
    <IconButton onClick={action} disabled={disabled} aria-label={label}>
      <Icon>{icon}</Icon>
    </IconButton>
  );

  render() {
    const { classes, count, page, rowsPerPage, t } = this.props;

    const disablePrevButton = page === 0;
    const disableNextButton = page >= Math.ceil(count / rowsPerPage) - 1;
    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={disablePrevButton}
          aria-label={t('First Page')}
        >
          <FirstPageIcon />
        </IconButton>

        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={disablePrevButton}
          aria-label={t('Previous Page')}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <span className={classes.page}>{page + 1}</span>

        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={disableNextButton}
          aria-label={t('Next Page')}
        >
          <KeyboardArrowRightIcon />
        </IconButton>

        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={disableNextButton}
          aria-label={t('Last Page')}
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

export const TablePaginationActions = compose(
  withStyles(styles),
  withNamespaces('admin'),
)(_TablePaginationActions);
