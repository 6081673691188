import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { en } from 'ui/locales/en/index';
import { uk } from 'ui/locales/uk/index';

const resources = {
  en,
  uk,
};

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    lng: 'en',
    resources,
    defaultNS: 'checkAge',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // debug: true
  });

export default i18n;
