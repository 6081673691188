import { fromJS } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.setIn(['customer', 'singleOrderPage', 'orderData'], fromJS(data));

export const setSingleOrderData = createAction({
  action: ['setSingleOrderData', 'data'],
  handler,
});
