import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import injectSheet from 'react-jss';
import { thunks } from '../../../../services/thunks';
import { AppTopBar } from '../../ecosystems/Customer/AppTopBar';
import { SignUpCard } from '../../ecosystems/General/SignUpCard';
import { signUp } from '../../../styles/General/SignUp/signUp';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      acceptAgePolicy: thunks.acceptAgePolicy,
    },
    dispatch,
  );

const _SignUp = ({ classes, location }) => (
  <>
    <AppTopBar login />
    <div className={classes.container}>
      <SignUpCard location={location} />
    </div>
  </>
);

export const SignUp = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withNamespaces(),
  injectSheet(signUp),
)(_SignUp);
