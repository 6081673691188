import { media } from 'ui/styles/theme';

export const deliveryZoneDropDown = {
  container: {
    width: '100%',
    [media.desktop]: {
      width: '25.2%',
      alignSelf: 'flex-end',
    },
  },
};
