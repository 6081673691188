import React from 'react';
import { ProfileCategory } from 'ui/components/organisms/Driver/Profile/ProfileCategory';
import injectSheet from 'react-jss';
import { inventory as inventoryStyles } from 'ui/styles/Driver/Profile/inventory';

const _ProfileInventory = ({ classes, inventory }) => (
  <div className={classes.container}>
    {inventory.map(category => (
      <ProfileCategory key={category.get('name')} category={category} />
    ))}
  </div>
);

export const ProfileInventory = injectSheet(inventoryStyles)(_ProfileInventory);
