import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const setDriverRating = (id, rate) => async dispatch => {
  try {
    await api.setDriverRating(id, rate);
    dispatch(actions.updateDriver.act({ id, rate }));
  } catch (e) {
    return e;
  }
};
