import { media } from '../../theme';

export const addresses = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0.5rem 0 4rem 0',
    [media.desktop]: {
      margin: '1rem 0 4rem 0',
    },
  },
};
