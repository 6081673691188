import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

export const getSchedule = ({
  deliveryZoneId,
  startLoading,
  endLoading,
}) => async dispatch => {
  startLoading();
  try {
    const { data } = await api.getSchedule(deliveryZoneId);
    dispatch(actions.setData.act(['admin', 'schedule'], data));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
  endLoading();
};
