import { privateAxios } from 'services/api/privateAxios';
import { adminAPI } from 'services/api/apiUrl';

export const editSchedule = data =>
  privateAxios({
    method: 'put',
    baseURL: adminAPI,
    url: '/schedule',
    data,
  });
