import React from 'react';
import { Modal } from 'ui/components/organisms/Commons/Modal';
import injectSheet from 'react-jss';
import { notifacationModal } from 'ui/styles/Commons/notifacationModal';

const _NotificationModal = ({ classes, isOpen, close, children }) => (
  <Modal
    $backstage={classes.backstage}
    $container={classes.container}
    isOpen={isOpen}
    onClose={close}
  >
    {children}
    <button type="button" onClick={close} className={classes.ok}>
      Close
    </button>
  </Modal>
);

export const NotificationModal = injectSheet(notifacationModal)(
  _NotificationModal,
);
