import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

export const activateCustomer = id => async dispatch => {
  try {
    await api.unblockCustomer(id);
    dispatch(
      actions.updateCustomer.act({ id, isBlocked: false, blockReason: null }),
    );
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
