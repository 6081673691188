import React, { useCallback } from 'react';
import Option from '../Option';

const hiddenStyle = {
  display: 'none',
};

export default ({ update }) => {
  const processImage = useCallback(({ target }) => {
    if (target.files == null || target.files[0] == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onloadend = () => update({ src: reader.result });
  }, [update]);

  return (
    <label>
      <Option title="Background Image">
        <svg width="15" height="14" xmlns="http://www.w3.org/2000/svg">
          <g fill="#000" fillRule="evenodd">
            <path d="M14.741 0H.26C.116 0 0 .136 0 .304v13.392c0 .168.116.304.259.304H14.74c.143 0 .259-.136.259-.304V.304C15 .136 14.884 0 14.741 0zm-.258 13.391H.517V.61h13.966V13.39z" />
            <path d="M4.138 6.738c.794 0 1.44-.76 1.44-1.695s-.646-1.695-1.44-1.695c-.794 0-1.44.76-1.44 1.695 0 .934.646 1.695 1.44 1.695zm0-2.781c.509 0 .923.487.923 1.086 0 .598-.414 1.086-.923 1.086-.509 0-.923-.487-.923-1.086 0-.599.414-1.086.923-1.086zM1.81 12.174c.06 0 .122-.025.171-.076L6.2 7.728l2.664 3.134a.232.232 0 0 0 .366 0 .343.343 0 0 0 0-.43L7.987 8.969l2.374-3.06 2.912 3.142c.106.113.27.105.366-.02a.343.343 0 0 0-.016-.43l-3.104-3.347a.244.244 0 0 0-.186-.08.245.245 0 0 0-.18.1L7.622 8.537 6.394 7.094a.232.232 0 0 0-.354-.013l-4.4 4.56a.343.343 0 0 0-.024.43.243.243 0 0 0 .194.103z" />
          </g>
        </svg>
      </Option>
      <input
        style={hiddenStyle}
        type="file"
        accept="image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
        onChange={processImage}
      />
    </label>
  );
};
