import { button } from 'ui/styles/Commons/button';
import { palette, media } from 'ui/styles/theme';

export const payNow = {
  payNow: {
    ...button.white,
    fontSize: '1rem',
    fontWeight: 500,
    color: '#EF3F44',
    borderColor: palette.red,
    [media.desktop]: {
      ...button.white[media.desktop],
      gridArea: 'd',
      justifySelf: 'end',
      display: ({ mobile }) => mobile && 'none',
    },
    [media.mobile]: {
      ...button.white[media.mobile],
      gridArea: 'e',
      display: ({ desktop }) => desktop && 'none',
    }
  },
};
