import { Button, TableCell } from '@material-ui/core';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSheet from 'react-jss';
import { thunks } from 'services/thunks';
import { actions } from 'store/actions';
import { ConfirmModal } from 'ui/components/organisms/Admin/Commons/ConfirmModal';
import styles from '../../../../styles/Admin/RowActions';

const mapStateToProps = (state, { id }) => ({
  isDeleteProductOpen: state.getIn(['app', 'modals', `deleteProduct-${id}`]),
});

const mapDispatchToProps = (dispatch, { id }) => ({
  getProductForEdit: () => dispatch(thunks.getProductForEdit(id)),
  deleteProduct: () => dispatch(thunks.deleteProduct(id)),
  openConfirmModal: () =>
    dispatch(actions.openModal.act(`deleteProduct-${id}`)),
  closeConfirmModal: () =>
    dispatch(actions.closeModal.act(`deleteProduct-${id}`)),
});

const _RowActions = ({
  t,
  name,
  classes,
  getProductForEdit,
  deleteProduct,
  isDeleteProductOpen,
  openConfirmModal,
  closeConfirmModal,
}) => (
  <TableCell>
    <Button
      variant="contained"
      className={classes.button}
      onClick={getProductForEdit}
    >
      {t('Edit')}
    </Button>
    <Button
      variant="contained"
      onClick={openConfirmModal}
      className={classes.button}
    >
      {t('Delete')}
    </Button>
    {isDeleteProductOpen && (
      <ConfirmModal
        isOpenModal={isDeleteProductOpen}
        confirm={deleteProduct}
        closeModal={closeConfirmModal}
        actionText={`delete ${name}?`}
      />
    )}
  </TableCell>
);

export const RowActions = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  injectSheet(styles),
)(_RowActions);
