import { api } from 'services/api';
import { actions } from 'store/actions';
import { setErrorToStore } from 'services/utils';

export const getActiveOrders = (page, prevPage, endLoading) => async dispatch => {
  try {
    const response = await api.getActiveOrders(page);
    dispatch(actions.setActiveOrders.act(response.data, page, prevPage));
    endLoading();
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
