import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const ratePopup = {
  backstage: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5%',
    alignItems: 'stretch',
    backgroundColor: 'white',
    boxShadow: '0 11px 32px 2px rgba(0,0,0,0.1)',
    zIndex: 10,
    fontFamily: 'Roboto',
    [media.mobile]: {
      width: '90%',
      maxWidth: '400px',
    },
    [media.desktop]: {
      width: '630px',
      padding: '15%',
    },

    '& > *:not(:last-child)': {
      marginBottom: '5%',
    },
  },
  title: {
    fontSize: '1.4rem',
    textAlign: 'center',
    fontWeight: 500,
    [media.desktop]: {
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
    },
  },
  text: {
    width: '80%',
    fontWeight: 300,
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    [media.desktop]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  ratingWrapper: {
    alignSelf: 'center',
  },
  comment: {},
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancel: {
    ...button.white,
    alignSelf: 'start',
    justifySelf: 'start',
  },
  ok: {
    ...button.yellow,
    alignSelf: 'start',
    justifySelf: 'end',
  },
  backError: {
    marginTop: '3rem',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '300',
    color: 'red',
  },
};
