import { formatPhone, getPhoneForForm } from 'utils';
import * as Yup from 'yup';
import { validators } from 'ui/configs/formik/validators';

export const editCustomerConfig = {
  mapPropsToValues: ({ firstName, lastName, phone, email }) => ({
    firstName: firstName || '',
    lastName: lastName || '',
    phone: getPhoneForForm(phone),
    email: email || '',
    password: '',
    confirmPassword: '',
  }),
  validationSchema: Yup.object().shape({
    firstName: validators.firstName,
    lastName: validators.lastName,
    phone: validators.phone,
    email: validators.email,
    password: validators.editDataPassword,
    confirmPassword: validators.editDataConfirmPassword,
  }),
  handleSubmit: (
    values,
    { props: { editCustomer }, setErrors, setSubmitting },
  ) => {
    editCustomer(
      { ...values, phone: formatPhone(values.phone) },
      setErrors,
      setSubmitting,
    );
  },
  displayName: 'EditCustomer',
};
