import React from 'react';
import { bindActionCreators, compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import { withMobileDetect } from 'ui/hoc/withMobileDetect';
import injectSheet from 'react-jss';
import { PhoneInput } from 'ui/components/atoms/Commons/Client/PhoneInput';
import { AddressField } from 'ui/components/organisms/Commons/AddressField';
import { thunks } from '../../../../../services/thunks';
import { signUpCustomerConfig } from '../../../../configs/formik/signUpCustomerConfig';
import { Input } from '../../../atoms/Commons/Client/Input';
import { TextArea } from '../../../atoms/Commons/Client/TextArea';
import { FormikPersist } from 'ui/components/atoms/Commons/Client/FormikPersist';
import { FormCheckbox } from '../../../molecules/Commons/Client/FormCheckbox';
import { signUpCustomerForm } from '../../../../styles/General/SignUp/signUpCustomerForm';

const mapStateToProps = state => ({
  isAddressSelected: state.getIn(['app', 'validations', 'addressSelected']),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signUpCustomer: thunks.signUpCustomer,
      geocodeAddress: thunks.geocodeAddress,
    },
    dispatch,
  );

const _SignUpCustomerForm = ({
  classes,
  errors,
  touched,
  geocodeAddress,
  isMobile,
}) => {
  const termsText = (
    <span>
      I agree with&nbsp;
      <NavLink to="/customer/terms" className={classes.termsLink}>
        Terms and Conditions
      </NavLink>
    </span>
  );
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Enter Information</h2>
      <Form className={classes.form}>
        <Field
          component={Input}
          type="text"
          name="firstName"
          placeholder={isMobile ? 'John' : 'First Name'}
          labelText={isMobile ? 'First Name' : 'Name'}
          error={touched.firstName && errors.firstName}
          $container={classes.inputHalfContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="text"
          name="lastName"
          placeholder={isMobile ? 'Doe' : 'Last Name'}
          labelText={isMobile && 'Last Name'}
          error={touched.lastName && errors.lastName}
          $container={classes.inputHalfContainerWithoutLabel}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={PhoneInput}
          type="text"
          name="phone"
          labelText="Phone"
          error={touched.phone && errors.phone}
          $container={classes.inputHalfContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <Field
          component={Input}
          type="text"
          name="email"
          placeholder="example@gmail.com"
          labelText="Email"
          error={touched.email && errors.email}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={AddressField}
          type="text"
          name="address"
          labelText="Address"
          placeholder="Start to type your address"
          error={touched.address && errors.postalCode}
          geocodeAddress={geocodeAddress}
        />
        <Field
          component={Input}
          type="text"
          name="boozer"
          placeholder="Unit Number and Buzzer"
          error={touched.address2 && errors.address2}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <Field
          component={Input}
          type="text"
          name="intersection"
          labelText="Nearest main intersection"
          placeholder="Main and Broad"
          error={touched.intersection && errors.intersection}
          $container={classes.inputContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={TextArea}
          name="instructions"
          labelText="Instructions"
          placeholder="eg. Meet in lobby, Name for concierge, Basement or back, etc."
          error={touched.instructions && errors.instructions}
          $container={classes.textareaContainer}
          $label={classes.inputLabel}
          $textarea={classes.textarea}
          $error={classes.inputError}
        />
        <Field
          component={Input}
          type="password"
          name="password"
          placeholder="Setup a password"
          labelText="Password"
          error={touched.password && errors.password}
          $container={classes.inputHalfContainer}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
          required
        />
        <Field
          component={Input}
          type="password"
          name="confirmPassword"
          placeholder="Confirm password"
          error={touched.confirmPassword && errors.confirmPassword}
          $container={classes.inputHalfContainerWithoutLabel}
          $label={classes.inputLabel}
          $input={classes.input}
          $error={classes.inputError}
        />
        <Field
          component={FormCheckbox}
          name="terms"
          error={touched.terms && errors.terms}
          text={termsText}
          $container={classes.termsContainer}
          $error={classes.termsError}
        />
        <button type="submit" className={classes.submit}>
          Submit
        </button>
        <div className={classes.backError}>{errors.backError}</div>
        <FormikPersist name="signUpCustomer" />
      </Form>
    </div>
  );
};

export const SignUpCustomerForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
  withMobileDetect(),
  withFormik(signUpCustomerConfig),
  injectSheet(signUpCustomerForm),
)(_SignUpCustomerForm);
