/* eslint-disable camelcase */
import get from 'lodash/get';
import moment from 'moment';

import { actions } from 'store/actions';
import { dayOfWeek } from 'ui/constants';
import { cookieControlledStorage } from 'utils';

// TODO Set data to localstorage only before closing page
export const setUserData = ({
  first_name,
  last_name,
  role,
  access_token,
  refresh_token,
  expires_in,
}) => {
  localStorage.setItem('firstName', first_name);
  localStorage.setItem('lastName', last_name);
  localStorage.setItem('role', role.toLowerCase());
  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('refreshToken', refresh_token);
  localStorage.setItem('lifeTime', expires_in);
  localStorage.setItem('setIn', `${Date.now()}`);
  localStorage.setItem('isAuth', 'true');
};

export const getLocalData = () => ({
  isAuth: localStorage.getItem('isAuth'),
  isUserAdult: localStorage.getItem('isUserAdult'),
  activeOrder: localStorage.getItem('activeOrder'),
  pendingOrder: localStorage.getItem('pendingOrder'),
  firstName: localStorage.getItem('firstName'),
  lastName: localStorage.getItem('lastName'),
  role: localStorage.getItem('role'),
  deliveryZoneId: cookieControlledStorage.getItem('deliveryZoneId'),
});
/*
 * Tokens *********************************************************************
 */
export const setNewAccessToken = ({ access_token, expires_in }) => {
  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('lifeTime', expires_in);
  localStorage.setItem('setIn', `${Date.now()}`);
};

export const getAccessToken = () => localStorage.getItem('accessToken');
export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const isUserAdult = () => localStorage.getItem('isUserAdult');
export const isAuth = () => localStorage.getItem('isAuth');
export const role = () => localStorage.getItem('role');

export const getUserInfo = () => ({
  firstName: localStorage.getItem('firstName'),
  lastName: localStorage.getItem('lastName'),
  role: localStorage.getItem('role'),
});

export const isAccessTokenAlive = () => {
  const lifeTime = localStorage.getItem('lifeTime');
  const setIn = localStorage.getItem('setIn');
  // lifeTime * 1000 because we need to convert seconds to milliseconds
  return Date.now() < Number(setIn) + Number(lifeTime) * 1000 - 10000;
};

export const deleteUserData = () => {
  localStorage.removeItem('isAuth');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('role');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('lifeTime');
  localStorage.removeItem('setIn');
  localStorage.removeItem('deliveryZoneId');
};

export const getPostalCode = ({ address_components }) =>
  get(
    address_components.find(component =>
      component.types.includes('postal_code'),
    ),
    'long_name',
  );

const getReasonText = (field, reasons) => {
  if (field[0] === 'otherReason') return field[1];
  return field[1] ? `${reasons[`${field[0]}`]}, ` : ''; // if checkbox is checked return text
};

export const getRefuseReason = (values, reasons) =>
  Object.entries(values).reduce(
    (prev, field) => `${prev}${getReasonText(field, reasons)}`,
    '',
  );

export const setErrorToStore = (error, dispatch) => {
  if (typeof error === 'string') {
    dispatch(actions.setError.act(error));
  }
  if (typeof error === 'object') {
    const err = get(error, ['response', 'data', 'errorMsg'], ['Unknown error']);
    dispatch(actions.setError.act(err.join(', ')));
  }
};
