import { mixed, string } from 'yup';
import runValidations from 'yup/lib/util/runValidations';
import { inherits } from 'util';

export function mapSchema(keySchema, valueSchema) {
  if (!(this instanceof mapSchema))
    return new mapSchema(keySchema, valueSchema);

  mixed.call(this, { type: 'map' });

  this.key = keySchema || string();
  this.value = valueSchema || mixed();
}

inherits(mapSchema, mixed);

Object.assign(mapSchema.prototype, {
  _typeCheck(value) {
    return value && typeof value === 'object';
  },

  _cast(_value, options) {
    let value = mixed.prototype._cast.call(this, _value, options);

    const result = {};
    Object.entries(value).forEach(([key, value]) => {
      result[this.key.cast(key)] = this.value.cast(value);
    });

    return result;
  },

  _validate(_value, options = {}) {
    let errors = [];
    let { abortEarly, sync, path = '' } = options;

    let originalValue =
      options.originalValue != null ? options.originalValue : _value;

    let promise = mixed.prototype._validate.call(this, _value, options);

    if (!abortEarly) {
      promise = promise.catch(err => {
        errors.push(err);
        return err.value;
      });
    }

    return promise.then(value => {
      if (!this._typeCheck(value)) {
        if (errors.length) throw errors[0];
        return value;
      }

      originalValue = originalValue || value;

      let validations = [];
      Object.entries(value).forEach(([field, fieldValue]) => {
        let innerOptions = {
          ...options,
          strict: true,
          parent: value,
          path: path ? `${path}.${field}` : field,
          originalValue: originalValue[field],
        };

        validations.push(
          this.key.validate(field, innerOptions),
          this.value.validate(fieldValue, innerOptions),
        );
      });

      return runValidations({
        sync,
        path,
        value,
        errors,
        validations,
        endEarly: abortEarly,
      });
    });
  },
});
