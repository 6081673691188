const styles = theme => ({
  root: {
    '&>div': {
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
});

export default styles;
