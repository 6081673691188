import { button } from 'ui/styles/Commons/button';
import { media } from 'ui/styles/theme';

export const refuseOrder = {
  button: {
    ...button.yellow,
    backgroundColor: 'black',
    borderColor: 'black',
    [media.mobile]: {
      width: '100%',
      margin: '1rem 0',
    },
    [media.desktop]: {
      ...button.yellow[media.desktop],
      margin: '2rem 0 2rem 1.5rem',
      width: '13rem',
    },
  },
};
