import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';
import { setErrorToStore } from 'services/utils';

const prepUserDataForUpdate = (state, addressId) => {
  const customer = state.getIn(['app', 'selectedCustomer']);
  const customerAddresses = customer.customerAddresses
    .filter(address => address.get('id') !== addressId)
    .toJS();
  return {
    ...customer,
    customerAddresses,
  };
};

export const adminDeleteAddress = addressId => async (dispatch, getState) => {
  const state = getState();
  const data = prepUserDataForUpdate(state, addressId);
  const customerId = state.getIn(['app', 'selectedCustomer', 'id']);
  try {
    await api.editCustomer(customerId, data);
    const response = await api.getCustomerById(customerId);
    dispatch(actions.setCustomer.act({ ...response.data, id: customerId }));
  } catch (e) {
    setErrorToStore(e, dispatch);
  }
};
