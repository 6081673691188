import { createSelector } from 'reselect';

const handler = (orderList, items) =>
  orderList
    .map(id => ({
      productId: items.getIn([id, 'id']),
      quantity: items.getIn([id, 'currentQuantity']),
    }))
    .toJS();

export const getOrderListForApi = createSelector(
  state => state.getIn(['app', 'orderList']),
  state => state.getIn(['app', 'products', 'items']),
  (orderList, items) => handler(orderList, items),
);
