import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';

import styles from '../../../../styles/Admin/commons/nameCell';
import { InfoIconWithTooltip } from './InfoIconWithTooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';

export const _NameCell = ({
  blocked,
  suspend,
  reason,
  name,
  classes,
  isNew,
  isOnline,
  onlineTooltipText
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.iconBlock}>
        {isNew && <span className={classes.new}>New</span>}
        {reason && <InfoIconWithTooltip tooltipMessage={reason} />}
      </div>

      <div
        className={
          reason || isNew ? classes.nameBlock : classes.nameBlockWithMargin
        }
      >
        <p className={classes.name}>{name}</p>
        {blocked && <p className={classes.status}>Blocked</p>}
        {suspend && <p className={classes.status}>Suspended</p>}
      </div>
      <div className={classes.onlineStatus}>
        {isOnline && (
          <Tooltip
            title={<Fragment>{onlineTooltipText}</Fragment>}
            placement="top"
            classes={{ popper: classes.popper, tooltip: classes.lightTooltip }}
          >
            <FiberManualRecordIcon className={classes.onlineIcon} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const NameCell = withStyles(styles)(_NameCell);
