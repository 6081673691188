import { acceptAgePolicy } from 'services/thunks/general/acceptAgePolicy';
import { detectDeliveryZone } from 'services/thunks/general/detectDeliveryZone';
import { setActiveDeliveryZone } from 'services/thunks/general/setActiveDeliveryZone';
import { getDeliveryZone } from 'services/thunks/general/getDeliveryZone';
import { handleDeliveryZoneChoice } from 'services/thunks/general/handleDeliveryZoneChoice';
import { sendSubscribe } from 'services/thunks/general/sendSubscribe';
import { thxForSubscribe } from 'services/thunks/general/thxForSubscribe';
import { signUpCustomer } from 'services/thunks/auth/signUpCustomer';
import { signUpDriver } from 'services/thunks/auth/signUpDriver';
import { login } from 'services/thunks/auth/login';
import { resetPassword } from 'services/thunks/auth/resetPassword';
import { setNewPassword } from 'services/thunks/auth/setNewPassword';
import { checkAuth } from 'services/thunks/auth/checkAuth';
import { logout } from 'services/thunks/auth/logout';
import { getCustomerProfile } from 'services/thunks/customer/getCustomerProfile';
import { editCustomerAddress } from 'services/thunks/customer/editCustomerAddress';
import { editCustomerInfo } from 'services/thunks/customer/editCustomerInfo';
import { addAddress } from 'services/thunks/customer/addAddress';
import { deleteAddress } from 'services/thunks/customer/deleteAddress';
import { getProducts } from 'services/thunks/customer/getProducts';
import { adminLogin } from 'services/thunks/admin/adminLogin';
import { loginDriver } from 'services/thunks/auth/loginDriver';
import { getInventory } from 'services/thunks/driver/getInventory';
import { updateInventory } from 'services/thunks/driver/updateInventory';
import { sendAnonymousOrder } from 'services/thunks/customer/sendAnonymousOrder';
import { sendCustomerOrder } from 'services/thunks/customer/sendCustomerOrder';
import { onEnterCustomerOrder } from 'services/thunks/customer/onEnterCustomerOrder';
import { sendVerificationCode } from 'services/thunks/customer/sendVerificationCode';
import { confirmOrder } from 'services/thunks/customer/confirmOrder';
import { getCustomerSingleOrder } from 'services/thunks/customer/getCustomerSingleOrder';
import { cancelOrder } from 'services/thunks/customer/cancelOrder';
import { changePassword } from 'services/thunks/auth/changePassword';
import { getActiveOrders } from 'services/thunks/driver/getActiveOrders';
import { acceptOrder } from 'services/thunks/driver/acceptOrder';
import { getDriverOrder } from 'services/thunks/driver/getDriverOrder';
import { getCompletedOrders } from 'services/thunks/driver/getCompletedOrders';
import { completeOrder } from 'services/thunks/driver/completeOrder';
import { cancelRateCustomer } from 'services/thunks/driver/cancelRateCustomer';
import { rateCustomer } from 'services/thunks/driver/rateCustomer';
import { resendLink } from 'services/thunks/customer/resendLink';
import { cancelRateDriver } from 'services/thunks/customer/cancelRateDriver';
import { rateDriver } from 'services/thunks/customer/rateDriver';
import { geocodeAddress } from 'services/thunks/general/geocodeAddress';
import { getDriverProfile } from 'services/thunks/driver/getDriverProfile';
import { changeDeliveryZone } from 'services/thunks/driver/changeDeliveryZone';
import { editDriverInfo } from 'services/thunks/driver/editDriverInfo';
import { refuseOrder } from 'services/thunks/driver/refuseOrder';
import { rejectOrder } from 'services/thunks/driver/rejectOrder';
import { createStripeClient } from 'services/thunks/driver/createStripeClient';
import { payDebt } from 'services/thunks/driver/payDebt';
import { getDeliveryZones } from 'services/thunks/admin/deliveryZone/getDeliveryZones';
import { getDriversList } from 'services/thunks/admin/driver/getDriversList';
import { getCustomersList } from 'services/thunks/admin/customer/getCustomersList';
import { addDriver } from 'services/thunks/admin/driver/addDriver';
import { addCustomer } from 'services/thunks/admin/customer/addCustomer';
import { addDeliveryZone } from 'services/thunks/admin/deliveryZone/addDeliveryZone';
import { getPostalCodes } from 'services/thunks/admin/deliveryZone/getPostalCodes';
import { deleteDriver } from 'services/thunks/admin/driver/deleteDriver';
import { deleteCustomer } from 'services/thunks/admin/customer/deleteCustomer';
import { deleteDeliveryZone } from 'services/thunks/admin/deliveryZone/deleteDeliveryZone';
import { blockCustomer } from 'services/thunks/admin/customer/blockCustomer';
import { blockDriver } from 'services/thunks/admin/driver/blockDriver';
import { suspendDriver } from 'services/thunks/admin/driver/suspendDriver';
import { activateDriver } from 'services/thunks/admin/driver/activateDriver';
import { editDeliveryZone } from './admin/deliveryZone/editDeliveryZone';
import { editCustomer } from './admin/customer/editCustomer';
import { editDriver } from './admin/driver/editDriver';
import { getCustomerById } from './admin/customer/getCustomerById';
import { getDeliveryZoneById } from './admin/deliveryZone/getDeliveryZoneById';
import { getDriverById } from './admin/driver/getDriverById';
import { getOrders } from './admin/order/getOrders';
import { adminCancelOrder } from 'services/thunks/admin/order/adminCancelOrder';
import { setDriverRating } from './admin/driver/setDriverRating';
import { setCustomerRating } from './admin/customer/setCustomerRating';
import { getTransactions } from './admin/transaction/getTransactions';
import { getProductsByAdmin } from 'services/thunks/admin/products/getProductsByAdmin';
import { validateOrder } from 'services/thunks/customer/validateOrder';
import { getPhone } from 'services/thunks/general/getPhone';
import { addProduct } from 'services/thunks/admin/products/addProduct';
import { getProductForEdit } from 'services/thunks/admin/products/getProductForEdit';
import { getProduct } from 'services/thunks/admin/products/getProduct';
import { deleteProduct } from 'services/thunks/admin/products/deleteProduct';
import { getSchedule } from 'services/thunks/admin/schedule/getSchedule';
import { editSchedule } from 'services/thunks/admin/schedule/editSchedule';
import { editProduct } from 'services/thunks/admin/products/editProduct';
import { removeActiveOrder } from 'services/thunks/customer/removeActiveOrder';
import { activateCustomer } from 'services/thunks/admin/customer/activateCustomer';
import { setLocalDataToStore } from 'services/thunks/auth/setLocalDataToStore';
import { adminDeleteAddress } from 'services/thunks/admin/customer/adminDeleteAddress';
import { adminAddAddress } from 'services/thunks/admin/customer/adminAddAddress';
import { adminEditAddress } from 'services/thunks/admin/customer/adminEditAddress';
import { checkOperationHours } from 'services/thunks/general/checkOperationHours';

export const thunks = {
  adminEditAddress,
  adminAddAddress,
  adminDeleteAddress,
  setLocalDataToStore,
  activateCustomer,
  removeActiveOrder,
  editProduct,
  editSchedule,
  getSchedule,
  deleteProduct,
  getProductForEdit,
  getProduct,
  addProduct,
  getPhone,
  validateOrder,
  getProductsByAdmin,
  payDebt,
  createStripeClient,
  rejectOrder,
  refuseOrder,
  editDriverInfo,
  changeDeliveryZone,
  getDriverProfile,
  geocodeAddress,
  rateDriver,
  cancelRateDriver,
  resendLink,
  rateCustomer,
  cancelRateCustomer,
  completeOrder,
  getCompletedOrders,
  getDriverOrder,
  acceptOrder,
  getActiveOrders,
  cancelOrder,
  getCustomerSingleOrder,
  confirmOrder,
  sendVerificationCode,
  adminLogin,
  onEnterCustomerOrder,
  sendCustomerOrder,
  sendAnonymousOrder,
  acceptAgePolicy,
  detectDeliveryZone,
  setActiveDeliveryZone,
  getDeliveryZone,
  handleDeliveryZoneChoice,
  sendSubscribe,
  thxForSubscribe,
  signUpCustomer,
  signUpDriver,
  login,
  resetPassword,
  setNewPassword,
  checkAuth,
  logout,
  getCustomerProfile,
  editCustomerAddress,
  editCustomerInfo,
  addAddress,
  deleteAddress,
  getProducts,
  loginDriver,
  getInventory,
  updateInventory,
  changePassword,
  getDeliveryZones,
  getDriversList,
  getCustomersList,
  addDriver,
  addCustomer,
  addDeliveryZone,
  getPostalCodes,
  deleteDriver,
  deleteCustomer,
  deleteDeliveryZone,
  blockCustomer,
  blockDriver,
  suspendDriver,
  activateDriver,
  editDeliveryZone,
  editCustomer,
  editDriver,
  getDriverById,
  getCustomerById,
  getDeliveryZoneById,
  getOrders,
  adminCancelOrder,
  setDriverRating,
  setCustomerRating,
  getTransactions,
  checkOperationHours,
};
