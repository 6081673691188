import { cookieControlledStorage } from '../../../utils';
import { actions } from '../../../store/actions';
import { checkOperationHours } from './checkOperationHours';
import { getDeliveryZoneHeader } from './getDeliveryZoneHeader';

export const setActiveDeliveryZone = id => async dispatch => {
  try {
    dispatch(actions.setActiveDeliveryZone.act(id));
    dispatch(actions.clearOrderList.act());
    cookieControlledStorage.setItem('deliveryZoneId', id);
    dispatch(checkOperationHours(id));
    dispatch(getDeliveryZoneHeader(id));
  } catch (e) {
    return e;
  }
};
