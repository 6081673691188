import axios from 'axios';
import { publicAPI } from 'services/api/apiUrl';

export const rateDriver = (orderId, rating, text) =>
  axios({
    method: 'post',
    baseURL: publicAPI,
    url: '/review/fordriver',
    data: {
      orderId,
      rating,
      text,
    },
  });
