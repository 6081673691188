const createAction = ({ action, handler }) => {
  const args = [...action];
  const type = args.shift();
  return {
    type,
    act: (...values) => {
      const a = { type };
      args.forEach((key, i) => (a[key] = values[i]));
      return a;
    },
    handler,
  };
};

export default createAction;
