import React, { useCallback } from 'react';

import 'react-phone-number-input/style.css';
import PhoneNumberInput, {
  formatPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { palette } from '../../../../styles/theme';

const useStyles = createUseStyles({
  wrapper: {
    border: 'none !important',
    padding: '0 !important',
    background: 'transparent !important',
    alignItems: 'stretch',
    '--PhoneInputCountrySelectArrow-color--focus': palette.yellow,
    '--PhoneInputCountryFlag-borderColor--focus': palette.yellow,
  },
});

export const PhoneInput = ({
  field: { name, value, onChange, onBlur },
  type,
  labelText,
  error,
  placeholder = '(905) 555-1212',
  $container, // for compatibility
  $label, // for compatibility
  $input, // for compatibility
  $error, // for compatibility
  classes = {
    container: $container,
    label: $label,
    input: $input,
    error: $error,
  },
}) => {
  const classesEx = useStyles();

  const handleOnBlur = useCallback(
    e => {
      // in case it's autofilled
      if (formatPhoneNumber(e.target.value)) {
        const phone = parsePhoneNumber(e.target.value);
        phone &&
          onChange({
            target: { value: phone.number, name },
          });
      }
      onBlur(e);
    },
    [name, onChange, onBlur],
  );

  const handleOnChange = useCallback(
    value =>
      onChange({
        target: { value, name },
      }),
    [name, onChange],
  );

  return (
    <div className={classes.container}>
      {labelText && (
        <label className={classes.label} htmlFor={name}>
          {labelText}
          {<span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
        </label>
      )}
      <PhoneNumberInput
        id={name}
        className={clsx(classes.input, classesEx.wrapper)}
        numberInputProps={{ className: classes.input }}
        placeholder={placeholder}
        defaultCountry="CA"
        international
        withCountryCallingCode
        countryCallingCodeEditable={false}
        autoComplete="tel"
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      {error && <span className={classes.error}>{error}</span>}
    </div>
  );
};
