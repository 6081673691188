import { fromJS } from 'immutable';
import createAction from '../../../utils/redux-helpers/createAction';

const handler = (state, { data }) =>
  state.set('addresses', fromJS(data)).set('activeAddress', data[0].id);

export const setCustomerAddresses = createAction({
  action: ['setCustomerAddresses', 'data'],
  handler,
});
