import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { constants } from '../../../constants';
import { actions } from '../../../../store/actions';
import { thunks } from '../../../../services/thunks';
import { Dashboard } from './Dashboard';
import { PageTitle } from '../../atoms/Commons/Admin/PageTitle';
import { AddButton } from '../../atoms/Commons/Admin/AddButton';
import { PageSearch } from '../../molecules/Commons/Admin/PageSearch';
import { PageRow } from '../../atoms/Commons/Admin/PageRow';
import { BaseTable } from '../../ecosystems/Admin/BaseTable';
import CustomerRow from '../../organisms/Admin/Customer/CustomerRow';
import { AddCustomer } from '../../organisms/Admin/Customer/AddCustomer';
import { getTableData } from '../../../../selectors/getTableData';

// @TODO add snackbar

const mapStateToProps = state => ({
  customers: getTableData(state, ['app', 'tables', 'customers', 'items']),
  isOpenModal: state.getIn(['app', 'modals', 'addCustomer']),
  totalRecords: state.getIn(['app', 'tables', 'customers', 'totalRecords']),
  needToUpdateData: state.getIn(['app', 'tables', 'needToUpdateData']),
});

const mapDispatchToProps = dispatch => ({
  getCustomers: ({ ...data }) => dispatch(thunks.getCustomersList(data)),
  openModal: () => dispatch(actions.openModal.act('addCustomer')),
  closeModal: () => dispatch(actions.closeModal.act('addCustomer')),
  addCustomer: ({ ...data }) => dispatch(thunks.addCustomer(data)),
});

class _AdminCustomers extends Component {
  state = {
    keyWord: '',
    order: 'asc',
    sortColumn: 'name',
    pageSize: 10,
    pageIndex: 1,
  };

  handleChangeSearch = e => {
    this.setState({
      keyWord: e.target.value,
      pageIndex: 1,
    });
  };

  getData = () => {
    this.props.getCustomers({
      page: this.state.pageIndex,
      pageSize: this.state.pageSize,
      orderBy: this.state.sortColumn,
      orderDirection: this.state.order,
      search: this.state.keyWord,
    });
  };

  onChangePage = (data, value) => {
    this.setState({ pageIndex: value + 1 });
  };

  onChangeRowsPerPage = data => {
    this.setState({ pageSize: data.target.value });
  };

  onHandleRequestSort = (e, columnName) => {
    this.setState(prevState => ({
      order: prevState.order === 'asc' ? 'desc' : 'asc',
      sortColumn: columnName,
    }));
  };

  addCustomer = data => {
    this.props.addCustomer(data);
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.order !== this.state.order ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.pageIndex !== this.state.pageIndex ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.keyWord !== this.state.keyWord ||
      (prevProps.needToUpdateData !== this.props.needToUpdateData &&
        this.props.needToUpdateData)
    ) {
      this.getData();
    }
  }

  render() {
    const { keyWord, order, sortColumn, pageIndex, pageSize } = this.state;
    const {
      t,
      openModal,
      isOpenModal,
      closeModal,
      totalRecords,
      customers,
    } = this.props;

    return (
      <Dashboard>
        <PageRow>
          <PageTitle title={t('Customers')}>
            <AddButton text={t('Add New Customer')} handleClick={openModal} />
          </PageTitle>
        </PageRow>

        <PageRow>
          <PageSearch
            handleSearch={this.handleChangeSearch}
            onChange={this.handleChangeSearch}
            value={keyWord}
          />
        </PageRow>

        <BaseTable
          order={order}
          sortColumn={sortColumn}
          pageSize={pageSize}
          pageIndex={pageIndex}
          data={customers}
          customName="fullname"
          totalRecords={totalRecords}
          Row={CustomerRow}
          columnsData={constants.customerTableColumns}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onHandleRequestSort={this.onHandleRequestSort}
        />
        {isOpenModal && (
          <AddCustomer
            addCustomer={this.addCustomer}
            isOpenModal={isOpenModal}
            closeModal={closeModal}
          />
        )}
      </Dashboard>
    );
  }
}

export const AdminCustomers = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces('admin'),
)(_AdminCustomers);
