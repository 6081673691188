import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import injectSheet from 'react-jss';
import { uploadImage } from 'ui/styles/Commons/uploadImage';

class Thumb extends React.Component {
  state = {
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(nextProps.file);
    reader.onloadend = () => {
      this.setState({ thumb: reader.result });
    };
  }

  render() {
    const { file, className } = this.props;
    const { thumb } = this.state;
    if (!file) {
      return null;
    }
    return (
      <img
        src={thumb}
        alt={file.name}
        className={className}
        height={200}
        width={200}
      />
    );
  }
}

class _UploadImage extends React.PureComponent {
  onChange = e => {
    const { form, field } = this.props;
    form.setFieldValue(field.name, e.currentTarget.files[0]);
    form.setFieldTouched(field.name, true);
  };

  clearSelected = () => {
    const { form, field } = this.props;
    form.setFieldValue(field.name, null);
    this.input.value = '';
  };

  render() {
    const {
      form: { errors, touched },
      field: { name, value, onBlur },
      classes,
    } = this.props;
    const error = touched[name] && errors[name];
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <label htmlFor={name} className={classes.label}>
            <input
              type="file"
              ref={instance => (this.input = instance)}
              id={name}
              className={classes.input}
              onChange={this.onChange}
              onBlur={onBlur}
            />
            <Button
              variant="contained"
              color="default"
              component="span"
              className={classes.upload}
            >
              Upload Image
              <CloudUploadIcon className={classes.icon} />
            </Button>
          </label>
          {value && (
            <Button
              variant="contained"
              color="default"
              component="span"
              className={classes.delete}
              onClick={this.clearSelected}
            >
              Delete
              <DeleteIcon className={classes.icon} />
            </Button>
          )}
        </div>
        {typeof value === 'string' ? (
          <img src={value} className={classes.image} alt="for product" />
        ) : (
          <Thumb file={value} className={classes.image} />
        )}

        {error && <span className={classes.error}>{error}</span>}
      </div>
    );
  }
}

export const UploadImage = injectSheet(uploadImage)(_UploadImage);
