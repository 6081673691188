import { media } from '../../theme';

export const signUp = {
  container: {
    width: '100vw',
    display: 'grid',
    gridTemplateColumns: 'auto 950px auto',
    gridTemplateRows: '3rem auto',
    gridTemplateAreas: `
      '. . .'
      '. a .'
    `,
    [media.mobile]: {
      gridTemplateColumns: '5% 90% 5%',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
        '. a .'
      `,
    },
  },
};
