import React from 'react';

import { FormControl, Input, InputLabel } from '@material-ui/core';

export const InputField = ({
  margin,
  required,
  fullWidth,
  disabled,
  field: { name, value, onChange, onBlur },
  type = 'text',
  labelText,
  error,
  placeholder,
  shrink,
  autoFocus,
  multiline,
  rows,
  rowsMax,
  classes = {},
}) => (
  <FormControl
    margin={margin}
    required={required}
    fullWidth={fullWidth}
    disabled={disabled}
  >
    {labelText && (
      <InputLabel htmlFor={name} shrink={shrink}>
        {labelText}
      </InputLabel>
    )}
    <Input
      id={name}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      autoFocus={autoFocus}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      className={classes.input}
    />
    {error && (
      <span className={classes.error} style={{ color: 'red' }}>
        {error}
      </span>
    )}
  </FormControl>
);

InputField.defaultProps = {
  margin: 'normal',
  required: false,
  fullWidth: true,
  disabled: false,
  autoFocus: false,
  multiline: false,
  rows: 1,
  rowsMax: 10,
};
