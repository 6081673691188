import * as Yup from 'yup';

const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const uploadImage = Yup.mixed()
  .required('Image is required')
  .test('fileSize', 'Image size should be less than 1 MB', value => {
    if (value && typeof value === 'object') return value.size <= FILE_SIZE;
    return true;
  })
  .test(
    'fileFormat',
    'The image should be one of the following formats : jpg, jpeg, gif, png',
    value =>
      value && typeof value === 'object'
        ? SUPPORTED_FORMATS.includes(value.type)
        : true,
  );
