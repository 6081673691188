import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const deleteCustomer = id => async dispatch => {
  try {
    await api.deleteCustomer(id);
    dispatch(actions.deleteCustomer.act(id));
    dispatch(actions.needToUpdateData.act(true));
  } catch (e) {
    return e;
  }
};
