import React from 'react';
import { SpiritProduct } from 'ui/components/organisms/Customer/Products/SpiritProduct';
import { ProductWithDropdown } from 'ui/components/organisms/Customer/Products/ProductWithDropdown';
import injectSheet from 'react-jss';
import { spirits } from '../../../../styles/Customer/CustomerHome/spirits';


const _Category = ({ classes, category, items, setProductQuantity }) => (
  <div id={category.get('name').toLowerCase()}>
  <div className={classes.container}>
    <h3>{category.get('name')}</h3>
    <div className={classes.products}>
      {category
        .get('itemList')
        .map(productId =>
          items.getIn([productId, 'isFixedPrice']) ? (
            <SpiritProduct
              key={productId}
              data={items.get(productId)}
              id={productId}
              setProductQuantity={setProductQuantity}
            />
          ) : (
            <ProductWithDropdown key={productId} id={productId} />
          ),
        )}
    </div>
  </div>
  </div>
);

export const Category = injectSheet(spirits)(_Category);
