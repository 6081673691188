import { api } from '../../../api/index';
import { actions } from '../../../../store/actions/index';

export const getDeliveryZones = ({ ...data }) => async dispatch => {
  try {
    const response = await api.getDeliveryZones(data);
    dispatch(actions.setDeliveryZones.act(response.data));
    dispatch(actions.needToUpdateData.act(false));
  } catch (e) {
    return e;
  }
};
